import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Pagination from '@material-ui/lab/Pagination';
import React, { Fragment, useState } from "react";
import { Badge, Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// css
import '../../common.css';
import './package.css';

// components
import Navbar from '../../navbar';
import TableTemplate from '../../table-template';

// sample data (temp)
import { Delay } from "react-delay";
import { addPackage, deletePackage, getAllPackages, getPackage, searchPackages, updatePackageDetails } from "../../../utilities/api_calls/packageApi";
import { getAllServices } from "../../../utilities/api_calls/servicesApi";
import { refreshPage } from "../../../utilities/common";
import NoDataPrompt from "../../../utilities/no-data-prompt";
import DeleteModal from '../../deleteModal';
import { useNavigate, useParams } from 'react-router-dom';

function Package() {
    let navigate = useNavigate();
    const { page } = useParams();
    const [showLoader, setShowLoader] = useState(true)
    const [click, setClick] = useState(false);

    // Pagination
    const [pages, setPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(parseInt(page));

    function handlePageChange(event, value) {
        // console.log(value)
        setCurrentPage(value)
    }

    //All Services
    const [services, setServices] = useState([]);

    //Get Services
    async function fetchServices() {
        const response = await getAllServices();
        // console.log(response);
        setServices(response.data.data.services);
    }

    //Add Service Items
    const [addItemList, setAddItemList] = useState([
        {
            service: '',
            quantity: 1,
            isFree: '0',
        }]);

    function addAddItem() {
        const newList = [...addItemList,
        {
            service: '',
            quantity: 1,
            isFree: '0',
        }];
        setAddItemList(newList);
        // console.log(newList)
    }

    const handleAddItemChange = (e, index) => {
        const { name, value, checked } = e.target;
        const list = [...addItemList];

        if (!checked) {
            list[index][name] = "0";

        }
        else if (checked) {
            list[index][name] = "1";
        }

        list[index][name] = value;

        setAddItemList(list);
    };

    function deleteAddItem(index) {
        const newList = [...addItemList];
        newList.splice(index, 1);
        setAddItemList(newList);
        // console.log(newList)
    }


    //All Packages
    const [packages, setPackages] = useState([]);

    //Get Packages
    async function fetchPackages() {
        setShowLoader(true)
        const response = await getAllPackages();
        setPages(Math.ceil(response.data.data.packages.length / 11));
        // console.log(response);
        setPackages(response.data.data.packages);
        setShowLoader(false)
    }

    //Search Packages
    async function searchThis(name) {
        if (name === "") {
            setPackages([])
            fetchPackages();
        } else {
            setPackages([]);
            const response = await searchPackages(name);
            // console.log(response);
            setPackages(response.data.data);
        }
    }

    // --- Add Package Popup/Modal --- //
    const [showAddPackage, setShowAddPackage] = useState(false);
    const handleCloseAddPackage = () => setShowAddPackage(false);
    const handleShowAddPackage = () => setShowAddPackage(true);

    // --- Add Package Hooks --- //
    const [newPackage, setNewPackage] = useState({
        name: "",
        details: "",
        price: "",
    });

    // -- Add Package Functions --- //
    const handleAddChange = (e) => {
        const { name, value } = e.target;
        setNewPackage(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    async function addNewPackage() {
        var isComplete = true;
        addItemList.map((item, index) => {
            if (item.service === "") {
                isComplete = false;
                toast.error("Please enter values for required inputs!")
            }
        })
        if (isComplete === true) {
            { <Delay wait={1000} /> }
            if (click === false &&
                newPackage.name !== "" &&
                newPackage.price !== "") { // must add service validity checker!
                setClick(true);
                const response = await addPackage(newPackage, addItemList);
                // console.log(response);
                if (response.data.data.status === 201) {

                    toast.success(response.data.data.message.success);
                    setTimeout(function () {
                        navigate("/package/" + currentPage);
                        refreshPage();
                    }, 2000);
                }
                if (response.data.status === 400) {
                    toast.error(response.data.message[0]);
                }
            } else {
                toast.error("Please enter values for required inputs!")
            }
        }
    }



    // --- Edit Package Popup/Modal --- //
    const [showEditPackage, setShowEditPackage] = useState(false);
    const handleCloseEditPackage = () => setShowEditPackage(false);
    const handleShowEditPackage = () => setShowEditPackage(true);
    const [selected, setSelected] = useState("");

    const [editPackage, setEditPackage] = useState({
        name: "",
        details: "",
        price: "",
    });

    // -- Edit Package Functions --- //
    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditPackage(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    //Edit Items List
    const [editItemList, setEditItemList] = useState([]);

    function addEditItem() {
        const newList = [...editItemList,
        {
            service_id: '',
            qty: 1,
            is_free: '0',
        }];
        setEditItemList(newList);
        // console.log(newList)
    }

    const handleEditItemChange = (e, index) => {
        const { name, value, checked } = e.target;
        const list = [...editItemList];
        
        if(name === "is_free") {
            if(!checked) {
                list[index][name] = "0";
            } else if(checked) {
                list[index][name] = "1";
            }
        } else if (name === "qty" && value === "0") {
            list[index][name] = 1;

        } else {
            list[index][name] = value;
        }

        setEditItemList(list);
    };

    async function editPackageDetail() {
        var isComplete = true;
        editItemList.map((item, index) => {
            if (item.service_id === "") {
                isComplete = false;
                toast.error("Please enter values for required inputs!")
            }
        })
        if (isComplete === true) {
            { <Delay wait={1000} /> }
            if (editPackage.name !== "" &&
                editPackage.price !== "") {
                // console.log(editItemList)
                const response = await updatePackageDetails(editPackage, editItemList, selected);
                // console.log(response);
                if (response.data.data.status === 200) {
                    toast.success(response.data.data.message.success);
                    setTimeout(function () {
                        navigate("/package/" + currentPage);
                        refreshPage();
                    }, 2000);
                }
                if (response.data.status === 400) {
                    toast.error(response.data.message[0]);
                }
            } else {
                toast.error("Please enter values for required inputs!")
            }
        }
    }

    function deleteEditItem(index) {
        const newList = [...editItemList];
        newList.splice(index, 1);
        setEditItemList(newList);
        // console.log(newList)
    }

    function onclickEditPackage(id) {
        handleShowEditPackage()
        setSelected(id);
        viewPackage(id);
    }

    // --- Delete Package Prompt/Modal --- //
    const [showDeletePackage, setShowDeletePackage] = useState(false);
    const handleCloseDeletePackage = () => setShowDeletePackage(false);
    const handleShowDeletePackage = () => setShowDeletePackage(true);

    function onclickDeletePackage(id) {
        handleShowDeletePackage()
        setSelected(id);
    }

    async function delPackage() {
        const response = await deletePackage(selected);
        // console.log(response);
        if (response.data.data.status === 200) {
            toast.success("Package Deleted Successfully!");
            setTimeout(function () {
                navigate("/package/" + currentPage);
                refreshPage();
            }, 2000);
        }
        if (response.data.status === 400) {
            toast.error(response.data.message[0]);
        }
    }

    // --- vIRE Package Prompt/Modal --- //
    const [showViewPackage, setShowViewPackage] = useState(false);
    const handleCloseViewPackage = () => setShowViewPackage(false);
    const handleShowViewPackage = () => setShowViewPackage(true);

    // --- View Package Hooks --- //
    const [packageDetail, setPackageDetail] = useState({
        name: "",
        price: "",
        services: "",
        remarks: ""
    });

    async function viewPackage(id) {
        const response = await getPackage(id);
        // console.log(response)
        setPackageDetail({
            name: response.data.data[0].PACKAGE,
            details: response.data.data[0].DETAILS,
            price: parseFloat(response.data.data[0].PRICE).toFixed(2),
            services: response.data.data,
            remarks: response.data.data[0].REMARKS
        });

        var service_items = [];
        // eslint-disable-next-line
        response.data.data.map((data, index) => {
            // console.log(data)
            var info = {};
            info.service_id = data.SERVICE_ID;
            info.is_free = data.IS_FREE;
            info.name = data.PACKAGE;
            info.qty = data.QUANTITY;

            service_items.push(info);

        });

        setEditPackage({
            name: response.data.data[0].PACKAGE,
            details: response.data.data[0].DETAILS,
            price: parseFloat(response.data.data[0].PRICE).toFixed(2),
            services: service_items,
        });

        setEditItemList(service_items);
    }

    function onclickViewPackage(id) {
        handleShowViewPackage();
        viewPackage(id);

    }

    React.useEffect(() => {
        fetchPackages();
        fetchServices();
    }, []);

    // console.log(editItemList)

    // --- Render --- //

    return (
        <>
            <div>
                <Navbar />
                <div className="active-cont">
                    <Fragment>
                        {/* <ToastContainer /> */}
                        <div className="d-flex flex-row justify-content-between cont-section">
                            <h1 className="cont-sect-header">Packages</h1>
                            <div className="search-wrapper w-50">
                                <input type="search" class="form-control search-indent" placeholder="Search by Package Name" onChange={(e) => searchThis(e.target.value)} />
                                <div className="search-icon">
                                    <FontAwesomeIcon
                                        className="icon"
                                        icon={'search'}
                                        aria-hidden="true"
                                        alt={'search'}
                                        fixedWidth
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="cont-section-novertpad">
                            <div className="d-flex flex-row justify-content-between">
                                <div className="btn-conf-wrapper ms-auto mb-2">
                                    <Button type="button" className="px-5 py-1" onClick={handleShowAddPackage}>add Package</Button>
                                </div>
                            </div>
                        </div>

                        {showLoader ?
                            <ReactLoading type="spinningBubbles" color="#d4271c" height={200} width={100} className='list-loader' />
                            : <div className="cont-section-novertpad package-table">
                                {packages.length !== 0 ? <>
                                    <TableTemplate
                                        tableType='package'
                                        tableHeaders={["Action", "Package", "Price", ""]}
                                        tableData={packages.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))}
                                        tableFuncs={[onclickDeletePackage, onclickEditPackage, onclickViewPackage]}
                                        currentPage={currentPage}
                                    />
                                    <Pagination count={pages} page={currentPage} shape="rounded" defaultPage={1} siblingCount={2} onChange={handlePageChange} showFirstButton showLastButton />
                                </> : <NoDataPrompt text="packages" />
                                    // <span className="no-data-prompt">No Packages Found!</span>
                                }
                            </div>}

                    </Fragment>

                    {/* ADD Package MODAL*/}
                    <Modal show={showAddPackage} onHide={handleCloseAddPackage} size="lg" className="custom-modal-style" centered>
                        <Form>
                            <Modal.Header className="custom-modal-header d-flex justify-content-end" closeButton></Modal.Header>
                            <Modal.Body className="px-5 py-1">
                                <h1 className="custom-modal-body-title">Add Package</h1>
                                <Container>
                                    <Row className="nc-modal-custom-row">
                                        <Col>Name</Col>
                                        <Col xs={1}>:</Col>
                                        <Col xs={7}>
                                            <Form.Control type="text" name="name" placeholder="Enter Package name" className="nc-modal-custom-input" onChange={(e) => handleAddChange(e)} required />
                                            {newPackage.name === "" && (
                                                <div className="validity-error">
                                                    Required input*
                                                </div>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row className="nc-modal-custom-row">
                                        <Col>Details</Col>
                                        <Col xs={1}>:</Col>
                                        <Col xs={7}><Form.Control type="text" name="details" placeholder="Enter Package details" className="nc-modal-custom-input" onChange={(e) => handleAddChange(e)} /></Col>
                                    </Row>
                                    <Row className="nc-modal-custom-row">
                                        <Col>Price</Col>
                                        <Col xs={1}>:</Col>
                                        <Col xs={7}>
                                            <Form.Control type="number" min="0.01" step="0.01" name="price" placeholder="Enter price" className="nc-modal-custom-input" onChange={(e) => handleAddChange(e)} required />
                                            {newPackage.price === "" && (
                                                <div className="validity-error">
                                                    Required input*
                                                </div>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row className="nc-modal-custom-row">
                                        <Col className="fw-bold pb-2">Services</Col>
                                        {addItemList.map((item, index) => {
                                            return (
                                                <Row className="mb-2">
                                                    <Col xs={1} className="d-flex justify-content-center">

                                                        <Row>
                                                            <span className="free-label">Free</span>
                                                            <Form.Check
                                                                name="isFree"
                                                                value={1}
                                                                type="switch"
                                                                id="custom-switch"
                                                                className="custom-control-input"
                                                                onChange={(e) => handleAddItemChange(e, index)}
                                                            />
                                                        </Row>

                                                    </Col>
                                                    <Col xs={7}>
                                                        <Form.Select key={item.id} className="nc-modal-custom-input" name="service" value={addItemList[index].service} onChange={(e) => handleAddItemChange(e, index)} required >
                                                            <option value="" disabled hidden>--SELECT--</option>
                                                            {
                                                                services.map((service) => {
                                                                    return (<option key={service.id} value={service.id}>{service.name}</option>)
                                                                })
                                                            }
                                                        </Form.Select>
                                                    </Col>
                                                    <Col><Form.Control key={item.id} type="number" name="quantity" placeholder="Enter quantity" className="nc-modal-custom-input" value={addItemList[index].quantity} onChange={(e) => handleAddItemChange(e, index)} /></Col>
                                                    <Col xs={1}><FontAwesomeIcon role="button" className="align-self-center mx-1" icon={'trash'} onClick={() => deleteAddItem(index)} /></Col>
                                                    {item.service === "" && (
                                                        <div className="validity-error">
                                                            Select a service*
                                                        </div>)
                                                    }
                                                </Row>
                                            )
                                        })}
                                        <Col className="fw-bold pt-2">
                                            <div className="btn-conf-wrapper">
                                                <Button type="button" className="" onClick={addAddItem}>add service</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </Modal.Body>
                            <Modal.Footer className="border-0 px-5">
                                <div className="d-flex flex-row justify-content-between">
                                    {click === true && (
                                        <div className="d-flex justify-content-center">
                                            <div className="btn-conf-wrapper ms-auto">
                                                <Button type="submit" className="px-5 pb-1 mb-2">
                                                    <ReactLoading type="spinningBubbles" color="#d4271c" height={25} width={20} />
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                    {click === false && (
                                        <div className="btn-conf-wrapper ms-auto">
                                            <Button type="submit" className="px-5 pb-1 mb-2" onClick={() => addNewPackage()}>save</Button>
                                        </div>
                                    )}
                                </div>
                            </Modal.Footer>
                        </Form>
                    </Modal>

                    {/* EDIT Package MODAL*/}
                    <Modal show={showEditPackage} onHide={handleCloseEditPackage} size="lg" className="custom-modal-style" centered>
                        <Modal.Header className="custom-modal-header d-flex justify-content-end" closeButton></Modal.Header>
                        <Modal.Body className="px-5 py-1">
                            <h1 className="custom-modal-body-title">Edit Package</h1>
                            <Container>
                                <Row className="nc-modal-custom-row">
                                    <Col>Name</Col>
                                    <Col xs={1}>:</Col>
                                    <Col xs={7}>
                                        <Form.Control type="text" name="name" placeholder="Enter Package name" className="nc-modal-custom-input" value={editPackage.name} onChange={(e) => handleEditChange(e)} required />
                                        {editPackage.name === "" && (
                                            <div className="validity-error">
                                                Required input*
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                                <Row className="nc-modal-custom-row">
                                    <Col>Details</Col>
                                    <Col xs={1}>:</Col>
                                    <Col xs={7}><Form.Control type="text" name="details" placeholder="Enter Package details" className="nc-modal-custom-input" value={editPackage.details} onChange={(e) => handleEditChange(e)} /></Col>
                                </Row>
                                <Row className="nc-modal-custom-row">
                                    <Col>Price</Col>
                                    <Col xs={1}>:</Col>
                                    <Col xs={7}>
                                        <Form.Control type="number" min="0.01" step="0.01" name="price" placeholder="Enter price" className="nc-modal-custom-input" value={editPackage.price} onChange={(e) => handleEditChange(e)} required />
                                        {editPackage.price === "" && (
                                            <div className="validity-error">
                                                Required input*
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                                <Row className="nc-modal-custom-row">
                                    <Col className="fw-bold pb-2">Services Needed</Col>
                                    {editItemList.map((item, index) => {
                                        return (
                                            <Row className="mb-2">
                                                <Col xs={1} className="d-flex justify-content-center">

                                                    <Row>
                                                        <span className="free-label">Free</span>
                                                        <Form.Check
                                                            name="is_free"
                                                            value={1}
                                                            type="switch"
                                                            id="custom-switch"
                                                            className="custom-control-input"
                                                            defaultChecked={item.is_free === "1" ? true : false}
                                                            onChange={(e) => handleEditItemChange(e, index)}
                                                        />
                                                    </Row>

                                                </Col>
                                                <Col xs={7}>
                                                    <Form.Select key={item.id} className="nc-modal-custom-input" name="service_id" defaultValue={item.service_id} onChange={(e) => handleEditItemChange(e, index)}>
                                                        <option hidden>--SELECT--</option>
                                                        {
                                                            services.map((service) => {
                                                                return (<option key={service.id} value={service.id}>{service.name}</option>)
                                                            })
                                                        }
                                                    </Form.Select>
                                                </Col>
                                                <Col><Form.Control key={item.id} type="number" name="qty" placeholder="Enter quantity" className="nc-modal-custom-input" defaultValue={item.qty} onChange={(e) => handleEditItemChange(e, index)} /></Col>
                                                <Col xs={1}><FontAwesomeIcon role="button" className="align-self-center mx-1" icon={'trash'} onClick={() => deleteEditItem(index)} /></Col>
                                                {item.service_id === "" && (
                                                    <div className="validity-error">
                                                        Select a service*
                                                    </div>
                                                )}
                                            </Row>
                                        )
                                    })}
                                    <Col className="fw-bold pt-2">
                                        <div className="btn-conf-wrapper">
                                            <Button type="button" className="" onClick={addEditItem}>add service</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Modal.Body>
                        <Modal.Footer className="border-0 px-5">
                            <div className="d-flex flex-row justify-content-between">
                                <div className="btn-conf-wrapper ms-auto">
                                    <Button type="submit" className="px-5 pb-1 mb-2" onClick={() => editPackageDetail()}>save</Button>
                                </div>
                            </div>
                        </Modal.Footer>
                    </Modal>

                    <DeleteModal show={showDeletePackage} hide={handleCloseDeletePackage} text="package" handler={() => delPackage()} />

                    {/* Package DETAILS MODAL*/}
                    <Modal show={showViewPackage} onHide={handleCloseViewPackage} size="lg" className="custom-modal-style" centered>
                        <Modal.Header className="custom-modal-header d-flex justify-content-end" closeButton></Modal.Header>
                        <Modal.Body className="px-5 py-1">
                            <Container>
                                <Row className="modal-cont">
                                    <Col>
                                        <h1 className="text-center service-details-header">Package Details</h1>
                                    </Col>
                                    <Col className="details-cont">
                                        <Row className="nc-modal-custom-row ">
                                            PACKAGE NAME
                                        </Row>
                                        <Row className="nc-modal-custom-row modal-detail">
                                            {packageDetail.name}
                                        </Row>
                                        <Row className="nc-modal-custom-row modal-detail-small">
                                            Services offered: {packageDetail.services.length}
                                        </Row>
                                        <Row className="nc-modal-custom-row">
                                            PRICE
                                        </Row>
                                        <Row className="nc-modal-custom-row modal-detail">
                                            {packageDetail.price}
                                        </Row>
                                        <Row className="nc-modal-custom-row">
                                            SERVICES
                                        </Row>
                                        <Row className="nc-modal-custom-row modal-detail">

                                            {packageDetail.services && (
                                                packageDetail.services.map((data) => {
                                                    return (
                                                        <li className='modal-detail-list'>{data.QUANTITY} - {data.SERVICE} {(data.IS_FREE) === 1 && <span><Badge bg="danger">FREE</Badge></span>}</li>
                                                    )
                                                }))}

                                        </Row>
                                        <Row className="nc-modal-custom-row">
                                            REMARKS
                                        </Row>
                                        <Row className="nc-modal-custom-row modal-detail">
                                            {packageDetail.details}
                                        </Row>
                                    </Col>
                                </Row>
                            </Container>
                        </Modal.Body>
                        <Modal.Footer className="border-0 px-5">
                            <div className="d-flex flex-row justify-content-between">
                                {/* <div className="btn-conf-wrapper ms-auto">
                            <Button type="button" onClick={handleCloseViewPackage} className="px-5 pb-1 mb-2">save</Button>
                        </div> */}
                            </div>
                        </Modal.Footer>
                    </Modal>

                </div>
            </div>
        </>
    )
}

export default Package;