import { formatMDY, getAge, getBranch, getTodayDate, getToken, getUser, tokenExpiry } from '../common';
import { getAPICall, postAPICall, putAPICall, deleteAPICall } from './axiosMethodCalls';

/***************************
 * EXPENSES
 ***************************/

//GET
export const getAllExpenses = async (filter, name) => {
    try {
        const response = await getAPICall(window.$link + 'expenses/getAll', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            branch: filter.branch,
            date_from: formatMDY(filter.date_from),
            date_to: formatMDY(filter.date_to),
            expense_name: name
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

//Get
export const searchExpenses = async (name, filter) => {
    try {
        const response = await getAPICall(window.$link + 'expenses/search', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            name: name,
            date_from: formatMDY(filter.date_from),
            date_to: formatMDY(filter.date_to),
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}


//GET
export const getExpense = async (id) => {
    try {
        const response = await getAPICall(window.$link + 'expenses/show/' + id, {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch(),
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

//POST
export const addExpense = async (expense) => {

    try {
        const response = await postAPICall(window.$link + 'expenses/create', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            name: expense.name,
            quantity: expense.quantity,
            price: expense.price,
            grand_total: expense.grand_total,
            expense_date: expense.expense_date,
            remarks: expense.remarks,
            added_by: expense.added_by
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

//POST
export const updateExpenseDetails = async (expense, id) => {
    try {
        const response = await postAPICall(window.$link + 'expenses/update/' + id, {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            updated_by: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            name: expense.name,
            quantity: expense.quantity,
            price: expense.price,
            grand_total: expense.grand_total,
            expense_date: expense.expense_date,
            remarks: expense.remarks,
            added_by: expense.added_by
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

//DELETE
export const deleteExpense = async (id) => {
    try {
        const response = await getAPICall(window.$link + 'expenses/delete/' + id, {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            updated_by: getUser(),
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

