import moment from 'moment';
import { getBranch, getToken, getUser, tokenExpiry, formatMDY } from '../../common';
import { getAPICall } from '../axiosMethodCalls';

/***************************
 * DAILY SERVICES PURCHASED
 ***************************/

//GET
export const getTotalServicesRendered = async (branch) => {
    try {
        const response = await getAPICall(window.$link + 'reports/services_rendered_grand_total', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            branch: branch,
            date_from: formatMDY(new Date()),
            date_to: formatMDY(new Date())
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

export const getServicesRenderedList = async (filter) => {
    try {
        const response = await getAPICall(window.$link + 'reports/get_services_rendered_list', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            branch: filter.branch,
            date_from: formatMDY(filter.date_from),
            date_to: formatMDY(filter.date_to),
            customer: filter.search,
            service: filter.service
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

export const searchServicesRendered = async (input) => {
    try {
        const response = await getAPICall(window.$link + 'reports/search_daily_services_rendered', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            input: input,
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}





