import { getBranch, getToken, getUser, tokenExpiry } from '../common';
import { getAPICall } from './axiosMethodCalls';

/***************************
 * BOOKINGS
 ***************************/

//POST
export const createBooking = async (type, item, id) => {
    // console.log(item)
    var ids = [];
    var qty = [];
    var commissionedBy = [];
    var buyType = "";
    // eslint-disable-next-line
    item.map((data) => {
        ids.push(data.item);
        qty.push(data.quantity);
        commissionedBy.push(data.commissioned_by)
    });
    
    if (type === 'product') {
        buyType = 1;

        try {
            const response = await getAPICall(window.$link + 'bookings/create/' + id, {
                api_key: window.$api_key,
                token: getToken().replace(/['"]+/g, ''),
                requester: getUser(),
                branch_id: getBranch().replace(/['"]+/g, ''),
                buy: buyType,
                item_ids: ids,
                item_qtys: qty,
                commissioned_by:commissionedBy,  
            });
            return ({ data: response });
        } catch (error) {
            tokenExpiry(error);
            return ({ error: error });
        }

    } else if (type === 'service') {
        buyType = 2;

        try {
            const response = await getAPICall(window.$link + 'bookings/create/' + id, {
                api_key: window.$api_key,
                token: getToken().replace(/['"]+/g, ''),
                requester: getUser(),
                branch_id: getBranch().replace(/['"]+/g, ''),
                buy: buyType,
                service_ids: ids,
                service_qtys: qty,
                commissioned_by:commissionedBy, 
               
            });
            return ({ data: response });
        } catch (error) {
            tokenExpiry(error);
            return ({ error: error });
        }

    } else if (type === 'package') {
        buyType = 3;

        try {
            const response = await getAPICall(window.$link + 'bookings/create/' + id, {
                api_key: window.$api_key,
                token: getToken().replace(/['"]+/g, ''),
                requester: getUser(),
                branch_id: getBranch().replace(/['"]+/g, ''),
                buy: buyType,
                package_ids: ids,
                package_qtys: qty,
            });
            return ({ data: response });
        } catch (error) {
            tokenExpiry(error);
            return ({ error: error });
        }

    } else if (type === 'gift certificate') {
        buyType = 4;

        try {
            const response = await getAPICall(window.$link + 'bookings/create/' + id, {
                api_key: window.$api_key,
                token: getToken().replace(/['"]+/g, ''),
                requester: getUser(),
                branch_id: getBranch().replace(/['"]+/g, ''),
                buy: buyType,
                gc_ids: ids,
                gc_qtys: qty,
            });
            return ({ data: response });
        } catch (error) {
            tokenExpiry(error);
            return ({ error: error });
        }

    }

}

//POST
export const removeItemPurchase = async (type, booking_id, id) => {

    if (type === 'item') {

        try {
            const response = await getAPICall(window.$link + 'bookings/remove_product/' + id, {
                api_key: window.$api_key,
                token: getToken().replace(/['"]+/g, ''),
                updated_by: getUser(),
                branch_id: getBranch().replace(/['"]+/g, ''),
                booking_id: booking_id
            });
            return ({ data: response });
        } catch (error) {
            tokenExpiry(error);
            return ({ error: error });
        }

    } else if (type === 'service') {

        try {
            const response = await getAPICall(window.$link + 'bookings/remove_service/' + id, {
                api_key: window.$api_key,
                token: getToken().replace(/['"]+/g, ''),
                updated_by: getUser(),
                branch_id: getBranch().replace(/['"]+/g, ''),
                booking_id: booking_id
            });
            return ({ data: response });
        } catch (error) {
            tokenExpiry(error);
            return ({ error: error });
        }

    } else if (type === 'package') {

        try {
            const response = await getAPICall(window.$link + 'bookings/remove_package/' + id, {
                api_key: window.$api_key,
                token: getToken().replace(/['"]+/g, ''),
                updated_by: getUser(),
                branch_id: getBranch().replace(/['"]+/g, ''),
                booking_id: booking_id
            });
            return ({ data: response });
        } catch (error) {
            tokenExpiry(error);
            return ({ error: error });
        }

    } else if (type === 'gift certificate') {

        try {
            const response = await getAPICall(window.$link + 'bookings/remove_gift_certificate/' + id, {
                api_key: window.$api_key,
                token: getToken().replace(/['"]+/g, ''),
                updated_by: getUser(),
                branch_id: getBranch().replace(/['"]+/g, ''),
                booking_id: booking_id
            });
            return ({ data: response });
        } catch (error) {
            tokenExpiry(error);
            return ({ error: error });
        }

    }

}





