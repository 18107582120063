import { formatMDY, getAge, getBranch, getToken, getUser, tokenExpiry } from '../common';
import { getAPICall, postAPICall } from './axiosMethodCalls';

/***************************
 * CUSTOMERS BALANCE
 ***************************/

export const getAllBalance = async () => {
    try {
        const response = await getAPICall(window.$link + 'customer_balances/getAll', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}