import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useState } from "react";
import { Button } from 'react-bootstrap';
import { Navigate, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAllCustomers, searchCustomers } from "../../../utilities/api_calls/customerApi";
import ReactLoading from 'react-loading';
import Pagination from '@material-ui/lab/Pagination';
import NoDataPrompt from '../../../utilities/no-data-prompt'

// css
import '../../common.css';
import './customer.css';

// components
import Navbar from '../../navbar';
import TableTemplate from '../../table-template';
import AddCustomer from './addCustomer';

function Customer() {
  const { page } = useParams();
  const [showLoader, setShowLoader] = useState(true);
  const mainPage = '/customer'

  // Pagination
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(parseInt(page));

  function handlePageChange(event, value) {
    // console.log(value)
    setCurrentPage(value)
  }

  //All Customers
  const [customers, setCustomers] = useState([]);
  const [selected, setSelected] = useState({});
  const [customerName, setCustomerName] = useState('');

  //Get Customers
  async function fetchCustomers() {
    setShowLoader(true);
    const response = await getAllCustomers(currentPage, customerName);
    setShowLoader(false)
    setCustomers([])
    // eslint-disable-next-line
    // setPages(Math.ceil(response.data.data.customers.length / 11));
    // console.log(pages)
    setPages(parseInt(response.data.data.size)/10)
    let result = response.data.data.customers.map((data) => { return {
        id: data.id,
        first_name: data.first_name,
        last_name: data.last_name,
        contact_no: data.contact_no,
        added_on: data.added_on,
    }})
     setCustomers(result);
  }

  //Search Customers
  async function searchThis(name) {
    setCustomers(customers => []);
    setPages(parseInt(1))
    if (name === "") {
      fetchCustomers();
      setPages(parseInt(1))
    }
    else {
      setCustomerName(name)
      const response = await searchCustomers(name);
      setCustomers(response.data.data);
      setPages(parseInt(response.data.data.length >= 10 ? response.data.data.length/10 : 1));
    }
  }

  // Add Customer Popup/Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Customer Navigations
  const [redirect, setRedirect] = useState(false);
  function onClickToCustomerDeets(id) {
    setRedirect(true);
    setSelected(id);
  }

  React.useEffect(() => {
    fetchCustomers();
    // searchThis(customerName)
  }, [currentPage]);

  if (redirect === true) {
    var link = mainPage + '/details/' + currentPage + "/" + selected;
    return (
      <Navigate to={link} />
    )
  }

  return (
    <>
      <div>
        <Navbar />
        <div className="active-cont">
          <Fragment>
            {/* <ToastContainer /> */}

            <div className="d-flex flex-row justify-content-between cont-section">
              <h1 className="cont-sect-header">Customers</h1>
              <div className="search-wrapper w-50">
                <input type="search" class="form-control search-indent" placeholder="Search by Name" onChange={(e) => searchThis(e.target.value)} />
                <div className="search-icon">
                  <FontAwesomeIcon
                    className="icon"
                    icon={'search'}
                    aria-hidden="true"
                    alt={'search'}
                    fixedWidth
                  />
                </div>
              </div>
            </div>

            <div className="cont-section-novertpad">
              <div className="d-flex flex-row justify-content-between">
                <div className="btn-conf-wrapper ms-auto mb-2">
                  <Button type="button" className="px-5 py-1" onClick={handleShow}>add customer</Button>
                </div>
              </div>
            </div>

            {showLoader ?
              <ReactLoading type="spinningBubbles" color="#d4271c" height={200} width={100} className='list-loader' />
              : <div className="cont-section-novertpad customer-table">
                {/** To Add Functionality to save id of clicked customer clicked **/}
                {customers.length !== 0 ?
                  <><TableTemplate
                    tableType='customer'
                    tableHeaders={["First Name", "Last Name", "Contact No.", "Date Added", ""]}
                    tableData={customers}
                    //customers.sort((a, b) => (a.last_name > b.last_name) ? 1 : ((b.last_name > a.last_name) ? -1 : 0))
                    tableNavgn={[onClickToCustomerDeets]}
                    currentPage={currentPage}
                  />
                  <Pagination count={parseInt(pages)} page={currentPage} shape="rounded" defaultPage={1} siblingCount={2} onChange={handlePageChange} showFirstButton showLastButton/>
                  </> : <NoDataPrompt text="customers" />
                  }
              </div>}

          </Fragment>
          <AddCustomer show={show} hide={handleClose} allCustomers={customers} page={currentPage} />
        </div>
      </div>
    </>
  );
}

export default Customer;