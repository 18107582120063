import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Pagination from '@material-ui/lab/Pagination';
import React, { Fragment, useState } from "react";
import { Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import DatePicker from "react-datepicker";
import Select from 'react-select';

// css
import '../../common.css';
import './daily-services-rendered.css';

// components
import Navbar from '../../navbar';
import TableTemplate from '../../table-template';

// sample data (temp)
import ReactLoading from 'react-loading';
import { getAllBranches } from "../../../utilities/api_calls/branchApi";
import { getServicesRenderedList } from "../../../utilities/api_calls/reports/dailyServicesRenderedApi";
import { getAllServices } from "../../../utilities/api_calls/servicesApi";
import { getTodayDate } from "../../../utilities/common";
import NoDataPrompt from '../../../utilities/no-data-prompt';

function DailyServicesRenderedAll() {
  const [serviceOptions, setServiceOptions] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [totals, setTotals] = useState();
  const [dailyServices, setDailyServices] = useState([]);
  const { id } = useParams();
  var branchId;

  if (id === "0") {
    branchId = '';
  } else {
    branchId = id;
  }

  const [value, setValue] = useState({})

  // Pagination
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  function handlePageChange(event, value) {
    // console.log(value)
    setCurrentPage(value)
  }

  //Filter
  const [filter, setFilter] = useState({
    branch: branchId,
    service: "",
    date_from: getTodayDate(),
    date_to: getTodayDate(),
    search: ''
  });

  // -- Filter Functions --- //
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  const handleFilterServices = (e) => {
    setClear(false)
    setFilter(prevState => ({
      ...prevState,
      ['service']: e.value
    }));
    setValue({ label: e.label, value: e.value })
  }

  const handleDateFromChange = (date) => {
    setFilter(prevState => ({
      ...prevState,
      ['date_from']: date
    }))
  }

  const handleDateToChange = (date) => {
    setFilter(prevState => ({
      ...prevState,
      ['date_to']: date
    }))
  }

  const [clear, setClear] = useState(true);

  function removeFilter() {
    setFilter({
      branch: "0",
      service: "",
      date_from: "",
      date_to: "",
      search: ''
    })
    setClear(true);
    fetchServices();
  }

  const options = serviceOptions.map((emp) => (
    {
      label: emp.name,
      value: emp.id,
    }
  ))

  const [branches, setBranches] = useState([]);
  //Get all branches
  async function fetchBranches() {
    const response = await getAllBranches();
    // console.log(response);
    setBranches(response.data.data.branches);
  }

  //All Services
  const [services, setServices] = useState([]);

  //Get Services
  async function fetchServices() {
    const response = await getAllServices();
    // console.log(response);
    var service = response.data.data.services;
    setServices(response.data.data.services);

    // set serviceOptions
    setServiceOptions([]);
    var all = { name: "All Services", id: "" };
    setServiceOptions(prevState => [...prevState, all]);

    service.map((data) => {
      var info = {};

      info.name = data.name;
      info.id = data.id;

      setServiceOptions(prevState => [...prevState, info]);
    })
  }

  //Get Daily Services Rendered
  async function fetchServicesRenderedList() {
    setShowLoader(true);
    
    setDailyServices([]);
    const response = await getServicesRenderedList(filter);
    setPages(Math.ceil(response.data.data.data.services_rendered.length / 9));
    // console.log(response);
    
    var services = response.data.data.data.services_rendered.sort(function (a, b) { return new Date(b.DATE) - new Date(a.DATE); });
    setDailyServices(services);

    var total = 0;

    services.map((service) => {
      total += parseFloat(service.PRICE);
    })
    setTotals(total);
    
    setShowLoader(false);
  }

  React.useEffect(() => {
    fetchServicesRenderedList();
    // eslint-disable-next-line
  }, [filter]);

  React.useEffect(() => {
    fetchServicesRenderedList();
    fetchBranches();
    fetchServices();
    // eslint-disable-next-line
  }, []);

  function renderTable() {
    if (dailyServices.length === 0) {
      return (
        <NoDataPrompt text="services" />
        // <span className="no-data-prompt">No Services found!</span>
      )
    } else {
      return (
        <>
          <TableTemplate
            tableType='sample_daily_services_rendered'
            tableHeaders={["Date", "Branch", "Customer", "Service", "Price","Remarks", "Derm Tech"]}
            tableData={dailyServices}
            currentPage={currentPage}
            hasFooter={true}
            footerData={totals}
          />
          <Pagination count={pages} page={currentPage} shape="rounded" defaultPage={1} siblingCount={2} onChange={handlePageChange} showFirstButton showLastButton />
        </>
      )
    }
  }

  function handleShowData() {
    setTimeout(() => setShowLoader(false), 5000);
  }

  return (
    <>
      <div>
        <Navbar />
        <div className="active-cont">
          <Fragment>

            <div className="d-flex flex-row justify-content-between align-items-center cont-section">
              <h1 className="cont-sect-header">Daily Services Sales</h1>
              <div className="search-wrapper w-50">
                <input type="search" class="form-control search-indent" name="search" value={filter.search} onChange={(e) => handleFilterChange(e)} placeholder="Search by Customer Name" />
                <div className="search-icon">
                  <FontAwesomeIcon
                    className="icon"
                    icon={'search'}
                    aria-hidden="true"
                    alt={'search'}
                    fixedWidth
                  />
                </div>
              </div>
            </div>

            {/** FILTER */}
            <div className="filter-cont mt-0">
              <div className="filter-label-row1">FILTER BY:
                <select className="filter-dropdown-input" name="branch" value={filter.branch} onChange={(e) => handleFilterChange(e)}>
                  <option selected value="0">All Branches</option>
                  {
                    branches.map((branch) => {
                      return (<option key={branch.id} value={branch.id}>{branch.name}</option>)
                    })}
                </select>
                <Select className="react-select-container" name="service" classNamePrefix="react-select" value={clear ? options[0] : value} placeholder="Select Service..." options={options} onChange={(e) => handleFilterServices(e)} />
                {/* <select className="filter-dropdown-input" name="service" value={filter.service} onChange={(e) => handleFilterChange(e)}>
                  <option selected hidden value="" disabled>--SELECT SERVICE--</option>
                  <option value="">All Services</option>
                  {
                    services.map((service) => {
                      return (<option key={service.id} value={service.id}>{service.name}</option>)
                    })}
                </select> */}
              </div>
              <div className='filter-label-row1'>
                DATE FROM:
                <DatePicker selected={filter.date_from} dateFormat="MM/dd/yyyy" className="filter-date-picker mx-3 px-1" name="date_from" placeholderText="mm/dd/yyyy" onChange={date => handleDateFromChange(date)} />
                {/* <input type="date" className="filter-date-picker" name="date_from" value={filter.date_from} onChange={(e) => handleFilterChange(e)} /> */}
                
                TO:
                <DatePicker selected={filter.date_to} dateFormat="MM/dd/yyyy" className="filter-date-picker mx-3 px-1" name="date_to" placeholderText="mm/dd/yyyy" onChange={date => handleDateToChange(date)} />
                {/* <input type="date" className="filter-date-picker" name="date_to" value={filter.date_to} onChange={(e) => handleFilterChange(e)} /> */}
                
                <div className="btn-conf-wrapper in-line  remove-filter-btn">
                  <Button type="button" className="px-2 py-1 mtn-1" onClick={() => removeFilter()}>Remove Filter</Button>
                </div>
              </div>
            </div>


            <div className="cont-section-novertpad transac-table">
              {showLoader ? <>
                <ReactLoading type="spinningBubbles" color="#d4271c" height={200} width={100} className='list-loader' />
                {handleShowData()}</> : renderTable()}
            </div>

          </Fragment>

        </div>
      </div>
    </>
  );
}

export default DailyServicesRenderedAll;