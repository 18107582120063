import { formatMDY, getBranch, getToken, getUser, tokenExpiry } from '../../common';
import { getAPICall } from '../axiosMethodCalls';

/***************************
 * FREE SERVICE REPORT
 ***************************/

//GET
export const getFreeServiceReport = async (filter) => {
    try {
        const response = await getAPICall(window.$link + 'reports/get_free_service_report', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            date_from: formatMDY(filter.date_from),
            date_to: formatMDY(filter.date_to),
            branch: filter.branch,
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}






