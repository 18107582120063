import { getBranch, getToken, getUser, tokenExpiry } from '../common';
import { getAPICall } from './axiosMethodCalls';

//GET
export const getUserName = async (id) => {
    try {
        const response = await getAPICall(window.$link + 'users/show/' + id, {
            api_key: window.$api_key,
            token: getToken(),
            requester: getUser(),
            branch_id: getBranch(),
        });
        return (response.data.name);
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

//GET
export const createUser = async (user, id) => {
    // console.log(user, id);
    try {
        const response = await getAPICall(window.$link + 'users/create', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            employee_id: id,
            name: user.firstname + " " + user.lastname,
            email: user.email,
            password: user.password,
            confirm_password: user.confirm_password,
            role_id: user.role_id
        });
        return ({ data: response })
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

//GET
export const resetPassword = async (user, id) => {
    try {
        const response = await getAPICall(window.$link + 'users/resetPassword', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            employee_id: id,
            password: user.password,
        });
        return ({ data: response })
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}