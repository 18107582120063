import '../node_modules/bootstrap/dist/css/bootstrap.min.css'   // for bootstrap
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

//GLOBAL VARIABLES
// window.$link = "https://system-api.executivefacial.com/";
window.$link = "https://system-dev-api.executivefacial.com/"

// prod: https://system-api.executivefacial.com/
// uat: https://system-api-dev.executivefacial.com/

window.$api_key = "Y5QubbhTOb";


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(// console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
