
import DataImage from "./../images/icons/no-data.png";

import './../components/common.css'

export default function NoDataPrompt(props) {
    return(
        <div className={props.type === "customers" ? "no-data-prompt-customers" : "no-data-prompt-container"}>
            <img src={DataImage} alt='no data found' className="no-data-img" />
            <span>Uh Oh! No {props.text} found.</span>
        </div>
    )
}