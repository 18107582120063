import { formatMDY, getBranch, getToken, getUser, tokenExpiry } from '../common';
import { getAPICall } from './axiosMethodCalls';

/***************************
 * INVENTORY & ITEM
 ***************************/

//GET
export const getAllInventoryItems = async (page) => {
    try {
        const response = await getAPICall(window.$link + 'inventories/getAll', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            offset: page
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

//GET
export const getAvailableItems = async () => {
    try {
        const response = await getAPICall(window.$link + 'inventories/available_items', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

//Get
export const searchInventories = async (name) => {
    try {
        const response = await getAPICall(window.$link + 'inventories/search', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            name: name,
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

//GET
export const getAllItems = async () => {
    try {
        const response = await getAPICall(window.$link + 'items/getAll', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

//GET
export const getInventoryHistory = async (filter, name) => {
    try {
        const response = await getAPICall(window.$link + 'inventories/history', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            date_from: formatMDY(filter.date_from),
            date_to: formatMDY(filter.date_to),
            item_id: filter.item,
            branch: filter.branch,
            name: name
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

//Get
export const searchInventoriesHistory = async (name, filter) => {
    try {
        const response = await getAPICall(window.$link + 'inventories/historySearch', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            name: name,
            date_from: formatMDY(filter.date_from),
            date_to: formatMDY(filter.date_to),
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

//GET
export const getItem = async (id) => {
    try {
        const response = await getAPICall(window.$link + 'items/show/' + id, {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

//POST
export const addItem = async (item) => {
    // console.log(item)
    try {
        const response = await getAPICall(window.$link + 'items/create', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            name: item.name,
            default_unit: item.default_unit,
            price: item.price,
            remarks: item.remarks,
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

//POST
export const updateItem = async (item, id) => {
    try {
        const response = await getAPICall(window.$link + 'items/update/' + id, {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            updated_by: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            name: item.name,
            default_unit: item.default_unit,
            price: item.price,
            remarks: item.remarks,
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

//POST
export const stockInItem = async (item, id) => {
    // console.log(item);
    // console.log(id);
    try {
        const response = await getAPICall(window.$link + 'inventories/stock_in/' + id, {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            added_by: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            branch: item.branch_id,
            qty: item.initial_qty,
            price: item.price,
            remarks: item.remarks,
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

//DELETE
export const deleteItem = async (id) => {
    try {
        const response = await getAPICall(window.$link + 'items/delete/' + id, {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            updated_by: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}
