import axios from 'axios';

// API Axios Get Call.
export const getAPICall = (url, data) => {
    return axios.get(url, {
        params: data
    }, {
        withCredentials: false,
    });
}
// API Axios Post Call.
export const postAPICall = (url, data) => {
    return axios.post(url, data, {
        withCredentials: false,
    });
}

export const postAPICallParams = (url, data) => {
    return axios.post(url, {
        params: data
    }, {
        withCredentials: false,
    });
}
// API Axios Put Call.
export const putAPICall = (url, data) => {
    return axios.put(url, data, {
        withCredentials: false,
    });
}
// API Axios Delete Call.
export const deleteAPICall = (url) => {
    return axios.delete(url, {
        withCredentials: false,
    });
}