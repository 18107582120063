import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Pagination from '@material-ui/lab/Pagination';
import React, { Fragment, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import note from "../../../images/notes.png"
// css
import '../../common.css';
import './service.css';

// components
import Navbar from '../../navbar';
import TableTemplate from '../../table-template';

// sample data (temp)
import { addService, deleteService, getAllServices, getService, searchServices, updateServiceDetails } from "../../../utilities/api_calls/servicesApi";
import { refreshPage } from "../../../utilities/common";
import NoDataPrompt from '../../../utilities/no-data-prompt';
import DeleteModal from '../../deleteModal';
import { useNavigate, useParams } from 'react-router-dom';

function Service() {
    let navigate = useNavigate();
    const { page } = useParams();
    const [showLoader, setShowLoader] = useState(true)
    const [click, setClick] = useState(false);

    // Pagination
    const [pages, setPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(parseInt(page));

    function handlePageChange(event, value) {
        // console.log(value)
        setCurrentPage(value)

        let page_from = (value * 11)  - 11;
        setServices(allServices.slice(page_from, page_from + 11));
    }

    //All Services
    const [allServices, setAllServices] = useState([]);
    const [services, setServices] = useState([]);

    //Get Services
    async function fetchServices() {
        setShowLoader(true)
        const response = await getAllServices();
        setPages(Math.ceil(response.data.data.services.length / 11));
        // console.log(response);
        setAllServices(response.data.data.services);
        let page_from = (1 * 11)  - 11;
        setServices(response.data.data.services.slice(page_from, 11));
        setShowLoader(false)
    }

    //Search Services
    async function searchThis(name) {
        if (name === "") {
            setServices([])
            fetchServices();
        } else {
            setServices([])
            const response = await searchServices(name);
            // console.log(response);
            setServices(response.data.data);
        }
    }

    // --- Add Service Hooks --- //
    const [newService, setNewService] = useState({
        name: "",
        details: "",
        price: "",
        remarks: "",
        isExempted: "",
    });

    // -- Add Service Functions --- //
    const handleAddChange = (e) => {
        const { name, value, checked } = e.target;
        console.log("", name, value, checked)
        if(name === "isExempted"){
            if (!checked) {
                setNewService(prevState => ({
                    ...prevState,
                    [name]: "0"
                }));
            }
            else if (checked) {
                setNewService(prevState => ({
                    ...prevState,
                    [name]: "1"
                }))
            }
        }
        setNewService(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    async function addNewService() {
        if (click === false &&
            newService.name !== "" &&
            newService.price !== "") {
            setClick(true);
            const response = await addService(newService);
            // console.log(response);
            if (response.data.data.status === 201) {
                toast.success(response.data.data.message.success);
                setTimeout(function () {
                    navigate("/service/" + currentPage);
                    refreshPage();
                }, 2000);
            }
            if (response.data.status === 400) {
                toast.error(response.data.message[0]);
            }
        } else {
            toast.error("Please enter values for required inputs!")
        }
    }

    // --- Add Service Popup/Modal --- //
    const [showAddService, setShowAddService] = useState(false);
    const handleCloseAddService = () => setShowAddService(false);
    const handleShowAddService = () => setShowAddService(true);

    // --- Edit Service Popup/Modal --- //
    const [showEditService, setShowEditService] = useState(false);
    const handleCloseEditService = () => setShowEditService(false);
    const handleShowEditService = () => setShowEditService(true);
    const [selected, setSelected] = useState("");

    // --- Edit Service Hooks --- //
    const [editService, setEditService] = useState({
        name: "",
        details: "",
        price: "",
        free_service_exempt: "",
    });

    // -- Edit Service Functions --- //
    const handleEditChange = (e) => {
        const { name, value, checked } = e.target;
        console.log(checked)
        if(name === "free_service_exempt") {
            if(!checked) {
                setEditService(prevState => ({
                    ...prevState,
                    [name]: "0"
                }));
            } else if(checked) {
                setEditService(prevState => ({
                    ...prevState,
                    [name]: "1"
                }));
            }
        } else{
            setEditService(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    }
    console.log(editService.free_service_exempt)

    function onclickEditService(id) {
        handleShowEditService();
        setSelected(id);
        viewService(id);
    }

    async function updateService() {
        if (editService.name !== "" &&
            editService.price !== "") {
            const response = await updateServiceDetails(editService, selected);
            // console.log(response);
            if (response.data.data.status === 200) {
                toast.success(response.data.data.message.success);
                setTimeout(function () {
                    navigate("/service/" + currentPage);
                    refreshPage();
                }, 2000);
            }
            if (response.data.status === 400) {
                toast.error(response.data.message[0]);
            }
        } else {
            toast.error("Please enter values for required inputs!")
        }
    }

    // --- Delete Service Prompt/Modal --- //
    const [showDeleteService, setShowDeleteService] = useState(false);
    const handleCloseDeleteService = () => setShowDeleteService(false);
    const handleShowDeleteService = () => setShowDeleteService(true);

    function onclickDeleteService(id) {
        handleShowDeleteService();
        setSelected(id);
    }

    async function delService() {
        const response = await deleteService(selected);
        // console.log(response);
        if (response.data.data.status === 200) {
            toast.success("Service Deleted Successfully!");
            setTimeout(function () {
                navigate("/service/" + currentPage);
                refreshPage();
            }, 2000);
        }
        if (response.data.status === 400) {
            toast.error(response.data.message[0]);
        }
    }

    // --- View Service Prompt/Modal --- //
    const [showViewService, setShowViewService] = useState(false);
    const handleCloseViewService = () => setShowViewService(false);
    const handleShowViewService = () => setShowViewService(true);

    // --- View Service Hooks --- //
    const [service, setService] = useState({
        name: "",
        price: "",
        details: ""
    });

    async function viewService(id) {
        const response = await getService(id);
        // console.log(response.data.data)
        setService({
            name: response.data.data.name,
            price: parseFloat(response.data.data.price).toFixed(2),
            details: response.data.data.details,
            free_service_exempt: response.data.data.free_service_exempt,
        });
        setEditService({
            name: response.data.data.name,
            price: parseFloat(response.data.data.price).toFixed(2),
            details: response.data.data.details,
            free_service_exempt: response.data.data.free_service_exempt     
        });
    }

    function onclickViewService(id) {
        handleShowViewService();
        viewService(id);

    }

    React.useEffect(() => {
        fetchServices();
    }, []);

    // --- Render --- //

    return (
        <>
            <div>
                <Navbar />
                <div className="active-cont">
                    <Fragment>
                        {/* <ToastContainer /> */}
                        <div className="d-flex flex-row justify-content-between cont-section">
                            <h1 className="cont-sect-header">Services</h1>
                            <div className="search-wrapper w-50">
                                <input type="search" class="form-control search-indent" placeholder="Search by Service" onChange={(e) => searchThis(e.target.value)} />
                                <div className="search-icon">
                                    <FontAwesomeIcon
                                        className="icon"
                                        icon={'search'}
                                        aria-hidden="true"
                                        alt={'search'}
                                        fixedWidth
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="cont-section-novertpad">
                            <div className="d-flex flex-row justify-content-between">
                                <div className="btn-conf-wrapper ms-auto mb-2">
                                    <Button type="button" className="px-5 py-1" onClick={handleShowAddService}>add service</Button>
                                </div>
                            </div>
                        </div>

                        {showLoader ?
                            <ReactLoading type="spinningBubbles" color="#d4271c" height={200} width={100} className='list-loader' />
                            :
                            <div className="cont-section-novertpad service-table">
                                {services.length !== 0 ? <>
                                    <TableTemplate
                                        tableType='service'
                                        tableHeaders={["", "Service Name", "Price", ""]}
                                        tableData={services.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))}
                                        tableFuncs={[onclickDeleteService, onclickEditService, onclickViewService]}
                                        currentPage={currentPage}
                                    />
                                    <Pagination count={pages} page={currentPage} shape="rounded" defaultPage={1} siblingCount={2} onChange={handlePageChange} showFirstButton showLastButton />
                                </> : <NoDataPrompt text="services" />
                                    // <span className='no-data-prompt'>No Services Found!</span>
                                }
                            </div>}

                    </Fragment>

                    {/* ADD Service MODAL*/}
                    <Modal show={showAddService} onHide={handleCloseAddService} size="lg" className="custom-modal-style" centered>
                        <Modal.Header className="custom-modal-header d-flex justify-content-end" closeButton></Modal.Header>
                        <Modal.Body className="px-5 py-1">
                            <h1 className="custom-modal-body-title">Add Service</h1>
                            <Container>
                                <Row className="nc-modal-custom-row">
                                    <Col>Name</Col>
                                    <Col xs={1}>:</Col>
                                    <Col xs={7}>
                                        <Form.Control type="text" name="name" placeholder="Enter service name" className="nc-modal-custom-input" onChange={(e) => handleAddChange(e)} required />
                                        {newService.name === "" && (
                                            <div className="validity-error">
                                                Required input*
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                                <Row className="nc-modal-custom-row">
                                    <Col>Details</Col>
                                    <Col xs={1}>:</Col>
                                    <Col xs={7}><Form.Control type="text" name="details" placeholder="Enter service details" className="nc-modal-custom-input" onChange={(e) => handleAddChange(e)} required /></Col>
                                </Row>
                                <Row className="nc-modal-custom-row">
                                    <Col>Price</Col>
                                    <Col xs={1}>:</Col>
                                    <Col xs={7}>
                                        <Form.Control type="number" min="0.01" step="0.01" name="price" placeholder="Enter price" onChange={(e) => handleAddChange(e)} className="nc-modal-custom-input" />
                                        {newService.price === "" && (
                                            <div className="validity-error">
                                                Required input*
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                                <Row className="nc-modal-custom-row pt-3">
                                    <Col xs={1}>
                                        <Form.Check
                                            name="isExempted"
                                            // value={1}
                                            type="switch"
                                            id="custom-switch"
                                            className="custom-control-input"
                                            onChange={(e) => handleAddChange(e)}
                                        />
                                    </Col>
                                    <Col>Exempted from Free Services</Col>
                                </Row>
                            </Container>
                        </Modal.Body>
                        <Modal.Footer className="border-0 px-5">
                            <div className="d-flex flex-row justify-content-between">
                                {click === true && (
                                    <div className="d-flex justify-content-center">
                                        <div className="btn-conf-wrapper ms-auto">
                                            <Button type="submit" className="px-5 pb-1 mb-2">
                                                <ReactLoading type="spinningBubbles" color="#d4271c" height={25} width={20} />
                                            </Button>
                                        </div>
                                    </div>
                                )}
                                {click === false && (
                                    <div className="btn-conf-wrapper ms-auto">
                                        <Button type="submit" className="px-5 pb-1 mb-2" onClick={() => addNewService()}>save</Button>
                                    </div>
                                )}
                            </div>
                        </Modal.Footer>
                    </Modal>

                    {/* EDIT Service MODAL*/}
                    <Modal show={showEditService} onHide={handleCloseEditService} size="lg" className="custom-modal-style" centered>
                        <Modal.Header className="custom-modal-header d-flex justify-content-end" closeButton></Modal.Header>
                        <Modal.Body className="px-5 py-1">
                            <h1 className="custom-modal-body-title">Edit Service</h1>
                            <Container>
                                <Row className="nc-modal-custom-row">
                                    <Col>Name</Col>
                                    <Col xs={1}>:</Col>
                                    <Col xs={7}>
                                        <Form.Control type="text" name="name" placeholder="Enter service name" className="nc-modal-custom-input" value={editService.name} onChange={(e) => handleEditChange(e)} required />
                                        {editService.name === "" && (
                                            <div className="validity-error">
                                                Required input*
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                                <Row className="nc-modal-custom-row">
                                    <Col>Details</Col>
                                    <Col xs={1}>:</Col>
                                    <Col xs={7}><Form.Control type="text" name="details" placeholder="Enter service details" className="nc-modal-custom-input" value={editService.details} onChange={(e) => handleEditChange(e)} required /></Col>
                                </Row>
                                <Row className="nc-modal-custom-row">
                                    <Col>Price</Col>
                                    <Col xs={1}>:</Col>
                                    <Col xs={7}>
                                        <Form.Control type="number" min="0.01" step="0.01" name="price" placeholder="Enter price" className="nc-modal-custom-input" value={editService.price} onChange={(e) => handleEditChange(e)} required />
                                        {editService.price === "" && (
                                            <div className="validity-error">
                                                Required input*
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                                <Row className="nc-modal-custom-row pt-3">
                                    <Col xs={1}>
                                        <Form.Check
                                            name="free_service_exempt"
                                            value={1}
                                            type="switch"
                                            id="custom-switch"
                                            className="custom-control-input"
                                            checked={editService.free_service_exempt > 0 ? true : false}
                                            onChange={(e) => handleEditChange(e)}
                                        />
                                    </Col>
                                    <Col>Exempted from Free Services</Col>
                                </Row>
                            </Container>
                        </Modal.Body>
                        <Modal.Footer className="border-0 px-5">
                            <div className="d-flex flex-row justify-content-between">
                                <div className="btn-conf-wrapper ms-auto">
                                    <Button type="submit" className="px-5 pb-1 mb-2" onClick={() => updateService()}>save</Button>
                                </div>
                            </div>
                        </Modal.Footer>
                    </Modal>

                    <DeleteModal show={showDeleteService} hide={handleCloseDeleteService} text="service" handler={() => delService()} />

                    {/* Service DETAILS MODAL*/}
                    <Modal show={showViewService} onHide={handleCloseViewService} size="lg" className="custom-modal-style" centered>
                        <Modal.Header className="custom-modal-header d-flex justify-content-end" closeButton></Modal.Header>
                        <Modal.Body className="px-5 py-1">
                            <Container>
                                <Row className="modal-cont">
                                    <Col>
                                        <h1 className="text-center service-details-header">Service Details</h1>
                                    </Col>
                                    <Col className="details-cont">
                                        <Row className="nc-modal-custom-row ">
                                            <Col>SERVICE NAME</Col>
                                        </Row>
                                        <Row className="nc-modal-custom-row modal-detail">
                                            <Col>{service.name}</Col>
                                        </Row>
                                        <Row className="nc-modal-custom-row ">
                                            <Col>PRICE</Col>
                                        </Row>
                                        <Row className="nc-modal-custom-row modal-detail">
                                            <Col>{service.price}</Col>
                                        </Row>
                                        <Row className="nc-modal-custom-row ">
                                            <Col>DETAILS</Col>
                                        </Row>
                                        <Row className="nc-modal-custom-row modal-detail">
                                            <Col>{service.details}</Col>
                                        </Row>
                                        <Row className="nc-modal-custom-row modal-detail">
                                            {service.free_service_exempt > 0 &&
                                                <>
                                                <Col xs={2}><img src={note} style={{width: "25px"}}></img></Col>
                                                <Col><label>Exempted from free service counting.</label></Col>
                                                </>
                                            }
                                        </Row>
                                    </Col>
                                </Row>
                            </Container>
                        </Modal.Body>
                        <Modal.Footer className="border-0 px-5">
                            <div className="d-flex flex-row justify-content-between">
                                {/* <div className="btn-conf-wrapper ms-auto">
                            <Button type="button" onClick={handleCloseViewService} className="px-5 pb-1 mb-2">save</Button>
                        </div> */}
                            </div>
                        </Modal.Footer>
                    </Modal>

                </div>
            </div>
        </>
    )
}

export default Service;