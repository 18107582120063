import React, { Fragment, useState } from "react";
import { Button, Modal, Container, Row, Col, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
import Pagination from '@material-ui/lab/Pagination';

// css
import '../../common.css'
import './inventory.css'

// components
import Navbar from '../../navbar'
import TableTemplate from '../../table-template';

// sample data (temp)
import { getRoleId, refreshPage } from "../../../utilities/common";
import { addItem, getAllInventoryItems, getItem, updateItem, deleteItem, stockInItem, searchInventories } from "../../../utilities/api_calls/inventoryApi";
import { getAllBranches } from "../../../utilities/api_calls/branchApi";
import NoDataPrompt from "../../../utilities/no-data-prompt";


function Inventory() {
  let navigate = useNavigate();
  const { page } = useParams();
  const mainPage = '/inventory-item'

  const [showLoader, setShowLoader] = useState(true);
  const [click, setClick] = useState(false);

  // Pagination
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(parseInt(page));

  function handlePageChange(event, value) {
    // console.log(value)
    setCurrentPage(value)
  }

  //All Branches
  const [branches, setBranches] = useState([]);

  //Get Branches
  async function fetchBranches() {
    const response = await getAllBranches();
    // console.log(response);
    setBranches(response.data.data.branches);
  }

  //All Inventories
  const [inventories, setInventories] = useState([]);

  //Get Inventories
  async function fetchInventories() {
    setShowLoader(true);
    setTimeout(() => setShowLoader(false), 1000);
    const response = await getAllInventoryItems(currentPage);
    setPages(Math.ceil(parseInt(response.data.data.size)/10));
    console.log(Math.ceil(parseInt(response.data.data.size)/10));
    setInventories(response.data.data.inventory);
  }

  //Search Inventories
  async function searchThis(name) {
    if (name === "") {
      setInventories([])
      fetchInventories();
    } else {
      setInventories([])
      const response = await searchInventories(name);
      // console.log(response);
      setInventories(response.data.data);
    }
  }

  // --- Add Item Hooks --- //
  const [newItem, setNewItem] = useState({
    name: "",
    default_unit: "",
    price: "",
    remarks: "",
  });


  // -- Add Item Functions --- //
  const handleAddChange = (e) => {
    const { name, value } = e.target;
    setNewItem(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  async function addNewItem() {
    if (click === false) {
      setClick(true);
      const response = await addItem(newItem);
      // console.log(response);
      if (response.data.data.status === 201) {
        toast.success(response.data.data.message.success);
        setTimeout(function () {
          navigate("/inventory-item/" + currentPage)
          refreshPage();
        }, 3000);
      }
      if (response.data.status === 400) {
        toast.error(response.data.message[0]);
      }
    }
  }

  // -- Action Dropdown Select --//
  const [action, setAction] = useState("");

  // -- Add Item Popup/Modal -- //
  const [showAddItem, setShowAddItem] = useState(false);
  const handleCloseAddItem = () => setShowAddItem(false);
  const handleShowAddItem = () => setShowAddItem(true);

  // -- Stock In Modal -- //
  const [showStockIn, setShowStockIn] = useState(false);
  const handleCloseStockIn = () => {navigate(currentPage); refreshPage();}
  const handleShowStockIn = () => setShowStockIn(true);

  // --- Stock-In Item Hooks --- //
  const [stockIn, setStockIn] = useState({
    name: "",
    branch_id: "",
    initial_qty: "",
    price: "",
    remarks: "",
  });

  function onclickStockIn(id) {
    handleShowStockIn();
    viewItem(id);
    setSelected(id);
  }

  // -- Stock-in Functions --- //
  const handleStockInChange = (e) => {
    const { name, value } = e.target;
    setStockIn(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  const [isClick, setIsClick] = useState(false)

  async function stockInThisItem() {
    setIsClick(true)
    const response = await stockInItem(stockIn, selected);
    // console.log(response);
    if (response.data.data.status === 200) {
      toast.success(response.data.data.message.success, {autoClose: 1000});
      setTimeout(function () {
        navigate("/inventory-item/" + currentPage);
        refreshPage();
      }, 1000);
      setIsClick(false)
      setShowStockIn(false)
    }
    if (response.data.status === 400) {
      toast.error(response.data.message[0]);
      setIsClick(false)
    }
  }

  // -- Stock Out Modal -- //
  const [showStockOut, setShowStockOut] = useState(false);
  const handleCloseStockOut = () => setShowStockOut(false);

  // --- Edit Item Hooks --- //
  const [editItem, setEditItem] = useState({
    name: "",
    default_unit: "",
    price: "",
    remarks: "",
  });

  // -- Edit Item Functions --- //
  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditItem(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  async function updateItemDetail() {
    const response = await updateItem(editItem, selected);
    // console.log(response);
    if (response.data.data.status === 200) {
      toast.success(response.data.data.message.success);
      setTimeout(function () {
        navigate("/inventory-item/" + currentPage);
        refreshPage();
      }, 3000);
    }
    if (response.data.status === 400) {
      toast.error(response.data.message[0]);
    }
  }

  // -- Edit Item Popup/Modal -- //
  const [showEditItem, setShowEditItem] = useState(false);
  const handleCloseEditItem = () => {navigate("/inventory-item/" + currentPage); refreshPage();}
  const handleShowEditItem = () => setShowEditItem(true);
  const [selected, setSelected] = useState("");

  function onclickEditItem(id) {
    handleShowEditItem();
    viewItem(id);
    setSelected(id);
  }

  // -- Delete Item Popup/Modal -- //
  const [showDeleteItem, setShowDeleteItem] = useState(false);
  const handleCloseDeleteItem = () => setShowDeleteItem(false);
  const handleShowDeleteItem = () => setShowDeleteItem(true);

  function onclickDeleteItem(id) {
    handleShowDeleteItem();
    setSelected(id);
  }

  async function delItem() {
    // console.log("here")
    const response = await deleteItem(selected);
    // console.log(response);
    if (response.data.data.status === 200) {
      toast.success(response.data.data.message.success);
      setTimeout(function () {
        navigate("/inventory-item/" + currentPage);
        refreshPage();
      }, 3000);
    }
    if (response.data.status === 400) {
      toast.error(response.data.message[0]);
    }
  }

  async function viewItem(id) {
    const response = await getItem(id);
    // console.log(response.data.data);
    setEditItem(response.data.data);
    setStockIn({
      name: response.data.data.name,
      branch_id: "",
      initial_qty: "",
      price: response.data.data.price,
      remarks: "",
    })
  }
  // Inventory Navigations
  const [redirect, setRedirect] = useState(false);

  function onClickToInventoryNavs() {
    setRedirect(true);
  }

  function renderTable() {
    // console.log(inventories.length);
    if (inventories.length === 0) {
      return (
        <NoDataPrompt text="inventory items" />
        // <span className="no-data-prompt">No Inventory found!</span>
      )
    } else {
      return (<>
        <TableTemplate
          tableType='inventory'
          tableHeaders={["", "Item Name", "Imelda", "R Marasbaras", "R Ormoc", "Action"]}
          tableData={inventories.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))}
          tableFuncs={[onclickDeleteItem, onclickEditItem, onclickStockIn, setSelected]}
          action={action}
          setAction={setAction}
          tableNavgn={[onClickToInventoryNavs]}
          currentPage={currentPage}
        />
        <Pagination count={parseInt(pages)} page={currentPage} shape="rounded" defaultPage={1} siblingCount={2} onChange={handlePageChange} showFirstButton showLastButton />
      </>
      )
    }
  }

  function handleShowData() {
    setTimeout(() => setShowLoader(false), 5000);
  }

  React.useEffect(() => {
    fetchInventories();
    fetchBranches();
  }, [currentPage]);

  React.useEffect(() => {
    var actionInfo = action.split("@");
    console.log(actionInfo)
    if (actionInfo[0] === 'stock-in') {
      onclickStockIn(actionInfo[1]);
    }
    if (actionInfo[0] === 'view') {
      setRedirect(true);
    }
    //eslint-disable-next-line
  }, [action]);

  if (redirect === true) {
    var actionInfo = action.split("@");
    var link = mainPage + '/item-detail/' + actionInfo[1] + "/" + actionInfo[2];
    return (
      <Navigate to={link} />
    )
  }

  return (
    <>
      <div>
        <Navbar />
        <div className="active-cont">
          <Fragment>
            {/* <ToastContainer /> */}
            <div className="d-flex flex-row justify-content-between cont-section">
              <h1 className="cont-sect-header">Inventory</h1>
              <div className="search-wrapper w-50">
                <input type="search" className="form-control search-indent" placeholder="Search by Item" onChange={(e) => searchThis(e.target.value)} />
                <div className="search-icon">
                  <FontAwesomeIcon
                    className="icon"
                    icon={'search'}
                    aria-hidden="true"
                    alt={'search'}
                    fixedWidth
                  />
                </div>
              </div>
            </div>

            <div className="cont-section-novertpad">
              <div className="d-flex flex-row justify-content-between">
                <div className="btn-conf-wrapper ms-auto mb-2">
                  {getRoleId() === '4' ?
                    <Button type="button" className="px-5 py-1" onClick={handleShowAddItem}>add item</Button>
                    : <Button type="button" className="px-5 py-1 disabled-button" disabled>add item</Button>}
                </div>
              </div>
            </div>

            <div className="cont-section-novertpad inventory-table">
              {showLoader ? <>
                <ReactLoading type="spinningBubbles" color="#d4271c" height={200} width={100} className='list-loader' />
                {handleShowData()}</> : renderTable()}
            </div>

          </Fragment>

          {/* ADD ITEM MODAL */}
          <Modal show={showAddItem} onHide={handleCloseAddItem} size="lg" dialogClassName="border-radius-20" className="custom-modal-style" centered>

            <Modal.Header className="custom-modal-header d-flex justify-content-end" closeButton></Modal.Header>

            <Modal.Body className="px-5 py-1">
              <h1 className="custom-modal-body-title">Add Item</h1>
              <Container>
                <Row className="nc-modal-custom-row">
                  <Col>item name</Col>
                  <Col xs={1}>:</Col>
                  <Col xs={7}><Form.Control type="text" name="name" placeholder="Enter item name" className="nc-modal-custom-input" onChange={(e) => handleAddChange(e)} /></Col>
                </Row>
                <Row className="nc-modal-custom-row">
                  <Col>default unit</Col>
                  <Col xs={1}>:</Col>
                  <Col xs={7}><Form.Control type="text" name="default_unit" placeholder="Enter default unit" className="nc-modal-custom-input" onChange={(e) => handleAddChange(e)} /></Col>
                </Row>
                <Row className="nc-modal-custom-row">
                  <Col>input price</Col>
                  <Col xs={1}>:</Col>
                  <Col xs={7}><Form.Control type="text" name="price" placeholder="Enter input price" className="nc-modal-custom-input" onChange={(e) => handleAddChange(e)} /></Col>
                </Row>
                <Row className="nc-modal-custom-row">
                  <Col>Remarks</Col>
                  <Col xs={1}>:</Col>
                  <Col xs={7}><Form.Control as="textarea" name="remarks" className="nc-modal-custom-input" rows={5} onChange={(e) => handleAddChange(e)} /></Col>
                </Row>
              </Container>
            </Modal.Body>

            <Modal.Footer className="border-0 px-5">
              <div className="d-flex flex-row justify-content-between">
                <div className="btn-conf-wrapper ms-auto">
                  {click === true && (
                    <div className="d-flex justify-content-center">
                      <div className="btn-conf-wrapper ms-auto">
                        <Button type="submit" className="px-5 pb-1 mb-2">
                          <ReactLoading type="spinningBubbles" color="#d4271c" height={25} width={20} />
                        </Button>
                      </div>
                    </div>
                  )}
                  {click === false && (
                    <Button type="button" className="px-5 pb-1 mb-2" onClick={() => addNewItem()}>save</Button>
                  )}
                </div>
              </div>
            </Modal.Footer>

          </Modal>

          {/* STOCK IN MODAL */}
          <Modal show={showStockIn} onHide={() => { handleCloseStockIn() }} size="lg" className="custom-modal-style" centered>
            <Modal.Header className="custom-modal-header justify-content-end" closeButton></Modal.Header>
            <Modal.Body className="px-5 py-1 flex-row">
              <Container className="col-5 my-auto">
                <Row><div className="h1 fw-bold my-0">stock in</div></Row>
              </Container>

              <div className="custom-modal-vertline-border mx-3"></div>

              <Container className="my-auto">
                <Row className='nc-modal-custom-row my-1'>
                  <Col xs={4} className="fw-bold">item </Col>
                  <Col xs={7} className="fw-bold">{stockIn.name}</Col>
                </Row>
                <Row className='nc-modal-custom-row my-1'>
                  <Col xs={4} className="fw-bold">branch</Col>
                  <Col xs={8} className="fw-bold">
                    <Form.Select className="nc-modal-custom-input" name="branch_id" onChange={(e) => handleStockInChange(e)} required>
                      <option>--SELECT--</option>
                      {branches.map((data, index) => {
                        return <option value={data.id} key={index}>{data.name}</option>
                      })}
                    </Form.Select>
                  </Col>
                </Row>
                <Row className='nc-modal-custom-row my-1'>
                  <Col xs={4} className="fw-bold">quantity</Col>
                  <Col><Form.Control type="number" name="initial_qty" placeholder="Enter quantity" className="nc-modal-custom-input" onChange={(e) => handleStockInChange(e)} /></Col>
                </Row>
                <Row className='my-1'>
                  <Col className="nc-modal-custom-row my-1">
                    <Col className="fw-bold">remarks</Col>
                    <Form.Control className="nc-modal-custom-input" as="textarea" name="remarks" onChange={(e) => handleStockInChange(e)} rows={4} />
                  </Col>
                </Row>
              </Container>
            </Modal.Body>

            <Modal.Footer className="border-0 px-5">
              <div className="d-flex flex-row justify-content-between">
                <div className="btn-conf-wrapper ms-auto">
                  {isClick ?
                    <Button className="buy-btn px-4 py-1">
                        <ReactLoading type="spinningBubbles" color="#d4271c" height={20} width={20} />
                      </Button>
                    :
                    <Button type="button" className="px-5 pb-1 mb-2" onClick={() => stockInThisItem()}>save</Button>
                  }  
                </div>
              </div>
            </Modal.Footer>
          </Modal>

          {/* STOCK OUT MODAL */}
          <Modal show={showStockOut} onHide={() => { handleCloseStockOut() }} size="lg" className="custom-modal-style" centered>
            <Modal.Header className="custom-modal-header d-flex justify-content-end" closeButton></Modal.Header>
            <Modal.Body className="px-5 py-1 d-flex flex-row">
              <Container className="col-5 my-auto">
                <Row><div className="h1 fw-bold my-0">stock out</div></Row>
              </Container>

              <div className="custom-modal-vertline-border mx-3"></div>

              <Container className="my-auto">
                <Row className='nc-modal-custom-row my-1'>
                  <Col xs={5} className="fw-bold">quantity</Col>
                  <Col><Form.Control type="number" name="quantity" placeholder="Enter quantity" className="nc-modal-custom-input" /></Col>
                </Row>
                <Row className='my-1'>
                  <Col className="nc-modal-custom-row my-0">
                    <Col className="fw-bold">remarks</Col>
                    <Form.Control className="nc-modal-custom-input" as="textarea" rows={4} />
                  </Col>
                </Row>
              </Container>
            </Modal.Body>

            <Modal.Footer className="border-0 px-5">
              <div className="d-flex flex-row justify-content-between">
                <div className="btn-conf-wrapper ms-auto">
                  <Button type="button" className="px-5 pb-1 mb-2">save</Button>
                </div>
              </div>
            </Modal.Footer>
          </Modal>

          {/* EDIT ITEM MODAL */}
          <Modal show={showEditItem} onHide={handleCloseEditItem} size="lg" className="custom-modal-style" centered>

            <Modal.Header className="custom-modal-header d-flex justify-content-end" closeButton></Modal.Header>

            <Modal.Body className="px-5 py-1">
              <h1 className="custom-modal-body-title">Edit Item</h1>
              <Container>
                <Row className="nc-modal-custom-row">
                  <Col>item name</Col>
                  <Col xs={1}>:</Col>
                  <Col xs={7}><Form.Control type="text" name="name" placeholder="Enter item name" className="nc-modal-custom-input" value={editItem.name} onChange={(e) => handleEditChange(e)} /></Col>
                </Row>

                <Row className="nc-modal-custom-row">
                  <Col>default unit</Col>
                  <Col xs={1}>:</Col>
                  <Col xs={7}><Form.Control type="text" name="default_unit" placeholder="Enter default unit" className="nc-modal-custom-input" value={editItem.default_unit} onChange={(e) => handleEditChange(e)} /></Col>
                </Row>

                <Row className="nc-modal-custom-row">
                  <Col>default price</Col>
                  <Col xs={1}>:</Col>
                  <Col xs={7}><Form.Control type="text" name="price" placeholder="Enter input price" className="nc-modal-custom-input" value={editItem.price} onChange={(e) => handleEditChange(e)} /></Col>
                </Row>

                <Row className="nc-modal-custom-row">
                  <Col>Remarks</Col>
                  <Col xs={1}>:</Col>
                  <Col xs={7}><Form.Control as="textarea" name="remarks" className="nc-modal-custom-input" rows={5} value={editItem.remarks} onChange={(e) => handleEditChange(e)} /></Col>
                </Row>
              </Container>
            </Modal.Body>

            <Modal.Footer className="border-0 px-5">
              <div className="d-flex flex-row justify-content-between">
                <div className="btn-conf-wrapper ms-auto">
                  <Button type="button" className="px-5 pb-1 mb-2" onClick={() => updateItemDetail()}>save</Button>
                </div>
              </div>
            </Modal.Footer>

          </Modal>

          {/* DELETE EMPLOYEE MODAL*/}
          <Modal show={showDeleteItem} onHide={handleCloseDeleteItem} size="lg" className="custom-modal-style" centered>
            <Modal.Header className="custom-modal-header del-prompt-header-custom d-flex justify-content-end"></Modal.Header>
            <Modal.Body className="px-5">
              <Container className="d-flex flex-row">
                <FontAwesomeIcon
                  className="del-prompt-icon"
                  icon={'trash-alt'}
                  aria-hidden="true"
                  alt={'trash-alt'}
                  fixedWidth
                />
                <div className="ms-2">
                  <p className="del-prompt-par">Are you sure you want to delete this item?</p>
                  <p className="del-prompt-subpar">This item will be deleted immediately.<br />You can't undo this action.</p>
                </div>
              </Container>

            </Modal.Body>
            <Modal.Footer className="border-0 px-5">
              <div className="cancel-btn-conf-wrapper">
                <Button type="button" className="px-3" onClick={handleCloseDeleteItem}>cancel</Button>
              </div>
              <div className="btn-conf-wrapper">
                <Button type="submit" className="px-3" onClick={() => delItem()}>delete</Button>
              </div>
            </Modal.Footer>
          </Modal>

        </div>
      </div>
    </>
  );
}

export default Inventory;