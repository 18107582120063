import { Button, Container, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './common.css';

export default function DeleteModal(props) {
    return (
        <Modal show={props.show} onHide={props.hide} size="lg" className="custom-modal-style" centered>
            <Modal.Header className="custom-modal-header del-prompt-header-custom"></Modal.Header>
            <Modal.Body className="px-5">
                <Container className="d-flex flex-row">
                    <FontAwesomeIcon
                        className="del-prompt-icon"
                        icon={'trash-alt'}
                        aria-hidden="true"
                        alt={'trash-alt'}
                        fixedWidth
                    />
                    <div className="ms-2">
                        <p className="del-prompt-par">Are you sure you want to delete this {props.text}?</p>
                        <p className="del-prompt-subpar">This {props.text} will be deleted immediately.<br />You can't undo this action.</p>
                    </div>
                </Container>

            </Modal.Body>
            <Modal.Footer className="border-0 px-5">
                <div className="cancel-btn-conf-wrapper">
                    <Button type="button" className="px-3" onClick={props.hide}>cancel</Button>
                </div>
                <div className="btn-conf-wrapper">
                    <Button type="submit" className="px-3" onClick={props.handler}>delete</Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}