import { formatMDY } from '../../common';
import { getAge, getBranch, getTodayDate, getToken, getUser, tokenExpiry } from '../../common';
import { getAPICall, postAPICall, putAPICall, deleteAPICall } from '../axiosMethodCalls';

/***************************
 * COMMISSION REPORT
 ***************************/

//GET
export const getCommission = async (filter) => {
    try {
        const response = await getAPICall(window.$link + 'reports/get_commission_report', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            date_from: filter.date_from ? formatMDY(filter.date_from) : "",
            date_to: filter.date_to ? formatMDY(filter.date_to) : "",
            employee: filter.employee,
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

export const getTotalCommission = async (filter) => {
    try {
        const response = await getAPICall(window.$link + 'reports/get_total_commission', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            branch: filter.branch,
            date_from: formatMDY(filter.date_from),
            date_to: formatMDY(filter.date_to),
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

export const searchCommission = async (input) => {
    try {
        const response = await getAPICall(window.$link + 'reports/search_commission', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            input: input,
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}




