import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Pagination from '@material-ui/lab/Pagination';
import React, { Fragment, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import ReactLoading from 'react-loading';
import Select from 'react-select';
import { formatDate, getBranch, getRoleId, getTodayDate } from "../../../utilities/common";

// css
import '../../common.css';
import "../reports/daily-sales-report.css";

// components
import Navbar from '../../navbar';
import TableTemplate from '../../table-template';

// sample data (temp)
import { getBookingDetail } from "../../../utilities/api_calls/bookingDetailsApi";
import { getAllBranches, getBranchName } from '../../../utilities/api_calls/branchApi';
import { getSummary } from "../../../utilities/api_calls/paymentApi";
import { getSalesList } from '../../../utilities/api_calls/reports/dailySalesReportApi';
import NoDataPrompt from "../../../utilities/no-data-prompt";

export default function DailyTransactions() {
  const [showLoader, setShowLoader] = useState(true);
  const [totals, setTotals] = useState({});

  // Pagination
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  function handlePageChange(event, value) {
    // console.log(value)
    setCurrentPage(value)
  }

  // select Items
  const [clear, setClear] = useState(true);
  const [itemOptions, setItemOptions] = useState([]);
  const [value, setValue] = useState({})

  const handleFilterType = (e) => {
    setClear(false)
    setFilter(prevState => ({
      ...prevState,
      ['type']: e.value
    }));
    setValue({ label: e.label, value: e.value })
  }

  const [filter, setFilter] = useState({
    branch: getRoleId() === "4" ? "" : getBranch().replace(/['"]+/g, ''),
    type: "",
    date_from: getTodayDate(),
    date_to: getTodayDate(),
    search: ''
  })

  const options = [
    { label: "All Types", value: "" },
    { label: "Cash", value: "cash" },
    { label: "Debit/Credit Card", value: "card" },
    { label: "Bank Transfer", value: "bank_transfer" },
    { label: "Online", value: "online" }
  ]

  const [salesList, setSalesList] = useState([]);

  async function fetchSalesList() {
    if (filter.type === 'total-sales') {
      filter.type = '';
    }
    setShowLoader(true);
    setTimeout(() => setShowLoader(false), 1000)

    // console.log(filter)
    setSalesList([]);
    const response = await getSalesList(filter);
    setPages(Math.ceil(response.data.data.data.sales.length / 9));
    console.log(response.data.data.data);
    var salesSorted = response.data.data.data.sales.sort(function (a, b) { return new Date(b.DATE) - new Date(a.DATE) })
    setSalesList(salesSorted);
    /** footer **/
    var total = response.data.data.data.total_sales[0]
    setTotals({
      sub_total: total.SUB_TOTAL,
      discount: total.DISCOUNT,
      grand_total: total.GRAND_TOTAL,
      total_balance: total.TOTAL_BALANCE,
      total_paid_amount: total.TOTAL_PAID_AMOUNT
    })
  }

  const [branches, setBranches] = useState([]);

  //Get all branches
  async function fetchBranches() {
    const response = await getAllBranches();
    setBranches(response.data.data.branches);
  }

  // -- Filter Functions --- //
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  const handleDateFromChange = (date) => {
    setFilter(prevState => ({
      ...prevState,
      ['date_from']: date
    }))
  }

  const handleDateToChange = (date) => {
    setFilter(prevState => ({
      ...prevState,
      ['date_to']: date
    }))
  }

  function removeFilter() {
    setFilter({
      branch: getRoleId() === "4" ? "" : getBranch().replace(/['"]+/g, ''),
      type: "",
      date_from: "",
      date_to: "",
      search: ""
    })
    setClear(true)
    fetchSalesList();
  }

  //Branch
  const [branchName, setBranchName] = useState("");

  async function fetchBranch() {
    const response = await getBranchName(getBranch().replace(/['"]+/g, ''));
    // console.log(response)
    setBranchName(response.data.data.name)
  }

  // view button
  const [showSummary, setShowSummary] = useState(false);
  const handleCloseSummary = () => setShowSummary(false);
  const handleShowSummary = () => setShowSummary(true);

  const [existingSummary, setExistingSummary] = useState([{
    date: "",
    id: "",
    name: "",
    quantity: "",
    total_price: "",
  }]);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [branch, setBranch] = useState("");
  const [orNo, setOrNo] = useState([]);
  const [footer, setFooter] = useState({total: 0, balance: 0});
  const [subTotal, setSubTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0)
  const [selectedDetails, setSelectedDetails] = useState([{
    DATE_BOOKED: "",
    REMARKS: "",
  }])

  async function viewExistingSummary(id, booking_deets) {
    const response = await getSummary(id, booking_deets.branch_id);
    // console.log(id)
    console.log(response);
    setBranch(response.data.data.booked_at);
    setOrNo(response.data.data.or_no);
    setExistingSummary(response.data.data.summary)
    setPaymentHistory(response.data.data.payment_history === 0 ? [] : response.data.data.payment_history)
    var payments = response.data.data.payment_history;
    var total = 0;
    payments.map((payment) => {
      total += parseInt(payment.PAID_AMOUNT);
    })

    setSubTotal(response.data.data.sub_total);
    setGrandTotal(response.data.data.grand_total);
    setDiscount(response.data.data.discount)

    setFooter({total: total, balance: parseInt(response.data.data.grand_total) - total})

    var subTotal = 0;

    response.data.data.summary.map((data) => {
      return (subTotal += parseFloat(data.total_price))
    })

  }

  function renderTable() {
    // console.log(salesList.length);
    if (salesList.length === 0) {
      return (
        <NoDataPrompt text="sales" />
        // <span className="no-data-prompt">No Sales found!</span>
      )
    } else {
      return (
        <>
          <TableTemplate
            tableType='sample_daily_sales_report'
            tableHeaders={["Date", "Customer", "Booking #", "Subtotal", "Discount", "Grand Total", "Paid Amount", "Balance", "Payment Type", "Action"]}
            tableData={salesList}
            hasFooter={true}
            footerData={totals}
            tableFuncs={[null, null, null, [onclickSummary]]}
            currentPage={currentPage}
          />
          <Pagination count={pages} page={currentPage} shape="rounded" defaultPage={1} siblingCount={2} onChange={handlePageChange} showFirstButton showLastButton />
        </>
      )
    }
  }

  function handleShowData() {
    setTimeout(() => setShowLoader(false), 5000);
  }

  const [showLoaderModal, setShowLoaderModal] = useState(false)

  async function onclickSummary(id, row) {
    handleShowSummary();
    setShowLoaderModal(true)
    // console.log(id)
    const booking_deets = await getBookingDetail(id);
    // console.log(booking_deets);

    console.log(row)
    setSelectedDetails({ DATE_BOOKED: row.DATE, CUSTOMER: row.CUSTOMER })

    viewExistingSummary(id, booking_deets);
    setTimeout(() => setShowLoaderModal(false), 800)
  }

  React.useEffect(() => {
    fetchSalesList();
    fetchBranches();
    fetchBranch();
    // eslint-disable-next-line
  }, [filter]);

  return (
    <>
      <div>
        <Navbar />
        <div className="active-cont">
          <Fragment>

            <div className="d-flex flex-row justify-content-between align-items-center cont-section">
              <h1 className="cont-sect-header">Daily Transactions</h1>
              <div className="search-wrapper w-50">
                <input type="search" className="form-control search-indent" name="search" value={filter.search} onChange={(e) => handleFilterChange(e)} placeholder="Search by Customer Name" />
                <div className="search-icon">
                  <FontAwesomeIcon
                    className="icon"
                    icon={'search'}
                    aria-hidden="true"
                    alt={'search'}
                    fixedWidth
                  />
                </div>
              </div>
            </div>

            {/** FILTER */}
            <div className="filter-cont mt-0">
              <div className="filter-label-row1">FILTER BY:
                {getRoleId() === "4" ?
                  <select className="filter-dropdown-input" name="branch" value={filter.branch} onChange={(e) => handleFilterChange(e)}>
                    <option selected value="">All Branches</option>
                    {
                      branches.map((branch) => {
                        return (<option key={branch.id} value={branch.id}>{branch.name}</option>)
                      })}
                  </select> : null}
                <Select className="react-select-container" classNamePrefix="react-select" value={clear ? options[0] : value} placeholder="Select Payment Type..." options={options} onChange={(e) => handleFilterType(e)} />
                {/* <select className="filter-dropdown-input" name="type" value={filter.type} onChange={(e) => handleFilterChange(e)}>
                  <option value="" hidden>All Types</option>
                  <option value="cash">Cash</option>
                  <option value="card">Debit/Credit</option>
                  <option value="bank_transfer">Bank Transfer</option>
                  <option value="online">Online</option>
                </select> */}
              </div>
              <div className='filter-label-row1'>
                DATE FROM:
                <DatePicker selected={filter.date_from} dateFormat="MM/dd/yyyy" className="filter-date-picker mx-3 px-1" name="date_from" placeholderText="mm/dd/yyyy" onChange={date => handleDateFromChange(date)} />
                {/* <input type="date" className="filter-date-picker" name="date_from" value={filter.date_from} onChange={(e) => handleFilterChange(e)} /> */}

                TO:
                <DatePicker selected={filter.date_to} dateFormat="MM/dd/yyyy" className="filter-date-picker mx-3 px-1" name="date_to" placeholderText="mm/dd/yyyy" onChange={date => handleDateToChange(date)} />
                {/* <input type="date" className="filter-date-picker" name="date_to" value={filter.date_to} onChange={(e) => handleFilterChange(e)} /> */}

                <div className="btn-conf-wrapper in-line  remove-filter-btn">
                  <Button type="button" className="px-2 py-1 mtn-1" onClick={() => removeFilter()}>Remove Filter</Button>
                </div>
              </div>
            </div>

            <div className="cont-section-novertpad sales-table">
              <div>
                {showLoader ? <>
                  <ReactLoading type="spinningBubbles" color="#d4271c" height={200} width={100} className='list-loader' />
                  {handleShowData()}</> : renderTable()}
              </div>
            </div>


          </Fragment>
          <Modal show={showSummary} onHide={() => { handleCloseSummary() }} size="lg" className="custom-modal-style" centered>
            <Modal.Header className="custom-modal-header d-flex justify-content-end" closeButton></Modal.Header>
            {showLoaderModal ?
              <ReactLoading type="spinningBubbles" color="#d4271c" height={150} width={100} className='modal-loader' />
              : <>
                <Modal.Body className="px-5 py-1 flex-column">
                  <div className=" flex-row">
                    <Container className="col-5 my-auto">
                      <Row><div className="h1 fw-bold my-0">Summary</div></Row>
                      <Row><div className="h7 my-0">BRANCH: {branch}</div></Row>
                      <Row><div className="h7 my-0">DATE:  {formatDate(selectedDetails.DATE_BOOKED)}</div></Row>
                      <Row><div className='h7 my-0'>CUSTOMER: {selectedDetails.CUSTOMER}</div></Row>
                      
                      {/* <Row><div className="h7 my-0">REMARKS: {selectedDetails.REMARKS}</div></Row> */}
                    </Container>

                    <div className="custom-modal-vertline-border mx-3"></div>

                    <Container className="my-auto">
                      {existingSummary && existingSummary.map((data, index) => {
                        return <Row className='nc-modal-custom-row my-1'>
                        <Col xs={1}>{data?.quantity}</Col>
                        <Col xs={4} className="fw-bold">{data?.code ? (data?.code + "-") : ""}{data?.name ? data?.name : ""}</Col>
                        <Col xs={3} className="fw-bold">{data?.total_price ? data?.total_price : ""}</Col>
                        <Col xs={4} className="fw-bold">{data?.commissioner || "-"}</Col>
                        </Row>
                      })}

                      <Row className='nc-modal-custom-row my-1'>
                        <Col xs={7} className="fw-bold"></Col>
                        <Col xs={5} className="fw-bold"></Col>
                      </Row>
                      <Row className='nc-modal-custom-row my-1'>
                        <Col xs={7} className="fw-bold"></Col>
                        <Col xs={5} className="fw-bold"></Col>
                      </Row>
                      <Row className='nc-modal-custom-row my-1'>
                        <Col xs={7} className="fw-bold"></Col>
                        <Col xs={5} className="fw-bold"></Col>
                      </Row>
                      <Row className='nc-modal-custom-row my-1'>
                        <Col xs={7} className="fw-bold"></Col>
                        <Col xs={5} className="fw-bold"></Col>
                      </Row>
                      <Row className='nc-modal-custom-row my-1'>
                        <Col xs={7} className="fw-bold"> </Col>
                        <Col xs={5} className="fw-bold"> </Col>
                      </Row>
                      <Row className='nc-modal-custom-row my-1'>
                        <Col xs={7} className="fw-bold"></Col>
                        <Col xs={5} className="fw-bold"></Col>
                      </Row>
                      <Row className='nc-modal-custom-row my-1'>
                        <Col xs={7} className="fw-bold"></Col>
                        <Col xs={5} className="fw-bold"></Col>
                      </Row>
                      <Row className='nc-modal-custom-row my-1'>
                        <Col xs={7} className="fw-bold"></Col>
                        <Col xs={5} className="fw-bold"></Col>
                      </Row>
                      <Row className='my-1'>
                        <Row className="nc-modal-custom-row my-1">
                          <Col xs={5} className="fw-bold">Sub-Total</Col>
                          <Col xs={5} className="fw-bold">{subTotal?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Col>
                        </Row>
                        <Row className="nc-modal-custom-row my-1">
                          <Col xs={5} className="fw-bold">Discount</Col>
                          <Col xs={5} className="fw-bold">{discount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Col>
                        </Row>
                        <Row className="nc-modal-custom-row my-1">
                          <Col xs={5} className="fw-bold">Grand Total</Col>
                          <Col xs={5} className="fw-bold">{grandTotal?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Col>
                        </Row>
                        <Row className="nc-modal-custom-row my-1">
                          <Col xs={5} className="fw-bold">OR NO.</Col>
                          <Col xs={5} className="fw-bold">
                          <Col xs={5} className="fw-bold">{orNo == null || orNo.length === 0 ? "NONE" : orNo}</Col>

                            {/* {orNo == null || orNo.length === 0 ? "NONE" : orNo.map((data, index) => {
                            if(orNo.length - 1 === index) {
                                return <span>{data.OR_NO}</span>
                              } else {
                                return <span>{data.OR_NO}, </span>
                              }
                            })} */}
                        </Col>
                        </Row>
                        {/* <Row className="nc-modal-custom-row my-1">
                        <Col xs={10} className="fw-bold">COMMISSIONED BY: <br />
                          <h6 className="fw-bold px-5">{existingSummary[0].commissioner}</h6>
                        </Col>
                      </Row> */}
                      </Row>
                    </Container>
                  </div>

                  <hr className="text-danger" />

                  <div className="d-flex flex-column">
                    <div className="h5 my-0 mb-3 text-uppercase fw-bolder">Payment History</div>

                    <div>
                      <TableTemplate 
                        tableType="sales_payment_history"
                        tableHeaders={["DATE", "PAYMENT TYPE", "PAID AMOUNT", "REMARKS"]}
                        tableData={paymentHistory}
                        hasFooter={true}
                        footerData={footer}
                      />
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer className="border-0 px-5">
                  <div className="d-flex flex-row justify-content-between">
                    <div className="btn-conf-wrapper ms-auto">
                    </div>
                  </div>
                </Modal.Footer>
              </>}
          </Modal>

        </div>
      </div>
    </>
  );
}