import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Pagination from '@material-ui/lab/Pagination';
import React, { Fragment, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import DatePicker from "react-datepicker";

// css
import '../../common.css';
import './TransactionsReceipt.css';

// components
import Navbar from '../../navbar';
import TableTemplate from '../../table-template';

// sample data (temp)
import { getBranchName } from '../../../utilities/api_calls/branchApi';
import { getCustomer } from '../../../utilities/api_calls/customerApi';
import { getAllTransactions } from "../../../utilities/api_calls/legalAPI";
import { getSummary } from '../../../utilities/api_calls/paymentApi';
import { getUserName } from '../../../utilities/api_calls/userApi';
import { formatDate, getBranch, getRoleId, getTodayDate } from '../../../utilities/common';
import NoDataPrompt from '../../../utilities/no-data-prompt';

function TransactionsReceipt() {
  const [allTransactions, setAllTransactions] = useState([]);
  const [orNo, setOrNo] = useState([]);
  const [total, setTotal] = useState(0)
  const role = getRoleId()
  const branch = getBranch()

  const [filter, setFilter] = useState({
    branch: "0",
    service: "",
    date_from: new Date(),
    date_to: new Date(),
    search: ''
  });

  // Pagination
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  function handlePageChange(event, value) {
    // console.log(value)
    setCurrentPage(value)
  }


  function selectBranch() {
    if(role === "4") {
      return 0
    }
    if(role === "1") {
      return branch
    }
  }



  // FETCH ALL BOOKINGS WITH OR
  async function fetchData() {
    setAllTransactions([]); // set all transactions to empty para dili masumpay ang prev fetch
    setTotal(0) // set total to 0 every time mag fetch para dili ma add ang total sa prev fetch
    const response = await getAllTransactions(filter);
    console.log(response);
    if (response.response.data) {
      setAllTransactions([])
      setTotal(0)
      let bookings = response.response.data
      setPages(Math.ceil(bookings.length / 9));
      bookings.map(async (data, index) => {
        let branchName = await getBranchName(data.branch_id) // get branch per branch id
        let customerName = await getCustomer(data.customer_id) // get customer per customer id
        let dermTech = await getUserName(data.added_by) // get derm tech per id
        let info = {}

        info.remarks = data.remarks
        info.date = formatDate(data.updated_on) // format date to MMM DD, YYYY
        info.branch = branchName.data.data.name
        info.customer_name = customerName.data.data.first_name + " " + customerName.data.data.last_name //concat first and last name
        info.booking_id = data.id
        info.or_no = data.or_no
        info.grand_total = data.grand_total
        info.derm_tech = dermTech
        setAllTransactions(prev => [...prev, info]) // save current object to all transactions array

        setTotal(prev => prev + parseFloat(data.grand_total)) // compute grand total for table footer

      })
    }
  }

  function handleFilter(e) {
    // console.log(e.target.value)
    const { name, value } = e.target;
    setFilter(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  const handleDateFromChange = (date) => {
    setFilter(prevState => ({
      ...prevState,
      ['date_from']: date
    }))
  }

  const handleDateToChange = (date) => {
    setFilter(prevState => ({
      ...prevState,
      ['date_to']: date
    }))
  }
  // console.log(filter)

  function removeFilter() {
    setFilter({
      branch: "",
      service: "",
      date_from: "",
      date_to: "",
      search: ''
    })
    fetchData();
  }


  React.useEffect(() => {
    fetchData();
  }, [filter]);

  // View transactions 
  const [showSummary, setShowSummary] = useState(false);
  const handleCloseSummary = () => setShowSummary(false);
  const handleShowSummary = () => setShowSummary(true);

  const [selectedDetails, setSelectedDetails] = useState([{
    date: "",
    remarks: "",
    branch: "",
  }])

  const [existingSummary, setExistingSummary] = useState([])
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [footer, setFooter] = useState({total: 0, balance: 0});
  const [subTotal, setSubTotal] = useState("")
  const [grandTotal, setGrandTotal] = useState("")
  const [discount, setDiscount] = useState("")
  const [commissionedBy, setCommissionedBy] = useState("")

  async function viewExistingSummary(id,) {
    let result = await getSummary(id)
    console.log(result)
    let summaryArr = result.data.data.summary
    setOrNo(result.data.data.or_no);
    setPaymentHistory(result.data.data.payment_history === 0 ? [] : result.data.data.payment_history)
    var payments = result.data.data.payment_history;
    var total = 0;
    payments.map((payment) => {
      total += parseInt(payment.PAID_AMOUNT);
    })

    setFooter({total: total, balance: parseInt(result.data.data.grand_total) - total})

    setExistingSummary(summaryArr)
    let subTotal = summaryArr.reduce(function (sum, current) {
      return sum + parseFloat(current.total_price);
    }, 0.00);
    setSubTotal(subTotal)
    setGrandTotal(result.data.data.grand_total)
    setDiscount(result.data.data.discount)
    setCommissionedBy(summaryArr[0].COMMISSIONED_BY)

  }

  function onclickSummary(id, details) { // function for clicking view button
    viewExistingSummary(id);
    setSelectedDetails(details)
    handleShowSummary();
  }

  function searchCustomer(e) {
    if (e.target.value === "") {
      fetchData()
    } else {
      let test = new RegExp(e.target.value.toLowerCase())
      let result = allTransactions.filter(info => test.test(info.customer_name.replace(/\s\s+/g, ' ').toLowerCase()))
      setAllTransactions(result)
    }

  }

  return (
    <>
      <div>
        <Navbar />
        <div className="active-cont">
          <Fragment>

            <div className="d-flex flex-row justify-content-between align-items-center cont-section">
              <h1 className="cont-sect-header">TRANSACTIONS RECEIPT</h1>
              <div className="search-wrapper w-50">
                <input type="search" class="form-control search-indent" placeholder="Search By Customer" name="search" onChange={(e) => searchCustomer(e)} />
                <div className="search-icon">
                  <FontAwesomeIcon
                    className="icon"
                    icon={'search'}
                    aria-hidden="true"
                    alt={'search'}
                    fixedWidth
                  />
                </div>
              </div>
            </div>

            {/** FILTER */}
            <div className="filter-cont mt-0">
              <div className="filter-label">FILTER BY:
                <select name="branch" className="filter-dropdown-input" value={filter.branch} onChange={(e) => handleFilter(e)}>
                  <option value="" hidden>Select Branch</option>
                  <option value="0">All Branches</option>
                  <option value="1">Imelda Veteranos</option>
                  <option value="2">Robinsons Marasbaras</option>
                  <option value="3">Robinsons Place Ormoc</option>
                </select>
              </div>
              <div className='filter-label-row1'>
                DATE FROM:
                <DatePicker selected={filter.date_from} dateFormat="MM/dd/yyyy" className="filter-date-picker mx-3 px-1" name="date_from" placeholderText="mm/dd/yyyy" onChange={date => handleDateFromChange(date)} />
                {/* <input type="date" className="filter-date-picker" name="date_from" value={filter.date_from} onChange={(e) => handleFilter(e)} /> */}

                TO:
                <DatePicker selected={filter.date_to} dateFormat="MM/dd/yyyy" className="filter-date-picker mx-3 px-1" name="date_to" placeholderText="mm/dd/yyyy" onChange={date => handleDateToChange(date)} />
                {/* <input type="date" className="filter-date-picker" name="date_to" value={filter.date_to} onChange={(e) => handleFilter(e)} /> */}

                <div className="btn-conf-wrapper in-line  remove-filter-btn">
                  <Button type="button" className="px-2 py-1 mtn-1" onClick={() => removeFilter()}>Remove Filter</Button>
                </div>
              </div>
            </div>


            <div className="cont-section-novertpad">
              {allTransactions.length === 0 ?
                <NoDataPrompt text="transactions" />
                // <span className="no-data-prompt">No Transactions Found!</span> 
                :
                <>
                <TableTemplate
                  tableType='sample_transactions_receipt'
                  tableHeaders={["Date", "Branch", "Customer Name", "Booking ID", "OR NO.", "grand total", ""]}
                  tableData={allTransactions.sort(function (a, b) { return new Date(b.date) - new Date(a.date); })}
                  hasFooter={true}
                  footerData={parseFloat(total)}
                  tableFuncs={[onclickSummary]}
                  currentPage={currentPage}
                />
                <Pagination count={pages} page={currentPage} shape="rounded" defaultPage={1} siblingCount={2} onChange={handlePageChange} showFirstButton showLastButton />
                </>
                }
            </div>

          </Fragment>

          <Modal show={showSummary} onHide={() => { handleCloseSummary() }} size="lg" className="custom-modal-style" centered>
            <Form>
              <Modal.Header className="custom-modal-header justify-content-end" closeButton></Modal.Header>
              <Modal.Body className="px-5 py-1 flex-column">
              <div className=" flex-row">
                <Container className="col-5 my-auto">
                  <Row><div className="h1 fw-bold my-0">Summary</div></Row>
                  <Row><div className="h4 my-0">BRANCH: {selectedDetails.branch}</div></Row>
                  <Row><div className="h7 my-0">DATE:  {formatDate(selectedDetails.date)}</div></Row>
                  <Row><div className="h7 my-0">CUSTOMER: {selectedDetails.customer_name}</div></Row>
                  <Row><div className="h7 my-0">REMARKS: {selectedDetails.remarks}</div></Row>
                </Container>

                <div className="custom-modal-vertline-border mx-3"></div>

                <Container className="my-auto">
                  {existingSummary && existingSummary.map((data, index) => {
                    return <Row className='nc-modal-custom-row my-1'>
                        <Col xs={1}>{data?.quantity}</Col>
                        <Col xs={4} className="fw-bold">{data?.code ? (data?.code + "-") : ""}{data?.name ? data?.name : ""}</Col>
                        <Col xs={3} className="fw-bold">{data?.total_price ? data?.total_price : ""}</Col>
                        <Col xs={4} className="fw-bold">{data?.commissioner || "-"}</Col>
                    </Row>
                  })}


                  <Row className='nc-modal-custom-row my-1'>
                    <Col xs={7} className="fw-bold"></Col>
                    <Col xs={5} className="fw-bold"></Col>
                  </Row>
                  <Row className='nc-modal-custom-row my-1'>
                    <Col xs={7} className="fw-bold"></Col>
                    <Col xs={5} className="fw-bold"></Col>
                  </Row>
                  <Row className='nc-modal-custom-row my-1'>
                    <Col xs={7} className="fw-bold"></Col>
                    <Col xs={5} className="fw-bold"></Col>
                  </Row>
                  <Row className='nc-modal-custom-row my-1'>
                    <Col xs={7} className="fw-bold"></Col>
                    <Col xs={5} className="fw-bold"></Col>
                  </Row>
                  <Row className='nc-modal-custom-row my-1'>
                    <Col xs={7} className="fw-bold"> </Col>
                    <Col xs={5} className="fw-bold"> </Col>
                  </Row>
                  <Row className='nc-modal-custom-row my-1'>
                    <Col xs={7} className="fw-bold"></Col>
                    <Col xs={5} className="fw-bold"></Col>
                  </Row>
                  <Row className='nc-modal-custom-row my-1'>
                    <Col xs={7} className="fw-bold"></Col>
                    <Col xs={5} className="fw-bold"></Col>
                  </Row>
                  <Row className='nc-modal-custom-row my-1'>
                    <Col xs={7} className="fw-bold"></Col>
                    <Col xs={5} className="fw-bold"></Col>
                  </Row>
                  <Row className='my-1'>
                    <Row className="nc-modal-custom-row my-1">
                      <Col xs={5} className="fw-bold">Sub-Total</Col>
                      <Col xs={5} className="fw-bold">{subTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Col>
                    </Row>
                    <Row className="nc-modal-custom-row my-1">
                      <Col xs={5} className="fw-bold">Discount</Col>
                      <Col xs={5} className="fw-bold">{discount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Col>
                    </Row>
                    <Row className="nc-modal-custom-row my-1">
                      <Col xs={5} className="fw-bold">Grand Total</Col>
                      <Col xs={5} className="fw-bold">{grandTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Col>
                    </Row>
                    <Row className="nc-modal-custom-row my-1">
                      <Col xs={5} className="fw-bold">OR NO.</Col>
                      <Col xs={5} className="fw-bold">
                      <Col xs={5} className="fw-bold">{orNo == null || orNo.length === 0 ? "NONE" : orNo}</Col>

                        {/* {orNo == null || orNo.length === 0 ? "NONE" : orNo.map((data, index) => {
                            if(orNo.length - 1 === index) {
                                return <span>{data.OR_NO}</span>
                              } else {
                                return <span>{data.OR_NO}, </span>
                              }
                            })} */}
                      </Col>
                    </Row>
                  </Row>
                </Container>
                </div>

                <hr className="text-danger" />

                <div className="d-flex flex-column">
                    <div className="h5 my-0 mb-3 text-uppercase fw-bolder">Payment History</div>

                    <div>
                      <TableTemplate 
                        tableType="sales_payment_history"
                        tableHeaders={["DATE", "PAYMENT TYPE", "PAID AMOUNT", "REMARKS"]}
                        tableData={paymentHistory}
                        hasFooter={true}
                        footerData={footer}
                      />
                    </div>
                  </div>
              </Modal.Body>
              <Modal.Footer className="border-0 px-5">
                <div className="d-flex flex-row justify-content-between">
                  <div className="btn-conf-wrapper ms-auto">
                  </div>
                </div>
              </Modal.Footer>
            </Form>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default TransactionsReceipt;