import React, { Fragment, useState } from "react";
import { Button, Row, Col } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import { getTodayDate, numberFormat } from "../../../utilities/common";
import ReactLoading from 'react-loading';

// css
import '../../common.css'
import './daily-products-purchased-report.css'

// components
import Navbar from '../../navbar'
import { getTotalProductsRendered } from "../../../utilities/api_calls/reports/dailyProductsPurchasedApi";




function DailyProductsPurchasedReport() {
  const [showLoader, setShowLoader] = useState(false);
  const mainPage = '/daily-products-purchased-report'
  const dateToday = getTodayDate().toDateString();


  //Total Services Rendered
  const [data, setData] = useState({all: '', one: '', two: '', three: ''});

  async function fetchData() {
    setShowLoader(false)
    const all = await getTotalProductsRendered(0);
    const one = await getTotalProductsRendered(1);
    const two = await getTotalProductsRendered(2);
    const three = await getTotalProductsRendered(3);
    // console.log(all, one, two, three);
    if(!all.data.data.total_products_rendered[0].grand_total || all.error) {
      data['all'] = '0';
    } else if(all.data) {
      data['all'] = all.data.data.total_products_rendered[0].grand_total;
    }

    if(!one.data.data.total_products_rendered[0].grand_total || one.error) {
      data['one'] = '0';
    } else if(one.data) {
      data['one'] = one.data.data.total_products_rendered[0].grand_total;
    }

    if(!two.data.data.total_products_rendered[0].grand_total || two.error) {
      data['two'] = '0';
    } else if(two.data) {
      data['two'] = two.data.data.total_products_rendered[0].grand_total;
    }

    if(!three.data.data.total_products_rendered[0].grand_total || three.error) {
      data['three'] = '0';
    } else if(three.data) {
      data['three'] = three.data.data.total_products_rendered[0].grand_total;
    }
    setShowLoader(true)
  }

  React.useEffect(() => {
    fetchData();
  }, []);



  //Redirect

  const [redirectToAll, setRedirectToAll] = useState(false);
  const [redirectToBranch1, setRedirectToBranch1] = useState(false);
  const [redirectToBranch2, setRedirectToBranch2] = useState(false);
  const [redirectToBranch3, setRedirectToBranch3] = useState(false);


  function handleRedirectToAll() {
    setRedirectToAll(true);
  }
  function handleRedirectToBranch1() {
    setRedirectToBranch1(true);
  }
  function handleRedirectToBranch2() {
    setRedirectToBranch2(true);
  }
  function handleRedirectToBranch3() {
    setRedirectToBranch3(true);
  }


  if (redirectToAll === true) {
    const link = mainPage + '/all/0';
    return (
      <Navigate to={link} />
    )
  } else if (redirectToBranch1 === true) {
    const link = mainPage + '/all/1';
    return (
      <Navigate to={link} />
    )
  } else if (redirectToBranch2 === true) {
    const link = mainPage + '/all/2';
    return (
      <Navigate to={link} />
    )
  } else if (redirectToBranch3 === true) {
    const link = mainPage + '/all/3';
    return (
      <Navigate to={link} />
    )
  }

  // function handleShowData() {
  //   setTimeout(() => setShowLoader(true), 2000);
  // }


  return (
    <>
      <div>
        <Navbar />
        <div className="active-cont">
          <Fragment>
            <div className="cont-section cont-section-prod-purchased-report">

              <Row>
                <Col s={6}>
                  <span className="cont-sect-header">Daily Products Sales</span>
                </Col>
                <Col s={6} className="d-flex justify-content-end">
                  <span className="text-right">Date : {dateToday}</span>
                </Col>
              </Row>

              {/* will change this part to make them dynamic soon */}
              <Row className="branch-report-row d-flex">

                <Col>
                  <div className="d-flex justify-content-center align-items-center report-card">
                    <div className="btn-report-wrapper">
                      <Button type="button" onClick={handleRedirectToAll}>
                        {showLoader ?
                          <>
                            <h6 className="t-bold report-card-text">{numberFormat(data.all)}</h6>
                            {/* <h6 className="report-card-subtext">Total Products Purchased</h6> */}
                          </> :
                          <>
                            {/* {handleShowData()} */}
                            <ReactLoading type="spinningBubbles" color="#d4271c" height={105} width={50} className='products-loader' />
                          </>}
                      </Button>
                    </div>
                  </div>
                  <h1 className="tcenter cont-sect-header">All branches</h1>
                </Col>


                <Col>
                  <div className="d-flex justify-content-center align-items-center report-card">
                    <div className="btn-report-wrapper">
                      <Button type="button" onClick={handleRedirectToBranch1}>
                        {showLoader ?
                          <>
                            <h6 className="t-bold report-card-text">{numberFormat(data.one)}</h6>
                            {/* <h6 className="report-card-subtext">Total Purchased Products</h6> */}
                          </> :
                          <>
                            <ReactLoading type="spinningBubbles" color="#d4271c" height={105} width={50} className='products-loader' />
                          </>}
                      </Button>
                    </div>
                  </div>
                  <h1 className="tcenter cont-sect-header">Imelda Veteranos</h1>
                </Col>


                <Col>
                  <div className="d-flex justify-content-center align-items-center report-card">
                    <div className="btn-report-wrapper">
                      <Button type="button" onClick={handleRedirectToBranch2}>
                        {showLoader ?
                          <>
                            <h6 className="t-bold report-card-text">{numberFormat(data.two)}</h6> 
                            {/* <h6 className="report-card-subtext">Total Products Purchased</h6> */}
                          </> :
                          <>
                            <ReactLoading type="spinningBubbles" color="#d4271c" height={105} width={50} className='products-loader' />
                          </>}
                      </Button>
                    </div>
                  </div>
                  <h1 className="tcenter cont-sect-header">Robinsons Marasbaras</h1>
                </Col>

                <Col>
                  <div className="d-flex justify-content-center align-items-center report-card">
                    <div className="btn-report-wrapper">
                      <Button type="button" onClick={handleRedirectToBranch3}>
                        {showLoader ?
                          <>
                            <h6 className="t-bold report-card-text">{numberFormat(data.three)}</h6> 
                            {/* <h6 className="report-card-subtext">Total Products Purchased</h6> */}
                          </> :
                          <>
                            <ReactLoading type="spinningBubbles" color="#d4271c" height={105} width={50} className='products-loader' />
                          </>}
                      </Button>
                    </div>
                  </div>
                  <h1 className="tcenter cont-sect-header">Robinsons Place Ormoc</h1>
                </Col>
              </Row>

            </div>
          </Fragment>
        </div>
      </div>
    </>
  );
}

export default DailyProductsPurchasedReport;