import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Pagination from '@material-ui/lab/Pagination';
import React, { Fragment, useEffect, useState } from "react";

// css
import '../../common.css';
import './CustomerBalance.css';

// components
import ReactLoading from 'react-loading';
import Navbar from '../../navbar';
import TableTemplate from '../../table-template';

// sample data (temp)
import { Navigate, useParams } from "react-router-dom";
import { getAllBalance } from "../../../utilities/api_calls/customerBalanceAPI";
import NoDataPrompt from '../../../utilities/no-data-prompt';

function CustomerBalance() {
  const { from, page } = useParams();
  const mainPage = '/customer-balance'
  const [redirect, setRedirect] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [balanceReport, setBalanceReport] = useState([])
  const [customerID, setCustomerID] = useState("");

  // Pagination
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  function handlePageChange(event, value) {
    // console.log(value)
    setCurrentPage(value)
  }


  async function fetchBalance() {
    setShowLoader(true);

    const response = await getAllBalance()
    // console.log(response)

    if (response.error) {
      setBalanceReport([]);
    } else {
      setBalanceReport(response.data.data.customers)
      setPages(Math.ceil(response.data.data.customers.length / 11));
    }

    setShowLoader(false);
  }

  useEffect(() => {
    fetchBalance()
  }, [])

  function view(id) {
    setCustomerID(id)
    setRedirect(true);
  }

  if (redirect === true) {
    if (from === "0") {
      return ( // customer balance
        <Navigate to={mainPage + "/details/0/" + page + "/" + customerID} />
      )
    } else if (from === "1") {
      return ( // balance report
        <Navigate to={"/balance-report/details/1/" + page + "/" + customerID} />
      )
    }
  }


  function searchCustomer(e) {
    // console.log(e.target.value)
    if (e.target.value === "") {
      fetchBalance()
    } else {
      let test = new RegExp(e.target.value.toLowerCase())
      // console.log(test)
      let result = balanceReport.filter(info => test.test(info.CUSTOMER.replace(/\s\s+/g, ' ').toLowerCase()))
      setBalanceReport(result)
    }

  }


  function renderTable() {
    if (balanceReport.length < 1) {
      return (
        <NoDataPrompt text="customer balance" />
        // <span className="no-data-prompt">No Customers with Balance Found!</span>
      )
    } else {
      return (
        <>
          <TableTemplate
            tableType='balance'
            tableHeaders={["Customer Name", "Total Balance", ""]}
            tableData={balanceReport}
            tableNavgn={[view]}
            currentPage={currentPage}
          />
          <Pagination count={pages} page={currentPage} shape="rounded" defaultPage={1} siblingCount={2} onChange={handlePageChange} showFirstButton showLastButton />
        </>
      )
    }
  }

  // --- Render --- //

  return (
    <>
      <div>
        <Navbar />
        <div className="active-cont">
          <Fragment>

            <div className="d-flex flex-row justify-content-between cont-section">
              <h1 className="cont-sect-header">Customer Balance</h1>
              <div className="search-wrapper w-50">
                <input type="search" class="form-control search-indent" placeholder="Search by Name" onChange={e => searchCustomer(e)} />
                <div className="search-icon">
                  <FontAwesomeIcon
                    className="icon"
                    icon={'search'}
                    aria-hidden="true"
                    alt={'search'}
                    fixedWidth
                  />
                </div>
              </div>
            </div>

            <div className="cont-section-novertpad customer-balance-table">
              {showLoader ?
                <ReactLoading type="spinningBubbles" color="#d4271c" height={200} width={100} className='list-loader' />
                : renderTable()}
            </div>

          </Fragment>

        </div>
      </div>
    </>
  )
}

export default CustomerBalance;