import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAllBalance } from "../../../utilities/api_calls/customerBalanceAPI";
import { getSalesByType, getSalesByTypeOnBranch, getTotalExpenses, getTotalExpensesOnBranch, getTotalNet, getTotalNetByBranch, getTotalSales, getTotalSalesByBranch } from "../../../utilities/api_calls/reports/dailySalesReportApi";
import { getTotalServicesRendered } from "../../../utilities/api_calls/reports/dailyServicesRenderedApi";
import { getBranch, getRoleId, getTodayDate, numberFormat } from "../../../utilities/common";

import Pagination from '@material-ui/lab/Pagination';
import ReactLoading from 'react-loading';
import Navbar from "../../navbar";
import TableTemplate from "../../table-template";
import NoDataFound from '../no-data-found';

/** CSS **/
import '../../common.css';
import './balance-report.css';
import './daily-sales-report.css';
import './daily-services-rendered.css';
import './reports.css';

export default function Reports() {
    let navigate = useNavigate();

    const dateToday = getTodayDate().toDateString();
    const [clickNavbar, setClickNavbar] = useState(true);
    const [showLoader, setShowLoader] = useState(false);

    /** Total Net **/
    const [totalNet, setTotalNet] = useState("");

    async function fetchTotalNet() {
        setShowLoader(true);

        const response = await getTotalNetByBranch(getBranch());
        // console.log(response)

        if (response.error) {
            setTotalNet('0.00');
        } else {
            setTotalNet(response.data.data.data.NET);
        }
    }

    /** Total Sales **/
    const [totalSales, setTotalSales] = useState("");

    async function fetchTotalSales() {
        const response = await getTotalSalesByBranch(getBranch());
        // console.log(response)

        if (response.error) {
            setTotalSales('0.00');
        } else {
            setTotalSales(response.data.data.data.total_branch_sales);
        }
    }

    /** Total Expenses **/
    const [totalExpenses, setTotalExpenses] = useState("");

    async function fetchTotalExpenses() {
        const response = await getTotalExpensesOnBranch(getBranch());
        // console.log(response)

        if (response.data.data.data.branch_expenses === null || response.error) {
            setTotalExpenses('0.00');
        } else {
            setTotalExpenses(response.data.data.data.branch_expenses);
        }
    }

    /** Services Rendered **/
    const [servicesRendered, setServicesRendered] = useState("");

    async function fetchServicesRendered() {
        const response = await getTotalServicesRendered(getBranch());
        // console.log(response);

        setServicesRendered(response.data.data.services_rendered[0].grand_total);
    }

    /** Sales By Type **/
    const [salesByType, setSalesByType] = useState({ cash: '', card: '', bank_transfer: '', online: '' });

    async function fetchSalesByType() {
        var info1 = { ...salesByType };

        const cash = await getSalesByTypeOnBranch("cash", getBranch());
        console.log(cash)
        if (cash.error) {
            info1["cash"] = '0.00';
        } else {
            info1["cash"] = cash.data.data.data.total_type_sales_on_branch.total_type_sales_on_branch;
        }

        const card = await getSalesByTypeOnBranch("card", getBranch());
        // console.log(card)
        if (card.error) {
            info1["card"] = "0.00";
        } else {
            info1['card'] = card.data.data.data.total_type_sales_on_branch.total_type_sales_on_branch;
        }

        const bank = await getSalesByTypeOnBranch("bank_transfer", getBranch());
        // console.log(bank)
        if (bank.error) {
            info1["bank_transfer"] = "0.00";
        } else {
            info1['bank_transfer'] = bank.data.data.data.total_type_sales_on_branch.total_type_sales_on_branch;
        }

        const online = await getSalesByTypeOnBranch("online", getBranch());
        // console.log(online)
        if (online.error) {
            info1["online"] = "0.00";
        } else {
            info1['online'] = online.data.data.data.total_type_sales_on_branch.total_type_sales_on_branch;
        }
        setSalesByType(info1);
        setShowLoader(false);
    }

    /** Customer Balance **/
    const [customerBalance, setCustomerBalance] = useState([]);
    const [pages, setPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    async function fetchCustomerBalance() {
        setCustomerBalance([])

        const response = await getAllBalance();
        // console.log(response)

        setPages(Math.ceil(response.data.data.customers.length / 11));
        setCustomerBalance(response.data.data.customers)
    }

    function handlePageChange(event, value) {
        setCurrentPage(value)
    }

    function onClickCustomerReport(id) {
        navigate("/customer-balance/details/0/" + currentPage + "/" + id);
    }

    /** Button Templates **/
    function primaryButtons(data, label, link) {
        return (
            <div className="primary-cont-reports" onClick={link}>
                {showLoader ?
                    <ReactLoading type="spinningBubbles" color="#d4271c" height={50} width={50} />
                    : <>
                        <div className="primary-data-reports">
                            {label === "Services Rendered" ? null : <span>PHP</span>}
                            <text>{data}</text>
                        </div>

                        <div className="text-uppercase">
                            {label}
                        </div>
                    </>
                }
            </div>
        )
    }

    function secondaryButtons(data, label, link) {
        return (
            <div className="secondary-cont-reports">
                <div className="secondary-data-reports" onClick={link}>
                    {showLoader ?
                        <ReactLoading type="spinningBubbles" color="#d4271c" height={50} width={50} />
                        : data
                    }
                </div>
                <div className="text-uppercase">
                    {label}
                </div>
            </div>
        )
    }

    async function fetchData() {
        fetchTotalNet();
        fetchTotalSales();
        fetchTotalExpenses();
        fetchServicesRendered();

        fetchSalesByType();

        fetchCustomerBalance();
    }


    React.useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="page-bg">
            {clickNavbar ? <Navbar /> : null}
            <div className={clickNavbar ? "active-cont" : "inactive-cont"}>
                <Fragment>
                    <div className="cont-section cont-section-daily-sales-report">

                        {/* will change this part to make them dynamic soon */}

                        <div className='d-flex align-center justify-content-between align-items-center'>
                            <span className="cont-sect-header mt-0">Daily Reports</span>
                            <span className="text-right">Date : {dateToday}</span>
                        </div>

                        <div className="d-flex flex-row justify-content-between">
                            <div className="primary-cont-reports total-net">
                                {showLoader ?
                                    <ReactLoading type="spinningBubbles" color="#d4271c" height={50} width={50} />
                                    : <>
                                        <div className="primary-data-reports">
                                            <span>PHP</span>
                                            <text>{numberFormat(totalNet)}</text>
                                        </div>
                                        <div className="text-uppercase">
                                            Net Sales
                                        </div>
                                    </>
                                }
                            </div>

                            {primaryButtons(numberFormat(totalSales), 'Total Sales', () => navigate("/daily-sales-report/" + getBranch() + "/total-sales"))}
                            {primaryButtons(numberFormat(totalExpenses), 'Total Expenses', () => navigate("/expenses/1/" + getBranch()))}
                            {/* {primaryButtons(servicesRendered === null ? '0' : servicesRendered, 'Services Rendered', () => navigate("/daily-services-rendered/all/" + getBranch()))} */}
                        </div>

                        <div className="d-flex flex-row justify-content-between">
                            <div className="customer-balance-wrap">
                                <span className="customer-balance-header">Customer Balance Report</span>
                                <div className='customer-balance-report-table'>
                                    {showLoader ?
                                        <ReactLoading type="spinningBubbles" color="#d4271c" height={100} width={100} />
                                        : <div className="w-100">
                                            {customerBalance.length < 1 ?
                                                <NoDataFound
                                                    tableType='balance'
                                                    tableHeaders={["Customer Name", "Total Balance", ""]}
                                                    message="customers with balance"
                                                />
                                                :
                                                <>
                                                    <TableTemplate
                                                        tableType='balance'
                                                        tableHeaders={["Customer Name", "Total Balance", ""]}
                                                        tableData={customerBalance}
                                                        tableNavgn={[onClickCustomerReport]}
                                                        currentPage={currentPage}
                                                    />
                                                    <Pagination count={pages} page={currentPage} shape="rounded" defaultPage={1} siblingCount={2} onChange={handlePageChange} showFirstButton showLastButton />
                                                </>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="secondary-wrapper-reports">
                                <div className="d-flex flex-row justify-content-between">
                                    {secondaryButtons(numberFormat(salesByType.cash), "Cash", () => navigate("/daily-sales-report/" + getBranch() + "/cash"))}
                                    {secondaryButtons(numberFormat(salesByType.card), "Debit/Credit", () => navigate("/daily-sales-report/" + getBranch() + "/card"))}
                                </div>

                                <div className="d-flex flex-row justify-content-between">
                                    {secondaryButtons(numberFormat(salesByType.bank_transfer), "Bank", () => navigate("/daily-sales-report/" + getBranch() + "/bank_transfer"))}
                                    {secondaryButtons(numberFormat(salesByType.online), "Online", () => navigate("/daily-sales-report/" + getBranch() + "/online"))}
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            </div>
        </div>
    )
}