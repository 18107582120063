import { getAge, getBranch, getTodayDate, getToken, getUser, tokenExpiry } from '../../common';
import { getAPICall, postAPICall, putAPICall, deleteAPICall } from '../axiosMethodCalls';

/***************************
 * CUSTOMERS BALANCE
 ***************************/

export const getBalanceReport = async (customerID) => {
    try {
        const response = await getAPICall(window.$link + 'customer_balances/get_customer_balance_report', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            customer_id: customerID,
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}