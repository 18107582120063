import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pagination from "@material-ui/lab/Pagination";
import React, { Fragment, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import ReactLoading from "react-loading";
import Select from "react-select";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// css
import "../../common.css";
import "./gift-certificate.css";

// components
import { getRoleId, refreshPage } from "../../../utilities/common";
import Navbar from "../../navbar";

// sample table (temporary)
import TableTemplate from "../../table-template";

// modals
// import PaymentModal from "../payment_modal";
// import BuyModal from "../BuyModal";
import { getAllBranches } from "../../../utilities/api_calls/branchApi";
import {
  addGiftCertificate,
  availGiftCertificate,
  deleteGiftCertificate,
  deleteMultipleGiftCertificate,
  getAvailableGiftCert,
  getGiftCert,
  getGiftCertPurchased,
  getGiftCertUsed,
  searchGiftCertificate,
  updateGiftCertificate,
} from "../../../utilities/api_calls/giftCertApi";
import { getAllServices } from "../../../utilities/api_calls/servicesApi";
import NoDataPrompt from "../../../utilities/no-data-prompt";
import { useNavigate, useParams } from "react-router-dom";
import { getAllEmployees } from "../../../utilities/api_calls/employeeApi";
import { getAllCustomers } from "../../../utilities/api_calls/customerApi";
//import BuyModal from "../buy_modal";

function GiftCertificate() {
  let navigate = useNavigate();
  const { page } = useParams();

  const [showLoader, setShowLoader] = useState(true);
  const [filter, setFilter] = useState(3);
  const [hasFetchedAvailable, setHasFetchedAvailable] = useState(false);
  const [hasFetchedPurchased, setHasFetchedPurchased] = useState(false);
  const [hasFetchedUsed, setHasFetchedUsed] = useState(false);
  const [toDeleteGCs, setToDeleteGCs] = useState([]);

  // Pagination
  const [availablepages, setAvailablePages] = useState(1);
  const [availablecurrentPage, setAvailableCurrentPage] = useState(
    parseInt(page)
  );

  const [purchasedpages, setPurchasedPages] = useState(1);
  const [purchasedcurrentPage, setPurchasedCurrentPage] = useState(
    parseInt(page)
  );

  const [usedpages, setUsedPages] = useState(1);
  const [usedcurrentPage, setUsedCurrentPage] = useState(parseInt(page));

  function handlePageChange1(event, value) {
    setAvailableCurrentPage(value);
    // fetchGiftCertAvailableWithPage(value);
  }

  function handlePageChange2(event, value) {
    setPurchasedCurrentPage(value);
  }

  function handlePageChange3(event, value) {
    setUsedCurrentPage(value);
  }

  const role = getRoleId();

  //All Services
  const [services, setServices] = useState([]);

  //Get Services
  async function fetchServices() {
    const response = await getAllServices();
    setServices(response.data.data.services);
  }

  const [branches, setBranches] = useState([]);

  //Get all branches
  async function fetchBranches() {
    const response = await getAllBranches();

    setBranches(response.data.data.branches);
  }

  const [giftCertAvailable, setGiftCertAvailable] = useState([]);
  const [giftCertPurchased, setGiftCertPurchased] = useState([]);
  const [giftCertUsed, setGiftCertUsed] = useState([]);
  const [tabSelected, setTabSelected] = useState("available");
  const [isDeleteSuccessful, setIsDeleteSuccessful] = useState(true);

  function handleChecklistChange(e, index, type) {
    const { name } = e.target;
    if (type === "available") {
      var list = [...giftCertAvailable];
      if (name === "CHECKLIST_DELETE" && list.length !== 0) {
        if (list[index][name] === true) {
          list[index][name] = false;
        } else {
          list[index][name] = true;
        }
        setGiftCertAvailable(list);
      }
    } else if (type === "purchased") {
      var list = [...giftCertPurchased];

      if (name === "CHECKLIST_DELETE" && list.length !== 0) {
        if (list[index][name] === true) {
          list[index][name] = false;
        } else {
          list[index][name] = true;
        }
        setGiftCertPurchased(list);
      }
    } else if (type === "used") {
      var list = [...giftCertUsed];

      if (name === "CHECKLIST_DELETE" && list.length !== 0) {
        if (list[index][name] === true) {
          list[index][name] = false;
        } else {
          list[index][name] = true;
        }
        setGiftCertUsed(list);
      }
    }
  }

  //Search Gift Certicate
  async function searchThis(code, type) {
    if (code === "") {
      if (type === "available") {
        setGiftCertAvailable([]);
        fetchGiftCertAvailable();
      } else if (type === "purchased") {
        setGiftCertPurchased([]);
        fetchGiftCertPurchased();
      } else if (type === "used") {
        setGiftCertUsed([]);
        fetchGiftCertUsed();
      }
    } else {
      const response = await searchGiftCertificate(code, type);
      if (type === "available") {
        setGiftCertAvailable([]);
        // setGiftCertAvailable(response.data.data.search);
        let result = response.data.data.search.map((a, index) => {
          return {
            ID: a.ID,
            DATE_ADDED: a.DATE_ADDED,
            CODE: a.CODE,
            NAME: a.NAME,
            BRANCH: a.BRANCH,
            AMOUNT: a.AMOUNT,
            CHECKLIST_DELETE: false,
            INDEX: index,
            TYPE: "available",
          };
        });
        setGiftCertAvailable(result);
      } else if (type === "purchased") {
        setGiftCertPurchased([]);
        setGiftCertPurchased(response.data.data.search);
        let result = response.data.data.search.map((a, index) => {
          return {
            ID: a.ID,
            DATE_ADDED: a.DATE_ADDED,
            CODE: a.CODE,
            NAME: a.NAME,
            BRANCH: a.BRANCH,
            CUSTOMER: a.CUSTOMER,
            CHECKLIST_DELETE: false,
            INDEX: index,
            TYPE: "purchased",
          };
        });
        setGiftCertPurchased(result);
      } else if (type === "used") {
        setGiftCertUsed([]);
        setGiftCertUsed(response.data.data.search);
        let result = response.data.data.search.map((a, index) => {
          return {
            ID: a.ID,
            DATE_ADDED: a.DATE_ADDED,
            CODE: a.CODE,
            NAME: a.NAME,
            BRANCH: a.BRANCH,
            DERM_TECH: a.DERM_TECH,
            CUSTOMER: a.CUSTOMER,
            CHECKLIST_DELETE: false,
            INDEX: index,
            TYPE: "used",
          };
        });
        setGiftCertUsed(result);
      }
    }
  }
  //Get Gift certificates available
  async function fetchGiftCertAvailable() {
    setShowLoader(true);
    setTimeout(() => setShowLoader(false), 1000);
    setGiftCertAvailable([]);
    const response = await getAvailableGiftCert(filter, availablecurrentPage);
    // setAvailablePages(Math.ceil(response.data.data.available.length / 11));
    setAvailablePages(Math.ceil(parseInt(response.data.data.size) / 10));
    let result = response.data.data.available.map((a, index) => {
      return {
        ID: a.ID,
        DATE_ADDED: a.DATE_ADDED,
        CODE: a.CODE,
        NAME: a.NAME,
        BRANCH: a.BRANCH,
        AMOUNT: a.AMOUNT,
        CHECKLIST_DELETE: false,
        INDEX: index,
        TYPE: "available",
      };
    });
    setGiftCertAvailable(result);

    // setGiftCertAvailable(response.data.data.available);
  }

  //Get Gift certificates purchased
  async function fetchGiftCertPurchased() {
    const response = await getGiftCertPurchased(filter, purchasedcurrentPage);
    // setPurchasedPages(Math.ceil(response.data.data.purchased.length / 11));
    setPurchasedPages(Math.ceil(parseInt(response.data.data.size) / 10));
    let result = response.data.data.purchased.map((a, index) => {
      return {
        ID: a.ID,
        DATE_ADDED: a.DATE_ADDED,
        CODE: a.CODE,
        NAME: a.NAME,
        BRANCH: a.BRANCH,
        CUSTOMER: a.CUSTOMER,
        CHECKLIST_DELETE: false,
        INDEX: index,
        TYPE: "purchased",
      };
    });
    setGiftCertPurchased(result);
    // setGiftCertPurchased(response.data.data.purchased);
  }

  //Get Gift certificates used
  async function fetchGiftCertUsed() {
    const response = await getGiftCertUsed(filter, usedcurrentPage);
    // setUsedPages(Math.ceil(response.data.data.used.length / 11));
    setUsedPages(Math.ceil(parseInt(response.data.data.size) / 10));
    let result = response?.data?.data?.used.map((a, index) => {
      return {
        ID: a.ID,
        DATE_ADDED: a.DATE_ADDED,
        DATE_USED: a.DATE_USED,
        CODE: a.CODE,
        NAME: a.NAME,
        BRANCH: a.BRANCH,
        DERM_TECH: a.DERM_TECH,
        CUSTOMER: a.CUSTOMER,
        CHECKLIST_DELETE: false,
        INDEX: index,
        TYPE: "used",
      };
    });
    setGiftCertUsed(result);
    // setGiftCertUsed(response.data.data.used);
  }

  //functions with params (for pagination)

  //Get Gift certificates available
  //   async function fetchGiftCertAvailableWithPage(page) {
  //     setShowLoader(true);
  //     setTimeout(() => setShowLoader(false), 1000);
  //     setGiftCertAvailable([]);
  //     const response = await getAvailableGiftCert(filter, page);
  //     // setAvailablePages(Math.ceil(response.data.data.available.length / 11));
  //     setAvailablePages(Math.ceil(parseInt(response.data.data.size) / 10));
  //     let result = response.data.data.available.map((a, index) => {
  //       return {
  //         ID: a.ID,
  //         DATE_ADDED: a.DATE_ADDED,
  //         CODE: a.CODE,
  //         NAME: a.NAME,
  //         BRANCH: a.BRANCH,
  //         AMOUNT: a.AMOUNT,
  //         CHECKLIST_DELETE: false,
  //         INDEX: index,
  //         TYPE: "available",
  //       };
  //     });
  //     setGiftCertAvailable(result);

  //     // setGiftCertAvailable(response.data.data.available);
  //   }

  // --- Add Gift certificate Hooks --- //
  const [click, setClick] = useState(false);
  const [newGiftCertificate, setNewGiftCertificate] = useState({
    service_id: "",
    branch: "",
    amount: "",
    gc_code_from: "",
    gc_code_to: "",
  });

  // -- Add Item Functions --- //
  const handleAddChange = (e) => {
    const { name, value } = e.target;
    setNewGiftCertificate((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  async function addNewGiftCertificate() {
    if (click === false) {
      setClick(true);
      const response = await addGiftCertificate(newGiftCertificate);
      if (response.data.data.status === 201) {
        toast.success(response.data.data.message.success, { autoClose: 1000 });
        setTimeout(function () {
          navigate("/gift-certificate/" + availablecurrentPage);
          refreshPage();
        }, 1000);
      } else if (response.data.status === 400) {
        toast.error(response.data.message[0]);
      } else {
        toast.error("Error adding gift certicate.");
      }
    }
  }

  // --- Add Gift Certificate Popup/Modal --- //
  const [showAddGiftCertificate, setShowAddGiftCertificate] = useState(false);
  const handleCloseAddGiftCertificate = () => setShowAddGiftCertificate(false);
  const handleShowAddGiftCertificate = () => setShowAddGiftCertificate(true);
  const [isMultiple, setIsMultiple] = useState(false);

  function onclickAddGiftCertificate() {
    handleShowAddGiftCertificate();
  }

  // const [selectedService, setSelectedService] = useState(undefined);
  // function handleAddedServiceChange(e) {
  //     setSelectedService(e.target.value);
  // }

  // --- Edit Gift Certificate Popup/Modal --- //

  // --- Edit Employee Hooks --- //
  const [editGiftCert, setEditGiftCert] = useState({});

  const [selected, setSelected] = useState(""); // for delete and edit
  const [showEditGiftCertificate, setShowEditGiftCertificate] = useState(false);
  const handleCloseEditGiftCertificate = () =>
    setShowEditGiftCertificate(false);
  const handleShowEditGiftCertificate = () => setShowEditGiftCertificate(true);
  // eslint-disable-next-line
  const [isEditMultiple, setIsEditMultiple] = useState(false);

  function onclickEditGiftCertificate(gc_id) {
    fetchGiftCert(gc_id);
    setSelected(gc_id);
    handleShowEditGiftCertificate();
  }

  // -- Edit GC Functions --- //
  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditGiftCert((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [showAvail, setShowAvail] = useState(false);
  const [gc, setGC] = useState({});

  function onClickAvail(id) {
    gc.id = id;
    setShowAvail(true);
  }

  function handleCloseAvail() {
    setGC({});
    setShowAvail(false);
  }

  function handleAvailChange(name, value) {
    const newList = { ...gc };
    newList[name] = value;

    setGC(newList);
  }

  async function avail() {
    const response = await availGiftCertificate(gc);
  }

  /** EMPLOYEES **/
  const [employees, setEmployees] = useState([]);

  async function fetchEmployees() {
    setEmployees([]);
    const response = await getAllEmployees();
    var people = response.data.data.employees;
    people.map((person) => {
      var info = {};

      info.label = person.name;
      info.value = person.id;

      setEmployees((prev) => [...prev, info]);
    });
  }

  /** CUSTOMERs **/
  const [customers, setCustomers] = useState([]);

  async function fetchCustomers() {
    setCustomers([]);
    const response = await getAllCustomers();
    var people = response.data.data.customers.sort((a, b) =>
      a.first_name.toLowerCase() > b.first_name.toLowerCase()
        ? 1
        : b.first_name.toLowerCase() > a.first_name.toLowerCase()
        ? -1
        : 0
    );
    people.map((person) => {
      var info = {};

      info.label =
        person.first_name.toLowerCase() +
        " " +
        person.middle_name.toLowerCase() +
        " " +
        person.last_name.toLowerCase() +
        " " +
        person.suffix.toLowerCase();
      info.value = person.id;

      setCustomers((prev) => [...prev, info]);
    });
  }

  async function updateGiftCert() {
    const response = await updateGiftCertificate(editGiftCert, selected);
    if (response.data.data.status === 200) {
      toast.success(response.data.data.message.success);
      setTimeout(function () {
        navigate("/gift-certificate/" + availablecurrentPage);
        refreshPage();
      }, 3000);
    }
    if (response.data.status === 400) {
      toast.error(response.data.message[0]);
    }
  }

  // --- Delete Gift Certificate Prompt/Modal --- //
  const [showDeleteGiftCertificate, setShowDeleteGiftCertificate] =
    useState(false);
  const handleCloseDeleteGiftCertificate = () =>
    setShowDeleteGiftCertificate(false);
  const handleShowDeleteGiftCertificate = () =>
    setShowDeleteGiftCertificate(true);

  function onclickDeleteGiftCertificate(id) {
    handleShowDeleteGiftCertificate();
    setSelected(id);
  }

  async function delGiftCert() {
    const response = await deleteGiftCertificate(selected);
    if (response.data.data.status === 200) {
      toast.success(response.data.data.message.success);
      setTimeout(function () {
        navigate("/gift-certificate/" + availablecurrentPage);
        refreshPage();
      }, 3000);
    }
    if (response.data.status === 400) {
      toast.error(response.data.message[0]);
    }
  }

  async function delGiftCertMultiple(gcs) {
    const response = await deleteMultipleGiftCertificate(gcs);
    if (response.data.data.status === 200) {
      toast.success(response.data.data.message.success);
      setTimeout(function () {
        navigate("/gift-certificate/" + availablecurrentPage);
        refreshPage();
      }, 3000);
      setIsDeleteSuccessful(true);
    }
    if (response.data.status === 400) {
      toast.error(response.data.message[0]);
    }
  }

  async function delGiftCertSelected(id) {
    const response = await deleteGiftCertificate(id);
    if (response.data.data.status === 200) {
      setIsDeleteSuccessful(true);
    } else {
      setIsDeleteSuccessful(false);
    }
  }

  async function delGiftCertMulti() {
    setToDeleteGCs([]);

    if (tabSelected === "available") {
      let gcs = giftCertAvailable
        .filter((data) => data.CHECKLIST_DELETE === true)
        .map((a) => a.ID);
      delGiftCertMultiple(gcs);
    } else if (tabSelected === "purchased") {
      let gcs = giftCertPurchased
        .filter((data) => data.CHECKLIST_DELETE === true)
        .map((a) => a.ID);
      delGiftCertMultiple(gcs);
    } else if (tabSelected === "used") {
      let gcs = giftCertUsed
        .filter((data) => data.CHECKLIST_DELETE === true)
        .map((a) => a.ID);
      delGiftCertMultiple(gcs);
    }
    if (isDeleteSuccessful) {
      toast.success("Successfully deleted all selected gift certificates!");
      setTimeout(function () {
        navigate("/gift-certificate/" + availablecurrentPage);
        // refreshPage();
      }, 3000);
    } else {
      toast.error("Error! Unsucessful delete of gift certificates!");
    }
  }

  //Gift Certificate Details
  async function fetchGiftCert(id) {
    const response = await getGiftCert(id);
    setEditGiftCert(response.data.data[0]);
  }

  function renderTable(label) {
    if (label === "available") {
      if (giftCertAvailable.length === 0) {
        return (
          <NoDataPrompt text="available gift certificates" />
          // <span className="no-data-prompt">No Gift Certificates Available found!</span>
        );
      } else {
        return (
          <>
            <TableTemplate
              tableType="available_gift_certificates"
              tableHeaders={[
                "",
                "Date Added",
                "Code",
                "Name",
                "Branch",
                "Amount",
                "",
              ]}
              tableData={giftCertAvailable.sort(function (a, b) {
                return new Date(b.DATE_ADDED) - new Date(a.DATE_ADDED);
              })}
              tableFuncs={[
                onclickDeleteGiftCertificate,
                onclickEditGiftCertificate,
                handleChecklistChange,
              ]}
              currentPage={availablecurrentPage}
            />
            <Pagination
              count={parseInt(availablepages > 1 ? availablepages : 1)}
              page={availablecurrentPage}
              shape="rounded"
              defaultPage={1}
              siblingCount={2}
              onChange={handlePageChange1}
              showFirstButton
              showLastButton
            />
          </>
        );
      }
    } else if (label === "purchased") {
      if (giftCertPurchased.length === 0) {
        return (
          <NoDataPrompt text="purchased gift certificates" />
          // <span className="no-data-prompt">No Gift Certificates Purchased found!</span>
        );
      } else {
        return (
          <>
            <TableTemplate
              tableType="sample_purchased_gift_certificates"
              tableHeaders={[
                "",
                "Date Purchased",
                "Code",
                "Description",
                "Branch",
                "Customer",
                "",
              ]}
              tableData={giftCertPurchased.sort(function (a, b) {
                return new Date(b.DATE_ADDED) - new Date(a.DATE_ADDED);
              })}
              //tableData={getSampleGiftCertificatesPurchased()}
              tableFuncs={[
                onclickDeleteGiftCertificate,
                onclickEditGiftCertificate,
                handleChecklistChange,
              ]}
              currentPage={purchasedcurrentPage}
            />
            <Pagination
              count={parseInt(purchasedpages > 2 ? purchasedpages : 1)}
              page={purchasedcurrentPage}
              shape="rounded"
              defaultPage={1}
              siblingCount={2}
              onChange={handlePageChange2}
              showFirstButton
              showLastButton
            />
          </>
        );
      }
    } else if (label === "used") {
      if (giftCertUsed.length === 0) {
        return (
          <NoDataPrompt text="used gift certificates" />
          // <span className="no-data-prompt">No Gift Certificates Used found!</span>
        );
      } else {
        return (
          <>
            <TableTemplate
              tableType="sample_used_gift_certificates"
              tableHeaders={[
                "",
                "Date Added",
                "Date Used",
                "Code",
                "Description",
                "Branch",
                "Derm Tech",
                "Customer",
              ]}
              tableData={giftCertUsed.sort(function (a, b) {
                return new Date(b.DATE_ADDED) - new Date(a.DATE_ADDED);
              })}
              tableFuncs={[
                onclickDeleteGiftCertificate,
                onclickEditGiftCertificate,
                handleChecklistChange,
                null,
              ]}
              currentPage={usedcurrentPage}
            />
            <Pagination
              count={parseInt(usedpages > 2 ? usedpages : 1)}
              page={usedcurrentPage}
              shape="rounded"
              defaultPage={1}
              siblingCount={2}
              onChange={handlePageChange3}
              showFirstButton
              showLastButton
            />
          </>
        );
      }
    }
  }

  function handleShowData() {
    setTimeout(() => setShowLoader(false), 5000);
  }

  React.useEffect(() => {
    fetchGiftCertAvailable();
    // eslint-disable-next-line
  }, [filter]);

  React.useEffect(() => {
    fetchBranches();
    fetchServices();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (!hasFetchedAvailable && tabSelected === "available") {
      fetchGiftCertAvailable();
      //   setHasFetchedAvailable(true);
    }
    // eslint-disable-next-line
  }, [tabSelected, availablecurrentPage]);

  React.useEffect(() => {
    if (!hasFetchedUsed && tabSelected === "used") {
      fetchGiftCertUsed();
      setHasFetchedUsed(true);
    }
    // eslint-disable-next-line
  }, [tabSelected, usedcurrentPage]);

  React.useEffect(() => {
    if (tabSelected === "purchased") {
      fetchGiftCertPurchased();
      setHasFetchedPurchased(true);
    }
    // eslint-disable-next-line
  }, [tabSelected, purchasedcurrentPage]);

  return (
    <>
      <div>
        <Navbar />
        <div className="active-cont">
          <Fragment>
            {/* <ToastContainer /> */}

            {/* header */}
            <div className="d-flex flex-row justify-content-between cont-section">
              <h1 className="cont-sect-header">Gift Certificate</h1>
              <div className="search-wrapper w-50">
                <input
                  type="search"
                  class="form-control search-indent"
                  placeholder="Search by Code"
                  onChange={(e) => searchThis(e.target.value, tabSelected)}
                />
                <div className="search-icon">
                  <FontAwesomeIcon
                    className="icon"
                    icon={"search"}
                    aria-hidden="true"
                    alt={"search"}
                    fixedWidth
                  />
                </div>
              </div>
            </div>

            <div className="row filter-add-cont">
              <div className="col">
                {/** FILTER */}
                <div className="filter-cont-2">
                  <div className="filter-label">
                    FILTER BY:
                    <select
                      className="filter-dropdown-input"
                      defaultValue={"3"}
                      name="branch"
                      onChange={(e) => setFilter(e.target.value)}
                    >
                      <option selected value="" hidden>
                        Robinsons Ormoc
                      </option>
                      <option selected value="">
                        All Branches
                      </option>
                      {branches.map((branch) => {
                        return (
                          <option key={branch.id} value={branch.id}>
                            {branch.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-3 d-flex justify-content-end">
                <div className="btn-conf-wrapper">
                  {role === "4" && (
                    <Button
                      type="button"
                      className="px-5 py-1 add-gift-btn"
                      onClick={handleShowAddGiftCertificate}
                    >
                      add gift certificate
                    </Button>
                  )}
                </div>
                <div className="btn-conf-wrapper">
                  {role === "4" && (
                    <Button
                      type="button"
                      className="px-5 py-1 delete-selected-btn"
                      onClick={() => delGiftCertMulti()}
                    >
                      delete selected
                    </Button>
                  )}
                </div>
              </div>
            </div>

            {/* table section - temp */}
            <div className="cont-section-novertpad giftcerts-table">
              <Tabs
                defaultActiveKey="available_giftcert"
                id="customer-transaction"
                variant="pills"
                className="customer-transaction-tab"
                activeKey={tabSelected}
                onSelect={(k) => setTabSelected(k)}
              >
                <Tab
                  eventKey="available"
                  title="Available"
                  onSelect={() => setTabSelected("available")}
                >
                  {showLoader ? (
                    <>
                      <ReactLoading
                        type="spinningBubbles"
                        color="#d4271c"
                        height={200}
                        width={100}
                        className="list-loader"
                      />
                      {handleShowData()}
                    </>
                  ) : (
                    renderTable("available")
                  )}
                </Tab>
                <Tab
                  eventKey="purchased"
                  title="Purchased"
                  onSelect={() => setTabSelected("purchased")}
                >
                  {showLoader ? (
                    <>
                      <ReactLoading
                        type="spinningBubbles"
                        color="#d4271c"
                        height={200}
                        width={100}
                        className="list-loader"
                      />
                      {handleShowData()}
                    </>
                  ) : (
                    renderTable("purchased")
                  )}
                </Tab>
                <Tab
                  eventKey="used"
                  title="Used"
                  onSelect={() => setTabSelected("used")}
                >
                  {showLoader ? (
                    <>
                      <ReactLoading
                        type="spinningBubbles"
                        color="#d4271c"
                        height={200}
                        width={100}
                        className="list-loader"
                      />
                      {handleShowData()}
                    </>
                  ) : (
                    renderTable("used")
                  )}
                </Tab>
              </Tabs>
            </div>
          </Fragment>

          {/* ADD GiftCertificate MODAL*/}
          <Modal
            show={showAddGiftCertificate}
            onHide={handleCloseAddGiftCertificate}
            size="lg"
            className="custom-modal-style"
            centered
          >
            <Modal.Header
              className="custom-modal-header d-flex justify-content-end"
              closeButton
            ></Modal.Header>
            <Modal.Body className="px-5 py-1">
              <h1 className="custom-modal-body-title">Add Gift Certificate</h1>
              <Container>
                <Row className="nc-modal-custom-row">
                  <Col>Code</Col>
                  <Col xs={1}>:</Col>
                  <Col xs={7}>
                    <Row>
                      {isMultiple === false && (
                        <Col xs={11}>
                          <input
                            type="text"
                            name="gc_code_from"
                            className="nc-modal-custom-input full-width"
                            onChange={(e) => handleAddChange(e)}
                            required
                          />
                        </Col>
                      )}

                      {isMultiple === true && <Col xs={2}>From:</Col>}

                      {isMultiple === true && (
                        <Col xs={4}>
                          <input
                            type="text"
                            name="gc_code_from"
                            className="nc-modal-custom-input full-width"
                            onChange={(e) => handleAddChange(e)}
                            required
                          />
                        </Col>
                      )}

                      {isMultiple === true && <Col xs={1}>TO:</Col>}

                      {isMultiple === true && (
                        <Col xs={4}>
                          <input
                            type="text"
                            name="gc_code_to"
                            className="nc-modal-custom-input full-width"
                            onChange={(e) => handleAddChange(e)}
                            required
                          />
                        </Col>
                      )}

                      <input
                        type="checkbox"
                        className="input-checkbox"
                        onClick={() => setIsMultiple(!isMultiple)}
                      />
                    </Row>
                  </Col>
                </Row>
                <Row className="nc-modal-custom-row">
                  <Col>Branch</Col>
                  <Col xs={1}>:</Col>
                  <Col xs={7}>
                    <Form.Select
                      className="nc-modal-custom-input"
                      name="branch"
                      onChange={(e) => handleAddChange(e)}
                    >
                      <option value="" hidden>
                        --SELECT BRANCH--
                      </option>
                      {branches.map((branch) => {
                        return (
                          <option key={branch.id} value={branch.id}>
                            {branch.name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Col>
                </Row>
                <Row className="nc-modal-custom-row">
                  <Col>Amount</Col>
                  <Col xs={1}>:</Col>
                  <Col xs={7}>
                    <Form.Control
                      type="number"
                      min="0.01"
                      step="0.01"
                      name="amount"
                      className="nc-modal-custom-input"
                      onChange={(e) => handleAddChange(e)}
                    />
                  </Col>
                </Row>
                <Row className="nc-modal-custom-row">
                  <Col>Service</Col>
                  <Col xs={1}>:</Col>
                  <Col xs={7}>
                    <Form.Select
                      className="nc-modal-custom-input"
                      name="service_id"
                      onChange={(e) => handleAddChange(e)}
                    >
                      <option value="" hidden>
                        --SELECT SERVICE--
                      </option>
                      {services.map((service) => {
                        return (
                          <option key={service.id} value={service.id}>
                            {service.name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Col>
                </Row>
              </Container>
            </Modal.Body>
            <Modal.Footer className="border-0 px-5">
              <div className="d-flex flex-row justify-content-between">
                {click === true && (
                  <div className="d-flex justify-content-center">
                    <div className="btn-conf-wrapper ms-auto">
                      <Button type="submit" className="px-5 pb-1 mb-2">
                        <ReactLoading
                          type="spinningBubbles"
                          color="#d4271c"
                          height={25}
                          width={20}
                        />
                      </Button>
                    </div>
                  </div>
                )}
                {click === false && (
                  <div className="btn-conf-wrapper ms-auto">
                    <Button
                      type="submit"
                      className="px-5 pb-1 mb-2"
                      onClick={() => addNewGiftCertificate()}
                    >
                      save
                    </Button>
                  </div>
                )}
              </div>
            </Modal.Footer>
          </Modal>

          {/* EDIT GiftCertificate MODAL*/}
          <Modal
            show={showEditGiftCertificate}
            onHide={handleCloseEditGiftCertificate}
            size="lg"
            className="custom-modal-style"
            centered
          >
            <Modal.Header
              className="custom-modal-header d-flex justify-content-end"
              closeButton
            ></Modal.Header>
            <Modal.Body className="px-5 py-1">
              <h1 className="custom-modal-body-title">Edit Gift Certificate</h1>
              <Container>
                <Row className="nc-modal-custom-row">
                  <Col>Code</Col>
                  <Col xs={1}>:</Col>
                  <Col xs={7}>
                    <Row>
                      {isEditMultiple === false && (
                        <Col xs={12}>
                          <input
                            type="text"
                            name="gift_certificate_code"
                            className="nc-modal-custom-input full-width"
                            value={editGiftCert.gift_certificate_code}
                            onChange={(e) => handleEditChange(e)}
                            required
                          />
                        </Col>
                      )}
                      {/* {isEditMultiple === true && (<Col xs={2}>From:</Col>)}
                            {isEditMultiple === true && (<Col xs={3}><input type="text" name="gc_code_from" className="nc-modal-custom-input full-width" required/></Col>)}
                            {isEditMultiple === true && (<Col xs={1}>TO:</Col>)}
                            {isEditMultiple === true && (<Col xs={4}><input type="text" name="gc_code_to" className="nc-modal-custom-input full-width" required/></Col>)}
                            <input type="checkbox" className="input-checkbox" onClick={() => setIsEditMultiple(!isEditMultiple)}/> */}
                    </Row>
                  </Col>
                </Row>
                <Row className="nc-modal-custom-row">
                  <Col>Branch</Col>
                  <Col xs={1}>:</Col>
                  <Col xs={7}>
                    <Form.Select
                      className="nc-modal-custom-input"
                      name="branch_id"
                      value={editGiftCert.branch_id}
                      onChange={(e) => handleEditChange(e)}
                    >
                      {branches.map((branch) => {
                        return (
                          <option key={branch.id} value={branch.id}>
                            {branch.name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Col>
                </Row>
                <Row className="nc-modal-custom-row">
                  <Col>Amount</Col>
                  <Col xs={1}>:</Col>
                  <Col xs={7}>
                    <Form.Control
                      type="number"
                      min="0.01"
                      step="0.01"
                      name="amount"
                      className="nc-modal-custom-input"
                      value={editGiftCert.amount}
                      onChange={(e) => handleEditChange(e)}
                    />
                  </Col>
                </Row>
                <Row className="nc-modal-custom-row">
                  <Col className="fw-bold pb-2">Service</Col>
                  <Col xs={1}>:</Col>
                  <Col xs={7}>
                    <Form.Select
                      className="nc-modal-custom-input"
                      name="service_id"
                      value={editGiftCert.service_id}
                      onChange={(e) => handleEditChange(e)}
                    >
                      {services.map((service) => {
                        return (
                          <option key={service.id} value={service.id}>
                            {service.name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Col>
                </Row>
              </Container>
            </Modal.Body>
            <Modal.Footer className="border-0 px-5">
              <div className="d-flex flex-row justify-content-between">
                <div className="btn-conf-wrapper ms-auto">
                  <Button
                    type="submit"
                    className="px-5 pb-1 mb-2"
                    onClick={() => updateGiftCert()}
                  >
                    save
                  </Button>
                </div>
              </div>
            </Modal.Footer>
          </Modal>

          {/* DELETE Gift Certificate MODAL*/}
          <Modal
            show={showDeleteGiftCertificate}
            onHide={handleCloseDeleteGiftCertificate}
            size="lg"
            className="custom-modal-style"
            centered
          >
            <Modal.Header className="custom-modal-header del-prompt-header-custom"></Modal.Header>
            <Modal.Body className="px-5">
              <Container className="d-flex flex-row">
                <FontAwesomeIcon
                  className="del-prompt-icon"
                  icon={"trash-alt"}
                  aria-hidden="true"
                  alt={"trash-alt"}
                  fixedWidth
                />
                <div className="ms-2">
                  <p className="del-prompt-par">
                    Are you sure you want to delete this gift cert?
                  </p>
                  <p className="del-prompt-subpar">
                    This gift certificate will be deleted immediately.
                    <br />
                    You can't undo this action.
                  </p>
                </div>
              </Container>
            </Modal.Body>
            <Modal.Footer className="border-0 px-5">
              <div className="cancel-btn-conf-wrapper">
                <Button
                  type="button"
                  className="px-3"
                  onClick={handleCloseDeleteGiftCertificate}
                >
                  cancel
                </Button>
              </div>
              <div className="btn-conf-wrapper">
                <Button
                  type="submit"
                  className="px-3"
                  onClick={() => delGiftCert()}
                >
                  delete
                </Button>
              </div>
            </Modal.Footer>
          </Modal>

          {/* avail gift certificate */}
          <Modal
            show={showAvail}
            onHide={handleCloseAvail}
            size="lg"
            className="custom-modal-style"
            centered
          >
            <Modal.Header
              className="custom-modal-header d-flex justify-content-between"
              closeButton
            >
              <h1 className="custom-modal-body-title me-0 ms-3">
                Avail Gift Certificate
              </h1>
            </Modal.Header>
            <Modal.Body className="px-5 py-1">
              <Container>
                <Row className="nc-modal-custom-row gift-cert-form">
                  <Col>Availed By</Col>
                  <Col xs={1}>:</Col>
                  <Col xs={7}>
                    <Select
                      name="customer_id"
                      className="react-select-container-modal"
                      classNamePrefix="dropdown-modal"
                      placeholder="Select Customer..."
                      value={customers.filter(
                        (info) => info.value === gc.customer_id
                      )}
                      options={customers}
                      onChange={(e) =>
                        handleAvailChange("customer_id", e.value)
                      }
                    />
                    {!gc.customer_id && (
                      <div className="validity-error">
                        Availed By is required*
                      </div>
                    )}
                  </Col>
                </Row>
                <Row className="nc-modal-custom-row gift-cert-form">
                  <Col>Commissioned By</Col>
                  <Col xs={1}>:</Col>
                  <Col xs={7}>
                    <Select
                      name="commissioned_by"
                      className="react-select-container-modal"
                      classNamePrefix="dropdown-modal"
                      placeholder="Select Employee..."
                      value={employees.filter(
                        (info) => info.value === gc.commissioned_by
                      )}
                      options={employees}
                      onChange={(e) =>
                        handleAvailChange("commissioned_by", e.value)
                      }
                    />
                    {!gc.commissioned_by && (
                      <div className="validity-error">
                        Commissioned by is required*
                      </div>
                    )}
                  </Col>
                </Row>
                <Row className="nc-modal-custom-row">
                  <Col>Remarks</Col>
                </Row>
                <Row className="nc-modal-custom-row">
                  <Col>
                    <Form.Control
                      as="textarea"
                      className="nc-modal-custom-input"
                      rows={5}
                      name="remarks"
                      onChange={(e) =>
                        handleAvailChange("remarks", e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Container>
            </Modal.Body>
            <Modal.Footer className="border-0 px-5">
              <div className="d-flex flex-row justify-content-between">
                <div className="btn-conf-wrapper ms-auto">
                  <Button
                    type="submit"
                    className="px-5 pb-1 mb-2"
                    onClick={() => avail()}
                  >
                    save
                  </Button>
                </div>
              </div>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default GiftCertificate;
