import { formatMDY, getBranch, getToken, getUser, tokenExpiry } from '../common';
import { getAPICall } from './axiosMethodCalls';

/***************************
 * SERVICES
 ***************************/

//GET
export const getAllServices = async (id) => {
    try {
        const response = await getAPICall(window.$link + 'services/getAll', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

//Get
export const searchServices = async (name) => {
    try {
        const response = await getAPICall(window.$link + 'services/search', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            name: name,
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

//GET
export const getService = async (id) => {
    try {
        const response = await getAPICall(window.$link + 'services/view/' + id, {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

//POST
export const addService = async (service) => {
    // console.log(service)
    try {
        const response = await getAPICall(window.$link + 'services/create', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            name: service.name,
            details: service.details,
            price: service.price,
            remarks: "",
            free_service_exempt: service.isExempted === "on" ? 1 : 0
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

//POST
export const availService = async (service, table_origin, service_id, id) => {
    // console.log(service)
    try {
        const response = await getAPICall(window.$link + 'services/avail_service/' + service_id + "/" + table_origin, {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            updated_by: getUser(),
            branch_id: getBranch(),
            customer_id: id,
            id: service_id,
            table_origin: table_origin,
            serviced_on: formatMDY(service.serviced_on),
            serviced_by: service.serviced_by,
            remarks: service.remarks,
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

//POST
export const updateServiceDetails = async (service, id) => {
    try {
        const response = await getAPICall(window.$link + 'services/update/' + id, {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            updated_by: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            name: service.name,
            details: service.details,
            price: service.price,
            remarks: "",
            free_service_exempt: service.free_service_exempt,
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

//DELETE
export const deleteService = async (id) => {
    try {
        const response = await getAPICall(window.$link + 'services/delete/' + id, {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            updated_by: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

