import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Pagination from '@material-ui/lab/Pagination';
import React, { Fragment, useState } from "react";
import DatePicker from "react-datepicker";
import ReactLoading from 'react-loading';
import Select from 'react-select';

// css
import '../../common.css';
import './inventory.css';
import './item-history.css';

// components
import Navbar from '../../navbar';
import TableTemplate from '../../table-template';

// sample data (temp)
import { getAllBranches } from "../../../utilities/api_calls/branchApi";
import { getAllItems, getInventoryHistory, searchInventoriesHistory } from "../../../utilities/api_calls/inventoryApi";
import { getTodayDate } from "../../../utilities/common";
import NoDataPrompt from '../../../utilities/no-data-prompt';


function ItemHistory() {
  const [showLoader, setShowLoader] = useState(true);

  // Pagination
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  function handlePageChange(event, value) {
    // console.log(value)
    setCurrentPage(value)
    // let page_from = (value * 11)  - 11;
    // setHistory(allHistory.slice(page_from, page_from + 11));
  }

  // console.log(filter)

  //History
  const [history, setHistory] = useState([]);
  const [allHistory, setAllHistory] = useState([]);
  const [searchName, setSearchName] = useState("")
  //Get History
  async function fetchHistory() {
    setShowLoader(true);
    setTimeout(() => setShowLoader(false), 1000);
    // console.log(filter)
    setHistory([])
    const response = await getInventoryHistory(filter, searchName);
    console.log(response.data)

    if(response.data){
    setPages(Math.ceil(response.data.data.history.length / 11));
    // console.log(response);
    setHistory(response.data.data.history);
    // let page_from = (1 * 11)  - 11;
    // setHistory(response.data.data.services.slice(page_from, 11));
    }else{
      setHistory([])
    }
  }

  //Search Inventories History
  async function searchThis(name) {
    console.log(name)
    setSearchName(name)
    // if (name === "") {
    //   setHistory([])
      // fetchHistory(name, filter);
    // } else {
    //   setHistory([])
    //   const response = await searchInventoriesHistory(name, filter);
    //   console.log(response.data.data)
    //   setHistory(response.data.data);
    //   setPages(Math.ceil(response.data.data.length / 10));

    // }
  }

  //All Branches
  const [branches, setBranches] = useState([]);

  //Get Branches
  async function fetchBranches() {
    const response = await getAllBranches();
    // console.log(response);
    setBranches(response.data.data.branches);
  }

  //Filter
  const [filter, setFilter] = useState({
    branch: "",
    item: "",
    date_from: getTodayDate(),
    date_to: getTodayDate(),
  });

  // -- Filter Functions --- //
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  const handleDateFromChange = (date) => {
    setFilter(prevState => ({
      ...prevState,
      ['date_from']: date
    }))
  }

  const handleDateToChange = (date) => {
    setFilter(prevState => ({
      ...prevState,
      ['date_to']: date
    }))
  }

  // select Items
  const [clear, setClear] = useState(true);
  const [itemOptions, setItemOptions] = useState([]);
  const [value, setValue] = useState({})

  const handleFilterItems = (e) => {
    setClear(false)
    setFilter(prevState => ({
      ...prevState,
      ['item']: e.value
    }));
    setValue({ label: e.label, value: e.value })
  }

  //All Inventories
  const [items, setItems] = useState([]);

  //Get Inventories
  async function fetchItems() {
    const response = await getAllItems();
    // console.log(response);
    const allItems = response.data.data.items
    setItems(allItems);

    // set Options
    setItemOptions([]);
    var all = { name: "All Items", id: "" };
    setItemOptions(prevState => [...prevState, all]);

    allItems.map((data) => {
      var info = {};

      info.name = data.name;
      info.id = data.id;

      setItemOptions(prevState => [...prevState, info]);
    })
  }

  const options = itemOptions.map((emp) => (
    {
      label: emp.name,
      value: emp.id,
    }
  ))

  function renderTable() {
    // console.log(history.length);
    if (history.length === 0) {
      return (
        <div className="cont-section-novertpad history-table">
          <NoDataPrompt text="item history" />
          {/* <span className="no-data-prompt">No History found!</span> */}
        </div>
      )
    } else {
      return (<>
        <div className="cont-section-novertpad history-table">
          <TableTemplate
            tableType='item_history'
            tableHeaders={["Date", "Product Name", "Type", "Branch", "Previous Quantity", "Purchased/ Returned Quantity", "Remaining Quantity", "Customer", "Added By", "Remarks"]}
            tableData={history}
            currentPage={currentPage}
          />
          <Pagination count={pages} page={currentPage} shape="rounded" defaultPage={1} siblingCount={2} onChange={handlePageChange} showFirstButton showLastButton />
        </div>
      </>)
    }
  }

  function handleShowData() {
    setTimeout(() => setShowLoader(false), 5000);
  }

  React.useEffect(() => {
    fetchHistory();
    // eslint-disable-next-line
  }, [filter, searchName]);

  React.useEffect(() => {
    fetchBranches();
    fetchItems();
  }, []);

  return (
    <>
      <div>
        <Navbar />
        <div className="active-cont">
          <Fragment>

            <div className="d-flex flex-row justify-content-between cont-section">
              <h1 className="cont-sect-header">Inventory Report</h1>
              <div className="search-wrapper w-50">
                <input type="search" class="form-control search-indent" placeholder="Search by Name" onChange={(e) => searchThis(e.target.value)} />
                <div className="search-icon">
                  <FontAwesomeIcon
                    className="icon"
                    icon={'search'}
                    aria-hidden="true"
                    alt={'search'}
                    fixedWidth
                  />
                </div>
              </div>
            </div>

            {/** FILTER */}
            <div className="filter-cont mt-0">
              <div className="filter-label-row1">FILTER BY:
                <select className="filter-dropdown-input" name="branch" onChange={(e) => handleFilterChange(e)}>
                  <option value="" selected>All Branches</option>
                  {branches.map((data, index) => {
                    return <option value={data.id}>{data.name}</option>
                  })}
                </select>
                <Select className="react-select-container" classNamePrefix="react-select" value={clear ? options[0] : value} placeholder="Select Item..." options={options} onChange={(e) => handleFilterItems(e)} />
                {/* <select className="filter-dropdown-input" name="item" onChange={(e) => handleFilterChange(e)}>
                  <option value="" selected>All Items</option>
                  {items.map((data, index) => {
                    return <option value={data.id}>{data.name}</option>
                  })}
                </select> */}
              </div>
              <div className='filter-label-row1'>
                <span className="filter-label">DATE FROM: </span>
                <DatePicker selected={filter.date_from} dateFormat="MM/dd/yyyy" className="filter-date-picker mx-3 px-1" name="date_from" placeholderText="mm/dd/yyyy" onChange={date => handleDateFromChange(date)} />
                {/* <input type="date" className="filter-date-picker" name="date_from" value={filter.date_from} onChange={(e) => handleFilterChange(e)} /> */}
                <span className="filter-label mb-2">TO: </span>
                <DatePicker selected={filter.date_to} dateFormat="MM/dd/yyyy" className="filter-date-picker mx-3 px-1" name="date_to" placeholderText="mm/dd/yyyy" onChange={date => handleDateToChange(date)} />
                {/* <input type="date" className="filter-date-picker" name="date_to" value={filter.date_to} onChange={(e) => handleFilterChange(e)} /> */}
              </div>
            </div>

            {showLoader ?
              <ReactLoading type="spinningBubbles" color="#d4271c" height={200} width={100} className='list-loader' />
              : renderTable()}

          </Fragment>

        </div>
      </div>
    </>
  );
}

export default ItemHistory;