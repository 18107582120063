import React, { useState, useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getBranch, getRoleId, removeUserSession } from '../utilities/common';
import ReactLoading from 'react-loading';

import UserPreference from '../utilities/user_preference';

// css
import './navbar.css'

// logo
import cleanLogo from '../images/navbar-logo.png'

// navbar icons
import customerIcon from '../images/icons/customer-icon.png'
import DailyTransac from '../images/icons/daily-transactions-icon.png'
import expensesIcon from '../images/icons/expenses-icon.png'
import userIcon from '../images/icons/user-icon.png'
import inventoryIcon from '../images/icons/inventory-icon.png'
import reportIcon from '../images/icons/report.png'
import manageIcon from '../images/icons/manage-icon.png'
import gcIcon from '../images/icons/giftcert-icon.png'
import fsIcon from '../images/icons/free-service-icon.png'
import customerBal from '../images/icons/customer-balance-icon.png'
import transacReceipt from '../images/icons/transactions-receipt-icon.png'

// carets for dropdown
function caretDown() {
    return (<FontAwesomeIcon icon={"caret-down"} alt={"caret-down"} aria-hidden="true" className="caret-icon align-self-center" />)
}

function caretUp() {
    return (<FontAwesomeIcon icon={"caret-up"} alt={"caret-up"} aria-hidden="true" className="caret-icon align-self-center" />)
}

// admin/owner navbar
function adminOwnerNavbar(showInventory, setShowInventory, showManage, setShowManage, showReport, setShowReport, branchId) {

    return (
        <>
            <div className="side-navbar d-flex justify-content-between flex-column active-nav" id="sidebar">
                <ul className="nav flex-column text-white w-100 nav-elixir-custom"> {/* this might be involved with css responsive idk */}

                    <div className="mx-auto">
                        <img src={cleanLogo} alt={'cleanLogo'} className="my-4"></img>
                    </div>
                    
                    <div className='mx-auto branch-name-nav'>
                        <span className='d-flex justify-content-center'>
                            {branchId === "1" && ("Imelda Veteranos")}
                            {branchId === "2" && ("Robinsons Marasbaras")}
                            {branchId === "3" && ("Robinsons Ormoc")}
                        </span>
                    </div>

                    <NavLink to="/customer/1" className={({ isActive }) => "navlink-test" + (isActive ? " navlink-selected" : "")}>
                        <li key="customer" href="#">
                            <img src={customerIcon} alt={'customer'} className="nav-icon"></img>
                            <span className="mx-3">Customers</span>
                        </li>
                    </NavLink>

                    <NavLink to="/daily-transactions" className={({ isActive }) => "navlink-test" + (isActive ? " navlink-selected" : "")}>
                        <li key="daily-transactions" href="#">
                            <div className='row'>
                                <div className='col-3'>
                                    <img src={DailyTransac} alt={'daily-transactions'} className="daily-transac nav-icon" />
                                </div>
                                <div className='col'>
                                    <span>Daily Transactions</span>
                                </div>
                            </div>
                        </li>
                    </NavLink>

                     <NavLink to="/transactions-receipt" className={({ isActive }) => "navlink-test" + (isActive ? " navlink-selected" : "")}>
                        <li key="transactions-receipt" href="#">
                            <div className='row'>
                                <div className='col-3'>
                                    <img src={transacReceipt} alt={'user'} className="customer-balance nav-icon"></img>
                                </div>
                                <div className='col'>
                                    <span>Transactions Receipt</span>
                                </div>
                            </div>
                        </li>
                    </NavLink>

                    <NavLink to="/expenses/1/0" className={({ isActive }) => "navlink-test" + (isActive ? " navlink-selected" : "")}>
                        <li key="expenses" href="#">
                            <img src={expensesIcon} alt={'expenses'} className="nav-icon"></img>
                            <span className="mx-3">Expenses</span>
                        </li>
                    </NavLink>

                    {/* <NavLink to="/employee" className={({ isActive }) => "navlink-test" + (isActive ? " navlink-selected" : "")}>
                        <li key="employee" href="#">
                            <img src={userIcon} alt={'user'} className="nav-icon"></img>
                            <span className="mx-3">Employees</span>
                        </li>
                    </NavLink> */}

                    <NavLink to="/gift-certificate/1" className={({ isActive }) => "navlink-test" + (isActive ? " navlink-selected" : "")}>
                        <li key="gift-certificate" href="#">
                            <img src={gcIcon} alt={'user'} className="nav-icon"></img>
                            <span className="mx-3">Gift Certificate</span>
                        </li>
                    </NavLink>

                    <NavLink to="/free-service" className={({ isActive }) => "navlink-test" + (isActive ? " navlink-selected" : "")}>
                        <li key="free-service" href="#">
                            <img src={fsIcon} alt={'user'} className="free-service nav-icon"></img>
                            <span className="mx-3">Free Service</span>
                        </li>
                    </NavLink>

                    <NavLink to="/customer-balance/0/1" className={({ isActive }) => "navlink-test" + (isActive ? " navlink-selected" : "")}>
                        <li key="gift-certificate" href="#">
                            <div className='row'>
                                <div className='col-3'>
                                    <img src={customerBal} alt={'user'} className="customer-balance nav-icon"></img>
                                </div>
                                <div className='col'>
                                    <span>Customer Balance</span>
                                </div>
                            </div>
                        </li>
                    </NavLink>

                    <div onClick={(e) => setShowInventory(!showInventory)}>
                        <li key="manage" href="#" role="button" className="navlink-test">
                            <div>
                                <img src={inventoryIcon} alt={'inventory'} className="nav-icon p-1"></img>
                                <span className="mx-3">Inventory</span>
                                <span>
                                    {showInventory === true && caretUp()}
                                    {showInventory === false && caretDown()}
                                </span>
                            </div>
                        </li>
                    </div>

                    {
                        showInventory &&
                        <div>
                            <ul className="nav flex-column w-100 list-unstyled manage-dropdown-custom">
                                <NavLink to="/inventory-item/1" className={({ isActive }) => "dropdown-option" + (isActive ? " dropdown-option-selected" : "")}>
                                    <li key="inventory-item" href="#">Item</li>
                                </NavLink>
                                <NavLink to="/inventory-history" className={({ isActive }) => "dropdown-option" + (isActive ? " dropdown-option-selected" : "")}>
                                    <li key="inventory-item-history" href="#">History</li>
                                </NavLink>
                            </ul>
                        </div>
                    }



                    <div onClick={(e) => setShowReport(!showReport)}>
                        <li key="manage" href="#" role="button" className="navlink-test">
                            <div>
                                <img src={reportIcon} alt={'report'} className="nav-icon p-1"></img>
                                <span className="mx-3">Report</span>
                                <span>
                                    {showReport === true && caretUp()}
                                    {showReport === false && caretDown()}
                                </span>
                            </div>
                        </li>
                    </div>

                    {
                        showReport &&
                        <div>
                            <ul className="nav flex-column w-100 list-unstyled manage-dropdown-custom">
                                <NavLink to="/daily-sales-report" className={({ isActive }) => "dropdown-option" + (isActive ? " dropdown-option-selected" : "")}>
                                    <li key="report-daily-sales-report" href="#">Daily Sales Report</li>
                                </NavLink>
                                <NavLink to="/daily-products-purchased-report" className={({ isActive }) => "dropdown-option" + (isActive ? " dropdown-option-selected" : "")}>
                                    <li key="report-daily-products-purchased-report" href="#">Daily Products Sales</li>
                                </NavLink>
                                <NavLink to="/daily-services-rendered" className={({ isActive }) => "dropdown-option" + (isActive ? " dropdown-option-selected" : "")}>
                                    <li key="report-daily-services-rendered" href="#">Daily Services Sales</li>
                                </NavLink>
                                <NavLink to="/commission-report" className={({ isActive }) => "dropdown-option" + (isActive ? " dropdown-option-selected" : "")}>
                                    <li key="report-commission-report" href="#">Commission Report</li>
                                </NavLink>
                                <NavLink to="/free-service-report" className={({ isActive }) => "dropdown-option" + (isActive ? " dropdown-option-selected" : "")}>
                                    <li key="report-free-service-report" href="#">Free Service Report</li>
                                </NavLink>
                                <NavLink to="/balance-report/1/1" className={({ isActive }) => "dropdown-option" + (isActive ? " dropdown-option-selected" : "")}>
                                    <li key="report-balance-report" href="#">Customer Balance Report</li>
                                </NavLink>
                            </ul>
                        </div>
                    }

                    <div onClick={(e) => setShowManage(!showManage)}>
                        <li key="manage" href="#" role="button" className="navlink-test">
                            <div>
                                <img src={manageIcon} alt={'manage'} className="nav-icon p-1"></img>
                                <span className="mx-3">Manage</span>
                                <span>
                                    {showManage === true && caretUp()}
                                    {showManage === false && caretDown()}
                                </span>
                            </div>
                        </li>
                    </div>

                    {
                        showManage &&
                        <div>
                            <ul className="nav flex-column w-100 list-unstyled manage-dropdown-custom">
                                <NavLink to="/employees/1" className={({ isActive }) => "dropdown-option" + (isActive ? " dropdown-option-selected" : "")}>
                                    <li key="manage-employees" href="#">Employees</li>
                                </NavLink>
                                <NavLink to="/service/1" className={({ isActive }) => "dropdown-option" + (isActive ? " dropdown-option-selected" : "")}>
                                    <li key="manage-services" href="#">Services</li>
                                </NavLink>
                                <NavLink to="/package/1" className={({ isActive }) => "dropdown-option" + (isActive ? " dropdown-option-selected" : "")}>
                                    <li key="manage-packages" href="#">Packages</li>
                                </NavLink>
                            </ul>
                        </div>
                    }

                </ul>


                <div role="button" onClick={removeUserSession} className="d-flex justify-content-center logout-field py-3">
                    <FontAwesomeIcon className="align-self-center" icon={'sign-out-alt'} />
                    <span className="mx-2">Log-out</span>
                </div>


            </div>
        </>
    )
}

// fontdesk/Cashier navbar
function frontdeskCashierNavbar(showInventory, setShowInventory, showManage, setShowManage, showReport, setShowReport, branchId) {

    return (
        <>
            <div className="side-navbar d-flex justify-content-between flex-column active-nav" id="sidebar">
                <ul className="nav flex-column text-white w-100 nav-elixir-custom"> {/* this might be involved with css responsive idk */}

                    <div className="mx-auto">
                        <img src={cleanLogo} alt={'cleanLogo'} className="my-4"></img>
                    </div>

                    <div className='mx-auto branch-name-nav'>
                        <span className='d-flex justify-content-center'>
                            {branchId === "1" && ("Imelda Veteranos")}
                            {branchId === "2" && ("Robinsons Marasbaras")}
                            {branchId === "3" && ("Robinsons Ormoc")}
                        </span>
                    </div>


                    <NavLink to="/customer/1" className={({ isActive }) => "navlink-test" + (isActive ? " navlink-selected" : "")}>
                        <li key="customer" href="#">
                            <img src={customerIcon} alt={'customer'} className="nav-icon"></img>
                            <span className="mx-3">Customers</span>
                        </li>
                    </NavLink>

                    <NavLink to="/daily-transactions" className={({ isActive }) => "navlink-test" + (isActive ? " navlink-selected" : "")}>
                        <li key="daily-transactions" href="#">
                            <div className='row'>
                                <div className='col-3'>
                                    <img src={DailyTransac} alt={'daily-transactions'} className="daily-transac nav-icon" />
                                </div>
                                <div className='col'>
                                    <span>Daily Transactions</span>
                                </div>
                            </div>
                        </li>
                    </NavLink>

                     <NavLink to="/transactions-receipt" className={({ isActive }) => "navlink-test" + (isActive ? " navlink-selected" : "")}>
                        <li key="transactions-receipt" href="#">
                            <div className='row'>
                                <div className='col-3'>
                                    <img src={transacReceipt} alt={'user'} className="customer-balance nav-icon"></img>
                                </div>
                                <div className='col'>
                                    <span>Transactions Receipt</span>
                                </div>
                            </div>
                        </li>
                    </NavLink>

                    <NavLink to="/expenses/1/0" className={({ isActive }) => "navlink-test" + (isActive ? " navlink-selected" : "")}>
                        <li key="expenses" href="#">
                            <img src={expensesIcon} alt={'expenses'} className="nav-icon"></img>
                            <span className="mx-3">Expenses</span>
                        </li>
                    </NavLink>

                    <NavLink to="/gift-certificate/1" className={({ isActive }) => "navlink-test" + (isActive ? " navlink-selected" : "")}>
                        <li key="gift-certificate" href="#">
                            <img src={gcIcon} alt={'user'} className="nav-icon"></img>
                            <span className="mx-3">Gift Certificate</span>
                        </li>
                    </NavLink>

                    <NavLink to="/free-service" className={({ isActive }) => "navlink-test" + (isActive ? " navlink-selected" : "")}>
                        <li key="free-service" href="#">
                            <img src={fsIcon} alt={'user'} className="free-service nav-icon"></img>
                            <span className="mx-3">Free Service</span>
                        </li>
                    </NavLink>

                    <NavLink to="/customer-balance/0/1" className={({ isActive }) => "navlink-test" + (isActive ? " navlink-selected" : "")}>
                        <li key="gift-certificate" href="#">
                            <div className='row'>
                                <div className='col-3'>
                                    <img src={customerBal} alt={'user'} className="customer-balance nav-icon"></img>
                                </div>
                                <div className='col'>
                                    <span>Customer Balance</span>
                                </div>
                            </div>
                        </li>
                    </NavLink>

                    <div onClick={(e) => setShowInventory(!showInventory)}>
                        <li key="manage" href="#" role="button" className="navlink-test">
                            <div>
                                <img src={inventoryIcon} alt={'inventory'} className="nav-icon p-1"></img>
                                <span className="mx-3">Inventory</span>
                                <span>
                                    {showInventory === true && caretUp()}
                                    {showInventory === false && caretDown()}
                                </span>
                            </div>
                        </li>
                    </div>

                    {
                        showInventory &&
                        <div>
                            <ul className="nav flex-column w-100 list-unstyled manage-dropdown-custom">
                                <NavLink to="/inventory-item/1" className={({ isActive }) => "dropdown-option" + (isActive ? " dropdown-option-selected" : "")}>
                                    <li key="inventory-item" href="#">Item</li>
                                </NavLink>
                                <NavLink to="/inventory-history" className={({ isActive }) => "dropdown-option" + (isActive ? " dropdown-option-selected" : "")}>
                                    <li key="inventory-item-history" href="#">History</li>
                                </NavLink>
                            </ul>
                        </div>
                    }

                    <NavLink to="/reports" className={({ isActive }) => "navlink-test" + (isActive ? " navlink-selected" : "")}>
                        <li key="reports" href="#">
                            <div>
                                <img src={reportIcon} alt={'report'} className="nav-icon p-1"></img>
                                <span className="mx-3">Reports</span>
                            </div>
                        </li>
                    </NavLink>

                    {/* <div onClick={(e) => setShowManage(!showManage)}>
                        <li key="manage" href="#" role="button" className="navlink-test">
                            <div>
                                <img src={manageIcon} alt={'manage'} className="nav-icon p-1"></img>
                                <span className="mx-3">Manage</span>
                                <span>
                                    {showManage === true && caretUp()}
                                    {showManage === false && caretDown()}
                                </span>
                            </div>
                        </li>
                    </div>

                    {
                        showManage &&
                        <div>
                            <ul className="nav flex-column w-100 list-unstyled manage-dropdown-custom">
                                <NavLink to="/employees/1" className={({ isActive }) => "dropdown-option" + (isActive ? " dropdown-option-selected" : "")}>
                                    <li key="manage-employees" href="#">Employees</li>
                                </NavLink>
                                <NavLink to="/service" className={({ isActive }) => "dropdown-option" + (isActive ? " dropdown-option-selected" : "")}>
                                    <li key="manage-services" href="#">Services</li>
                                </NavLink>
                                <NavLink to="/package" className={({ isActive }) => "dropdown-option" + (isActive ? " dropdown-option-selected" : "")}>
                                    <li key="manage-packages" href="#">Packages</li>
                                </NavLink>
                            </ul>
                        </div>
                    } */}


                </ul>


                <div role="button" onClick={removeUserSession} className="d-flex justify-content-center logout-field py-3">
                    <FontAwesomeIcon className="align-self-center" icon={'sign-out-alt'} />
                    <span className="mx-2">Log-out</span>
                </div>


            </div>
        </>
    )
}

//Legal Navbar
function legalNavbar(showInventory, setShowInventory, showManage, setShowManage, showReport, setShowReport, branchId) {

    return (
        <>
            <div className="side-navbar d-flex justify-content-between flex-column active-nav" id="sidebar">
                <ul className="nav flex-column text-white w-100 nav-elixir-custom"> {/* this might be involved with css responsive idk */}

                    <div className="mx-auto">
                        <img src={cleanLogo} alt={'cleanLogo'} className="my-4"></img>
                    </div>

                    <div className='mx-auto branch-name-nav'>
                        <span className='d-flex justify-content-center'>
                            {branchId === "1" && ("Imelda Veteranos")}
                            {branchId === "2" && ("Robinsons Marasbaras")}
                            {branchId === "3" && ("Robinsons Ormoc")}
                        </span>
                    </div>

                    <NavLink to="/customer/1" className={({ isActive }) => "navlink-test" + (isActive ? " navlink-selected" : "")}>
                        <li key="customer" href="#">
                            <img src={customerIcon} alt={'customer'} className="nav-icon"></img>
                            <span className="mx-3">Customers</span>
                        </li>
                    </NavLink>

                    <NavLink to="/transactions-receipt" className={({ isActive }) => "navlink-test" + (isActive ? " navlink-selected" : "")}>
                        <li key="transactions-receipt" href="#">
                            <div className='row'>
                                <div className='col-3'>
                                    <img src={transacReceipt} alt={'user'} className="customer-balance nav-icon"></img>
                                </div>
                                <div className='col'>
                                    <span>Transactions Receipt</span>
                                </div>
                            </div>
                        </li>
                    </NavLink>

                </ul>


                <div role="button" onClick={removeUserSession} className="d-flex justify-content-center logout-field py-3">
                    <FontAwesomeIcon className="align-self-center" icon={'sign-out-alt'} />
                    <span className="mx-2">Log-out</span>
                </div>


            </div>
        </>
    )
}



// main navbar
function Navbar() {

    // Manage Tab State
    const { showInventory, setShowInventory, showManage, setShowManage, showReport, setShowReport } = useContext(UserPreference);

    //Role
    var role = getRoleId();
    var branchId = getBranch();
    // console.log(role);

    return (
        <div>
            {role === "4" && (adminOwnerNavbar(showInventory, setShowInventory, showManage, setShowManage, showReport, setShowReport, branchId))}
            {role === "1" && (frontdeskCashierNavbar(showInventory, setShowInventory, showManage, setShowManage, showReport, setShowReport, branchId))}
            {role === "3" && (legalNavbar(showInventory, setShowInventory, showManage, setShowManage, showReport, setShowReport, branchId))}

        </div>
    );
}

export default Navbar;