import moment from 'moment';
import { formatMDY, getBranch, getToken, getUser, tokenExpiry } from '../../common';
import { getAPICall } from '../axiosMethodCalls';

/***************************
 * DAILY SALES REPORT
 ***************************/

/*** ALL BRANCHES ***/
export const getTotalNet = async () => {
    try {
        const response = await getAPICall(window.$link + 'reports/calculate_total_NET', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

export const getTotalSales = async () => {
    try {
        const response = await getAPICall(window.$link + 'reports/total_sales', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

export const getTotalDiscounts = async (dateFrom, dateTo) => {
    try {
        const response = await getAPICall(window.$link + 'reports/get_discounts', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            date_from: dateFrom,
            date_to: dateTo
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

export const getSalesByType = async (type) => {
    try {
        const response = await getAPICall(window.$link + 'reports/sales_by_type', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            type: type,
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}


export const getTotalExpenses = async () => {
    try {
        const response = await getAPICall(window.$link + 'reports/get_total_expenses', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

/*** BY BRANCH ***/

export const getTotalNetByBranch = async (branch) => {
    try {
        const response = await getAPICall(window.$link + 'reports/calculate_total_NET_on_branch', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            branch: branch,
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

export const getTotalSalesByBranch = async (branch) => {
    try {
        const response = await getAPICall(window.$link + 'reports/total_sales_by_branch', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            branch: branch,
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

export const getTotalDiscountsByBranch = async (branch, dateFrom, dateTo) => {
    try {
        const response = await getAPICall(window.$link + 'reports/get_discounts', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            branch: branch,
            date_from: dateFrom,
            date_to: dateTo
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}



export const getSalesByTypeOnBranch = async (type, branch) => {
    try {
        const response = await getAPICall(window.$link + 'reports/sales_by_type_on_branch', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            branch: branch,
            type: type,
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}


export const getTotalExpensesOnBranch = async (branch) => {
    try {
        const response = await getAPICall(window.$link + 'reports/get_total_expenses_on_branch', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            branch: branch,
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

/*** SALES LIST ***/

export const getSalesList = async (filter) => {
    try {
        const response = await getAPICall(window.$link + 'reports/get_sales_list', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            type: filter.type,
            branch: filter.branch,
            date_from: formatMDY(filter.date_from),
            date_to: formatMDY(filter.date_to),
            customer: filter.search
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}



