import { useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import ReactLoading from 'react-loading';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { addEmployee } from "../../../utilities/api_calls/employeeApi";
import { checkContact, refreshPage } from "../../../utilities/common";

export default function AddEmployee(props) {
    let navigate = useNavigate();
    const [isValid, setIsValid] = useState(true);
    const [click, setClick] = useState(false);

    // --- Add Employee Hooks --- //
    const [newEmployee, setNewEmployee] = useState({
        email: "",
        password: "",
        // branch: "",
        first_name: "",
        // middlename: "",
        last_name: "",
        nickname: "",
        role: "",
        date_hired: "",
        gender: "female",
        address: "",
        contact_no: "",
        // salary: "",
        tin: "",
        // sss: "",
        // philhealth: "",
        // pagibig: "",
    });

    const handleAddChange = (e) => {
        const { name, value } = e.target;
        setNewEmployee(prevState => ({
            ...prevState,
            [name]: value
        }));

        if (name === "contact_no") {
            setIsValid(checkContact(value))
        }
    }

    function addNewEmployee() {
        if (newEmployee.contact_no !== "") {
            setIsValid(checkContact(newEmployee.contact_no))
        } else {
            setIsValid(true);
        }
        setTimeout(() => createEmployee(), 1000)
    }

    async function createEmployee() {
        if (click === false &&
            newEmployee.first_name !== "" &&
            newEmployee.last_name !== "" &&
            checkContact(newEmployee.contact_no) !== false) {
            setClick(true);
            const response = await addEmployee(newEmployee);
            // console.log(response);
            // console.log(response.data.config.data);
            if (response.data.data.status === 201 || response.data.data.status === 200) {
                toast.success("Adding Employee Successful!");
                setTimeout(function () {
                    navigate("/employees/" + props.currentPage);
                    refreshPage();
                }, 1000);
            } else {
                toast.error(response.data.data.error);
                setTimeout(function () {
                    navigate("/employees/" + props.currentPage);
                    refreshPage();
                }, 1000);
            }
        } else {
            toast.error("Please enter values for required inputs!");
        }
    }

    return (
        <>
            <Modal show={props.show} onHide={props.hide} size="lg" className="custom-modal-style" centered>
                <Modal.Header className="custom-modal-header view-employee-header justify-content-between mt-3" closeButton>
                    ADD EMPLOYEE
                </Modal.Header>
                <Modal.Body className="px-5 py-1">
                    <Container>
                        <Row className="nc-modal-custom-row">
                            <Col>First Name</Col>
                            <Col xs={1}>:</Col>
                            <Col xs={7}>
                                <Form.Control type="text" name="first_name" placeholder="Enter first name" className="nc-modal-custom-input" onChange={(e) => handleAddChange(e)} required />
                                {newEmployee.first_name === "" && (
                                    <div className="validity-error">
                                        Required input*
                                    </div>
                                )}
                            </Col>
                        </Row>
                        {/* <Row className="nc-modal-custom-row">
                            <Col>Middle Name</Col>
                            <Col xs={1}>:</Col>
                            <Col xs={7}>
                                <Form.Control type="text" name="middlename" placeholder="Enter middle name" className="nc-modal-custom-input" onChange={(e) => handleAddChange(e)} required />
                            </Col>
                        </Row> */}
                        <Row className="nc-modal-custom-row">
                            <Col>Last Name</Col>
                            <Col xs={1}>:</Col>
                            <Col xs={7}>
                                <Form.Control type="text" name="last_name" placeholder="Enter last name" className="nc-modal-custom-input" onChange={(e) => handleAddChange(e)} required />
                                {newEmployee.last_name === "" && (
                                    <div className="validity-error">
                                        Required input*
                                    </div>
                                )}
                            </Col>
                        </Row>
                        <Row className="nc-modal-custom-row">
                            <Col>Nickname</Col>
                            <Col xs={1}>:</Col>
                            <Col xs={7}><Form.Control type="text" name="nickname" placeholder="Enter nickname" className="nc-modal-custom-input" onChange={(e) => handleAddChange(e)} required /></Col>
                        </Row>
                        <Row className="nc-modal-custom-row">
                            <Col>Date Hired</Col>
                            <Col xs={1}>:</Col>
                            <Col xs={7}>
                                <Form.Control type="date" name="date_hired" placeholder="Enter date hired" className="nc-modal-custom-input" onChange={(e) => handleAddChange(e)} required />
                            </Col>
                        </Row>
                        {/* <Row className="nc-modal-custom-row">
                            <Col>Gender</Col>
                            <Col xs={1}>:</Col>
                            <Col xs={7}>
                                <Form.Select className="nc-modal-custom-input" name="gender" onChange={(e) => handleAddChange(e)} defaultValue="" required>
                                    <option value="" disabled hidden>--SELECT--</option>
                                    <option value="female">Female</option>
                                    <option value="male">Male</option>
                                </Form.Select>
                                {newEmployee.gender === "" && (
                                    <div className="validity-error">
                                        Required input*
                                    </div>
                                )}
                            </Col>
                        </Row> */}
                        <Row className="nc-modal-custom-row">
                            <Col>Address</Col>
                            <Col xs={1}>:</Col>
                            <Col xs={7}>
                                <Form.Control type="text" name="address" placeholder="Enter address" className="nc-modal-custom-input" onChange={(e) => handleAddChange(e)} required />
                            </Col>
                        </Row>
                        <Row className="nc-modal-custom-row">
                            <Col>Contact Number</Col>
                            <Col xs={1}>:</Col>
                            <Col xs={7}>
                                <Form.Control type="text" name="contact_no" placeholder="Enter contact number" className="nc-modal-custom-input" onChange={(e) => handleAddChange(e)} required />
                                {isValid === false && (
                                    <div className="validity-error">
                                        Invalid contact number format.
                                    </div>
                                )}
                            </Col>
                        </Row>
                        {/* <Row className="nc-modal-custom-row">
                            <Col>Salary</Col>
                            <Col xs={1}>:</Col>
                            <Col xs={7}><Form.Control type="number" min="0.01" step="0.01" name="salary" placeholder="Enter salary" className="nc-modal-custom-input" onChange={(e) => handleAddChange(e)} required /></Col>
                        </Row> */}
                        <Row className="nc-modal-custom-row">
                            <Col>tin</Col>
                            <Col xs={1}>:</Col>
                            <Col xs={7}><Form.Control type="text" name="tin" placeholder="Enter TIN" className="nc-modal-custom-input" onChange={(e) => handleAddChange(e)} required /></Col>
                        </Row>
                        {/* <Row className="nc-modal-custom-row">
                            <Col>sss</Col>
                            <Col xs={1}>:</Col>
                            <Col xs={7}><Form.Control type="text" name="sss" placeholder="Enter SSS" className="nc-modal-custom-input" onChange={(e) => handleAddChange(e)} required /></Col>
                        </Row> */}
                        {/* <Row className="nc-modal-custom-row">
                            <Col>philhealth (phic)</Col>
                            <Col xs={1}>:</Col>
                            <Col xs={7}><Form.Control type="text" name="philhealth" placeholder="Enter PHIC" className="nc-modal-custom-input" onChange={(e) => handleAddChange(e)} required /></Col>
                        </Row> */}
                        {/* <Row className="nc-modal-custom-row">
                            <Col>pag-ibig (hdmf)</Col>
                            <Col xs={1}>:</Col>
                            <Col xs={7}><Form.Control type="text" name="pagibig" placeholder="Enter HDMF" className="nc-modal-custom-input" onChange={(e) => handleAddChange(e)} required /></Col>
                        </Row> */}
                        <hr />
                        <Row className="nc-modal-custom-row">
                            <Col>Role</Col>
                            <Col xs={1}>:</Col>
                            <Col xs={7}>
                                <Form.Select className="nc-modal-custom-input" name="role" onChange={(e) => handleAddChange(e)} defaultValue="" required>
                                    <option value="" disabled hidden>--SELECT--</option>
                                    <option value="4">Owner / Admin</option>
                                    <option value="1">Frontdesk / Cashier</option>
                                    <option value="3">Legal</option>
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row className="nc-modal-custom-row">
                            <Col>Email</Col>
                            <Col xs={1}>:</Col>
                            <Col xs={7}><Form.Control type="email" name="email" placeholder="Enter email address" className="nc-modal-custom-input" onChange={(e) => handleAddChange(e)} required /></Col>
                        </Row>
                        <Row className="nc-modal-custom-row">
                            <Col>Password</Col>
                            <Col xs={1}>:</Col>
                            <Col xs={7}><Form.Control type="text" name="password" placeholder="Enter password" className="nc-modal-custom-input" onChange={(e) => handleAddChange(e)} required /></Col>
                        </Row>
                        {/* <Row className="nc-modal-custom-row">
                            <Col>Branch</Col>
                            <Col xs={1}>:</Col>
                            <Col xs={7}><Form.Control type="text" name="branch" placeholder="Enter Branch" className="nc-modal-custom-input" onChange={(e) => handleAddChange(e)} required /></Col>
                        </Row> */}
                    </Container>
                </Modal.Body>
                <Modal.Footer className="border-0 px-5">
                    <div className="d-flex flex-row justify-content-between">
                        {click === true && (
                            <div className="d-flex justify-content-center">
                                <div className="btn-conf-wrapper ms-auto">
                                    <Button type="button" className="px-5 pb-1 mb-2">
                                        <ReactLoading type="spinningBubbles" color="#d4271c" height={25} width={20} />
                                    </Button>
                                </div>
                            </div>
                        )}
                        {click === false && (
                            <div className="btn-conf-wrapper ms-auto">
                                <Button type="button" className="px-5 pb-1 mb-2" onClick={() => addNewEmployee()}>save</Button>
                            </div>
                        )}
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}