import { getAge, getBranch, getTodayDate, getToken, getUser, tokenExpiry } from '../common';
import { getAPICall, postAPICall, putAPICall, deleteAPICall } from './axiosMethodCalls';

/***************************
 * BRANCH
 ***************************/

//GET
export const getAllBranches = async () => {
    try {
        const response = await getAPICall(window.$link + 'branches/getAll', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

//GET
export const getBranchName = async (id) => {
    try {
        const response = await getAPICall(window.$link + 'branches/show/' + id, {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

