import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal, Row, Col, Form, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
import Select from 'react-select'


// css
import '../common.css'
import { createBooking } from "../../utilities/api_calls/bookingApi";
import { refreshPage } from "../../utilities/common";
import { getAllEmployees } from "../../utilities/api_calls/employeeApi";

export default function BuyModal({ showBuy_, closeBuy_, buyType, dataList, customerId, bookId, employeeList }) {
    const tbHeader = buyType === "product" || buyType === "service" ? ['QTY', 'UNIT PRICE', 'TOTAL', 'COMMISSIONED BY', 'ACTION'] : ['QTY', 'UNIT PRICE', 'TOTAL', 'ACTION']
    // const tbHeaderProduct = 
    const [click, setClick] = useState(false);
    const [itemList, setItemList] = useState([
        {
            item: '',
            quantity: 1,
            price: 0,
            total: 0,
            commissioned_by: ''
        }]);


    function addItem() {
        const newList = [...itemList,
        {
            item: '',
            quantity: 1,
            price: 0,
            total: 0,
            commissioned_by: ''
        }];
        setItemList(newList);
    }

    function updateItemOnChange(e, index) {
        const newList = [...itemList];
        newList[index].item = e.value;
        newList[index].price = e.price;
        setItemList(newList);
    }

    function updateQtyOnChange(e) {
        const rowId = parseInt((e.target.id).slice((e.target.id).length - 1));
        const newList = [...itemList];

        newList[rowId].quantity = e.target.value;
        setItemList(newList);
    }

    function updateCommissionedOnChange(e, index) {
        const newList = [...itemList];

        newList[index].commissioned_by = e.value;
        setItemList(newList);
    }

    function deleteItem(id) {
        const rowId = id;
        const newList = [...itemList];
        newList.splice(rowId, 1);
        setItemList(newList);
    }

    function formatAmount(amount) {
        return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }

    async function addPurchase() {
        console.log(itemList)
        if (click === false) {
            // console.log("hello")
            const response = await createBooking(buyType, itemList, customerId);
            // console.log(response);
            if (response.data.data.status === 201 || response.data.status === 200) {
                setClick(true);
                toast.success(response.data.data.message.success);
                setTimeout(function () {
                    refreshPage();
                }, 2000);
            }
            if (response.data.data.status === 400) {
                toast.error("Only " + response.data.data.current_stock + " stocks left.");
                toast.error(response.data.data.message.failed);
                setTimeout(function () {
                    refreshPage();
                }, 2000);
            }
        }
    }

    const [options, setOptions] = useState([])
    React.useEffect(() => {
        setOptions([])
        if (buyType === 'product') {
            dataList.map((data) => {
                var info = {}
                info.value = data.item_id
                info.label = data.item
                info.price = data.price
                setOptions(prev => [...prev, info])
            })
        } else if (buyType === 'service' || buyType === 'package') {
            dataList.map((data) => {
                var info = {}
                info.value = data.id
                info.label = data.name
                info.price = data.price
                setOptions(prev => [...prev, info])
            })
        }
    }, [dataList])

    const [optionsComm, setOptionsComm] = useState([]);

    async function fetchEmployees() {
        const response = await getAllEmployees();
        var employees = response.data.data.employees;

        setOptionsComm([]);
        employees.map((employee) => {
            var info = {};

            info.value = employee.id;
            info.label = employee.name;

            setOptionsComm(prev => [...prev, info]);
        })
    }

    React.useEffect(() => {
        fetchEmployees();
    }, [])

    /* MAIN MODAL */
    return (
        <div>
            <Modal show={showBuy_} onHide={closeBuy_} size="lg" className="custom-modal-style" centered>
                <Modal.Header className="custom-modal-header" closeButton>
                    <span className="custom-modal-body-title">ADD {buyType}</span>
                </Modal.Header>
                <Modal.Body className="elixir-table-wrapper">
                    <Table size="sm" className='text-center buy-modal-table table-modal'>
                        
                         <thead>
                            <tr> 
                                {/* render header titles */}
                                 <th className="tcenter" >{buyType}</th>
                                {tbHeader.map((header, index) => (
                                    <th className={((buyType === 'product' || buyType === 'service') && index == '3') ? 'modal-commission' : 'tcenter'} key={index}>{header}</th>
                                ))}

                            </tr>
                        </thead>
                        <tbody>
                            {itemList.map((e, index) => {
                                return (
                                    <tr>
                                        {/* displays available items to choose from */}
                                        <td width={280} className="nc-modal-custom-row buy-input-field">
                                            <Select
                                                // id={`added-item-name-${index}`}
                                                onChange={(f) => updateItemOnChange(f, index)}
                                                value={options.filter((info) => info.value == itemList[index].item)}
                                                name="buy_item"
                                                options={options.sort((a, b) => a.label > b.label ? 1 : b.label > a.label ? -1 : 0)}
                                                className='react-select-container-modal'
                                                classNamePrefix="dropdown-modal"
                                            />
                                             {/* <Form.Select
                                                id={`added-item-name-${index}`}
                                                onChange={(f) => updateItemOnChange(f)}
                                                value={itemList[index].item}
                                                name="buy_item"
                                                className="nc-modal-custom-input"
                                                required
                                            >
                                                <option value="" disabled hidden>--Select--</option>
                                                {(buyType === 'product') &&
                                                    dataList.map((item) => {
                                                        return (
                                                            <option
                                                                key={item.item_id}
                                                                value={item.item_id}
                                                                data-price={parseFloat(item.price)}>
                                                                {item.item}
                                                            </option>
                                                        )
                                                    })}


                                                {(buyType === 'service') &&
                                                    dataList.map((item) => {
                                                        return (
                                                            <option
                                                                key={item.id}
                                                                value={item.id}
                                                                data-price={parseFloat(item.price)}>
                                                                {item.name}
                                                            </option>
                                                        )
                                                    })}


                                                {(buyType === 'package') &&
                                                    dataList.map((item) => {
                                                        return (
                                                            <option
                                                                key={item.id}
                                                                value={item.id}
                                                                data-price={parseFloat(item.price)}>
                                                                {item.name}
                                                            </option>
                                                        )
                                                    })}


                                            </Form.Select> */}
                                         </td>

                                        {/* quantity input field */}
                                        <td className="tcenter nc-modal-custom-row buy-input-field" width={120}>
                                            <Form.Control
                                                id={`added-item-qty-${index}`}
                                                onChange={(f) => updateQtyOnChange(f)}
                                                value={itemList[index].quantity}
                                                type="number"
                                                name="buy_item_qty"
                                                className="nc-modal-custom-input input-quantity"
                                                required>
                                            </Form.Control>
                                        </td>

                                        {/* dynamically display the unit price of the selected item */}
                                        <td className="tcenter" width={120}>{formatAmount(parseFloat(itemList[index].price))}</td>

                                        {/* dynamically display the price x quantity */}
                                        <td className="tcenter" width={120}>
                                            {(itemList[index].total = itemList[index].price * itemList[index].quantity)
                                                &&
                                                (formatAmount(parseFloat(itemList[index].total)))}
                                        </td>
                                        {/* Commissioned by */}
                                        {(buyType === 'product' || buyType === 'service') &&
                                            <td className="nc-modal-custom-row buy-input-field" >
                                                <Select
                                                    // id={`added-item-name-${index}`}
                                                    onChange={(f) => updateCommissionedOnChange(f, index)}
                                                    value={optionsComm.filter((info) => info.value == itemList[index].commissioned_by)}
                                                    name="buy_item"
                                                    options={optionsComm.sort((a, b) => a.label > b.label ? 1 : b.label > a.label ? -1 : 0)}
                                                    className='react-select-container-modal'
                                                    classNamePrefix="dropdown-modal"
                                                />
                                                {/* <Form.Select
                                                    id={`added-item-commissioned-${index}`}
                                                    onChange={(f) => updateCommissionedOnChange(f)}
                                                    value={itemList[index].commissioned_by}
                                                    className="nc-modal-custom-input">
                                                    <option value=''>--Select--</option>
                                                    {employeeList.map((employee) => {
                                                        return (<option key={employee.id} value={employee.id}>{employee.name}</option>)
                                                    })}
                                                </Form.Select> */}
                                            </td>}

                                        {/* delete button */}
                                        <td className="tcenter">
                                            <div className="btn-conf-wrapper del-action">
                                                <FontAwesomeIcon
                                                    id={`del-btn-${index}`}
                                                    onClick={() => deleteItem(index)}
                                                    role="button"
                                                    className="align-self-center mx-1"
                                                    icon={'trash'} />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            <tr className="grand-total-row ">
                                <td></td>
                                <td></td>
                                <td className="tcenter pt-3" width={200}>
                                    GRAND TOTAL:
                                </td>
                                <td className="tcenter  pt-3">
                                    {itemList && formatAmount(itemList.reduce(
                                        (total, item) =>
                                            total + (parseFloat(item.total)), 0.0
                                    ))}
                                </td>
                                {(buyType === 'product' || buyType === 'service') && <td></td>}
                                <td></td>


                            </tr>
                        </tbody>
                    </Table>

                    <Row className="d-flex flex-row justify-content-between">
                        {/* add item button */}
                        <Col>
                            <div className="btn-conf-wrapper mt-0">
                                <Button className="px-5 pb-1 mb-2 mt-0" type="button" onClick={addItem}>add {buyType}</Button>
                            </div>
                        </Col>

                        {/* grand total display (read-only) */}
                        {/* <Col className="grand-total">
                            <div className="total">
                                <span>Grand Total: </span>
                                <span className="total-amount">
                                    {itemList && formatAmount(itemList.reduce(
                                        (total, item) =>
                                            total + (parseFloat(item.total)), 0.0
                                    ))}
                                </span>
                            </div>
                        </Col> */}
                    </Row>
                </Modal.Body>

                {/* save button */}
                <Modal.Footer className="border-0 px-5">
                    <div className="d-flex flex-row justify-content-between">
                        <div className="btn-conf-wrapper ms-auto">
                            {click === true && (
                                <div className="d-flex justify-content-center">
                                    <div className="btn-conf-wrapper ms-auto">
                                        <Button type="submit" className="px-5 pb-1 mb-2">
                                            <ReactLoading type="spinningBubbles" color="#d4271c" height={25} width={20} />
                                        </Button>
                                    </div>
                                </div>
                            )}
                            {click === false && (
                                <Button type="submit" className="px-5 pb-1 mb-2" onClick={() => addPurchase()}>save</Button>
                            )}
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
