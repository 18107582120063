import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useState } from "react";
import { Button, Tab, Tabs, Modal, Container, Row, Col, Form } from 'react-bootstrap';
import ReactLoading from 'react-loading';
import DatePicker from "react-datepicker";
import Pagination from '@material-ui/lab/Pagination';

// css
import '../../common.css';
import './daily-services-rendered.css';

// components
import { getFreeServiceReport } from '../../../utilities/api_calls/reports/freeServiceReportApi';
import Navbar from '../../navbar';
import TableTemplate from '../../table-template';
import { getTodayDate, getTodayDateISO } from '../../../utilities/common';
import { getAllServices, getService } from '../../../utilities/api_calls/servicesApi';
import { getAllFreeService } from '../../../utilities/api_calls/freeServicesAPI';
import NoDataPrompt from '../../../utilities/no-data-prompt';

// sample data (temp)

function FreeServiceReport() {
  const [showLoader, setShowLoader] = useState(true);

  // Pagination
  const [pageslist, setPageslist] = useState(1);
  const [currentPagelist, setCurrentPagelist] = useState(1);

  function handlePageChangelist(event, value) {
    // console.log(value)
    setCurrentPagelist(value)
  }

  // Pagination
  const [pagesreport, setPagesreport] = useState(1);
  const [currentPagereport, setCurrentPagereport] = useState(1);

  function handlePageChangereport(event, value) {
    // console.log(value)
    setCurrentPagereport(value)
  }

  const [freeServicesRendered, setFreeServicesRendered] = useState([]);
  const [filter, setFilter] = useState({
    date_from: getTodayDate(),
    date_to: getTodayDate(),
    branch: '',
    service: ''
  });

  async function fetchFreeServicesReport() {
    setShowLoader(true);
    const response = await getFreeServiceReport(filter);
    setShowLoader(false)
    setPagesreport(Math.ceil(response.data.data.free_services.length / 11));
    // console.log(response)
    setFreeServicesRendered(response.data.data.free_services);
  }

  const [freeServiceList, setFreeServiceList] = useState([])
  async function fetchFreeServicesList() {
    const response = await getAllFreeService(filter);
    setPageslist(Math.ceil(response.data.data.free_services.length / 11));
    // console.log(response)
    setFreeServiceList(response.data.data.free_services);
  }

  // const [services, setServices] = useState([])
  // async function fetchServices(){
  //   const response = await getAllServices();
  //   setServices(response.data.data.services)
  // }

  React.useEffect(() => {
    fetchFreeServicesReport();
    fetchFreeServicesList()
    // eslint-disable-next-line
  }, [filter]);


  // -- Filter Functions --- //
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  const handleDateFromChange = (date) => {
    setFilter(prevState => ({
      ...prevState,
      ['date_from']: date
    }))
  }

  const handleDateToChange = (date) => {
    setFilter(prevState => ({
      ...prevState,
      ['date_to']: date
    }))
  }

  // Tabs
  const [tabSelected, setTabSelected] = useState("eligible");
  function handleShowData() {
    setTimeout(() => setShowLoader(false), 5000);
  }
  function renderReport() {
    if (freeServicesRendered.length < 1) {
      return (
        <NoDataPrompt text="free service report" />
        // <span className="no-data-prompt">No Free Service Report Found!</span>
      )
    } else {
      return (
        <>
          <TableTemplate
            tableType='free_service_report'
            tableHeaders={["Date Availed", "Branch", "Customer Name", "Name", "Derm Tech"]}
            tableData={freeServicesRendered}
            currentPage={currentPagereport}
          />
          <Pagination count={pagesreport} page={currentPagereport} shape="rounded" defaultPage={1} siblingCount={2} onChange={handlePageChangereport} showFirstButton showLastButton />
        </>
      )
    }
  }

  function renderList() {
    if (freeServiceList.length < 1) {
      return (
        <NoDataPrompt text="available free services" />
        // <span className="no-data-prompt">No Customer Eligible for Free Service Found!</span>
      )
    } else {
      return (
        <>
          <TableTemplate
            tableType='free_service_list'
            tableHeaders={["Customer Name"]}
            tableData={freeServiceList}
            currentPage={currentPagelist}
          />
          <Pagination count={pageslist} page={currentPagelist} shape="rounded" defaultPage={1} siblingCount={2} onChange={handlePageChangelist} showFirstButton showLastButton />
        </>
      )
    }
  }
  return (
    <>
      <div>
        <Navbar />
        <div className="active-cont">
          <Fragment>

            <div className="d-flex flex-row justify-content-between align-items-center cont-section">
              <h1 className="cont-sect-header">Free Service Report</h1>
              <div className="search-wrapper w-50">
                <input type="search" class="form-control search-indent" placeholder="Search by Name" />
                <div className="search-icon">
                  <FontAwesomeIcon
                    className="icon"
                    icon={'search'}
                    aria-hidden="true"
                    alt={'search'}
                    fixedWidth
                  />
                </div>
              </div>
            </div>

            {/** FILTER */}
            <div className="filter-cont mt-0">
              {/* <div className="filter-label">FILTER BY:
                 <select className="filter-dropdown-input" name="branch" onChange={(e) => handleFilterChange(e)}>
                  <option value="">Branch</option>
                  <option value="1">Imelda Veteranos</option>
                  <option value="2">Robinsons Marasbaras</option>
                  <option value="3">Robinsons Place Ormoc</option>
                </select>
                <select className="filter-dropdown-input">
                  <option value="" selected disabled>All Service</option>
                  {
                    services.map((data, index)=>
                      <option value={data.id} onChange={e=>handleFilterChange(e)} name="services">{data.name}</option>
                    )
                  }
                </select>
              </div> */}
              <div className='filter-label-row1'>
                DATE FROM:
                <DatePicker selected={filter.date_from} dateFormat="MM/dd/yyyy" className="filter-date-picker mx-3 px-1" name="date_from" placeholderText="mm/dd/yyyy" onChange={date => handleDateFromChange(date)} />
                {/* <input type="date" className="filter-date-picker" name="date_from" value={filter.date_from} onChange={(e) => handleFilterChange(e)} /> */}
                
                TO:
                <DatePicker selected={filter.date_to} dateFormat="MM/dd/yyyy" className="filter-date-picker mx-3 px-1" name="date_to" placeholderText="mm/dd/yyyy" onChange={date => handleDateToChange(date)} />
                {/* <input type="date" className="filter-date-picker" name="date_to" value={filter.date_to} onChange={(e) => handleFilterChange(e)} /> */}
              </div>
            </div>


            <div className="cont-section-novertpad service-report-table">
              {/* {renderList()} */}
              <Tabs defaultActiveKey="eligible" id="free_service_eligible" variant="pills" className="customer-transaction-tab" activeKey={tabSelected} onSelect={(k) => setTabSelected(k)}>
                <Tab eventKey="eligible" title="Eligible">
                  {showLoader ?
                    <><ReactLoading type="spinningBubbles" color="#d4271c" height={200} width={100} className='list-loader' />{handleShowData()}</>
                    : renderList()
                  }
                </Tab>
                <Tab eventKey="history" title="History">
                  {showLoader ?
                    <><ReactLoading type="spinningBubbles" color="#d4271c" height={200} width={100} className='list-loader' />{handleShowData()}</>
                    : renderReport()
                  }
                </Tab>
              </Tabs>
            </div>

          </Fragment>

        </div>
      </div>
    </>
  );
}

export default FreeServiceReport;