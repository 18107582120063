import { getEmployee } from "../../../utilities/api_calls/employeeApi";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import React, { useState } from "react";

/* CSS */
import '../../common.css';
import './employee.css';

/* COMPONENTS */
import ReactLoading from 'react-loading';

export default function ViewEmployee(props) {
    const [showLoader, setShowLoader] = useState(true);
    const [employee, setEmployee] = useState({});

    async function viewEmployee() {
        setShowLoader(true);
        const response = await getEmployee(props.id);
        const employee = response.data.data[0];

        setEmployee({
            email: employee.email,
            password: "",
            firstname: employee.first_name,
            middlename: employee.middle_name,
            lastname: employee.last_name,
            nickname: employee.nickname,
            role: employee.role,
            date_hired: employee.date_hired,
            gender: employee.gender,
            address: employee.address,
            contact_no: employee.contact_no,
            salary: employee.salary,
            tin: employee.tin,
            sss: employee.sss,
            philhealth: employee.phic,
            pagibig: employee.hdmf,
        });
        setShowLoader(false);
    }

    React.useEffect(() => {
        viewEmployee();
    }, [props.show])

    return (
        <Modal show={props.show} onHide={props.hide} size="lg" className="custom-modal-style" centered>
            <Modal.Header className="custom-modal-header view-employee-header justify-content-between" closeButton>
                Employee Details
            </Modal.Header>
            <Modal.Body className="px-5 py-1">
                {showLoader ?
                    <div className="d-flex justify-content-center my-2">
                        <ReactLoading type="spinningBubbles" color="#d4271c" height={100} width={100} className='modal-loader' />
                    </div> :
                    <Container>
                        <Row className="nc-modal-custom-row">
                            <Col>First Name</Col>
                            <Col xs={1}>:</Col>
                            <Col xs={7}>{employee.firstname}</Col>
                        </Row>
                        <Row className="nc-modal-custom-row">
                            <Col>Last Name</Col>
                            <Col xs={1}>:</Col>
                            <Col xs={7}>{employee.lastname}</Col>
                        </Row>
                        <Row className="nc-modal-custom-row">
                            <Col>Nickname</Col>
                            <Col xs={1}>:</Col>
                            <Col xs={7}>{employee.nickname}</Col>
                        </Row>
                        <Row className="nc-modal-custom-row">
                            <Col>Address</Col>
                            <Col xs={1}>:</Col>
                            <Col xs={7}>{employee.address}</Col>
                        </Row>
                        <Row className="nc-modal-custom-row">
                            <Col>Contact Number</Col>
                            <Col xs={1}>:</Col>
                            <Col xs={7}>{employee.contact_no}</Col>
                        </Row>
                        <Row className="nc-modal-custom-row">
                            <Col>tin</Col>
                            <Col xs={1}>:</Col>
                            <Col xs={7}>{employee.tin}</Col>
                        </Row>
                        <hr />
                        <Row className="nc-modal-custom-row">
                            <Col>Role</Col>
                            <Col xs={1}>:</Col>
                            <Col xs={7}>{employee.role}</Col>
                        </Row>
                        <Row className="nc-modal-custom-row">
                            <Col>Email Address</Col>
                            <Col xs={1}>:</Col>
                            <Col xs={7}>{employee.email}</Col>
                        </Row>
                    </Container>}
            </Modal.Body>
            <Modal.Footer className="border-0 px-5">
                <div className="d-flex flex-row justify-content-between">
                    <div className="btn-conf-wrapper ms-auto">
                        <Button type="button" onClick={props.hide} className="px-5 pb-1 mb-2">CLOSE</Button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    )
}