import { getBranch, getToken, getUser, tokenExpiry } from '../common';
import { getAPICall } from './axiosMethodCalls';

/***************************
 * BOOKING DETAILS
 ***************************/

//GET
export const getBookingDetail = async (id) => {
    try {
        const response = await getAPICall(window.$link + 'booking_details/view_booking/' + id, {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch(),
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

