import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Pagination from '@material-ui/lab/Pagination';
import React, { Fragment, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// css
import '../../common.css';
import './expenses.css';

// components
import Navbar from '../../navbar';
import TableTemplate from '../../table-template';

// sample data (temp)
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { getAllBranches, getBranchName } from "../../../utilities/api_calls/branchApi";
import { getAllEmployees } from "../../../utilities/api_calls/employeeApi";
import { addExpense, deleteExpense, getAllExpenses, getExpense, searchExpenses, updateExpenseDetails } from "../../../utilities/api_calls/expensesApi";
import { getBranch, getRoleId, getTodayDate, getTodayDateISO, numberWithCommas, refreshPage } from "../../../utilities/common";
import NoDataPrompt from '../../../utilities/no-data-prompt';
import DeleteModal from '../../deleteModal';

function Expenses() {
    let navigate = useNavigate();
    const [showLoader, setShowLoader] = useState(true);
    const { branchId, page } = useParams();
    const role = getRoleId();
    const defaultBranch = role === "4" ? branchId : getBranch().replace(/['"]+/g, '');

    // Pagination
    const [pages, setPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(parseInt(page));

    function handlePageChange(event, value) {
        // console.log(value)
        setCurrentPage(value)
    }


    //Filter
    const [filter, setFilter] = useState({
        branch: defaultBranch,
        date_from: getTodayDate(),
        date_to: getTodayDate(),
    });

    // -- Filter Functions --- //
    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilter(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleDateFromChange = (date) => {
        setFilter(prevState => ({
            ...prevState,
            ['date_from']: date
        }))
    }

    const handleDateToChange = (date) => {
        setFilter(prevState => ({
            ...prevState,
            ['date_to']: date
        }))
    }
    //  console.log(filter)

    //All Branches
    const [branches, setBranches] = useState([]);

    //Get Branches
    async function fetchBranches() {
        const response = await getAllBranches();
        // console.log(response);
        setBranches(response.data.data.branches);
    }

    const [click, setClick] = useState(false);

    //All Expenses
    const [expenses, setExpenses] = useState([]);
    const [grandTotal, setGrandTotal] = useState(0);
    const [searchName, setSearchName] = useState("")

    // console.log(filter)
    //Get Expenses
    async function fetchExpenses() {
        setShowLoader(true);
        setTimeout(() => setShowLoader(false), 1000);
        setExpenses([])
        const response = await getAllExpenses(filter, searchName);
         console.log(response);
         if(response.data){
            setPages(Math.ceil(response.data.data.expenses.length / 10));

            setExpenses(response.data.data.expenses.sort(function (a, b) { return new Date(b.DATE_ADDED) - new Date(a.DATE_ADDED) }));

            //set total
            var total = 0;
            response.data.data.expenses.map((data) => {
                total += parseInt(data.GRAND_TOTAL);
            })
            setGrandTotal(total)
        }else{
            setExpenses([])
        }
    }

    //Search Expenses
    async function searchThis(name) {
        setSearchName(name)
        // if (name === "") {
        //     setExpenses([])
        //     fetchExpenses();
        //     setPages(1)
        // } else {
        //     setExpenses([])
        //     const response = await searchExpenses(name, filter);
        //     // console.log(response);
        //     setExpenses(response.data.data);
        //     setPages(Math.ceil(response.data.data.length / 10));
        // }
    }

    //All Employees
    const [employees, setEmployees] = useState([]);

    //Get Employees
    async function fetchEmployees() {
        const response = await getAllEmployees();
        // console.log(response);
        setEmployees(response.data.data.employees);
    }

    //Get Branch
    const [branch, setBranch] = useState("");

    //Get Branch
    async function fetchBranch() {
        const branchID = getBranch().replace(/['"]+/g, '');
        // console.log(branchID);


        const response = await getBranchName(branchID);
        // console.log(response);
        setBranch(response.data.data.name);
    }


    // --- Add Expense Hooks --- //
    const [newExpense, setNewExpense] = useState({
        name: "",
        quantity: "",
        price: "",
        grand_total: "",
        expense_date: getTodayDateISO(),
        remarks: "",
        added_by: "",
    });

    // -- Add Expense Functions --- //
    const handleAddChange = (e) => {
        const { name, value } = e.target;
        setNewExpense(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    // handles price and quantity to dynamically calculates the grand_total

    const [values, setValues] = useState({
        quantity: 0,
        price: 0,
        grand_total: 0
    });

    const handleValuesOnAddChange = (e) => {
        const dynamicVal = { ...values };
        dynamicVal[e.target.name] = e.target.value;
        dynamicVal['grand_total'] = (dynamicVal.quantity * dynamicVal.price).toFixed(2);
        setValues(dynamicVal);
        setNewExpense(prev => ({
            ...prev,
            'price': dynamicVal.price,
            'quantity': dynamicVal.quantity,
            'grand_total': dynamicVal.grand_total
        }));
        // console.log(values);
        // console.log(newExpense);
        //setNewExpense();
    }

    async function addNewExpense() {
        // console.log(newExpense)
        if (click === false) {
            setClick(true);
            const response = await addExpense(newExpense);
            console.log(response);
            if (response.data.data.status === 201) {
                toast.success(response.data.data.message.success);
                setTimeout(function () {
                    navigate("/expenses/" + currentPage + "/" + branchId)
                    refreshPage();
                }, 2000);
            }
            if (response.data.status === 400) {
                toast.error(response.data.message[0]);
            }
        }
    }

    // --- Add Expenses Popup/Modal --- //
    const [showAddExpenses, setShowAddExpenses] = useState(false);
    const handleCloseAddExpenses = () => setShowAddExpenses(false);
    const handleShowAddExpenses = () => setShowAddExpenses(true);

    // --- Edit Expenses Popup/Modal --- //
    const [showEditExpenses, setShowEditExpenses] = useState(false);
    const handleCloseEditExpenses = () => setShowEditExpenses(false);
    const handleShowEditExpenses = () => setShowEditExpenses(true);
    const [selected, setSelected] = useState("");

    // --- Edit Expense Hooks --- //
    const [editExpense, setEditExpense] = useState({
        name: "",
        quantity: "",
        price: "",
        grand_total: "",
        expense_date: "",
        remarks: "",
        added_by: "",
    });

    // -- Edit Expense Functions --- //
    const handleEditChange = (e) => {
        const { name, value } = e.target;
        if (name === "price" || name === "quantity") {
            // setEditExpense(prevState => ({
            //   ...prevState,
            //   ["grand_total"]: value,
            // }));
        }
        setEditExpense(prevState => ({
            ...prevState,
            [name]: value,
        }));
    }
    // console.log(editExpense)

    async function editExpenseDetail() {
        if (click === false) {
            setClick(true);
            setEditExpense(prevState => ({
                ...prevState,
                "grand_total": parseFloat(prevState["price"] * prevState["quantity"]).toFixed(2),
            }));

            const response = await updateExpenseDetails(editExpense, selected);
            // console.log(response);
            if (response.data.data.status === 200) {
                toast.success(response.data.data.message.success);
                setTimeout(function () {
                    navigate("/expenses/" + currentPage + "/" + branchId)
                    refreshPage();
                }, 2000);
            }
            if (response.data.status === 400) {
                toast.error(response.data.message[0]);
            }
        }
    }

    function onclickEditExpenses(id) {
        handleShowEditExpenses();
        viewExpense(id);
        setSelected(id);
    }

    // --- Delete Expenses Prompt/Modal --- //
    const [showDeleteExpenses, setShowDeleteExpenses] = useState(false);
    const handleCloseDeleteExpenses = () => setShowDeleteExpenses(false);
    const handleShowDeleteExpenses = () => setShowDeleteExpenses(true);

    async function delExpense() {
        const response = await deleteExpense(selected);
        // console.log(response);
        if (response.data.data.status === 200) {
            toast.success("Expenses Deleted Successfully!");
            setTimeout(function () {
                navigate("/expenses/" + currentPage + "/" + branchId)
                refreshPage();
            }, 2000);
        }
        if (response.data.status === 400) {
            toast.error(response.data.message[0]);
        }
    }

    function onclickDeleteExpenses(id) {
        handleShowDeleteExpenses();
        setSelected(id);
    }

    // --- vIRE Expenses Prompt/Modal --- //
    const [showViewExpenses, setShowViewExpenses] = useState(false);
    const handleCloseViewExpenses = () => setShowViewExpenses(false);

    async function viewExpense(id) {
        const response = await getExpense(id);
        console.log(response)
        setEditExpense({
            name: response.data.data.name,
            quantity: response.data.data.quantity,
            price: response.data.data.price,
            grand_total: response.data.data.grand_total,
            expense_date: response.data.data.expense_date.split(" ")[0],
            added_by: response.data.data.added_by,
        })
    }

    function onclickViewExpenses(id) {
        // handleShowViewExpenses()
    }

    React.useEffect(() => {
        fetchExpenses();
        // eslint-disable-next-line
    }, [filter, searchName]);

    React.useEffect(() => {
        fetchEmployees();
        fetchExpenses();
        fetchBranch();
        fetchBranches();
        // eslint-disable-next-line
    }, []);

    function renderTable() {
        // console.log(expenses.length);
        if (expenses.length === 0) {
            return (
                <NoDataPrompt text="expenses" />
                // <span className="no-data-prompt">No Expenses found!</span>
            )
        } else {
            return (<>
                <TableTemplate
                    tableType='expenses'
                    tableHeaders={["", "Date Added", "Branch", "Item", "Quantity", "Price", "Grand Total", "Remarks", "Added By"]}
                    tableData={expenses}
                    tableFuncs={[onclickDeleteExpenses, onclickEditExpenses, onclickViewExpenses]}
                    currentPage={currentPage}
                />
                <Pagination count={pages} page={currentPage} shape="rounded" defaultPage={1} siblingCount={2} onChange={handlePageChange} showFirstButton showLastButton />
            </>
            )
        }
    }

    function handleShowData() {
        setTimeout(() => setShowLoader(false), 5000);
    }

    // --- Render --- //

    return (
        <>
            <div>
                <Navbar />
                <div className="active-cont">
                    <Fragment>
                        {/* <ToastContainer /> */}
                        <div className="d-flex flex-row justify-content-between cont-section">
                            <h1 className="cont-sect-header">Expenses</h1>
                            <div className="search-wrapper w-50">
                                <input type="search" class="form-control search-indent" placeholder="Search by Item" onChange={(e) => searchThis(e.target.value)} />
                                <div className="search-icon">
                                    <FontAwesomeIcon
                                        className="icon"
                                        icon={'search'}
                                        aria-hidden="true"
                                        alt={'search'}
                                        fixedWidth
                                    />
                                </div>
                            </div>
                        </div>

                        {/** FILTER */}
                        <div className="filter-cont mt-0">
                            {role === "4" && (
                                <div className="filter-label">FILTER BY:

                                    <select className="filter-dropdown-input" name="branch" value={filter.branch} onChange={(e) => handleFilterChange(e)}>
                                        <option value="" selected>All Branches</option>
                                        {branches.map((data, index) => {
                                            return <option value={data.id}>{data.name}</option>
                                        })}
                                    </select>

                                </div>
                            )}
                            <div className='filter-label'>
                                DATE FROM:
                                <DatePicker selected={filter.date_from} dateFormat="MM/dd/yyyy" className="filter-date-picker mx-3 px-1" name="date_from" placeholderText="mm/dd/yyyy" onChange={date => handleDateFromChange(date)} />
                                {/* <input type="date" className="filter-date-picker" name="date_from" value={filter.date_from} onChange={(e) => handleFilterChange(e)} /> */}

                                TO:
                                <DatePicker selected={filter.date_to} dateFormat="MM/dd/yyyy" className="filter-date-picker mx-3 px-1" name="date_to" placeholderText="mm/dd/yyyy" onChange={date => handleDateToChange(date)} />
                                {/* <input type="date" className="filter-date-picker" name="date_to" value={filter.date_to} onChange={(e) => handleFilterChange(e)} /> */}
                            </div>
                        </div>


                        <div className="cont-section-novertpad">
                            <div className="d-flex flex-row justify-content-between">
                                <div className="btn-conf-wrapper ms-auto mb-2">
                                    <Button type="button" className="px-5 py-1" onClick={handleShowAddExpenses}>add Expenses</Button>
                                </div>
                            </div>
                        </div>

                        <div className="cont-section-novertpad expenses-table">
                            {showLoader ? <>
                                <ReactLoading type="spinningBubbles" color="#d4271c" height={200} width={100} className='list-loader' />
                                {handleShowData()}</> : renderTable()}
                            <div className='grand-total-cont'>
                                <span className='grand-total-label'>TOTAL EXPENSE:</span>  ₱ {numberWithCommas(grandTotal.toFixed(2))}
                            </div>
                        </div>

                    </Fragment>

                    {/* ADD Expense MODAL*/}
                    <Modal show={showAddExpenses} onHide={handleCloseAddExpenses} size="lg" className="custom-modal-style" centered>
                        <Form>
                            <Modal.Header className="custom-modal-header d-flex justify-content-end" closeButton></Modal.Header>
                            <Modal.Body className="px-5 py-1">
                                <h1 className="custom-modal-body-title">Add Expenses</h1>
                                <h2 className="custom-modal-body-description">BRANCH: {branch} </h2>
                                <Container>
                                    <Row className="nc-modal-custom-row">
                                        <Col>Item</Col>
                                        <Col xs={1}>:</Col>
                                        <Col xs={7}><Form.Control type="text" name="name" className="nc-modal-custom-input" onChange={(e) => handleAddChange(e)} required /></Col>
                                    </Row>
                                    <Row className="nc-modal-custom-row">
                                        <Col>Quantity</Col>
                                        <Col xs={1}>:</Col>
                                        <Col xs={7}><Form.Control type="number" min="1" step="1" name="quantity" className="nc-modal-custom-input" onChange={(e) => handleValuesOnAddChange(e)} value={values.quantity} required /></Col>
                                    </Row>
                                    <Row className="nc-modal-custom-row">
                                        <Col>Price</Col>
                                        <Col xs={1}>:</Col>
                                        <Col xs={7}><Form.Control type="number" min="0.01" step="0.01" name="price" className="nc-modal-custom-input" onChange={(e) => handleValuesOnAddChange(e)} value={values.price} required /></Col>
                                    </Row>
                                    <Row className="nc-modal-custom-row">
                                        <Col>Grand Total</Col>
                                        <Col xs={1}>:</Col>
                                        <Col xs={7}>Php {values.grand_total}</Col>
                                    </Row>
                                    <Row className="nc-modal-custom-row">
                                        <Col>Expense Date</Col>
                                        <Col xs={1}>:</Col>
                                        <Col xs={7}><Form.Control type="date" name="expense_date" value={newExpense.expense_date} className="nc-modal-custom-input" onChange={(e) => handleAddChange(e)} required /></Col>
                                    </Row>
                                    <Row className="nc-modal-custom-row">
                                        <Col>Remarks</Col>
                                        <Col xs={1}>:</Col>
                                        <Col xs={7}><Form.Control type="text" name="remarks" className="nc-modal-custom-input" onChange={(e) => handleAddChange(e)} /></Col>
                                    </Row>
                                    <Row className="nc-modal-custom-row">
                                        <Col>Added By</Col>
                                        <Col xs={1}>:</Col>
                                        <Col xs={7}>
                                            <Form.Select className="nc-modal-custom-input" name="added_by" onChange={(e) => handleAddChange(e)}>
                                                <option value="" hidden>--SELECT--</option>
                                                {employees.map((data, index) => {
                                                    return <option value={data.id}>{data.name}</option>
                                                })}
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                </Container>
                            </Modal.Body>
                            <Modal.Footer className="border-0 px-5">
                                <div className="d-flex flex-row justify-content-between">
                                    <div className="btn-conf-wrapper ms-auto">
                                        {click === true && (
                                            <div className="d-flex justify-content-center">
                                                <div className="btn-conf-wrapper ms-auto">
                                                    <Button type="submit" className="px-5 pb-1 mb-2">
                                                        <ReactLoading type="spinningBubbles" color="#d4271c" height={25} width={20} />
                                                    </Button>
                                                </div>
                                            </div>
                                        )}
                                        {click === false && (
                                            <Button type="submit" className="px-5 pb-1 mb-2" onClick={() => addNewExpense()}>save</Button>
                                        )}
                                    </div>
                                </div>
                            </Modal.Footer>
                        </Form>
                    </Modal>

                    {/* EDIT Package MODAL*/}
                    <Modal show={showEditExpenses} onHide={handleCloseEditExpenses} size="lg" className="custom-modal-style" centered>
                        <Modal.Header className="custom-modal-header d-flex justify-content-end" closeButton></Modal.Header>
                        <Modal.Body className="px-5 py-1">
                            <h1 className="custom-modal-body-title">Edit Expenses</h1>
                            <h2 className="custom-modal-body-description">BRANCH: {branch} </h2>
                            <Container>
                                <Row className="nc-modal-custom-row">
                                    <Col>Item</Col>
                                    <Col xs={1}>:</Col>
                                    <Col xs={7}><Form.Control type="text" name="name" className="nc-modal-custom-input" value={editExpense.name} onChange={(e) => handleEditChange(e)} required /></Col>
                                </Row>
                                <Row className="nc-modal-custom-row">
                                    <Col>Quantity</Col>
                                    <Col xs={1}>:</Col>
                                    <Col xs={7}><Form.Control type="number" min="1" step="1" name="quantity" className="nc-modal-custom-input" value={editExpense.quantity} onChange={(e) => handleEditChange(e)} required /></Col>
                                </Row>
                                <Row className="nc-modal-custom-row">
                                    <Col>Price</Col>
                                    <Col xs={1}>:</Col>
                                    <Col xs={7}><Form.Control type="number" min="0.01" step="0.01" name="price" className="nc-modal-custom-input" value={editExpense.price} onChange={(e) => handleEditChange(e)} /></Col>
                                </Row>
                                <Row className="nc-modal-custom-row">
                                    <Col>Grand Total</Col>
                                    <Col xs={1}>:</Col>
                                    <Col xs={7}>
                                        {/* <Form.Control type="number" min="0.01" step="0.01" name="grand_total"  className="nc-modal-custom-input" value={editExpense.price} onChange={(e) => handleEditChange(e)} disabled/> */}
                                        PHP {parseFloat(editExpense.price * editExpense.quantity).toFixed(2)}</Col>
                                </Row>
                                 <Row className="nc-modal-custom-row">
                                        <Col>Expense Date</Col>
                                        <Col xs={1}>:</Col>
                                        <Col xs={7}><Form.Control type="date" name="expense_date" value={editExpense.expense_date} className="nc-modal-custom-input" onChange={(e) => handleEditChange(e)} required /></Col>
                                    </Row>
                                <Row className="nc-modal-custom-row">
                                    <Col>Added By</Col>
                                    <Col xs={1}>:</Col>
                                    <Col xs={7}>
                                        <Form.Select className="nc-modal-custom-input" name="added_by" value={editExpense.added_by} onChange={(e) => handleEditChange(e)}>
                                            <option value="" hidden>--SELECT--</option>
                                            {employees.map((data, index) => {
                                                return <option value={data.id}>{data.name}</option>
                                            })}
                                        </Form.Select>
                                    </Col>
                                </Row>
                            </Container>
                        </Modal.Body>
                        <Modal.Footer className="border-0 px-5">
                            <div className="d-flex flex-row justify-content-between">
                                <div className="btn-conf-wrapper ms-auto">
                                    <Button type="submit" className="px-5 pb-1 mb-2" onClick={() => editExpenseDetail()}>save</Button>
                                </div>
                            </div>
                        </Modal.Footer>
                    </Modal>

                    <DeleteModal show={showDeleteExpenses} hide={handleCloseDeleteExpenses} text="expense" handler={() => delExpense()} />

                    {/* Expenses DETAILS MODAL*/}
                    <Modal show={showViewExpenses} onHide={handleCloseViewExpenses} size="lg" className="custom-modal-style" centered>
                        <Modal.Header className="custom-modal-header d-flex justify-content-end " closeButton></Modal.Header>
                        <Modal.Body className="px-5 py-1">
                            <h1 className="custom-modal-body-title">Package Expenses</h1>
                            <Container>
                                <Row className="nc-modal-custom-row">
                                    <Col>Username</Col>
                                    <Col xs={1}>:</Col>
                                    <Col xs={7}>data</Col>
                                </Row>
                                <Row className="nc-modal-custom-row">
                                    <Col>Password</Col>
                                    <Col xs={1}>:</Col>
                                    <Col xs={7}>data</Col>
                                </Row>
                                <Row className="nc-modal-custom-row">
                                    <Col>First Name</Col>
                                    <Col xs={1}>:</Col>
                                    <Col xs={7}>data</Col>
                                </Row>
                                <Row className="nc-modal-custom-row">
                                    <Col>Middle Name</Col>
                                    <Col xs={1}>:</Col>
                                    <Col xs={7}>data</Col>
                                </Row>
                                <Row className="nc-modal-custom-row">
                                    <Col>Last Name</Col>
                                    <Col xs={1}>:</Col>
                                    <Col xs={7}>data</Col>
                                </Row>
                                <Row className="nc-modal-custom-row">
                                    <Col>Nickname</Col>
                                    <Col xs={1}>:</Col>
                                    <Col xs={7}>data</Col>
                                </Row>
                                <Row className="nc-modal-custom-row">
                                    <Col>Role</Col>
                                    <Col xs={1}>:</Col>
                                    <Col xs={7}>data</Col>
                                </Row>
                                <Row className="nc-modal-custom-row">
                                    <Col>Date Hired</Col>
                                    <Col xs={1}>:</Col>
                                    <Col xs={7}>data</Col>
                                </Row>
                                <Row className="nc-modal-custom-row">
                                    <Col>Gender</Col>
                                    <Col xs={1}>:</Col>
                                    <Col xs={7}>data</Col>
                                </Row>
                                <Row className="nc-modal-custom-row">
                                    <Col>Address</Col>
                                    <Col xs={1}>:</Col>
                                    <Col xs={7}>data</Col>
                                </Row>
                                <Row className="nc-modal-custom-row">
                                    <Col>Contact Number</Col>
                                    <Col xs={1}>:</Col>
                                    <Col xs={7}>data</Col>
                                </Row>
                                <Row className="nc-modal-custom-row">
                                    <Col>Salary</Col>
                                    <Col xs={1}>:</Col>
                                    <Col xs={7}>data</Col>
                                </Row>
                                <Row className="nc-modal-custom-row">
                                    <Col>tin</Col>
                                    <Col xs={1}>:</Col>
                                    <Col xs={7}>data</Col>
                                </Row>
                                <Row className="nc-modal-custom-row">
                                    <Col>sss</Col>
                                    <Col xs={1}>:</Col>
                                    <Col xs={7}>data</Col>
                                </Row>
                                <Row className="nc-modal-custom-row">
                                    <Col>philhealth (phic)</Col>
                                    <Col xs={1}>:</Col>
                                    <Col xs={7}>data</Col>
                                </Row>
                                <Row className="nc-modal-custom-row">
                                    <Col>pag-ibig (hdmf)</Col>
                                    <Col xs={1}>:</Col>
                                    <Col xs={7}>data</Col>
                                </Row>
                            </Container>
                        </Modal.Body>
                        <Modal.Footer className="border-0 px-5">
                            <div className="d-flex flex-row justify-content-between">
                                <div className="btn-conf-wrapper ms-auto">
                                    <Button type="button" onClick={handleCloseViewExpenses} className="px-5 pb-1 mb-2">save</Button>
                                </div>
                            </div>
                        </Modal.Footer>
                    </Modal>

                </div>
            </div>
        </>
    )
}

export default Expenses;