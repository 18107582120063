import React, { Fragment, useEffect, useState } from "react";
import { Button, Tab, Tabs, Modal, Container, Row, Col, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from "react-datepicker";

// css
import '../../common.css'

// components
import Navbar from '../../navbar'
import { getTodayDate, subtractMonths } from '../../../utilities/common'

// sample table (temporary)
import { getCustomerBooking, getAvailableServices, getHistory, getServicePurchases, getPackagePurchases, getItemPurchases, getSampleEmployees, getGiftCertPurchases, getBookingServices, getBookingPackages, getCustomerBalance } from '../../temp-data-sample'
import TableTemplate from "../../table-template";

// modals
import PaymentModal from "../payment_modal";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { getBalanceReport } from "../../../utilities/api_calls/reports/customerBalanceReport";
import { getCustomer } from "../../../utilities/api_calls/customerApi";
import { getAge } from "../../../utilities/common";
import { getUserName } from "../../../utilities/api_calls/userApi";
import { getSummary } from "../../../utilities/api_calls/paymentApi";

export default function CustomerBalanceDetails() {
  let navigate = useNavigate();
  const { id, page, from } = useParams()

  const today = (getTodayDate()).toLocaleDateString("fr-CA");

  //Filter
  const [filter, setFilter] = useState({
    date_from: subtractMonths(1),
    date_to: getTodayDate(),
  });

  // console.log(filter)

  // -- Filter Functions --- //
  const handleDateFromChange = (date) => {
    setFilter(prevState => ({
      ...prevState,
      ['date_from']: date
    }))
  }

  const handleDateToChange = (date) => {
    setFilter(prevState => ({
      ...prevState,
      ['date_to']: date
    }))
  }

  function removeFilter() {
    setFilter({
      date_from: "",
      date_to: "",
    })
  }

  // Payment details
  const [paymentDeets, setPaymentDeets] = useState({
    payment_type: "",
    amount: "",
    card_type: "",
    card_expiry: "",
    cvc_cvv: "",
    acc_no: "",
    type: "",
    proof: "",
    code: "",
    or_no: "",
    remarks: "",
  });
  //Handle Payment Details Change
  const handlePaymentChange = (e) => {
    const { name, value } = e.target;
    setPaymentDeets(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  // boopking details
  const [existingSummary, setExistingSummary] = useState([])
  const [balanceDetails, setBalanceDetails] = useState([])
  const [totalBalance, setTotalBalance] = useState("");
  const [totalCost, setTotalCost] = useState(0)
  const [totalDiscount, setTotalDiscount] = useState(0)
  const [total, setTotal] = useState(0)
  async function fetchDetails() {
    setExistingSummary([])
    setBalanceDetails([])
    setTotalCost(0)
    setTotal(0)
    setTotalDiscount(0)
    let response = await getBalanceReport(id, filter)
    let data = response.data.data.customer_balance_report
    // console.log(response)
    setBalanceDetails(data)
    data.map(async (data) => {
      let details = await getSummary(data.ID)
      let summary = details.data.data

      let info = {}
      info.id = data.ID
      info.balance = data.BALANCE
      info.quantity = summary.summary[0].quantity
      info.name = summary.summary[0].name
      info.price = summary.summary[0].total_price
      info.COMMISSIONED_BY = summary.summary[0].COMMISSIONED_BY
      setExistingSummary(prev => [...prev, info])

      setTotalCost(prev => prev + parseFloat(summary.summary[0].total_price))
      setTotalDiscount(prev => prev + parseFloat(summary.discount))
      setTotal(prev => prev + parseFloat(summary.grand_total))
    })
    const total = data.reduce(function (sum, current) {
      return sum + parseFloat(current.BALANCE);
    }, 0.00);
    setTotalBalance(total.toFixed(2))

  }


  // Customer details
  const [customer, setCustomer] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    id: "",
  })
  const [addedBy, setAddedBy] = useState("");
  async function fetchCustomer() {
    const info = await getCustomer(id)
    // console.log(info)
    setCustomer(info.data.data)
    const user = await getUserName(info.data.data.added_by)
    setAddedBy(user)
  }

  useEffect(() => {
    fetchDetails()
    fetchCustomer()
  }, [])


  // Edit Customer Popup/Modal
  const [showEdit, setShowEdit] = useState(false);
  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = () => setShowEdit(true);

  // Avail Popup/Modal
  const [showAvail, setShowAvail] = useState(false);
  const handleCloseAvail = () => setShowAvail(false);
  const handleShowAvail = () => setShowAvail(true);

  function onclickAvail() {
    handleShowAvail()
  }

  // Payment Popup/Modal
  const [showPayment, setShowPayment] = useState(false);
  const handleClosePayment = () => setShowPayment(false);
  const handleShowPayment = () => setShowPayment(true);

  function onclickPayment() {
    handleShowPayment()
  }

  const [showSummary, setShowSummary] = useState(false);
  const handleCloseSummary = () => setShowSummary(false);
  const handleShowSummary = () => setShowSummary(true);

  function onclickSummary() {
    handleShowSummary()
  }

  // Transaction button
  const mainPage = '/customer';

  const [redirectToPayNow, setRedirectToPayNow] = useState(false);

  function handleRedirectToPayNow() {
    setRedirectToPayNow(true);
  }

  if (redirectToPayNow === true) {
    const link = mainPage + '/pay-now/' + page + "/" + id;
    return (
      <Navigate to={link} />
    )
  }

  // function onclickPayment(id, balance) {
  // console.log(id)
  // console.log(balance)
  // handleShowPayment();
  // viewExistingSummary(id, "");
  // setSelectedPurchase({ booking_id: id, booking_balance: balance });
  // }

  let link;
  if(from === "0") {
    link = "/customer-balance/1/" + page;
  } else if(from === "1") {
    link = "/balance-report/1/" + page;
  }

  return (
    <>
      <div>
        <Navbar />
        <div className="active-cont">
          <Fragment>

            {/* header */}
            <div className="d-flex flex-row cont-section">
              <FontAwesomeIcon
                className="icon me-3 fa-xl align-self-center edit-details-icon"
                icon="fa-solid fa-angle-left"
                onClick={() => navigate(link)}
              />
              <h1 className="cont-sect-header mt-0">Customer Details</h1>
            </div>

            {/* customer info section */}
            <div className="cont-section-novertpad">
              <div className="customer-deets d-flex flex-row">

                <div className="d-flex flex-column px-3 mr-5">
                  <table class="table table-lg table-responsive">
                    <tbody>
                      <tr >
                        <th scope="row">Name</th>
                        <td>{customer.first_name + " " + customer.middle_name + " " + customer.last_name}</td>
                      </tr>
                      <tr>
                        <th scope="row">Customer ID</th>
                        <td>{customer.id}</td>
                      </tr>
                      {/* <tr>
                        <th scope="row">Birthdate</th>
                        <td>{formatDate(customer.birthdate)}</td>
                      </tr> */}
                      {/* <tr>
                        <th scope="row">Age</th>
                        <td>{customer.birthdate&&getAge(customer.birthdate)}</td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>

                <div className="d-flex flex-column px-3">
                  <table class="table table-lg table-responsive">
                    <tbody>
                      {/* <tr>
                        <th scope="row">Gender</th>
                        <td>{customer.gender}</td>
                      </tr> */}
                      <tr>
                        <th scope="row">Contact Number</th>
                        <td>{customer.contact_no}</td>
                      </tr>
                      {/* <tr>
                        <th scope="row">Email Address</th>
                        <td>{customer.email}</td>
                      </tr> */}
                      <tr>
                        <th scope="row">Address</th>
                        <td>{customer.address}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="d-flex flex-column px-3">
                  <table class="table table-lg table-responsive">
                    <tbody>
                      <tr>
                        <th scope="row">Added By</th>
                        <td>{addedBy}</td>
                      </tr>
                      <tr>
                        <th scope="row">Remarks</th>
                        <td>{customer.remarks}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>


              </div>
              <hr />
            </div>

            {/** FILTER */}
            <div className='filter-cont filter-label-row1'>
              FROM:
              <DatePicker selected={filter.date_from} dateFormat="MM/dd/yyyy" className="filter-date-picker mx-3 px-1" name="date_from" placeholderText="mm/dd/yyyy" onChange={date => handleDateFromChange(date)} />
              {/* <input type="date" className="filter-date-picker" name="date_from" value={filter.date_from} onChange={(e) => handleFilterChange(e)} /> */}

              TO:
              <DatePicker selected={filter.date_to} dateFormat="MM/dd/yyyy" className="filter-date-picker mx-3 px-1" name="date_to" placeholderText="mm/dd/yyyy" onChange={date => handleDateToChange(date)} />
              {/* <input type="date" className="filter-date-picker" name="date_to" value={filter.date_to} onChange={(e) => handleFilterChange(e)} /> */}

              <div className="btn-conf-wrapper in-line  remove-filter-btn">
                <Button type="button" className="px-2 py-1 mtn-1" onClick={() => removeFilter()}>Remove Filter</Button>
              </div>
            </div>

            {/* table section - temp */}
            <div className="cont-section-novertpad">
              <TableTemplate
                tableType='customer_balance_details'
                tableHeaders={["Date Purchased", "Branch", "Name", "Amount", "Balance"]}
                tableData={balanceDetails}
                hasFooter={true}
                footerData={totalBalance}
              />
            </div>

            <div className="cont-section-novertpad">
              <div className="d-flex justify-content-end">

                <div className="srv-conf-wrapper ms-3 mt-2 transaction-button">
                  <Button onClick={onclickPayment} type="button" className="px-2 py-1 mb-1">Pay Now</Button>
                </div>
              </div>
            </div>

          </Fragment>

          <PaymentModal
            showVar={showPayment}
            showVarSetter={handleClosePayment}
            cartPayment={[{ id: 7, name: 'underarm whitening', price: 2500 }]} // sample temporary
            modalData={[getBookingServices(), getBookingPackages()]}
            paymentType='balance'
            existingSummary={existingSummary}
            balance={totalBalance}
            subTotal={totalCost}
            discount={totalDiscount}
            grandTotal={total}
            payDetails={paymentDeets}
            handlePaymentChange={handlePaymentChange}
          />

        </div>
      </div>
    </>
  );
}