import React, { useState } from "react";
import { Button, Col, Container, Form, Modal, Row, Tab, Tabs } from "react-bootstrap";
import ReactLoading from 'react-loading';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getEmployee, updateEmployeeDetails } from "../../../utilities/api_calls/employeeApi";
import { createUser, resetPassword } from "../../../utilities/api_calls/userApi";
import { checkContact, refreshPage } from "../../../utilities/common";

export default function EditEmployee(props) {
    let navigate = useNavigate();
    const [hasAcct, setHasAcct] = useState(false);
    const [employee, setEmployee] = useState({});
    const [isEditValid, setIsEditValid] = useState(true);

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEmployee(prevState => ({
            ...prevState,
            [name]: value
        }));

        if (name === "contact_no") {
            setIsEditValid(checkContact(value))
        }
    }

    const [showLoader, setShowLoader] = useState(true);

    async function setEmployeeValues() {
        setShowLoader(true)
        setEmployee({});
        setHasAcct(false);

        const response = await getEmployee(props.id);
        // console.log(response);
        const emp = response.data.data[0];

        if (emp.email === "" || emp.email === null) {
            setHasAcct(false);
        } else {
            setHasAcct(true);
            if (emp.role === "owner") {
                emp.role_id = "4";
            } else if (emp.role === "legal") {
                emp.role_id = "3";
            } else if (emp.role === "cashier") {
                emp.role_id = "1";
            }
        }

        setEmployee({
            firstname: emp.first_name,
            lastname: emp.last_name,
            nickname: emp.nickname,
            date_hired: emp.date_hired,
            gender: emp.gender,
            address: emp.address,
            contact_no: emp.contact_no,
            salary: emp.salary,
            tin: emp.tin,
            sss: emp.sss,
            philhealth: emp.phic,
            pagibig: emp.hdmf,
            role: emp.role_id,
            email: emp.email,
            password: "",
            confirm_password: ""
        });
        setShowLoader(false)
    }

    function updateEmployee() {
        if (employee.contact_no !== "") {
            setIsEditValid(checkContact(employee.contact_no))
        } else {
            setIsEditValid(true);
        }
        setTimeout(() => createEmployee(), 1000)
    }

    async function createEmployee() {
        if (employee.firstname !== "" &&
            employee.lastname !== "" &&
            checkContact(employee.contact_no) !== false) {

            // console.log(employee)
            const response = await updateEmployeeDetails(employee, props.id);
            // console.log(response);

            if (response.data.data.status === 200) {
                toast.success(response.data.data.message.success);
                setTimeout(function () {
                    navigate("/employees/" + props.currentPage)
                    refreshPage();
                }, 2000);

            } else if (response.data.status === 400) {
                toast.error(response.data.message[0]);
                setTimeout(() => {
                    navigate("/employees/" + props.currentPage)
                    refreshPage();
                }, 2000);
            }

        } else {
            toast.error("Please enter values for required inputs!");
        }
    }

    async function createAccount() {
        if (employee.role_id !== "" &&
            employee.email !== "" &&
            employee.password !== "" &&
            employee.password === employee.confirm_password &&
            employee.password.length >= 8) {
            const response = await createUser(employee, props.id);
            console.log(response);
            if (!response.data.data.error) {
                toast.success("Account Created Successfully!");
                setTimeout(() => {
                    navigate("/employees/" + props.currentPage)
                    refreshPage();
                }, 2000);
            } else {
                toast.error("Error Creating Account!")
                setTimeout(() => {
                    navigate("/employees/" + props.currentPage)
                    refreshPage();
                }, 2000);
            }
        } else {
            if (employee.password.length < 8) {
                toast.error("Password must be at least 8 characters in length.")
            } else {
                toast.error("Please enter values for required inputs!");
            }
        }
    }

    async function resetPass() {
        if (employee.password !== "" &&
            employee.confirm_password !== "") {
            if (employee.password !== employee.confirm_password) {
                toast.error("Password and Confirmation does not match. Try Again!");
            } else if (employee.password.length < 8) {
                toast.error("Please enter a password with at least 8 characters.")
            } else {
                const response = await resetPassword(employee, props.id);
                // console.log(response);
                if (!response.data.data.error) {
                    toast.success("Successful Password Reset");
                    setTimeout(() => {
                        navigate("/employees/" + props.currentPage)
                        refreshPage();
                    }, 2000);
                } else {
                    toast.error("Error Resetting Password");
                    setTimeout(() => {
                        navigate("/employees/" + props.currentPage)
                        refreshPage();
                    }, 2000);
                }
            }
        } else {
            toast.error("Please enter values for required inputs!");
        }
    }

    React.useEffect(() => {
        setEmployeeValues();
    }, [props.id])

    return (
        <>
            {/* EDIT EMPLOYEE MODAL*/}
            <Modal show={props.show} onHide={props.hide} size="lg" className="custom-modal-style" centered>
                <Modal.Header className="custom-modal-header view-employee-header justify-content-between mt-2" closeButton>
                    EDIT EMPLOYEE
                </Modal.Header>
                {showLoader ?
                    <div className="d-flex justify-content-center my-2">
                        <ReactLoading type="spinningBubbles" color="#d4271c" height={100} width={100} className='modal-loader' />
                    </div>
                    :
                    <Modal.Body className="px-5 py-1">
                        <Tabs defaultActiveKey="edit-employee" id="edit" variant="pills" className='edit-employee-tabs'>
                            <Tab eventKey="edit-employee" title="Edit Employee">
                                <Container>
                                    <Row className="nc-modal-custom-row">
                                        <Col>First Name</Col>
                                        <Col xs={1}>:</Col>
                                        <Col xs={7}>
                                            <Form.Control type="text" name="firstname" placeholder="Enter first name" className="nc-modal-custom-input" value={employee.firstname} onChange={(e) => handleEditChange(e)} required />
                                            {employee.firstname === "" && (
                                                <div className="validity-error">
                                                    Required input*
                                                </div>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row className="nc-modal-custom-row">
                                        <Col>Last Name</Col>
                                        <Col xs={1}>:</Col>
                                        <Col xs={7}>
                                            <Form.Control type="text" name="lastname" placeholder="Enter last name" className="nc-modal-custom-input" value={employee.lastname} onChange={(e) => handleEditChange(e)} />
                                            {employee.lastname === "" && (
                                                <div className="validity-error">
                                                    Required input*
                                                </div>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row className="nc-modal-custom-row">
                                        <Col>Nickname</Col>
                                        <Col xs={1}>:</Col>
                                        <Col xs={7}><Form.Control type="text" name="nickname" placeholder="Enter nickname" className="nc-modal-custom-input" value={employee.nickname} onChange={(e) => handleEditChange(e)} /></Col>
                                    </Row>
                                    <Row className="nc-modal-custom-row">
                                        <Col>Date Hired</Col>
                                        <Col xs={1}>:</Col>
                                        <Col xs={7}><Form.Control type="date" name="date_hired" placeholder="Enter date hired" className="nc-modal-custom-input" value={employee.date_hired} onChange={(e) => handleEditChange(e)} /></Col>
                                    </Row>
                                    <Row className="nc-modal-custom-row">
                                        <Col>Address</Col>
                                        <Col xs={1}>:</Col>
                                        <Col xs={7}>
                                            <Form.Control type="text" name="address" placeholder="Enter address" className="nc-modal-custom-input" value={employee.address} onChange={(e) => handleEditChange(e)} />
                                        </Col>
                                    </Row>
                                    <Row className="nc-modal-custom-row">
                                        <Col>Contact Number</Col>
                                        <Col xs={1}>:</Col>
                                        <Col xs={7}>
                                            <Form.Control type="text" name="contact_no" placeholder="Enter contact number" className="nc-modal-custom-input" value={employee.contact_no} onChange={(e) => handleEditChange(e)} />
                                            {isEditValid === false && (
                                                <div className="validity-error">
                                                    Invalid contact number format
                                                </div>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row className="nc-modal-custom-row">
                                        <Col>tin</Col>
                                        <Col xs={1}>:</Col>
                                        <Col xs={7}><Form.Control type="text" name="tin" placeholder="Enter TIN" className="nc-modal-custom-input" value={employee.tin} onChange={(e) => handleEditChange(e)} /></Col>
                                    </Row>
                                    {/* <Row className="nc-modal-custom-row">
                                    <Col>Role</Col>
                                    <Col xs={1}>:</Col>
                                    <Col xs={7}>
                                        <Form.Select className="nc-modal-custom-input" name="role_id" defaultValue="" onChange={(e) => handleEditChange(e)}>
                                            <option value="" hidden>--SELECT--</option>
                                            <option value="4">Owner / Admin</option>
                                            <option value="1">Frontdesk / Cashier</option>
                                            <option value="3">Legal</option>
                                        </Form.Select>
                                        {(!employee.role_id) && (
                                            <div className="validity-error">
                                                Required input*
                                            </div>
                                        )}
                                    </Col>
                                </Row> */}
                                    <div className="d-flex flex-row justify-content-between">
                                        <div className="btn-conf-wrapper ms-auto">
                                            <Button type="submit" className="px-5 pb-1 mb-2" onClick={() => updateEmployee()}>save</Button>
                                        </div>
                                    </div>
                                </Container>
                            </Tab>
                            {hasAcct ?
                                <Tab eventKey="reset-pass" title="Reset Password">
                                    <Container>
                                        <Row className="nc-modal-custom-row">
                                            <Col>Email</Col>
                                            <Col xs={1}>:</Col>
                                            <Col xs={7}>
                                                <Form.Control type="text" name="password" placeholder="Email" className="nc-modal-custom-input" value={employee.email} disabled />
                                            </Col>
                                        </Row>
                                        <Row className="nc-modal-custom-row">
                                            <Col>Password</Col>
                                            <Col xs={1}>:</Col>
                                            <Col xs={7}>
                                                <Form.Control type="text" name="password" placeholder="Password" className="nc-modal-custom-input" onChange={(e) => handleEditChange(e)} required />
                                                {employee.password === "" && (
                                                    <div className="validity-error">
                                                        Required input*
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>
                                        <Row className="nc-modal-custom-row">
                                            <Col>Confirm Password</Col>
                                            <Col xs={1}>:</Col>
                                            <Col xs={7}>
                                                <Form.Control type="text" name="confirm_password" placeholder="Confirm Password" className="nc-modal-custom-input" onChange={(e) => handleEditChange(e)} required />
                                                {employee.confirm_password === "" && (
                                                    <div className="validity-error">
                                                        Required input*
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>
                                        <div className="d-flex flex-row justify-content-between">
                                            <div className="btn-conf-wrapper ms-auto">
                                                <Button type="button" className="px-5 pb-1 mb-2" onClick={() => resetPass()}>save</Button>
                                            </div>
                                        </div>
                                    </Container>
                                </Tab> :
                                <Tab eventKey="create-acct" title="Create Account">
                                    <Container>
                                        <Row className="nc-modal-custom-row">
                                            <Col>Role</Col>
                                            <Col xs={1}>:</Col>
                                            <Col xs={7}>
                                                <Form.Select className="nc-modal-custom-input" name="role_id" defaultValue="" onChange={(e) => handleEditChange(e)}>
                                                    <option value="" hidden>--SELECT--</option>
                                                    <option value="4">Owner / Admin</option>
                                                    <option value="1">Frontdesk / Cashier</option>
                                                    <option value="3">Legal</option>
                                                </Form.Select>
                                                {(!employee.role_id) && (
                                                    <div className="validity-error">
                                                        Required input*
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>
                                        <Row className="nc-modal-custom-row">
                                            <Col>Email Address</Col>
                                            <Col xs={1}>:</Col>
                                            <Col xs={7}>
                                                <Form.Control type="email" name="email" placeholder="Enter email address" className="nc-modal-custom-input" onChange={(e) => handleEditChange(e)} required />
                                                {!employee.email && (
                                                    <div className="validity-error">
                                                        Required input*
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>
                                        <Row className="nc-modal-custom-row">
                                            <Col>Password</Col>
                                            <Col xs={1}>:</Col>
                                            <Col xs={7}>
                                                <Form.Control type="password" name="password" placeholder="Enter password" className="nc-modal-custom-input" onChange={(e) => handleEditChange(e)} />
                                                {!employee.password && (
                                                    <div className="validity-error">
                                                        Required input*
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>
                                        <Row className="nc-modal-custom-row">
                                            <Col>Confirm Password</Col>
                                            <Col xs={1}>:</Col>
                                            <Col xs={7}>
                                                <Form.Control type="password" name="confirm_password" placeholder="Enter password" className="nc-modal-custom-input" onChange={(e) => handleEditChange(e)} />
                                                {!employee.confirm_password && (
                                                    <div className="validity-error">
                                                        Required input*
                                                    </div>
                                                )}
                                                {employee.confirm_password !== employee.password && (
                                                    <div className="validity-error">
                                                        does not match with the password entered*
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>
                                        <div className="d-flex flex-row justify-content-between">
                                            <div className="btn-conf-wrapper ms-auto">
                                                <Button type="submit" className="px-5 pb-1 mb-2" onClick={() => createAccount()}>save</Button>
                                            </div>
                                        </div>
                                    </Container>
                                </Tab>}
                        </Tabs>
                    </Modal.Body>}
                <Modal.Footer className="border-0 px-5">
                </Modal.Footer>
            </Modal>
        </>
    )
}