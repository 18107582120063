import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useState } from "react";
import { Button } from 'react-bootstrap';
import ReactLoading from 'react-loading';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import Pagination from '@material-ui/lab/Pagination';

// css
import '../../common.css';
import './commission-report.css';

// components
import Navbar from '../../navbar';
import TableTemplate from '../../table-template';
import NoDataPrompt from '../../../utilities/no-data-prompt';
import NoEmployee from "./../../../images/icons/employee.png";

// sample data (temp)
import { getAllEmployees } from "../../../utilities/api_calls/employeeApi";
import { getCommission, searchCommission } from "../../../utilities/api_calls/reports/commissionReportApi";
import { getTodayDate, getTodayDateISO, numberFormat } from "../../../utilities/common";

function CommissionReport() {
  const [showLoader, setShowLoader] = useState(true);
  const [commission, setCommission] = useState([]);
  const [commissionTotal, setCommissionTotal] = useState(0);

  // Pagination
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  function handlePageChange(event, value) {
    // console.log(value)
    setCurrentPage(value)
  }

  //Filter
  const [filter, setFilter] = useState({
    employee: "",
    date_from: getTodayDate(),
    date_to: getTodayDate(),
    search: ""
  });

  // -- Filter Functions --- //
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  const handleDateFromChange = (date) => {
    setFilter(prevState => ({
      ...prevState,
      ['date_from']: date
    }))
  }

  const handleDateToChange = (date) => {
    setFilter(prevState => ({
      ...prevState,
      ['date_to']: date
    }))
  }

  const handleEmployeeChange = (e) => {
    setFilter(prevState => ({
      ...prevState,
      employee: e.value
    }))
  }

  function removeFilter() {
    setFilter({
      employee: "",
      date_from: "",
      date_to: "",
      search: ""
    })
    fetchCommission();
    // fetchTotalCommission();
  }

  //All Employees
  const [employees, setEmployees] = useState([]);

  //Get Employees
  async function fetchEmployees() {
    const response = await getAllEmployees();
    // console.log(response);
    setEmployees(response.data.data.employees);
  }

  //Get Commission
  async function fetchCommission() {
    setShowLoader(true);
    setTimeout(() => setShowLoader(false), 500);
    setCommission([]);
    const response = await getCommission(filter);
    setPages(Math.ceil(response.data.data.commission.length / 11));
    // console.log(response);
    setCommission(response.data.data.commission.sort(function (a, b) { return new Date(b.DATE) - new Date(a.DATE); }));

    // Calculating Total per Employee
    setCommissionTotal(0)
    response.data.data.commission.map((data, index) => {
      setCommissionTotal(prev => prev + parseFloat(data.COMMISISON))
    })
  }



  // async function fetchTotalCommission() {
  //   setCommissionTotal("");
  //   const response = await getTotalCommission(filter);
  //   // console.log(response);
  //   setCommissionTotal(response.data.data.commission);
  // }

  //Search Commission

  async function searchThis(name) {
    if (name === "") {
      setCommission([]);
      setCommissionTotal(0);
      fetchCommission();
      // fetchTotalCommission();
    } else {
      setCommission([]);
      const response = await searchCommission(name);
      // console.log(response);
      setCommission(response.data.data.search);

      var newTotal = 0;
      // eslint-disable-next-line
      response.data.data.search.map((data) => {
        newTotal += parseFloat(data.COMMISISON); //to change
      });
      // console.log(newTotal)

      setCommissionTotal(newTotal);

    }
  }

  const options = employees.map((emp) => (
    {
      label: emp.name,
      value: emp.id
    }
  ))

  function renderTable() {
    if (showLoader === true) {
      return <ReactLoading type="spinningBubbles" color="#d4271c" height={200} width={100} className='list-loader' />
    } else {
      if (commission.length === 0) {
        return <NoDataPrompt text="commissions" />
        // return <span className="no-data-prompt px-5">No Data Found</span>
      } else {
        return (
          <>
            <div className="cont-section-novertpad commission-table">
              <TableTemplate
                tableType='sample_commission_report'
                tableHeaders={["Date", "Particulars", "Name", "Transaction ID", "Commission"]}
                tableData={commission}
                currentPage={currentPage}
              />
            </div>

            <div className="cont-section-novertpad">
              <div className="d-flex justify-content-end">
                <div className="test ms-3">
                  TOTAL: {numberFormat(commissionTotal)}
                </div>
              </div>
            </div>
            <Pagination count={pages} page={currentPage} shape="rounded" defaultPage={1} siblingCount={2} onChange={handlePageChange} showFirstButton showLastButton />
          </>
        )
      }
    }
  }

  React.useEffect(() => {
    fetchCommission();
    // fetchTotalCommission();
    // eslint-disable-next-line
  }, [filter]);

  React.useEffect(() => {
    fetchEmployees();
    fetchCommission();
    // fetchTotalCommission();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div>
        <Navbar />
        <div className="active-cont">
          <Fragment>

            <div className="d-flex flex-row justify-content-between align-items-center cont-section">
              <h1 className="cont-sect-header">Commission Report</h1>
              <div className="search-wrapper w-50">
                <input type="search" class="form-control search-indent" placeholder="Search by Particulars" onChange={(e) => searchThis(e.target.value)} />
                <div className="search-icon">
                  <FontAwesomeIcon
                    className="icon"
                    icon={'search'}
                    aria-hidden="true"
                    alt={'search'}
                    fixedWidth
                  />
                </div>
              </div>
            </div>

            {/** FILTER */}
            <div className="filter-cont mt-0">
              <div className="filter-label">EMPLOYEE:
                <Select className="react-select-container" classNamePrefix="react-select" value={options.filter((info) => info.value == filter.employee)} placeholder="Select Employee..." options={options} onChange={(e) => handleEmployeeChange(e)} />
              </div>
              <div className='filter-label-row1'>
                DATE FROM:
                <DatePicker selected={filter.date_from} dateFormat="MM/dd/yyyy" className="filter-date-picker mx-3 px-1" name="date_from" placeholderText="mm/dd/yyyy" onChange={date => handleDateFromChange(date)} />
                {/* <input type="date" className="filter-date-picker" name="date_from" value={filter.date_from} onChange={(e) => handleFilterChange(e)} /> */}
                
                TO:
                <DatePicker selected={filter.date_to} dateFormat="MM/dd/yyyy" className="filter-date-picker mx-3 px-1" name="date_to" placeholderText="mm/dd/yyyy" onChange={date => handleDateToChange(date)} />
                {/* <input type="date" className="filter-date-picker" name="date_to" value={filter.date_to} onChange={(e) => handleFilterChange(e)} /> */}
                
                <div className="btn-conf-wrapper in-line  remove-filter-btn">
                  <Button type="button" className="px-2 py-1 mtn-1" onClick={() => removeFilter()}>Remove Filter</Button>
                </div>
              </div>
            </div>

            {filter.employee === "" ?
              <div className='no-data-prompt-container'>
                <img src={NoEmployee} alt='choose an employee' className='no-data-img' />
                <span>Please Select an Employee</span>
              </div> :
              renderTable()
            }


          </Fragment>

        </div>
      </div>
    </>
  );
}

export default CommissionReport;