import { getBranch, getToken, getUser, tokenExpiry } from '../common';
import { getAPICall, postAPICall } from './axiosMethodCalls';

/***************************
 * EMPLOYEE
 ***************************/

//GET
export const getAllEmployees = async (id) => {
    try {
        const response = await getAPICall(window.$link + 'employees/getAll', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch(),
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

//GET
export const getEmployee = async (id) => {
    try {
        const response = await getAPICall(window.$link + 'employees/view/' + id, {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch(),
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

//Get
export const searchEmployees = async (name) => {
    try {
        const response = await getAPICall(window.$link + 'employees/search', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            name: name,
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

//POST
// export const addEmployee = async (employee) => {
//     // console.log(employee)
//     try {
//         const response = await getAPICall(window.$link + 'employees/create', {
//             api_key: window.$api_key,
//             token: getToken().replace(/['"]+/g, ''),
//             requester: getUser(),
//             branch_id: getBranch().replace(/['"]+/g, ''),
//             // branch: employee.branch,
//             last_name: employee.lastname,
//             first_name: employee.firstname,
//             // middle_name: employee.middlename,
//             nickname: employee.nickname,
//             role: employee.role,
//             username: employee.username,
//             password: employee.password,
//             gender: employee.gender,
//             address: employee.address,
//             contact_no: employee.contact_no,
//             // salary: employee.salary,
//             tin: employee.tin,
//             // sss: employee.sss,
//             // phic: employee.philhealth,
//             // hdmf: employee.pagibig,
//             date_hired: employee.date_hired,
//             // added_by: getUser()
//         });
//         return ({ data: response });
//     } catch (error) {
//         tokenExpiry(error);
//         return ({ error: error });
//     }
// }

//POST
export const updateEmployeeDetails = async (employee, id) => {
    try {
        const response = await getAPICall(window.$link + 'employees/update/' + id, {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            updated_by: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            branch: employee.branch,
            last_name: employee.lastname,
            first_name: employee.firstname,
            middle_name: employee.middlename,
            nickname: employee.nickname,
            role: employee.role,
            email: employee.email,
            password: employee.password,
            gender: "female",
            address: employee.address,
            contact_no: employee.contact_no,
            salary: employee.salary,
            tin: employee.tin,
            sss: employee.sss,
            phic: employee.philhealth,
            hdmf: employee.pagibig,
            date_hired: employee.date_hired,
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

//DELETE
export const deleteEmployee = async (id) => {
    try {
        const response = await getAPICall(window.$link + 'employees/delete/' + id, {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            updated_by: getUser(),
            branch_id: getBranch(),
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

// POST
export const addEmployee = async (employee) => {
    try {
        const response = await postAPICall(window.$link + 'employees/add', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            last_name: employee.last_name,
            first_name: employee.first_name,
            middle_name: employee.middle_name,
            nickname: employee.nickname,
            role: employee.role,
            email: employee.email,
            password: employee.password,
            gender: employee.gender,
            address: employee.address,
            contact_no: employee.contact_no,
            salary: employee.salary,
            date_hired: employee.date_hired,
            added_by: employee.added_by,
            tin: employee.tin,
            sss: employee.sss,
            phic: employee.phic,
            hdmf: employee.hdmf,
        });
        return({data: response});
    } catch (error) {
        return({error: error});
    }
}