import { deleteEmployee, getAllEmployees, searchEmployees } from "../../../utilities/api_calls/employeeApi";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { refreshPage } from "../../../utilities/common";
import React, { Fragment, useState } from "react";
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

/* CSS */
import 'react-toastify/dist/ReactToastify.css';
import '../../common.css';
import './employee.css';

/* COMPONENTS */
import NoDataPrompt from '../../../utilities/no-data-prompt';
import Pagination from '@material-ui/lab/Pagination';
import TableTemplate from '../../table-template';
import ReactLoading from 'react-loading';
import Navbar from '../../navbar';

import DeleteModal from '../../deleteModal';
import EditEmployee from './editEmployee';
import ViewEmployee from './viewEmployee';
import AddEmployee from './addEmployee';
import { useNavigate, useParams } from "react-router-dom";

export default function Employee() {
    let navigate = useNavigate();
    const { page } = useParams();
    const [showLoader, setShowLoader] = useState(true)

    /* PAGINATION */
    const [pages, setPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(parseInt(page));

    function handlePageChange(event, value) {
        setCurrentPage(value)
    }

    /* EMPLOYEE LIST */
    const [employees, setEmployees] = useState([]);

    async function fetchEmployees() {
        setShowLoader(true)
        const response = await getAllEmployees();

        setPages(Math.ceil(response.data.data.employees.length / 11));
        setEmployees(response.data.data.employees);

        setShowLoader(false);
    }

    /* SEARCH COMPONENT */
    async function searchThis(name) {
        if (name === "") {
            setEmployees([])
            fetchEmployees();
        } else {
            setEmployees([]);
            const response = await searchEmployees(name);
            // console.log(response);
            setEmployees(response.data.data)
        }
    }

    /* ADD EMPLOYEE */
    const [showAddEmployee, setShowAddEmployee] = useState(false);
    const handleCloseAddEmployee = () => setShowAddEmployee(false);
    const handleShowAddEmployee = () => setShowAddEmployee(true);

    /* EDIT EMPLOYEE */
    const [showEditEmployee, setShowEditEmployee] = useState(false);
    const handleCloseEditEmployee = () => setShowEditEmployee(false);
    const handleShowEditEmployee = () => setShowEditEmployee(true);
    const [selected, setSelected] = useState("");

    function onclickEditEmployee(id) {
        handleShowEditEmployee();
        setSelected(id);
    }

    /* DELETE EMPLOYEE */
    const [showDeleteEmployee, setShowDeleteEmployee] = useState(false);
    const handleCloseDeleteEmployee = () => setShowDeleteEmployee(false);
    const handleShowDeleteEmployee = () => setShowDeleteEmployee(true);

    function onclickDeleteEmployee(id) {
        handleShowDeleteEmployee()
        setSelected(id);
    }

    async function delEmployee() {
        const response = await deleteEmployee(selected);
        // console.log(response);

        if (response.data.data.status === 200) {
            toast.success(response.data.data.message.success);
            setTimeout(function () {
                navigate("/employees/" + currentPage);
                refreshPage();
            }, 1000);

        } else if (response.data.status === 400) {
            toast.error(response.data.message[0]);
            setTimeout(function () {
                navigate("/employees/" + currentPage);
                refreshPage();
            }, 1000);
        }
    }

    /* VIEW EMPLOYEE */
    const [showViewEmployee, setShowViewEmployee] = useState(false);
    const handleCloseViewEmployee = () => setShowViewEmployee(false);
    const handleShowViewEmployee = () => setShowViewEmployee(true);

    function onclickViewEmployee(id) {
        handleShowViewEmployee();
        setSelected(id);
    }

    React.useEffect(() => {
        fetchEmployees();
    }, [])

    return (
        <div>
            <Navbar />
            <div className="active-cont">
                <Fragment>

                    {/* headers */}
                    <div className="d-flex flex-row justify-content-between cont-section">
                        <h1 className="cont-sect-header">Employees</h1>
                        <div className="search-wrapper w-50">
                            <input type="search" class="form-control search-indent" placeholder="Search by Name" onChange={(e) => searchThis(e.target.value)} on />
                            <div className="search-icon">
                                <FontAwesomeIcon
                                    className="icon"
                                    icon={'search'}
                                    aria-hidden="true"
                                    alt={'search'}
                                    fixedWidth
                                />
                            </div>
                        </div>
                    </div>

                    {/* add employee */}
                    <div className="cont-section-novertpad">
                        <div className="d-flex flex-row justify-content-between">
                            <div className="btn-conf-wrapper ms-auto mb-2">
                                <Button type="button" className="px-5 py-1" onClick={handleShowAddEmployee}>add employee</Button>
                            </div>
                        </div>
                    </div>

                    {/* table */}
                    {showLoader ?
                        <ReactLoading type="spinningBubbles" color="#d4271c" height={200} width={100} className='list-loader' />
                        : <div className="cont-section-novertpad employee-table">
                            {employees.length !== 0 ? <>
                                <TableTemplate
                                    tableType='employee'
                                    tableHeaders={["", "Employee Name", "Role", ""]}
                                    tableData={employees.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))}
                                    tableFuncs={[onclickDeleteEmployee, onclickEditEmployee, onclickViewEmployee]}
                                    currentPage={currentPage}
                                />
                                <Pagination count={pages} page={currentPage} shape="rounded" defaultPage={1} siblingCount={2} onChange={handlePageChange} showFirstButton showLastButton />
                            </> : <NoDataPrompt text="employees" />
                                // <span className='no-data-prompt'>No Employees Found!</span>
                            }
                        </div>}
                </Fragment>

                {/* modals */}
                <AddEmployee show={showAddEmployee} hide={handleCloseAddEmployee} currentPage={currentPage} />
                <EditEmployee show={showEditEmployee} hide={handleCloseEditEmployee} id={selected} currentPage={currentPage} />
                <DeleteModal show={showDeleteEmployee} hide={handleCloseDeleteEmployee} text="employee" handler={() => delEmployee()} />
                <ViewEmployee show={showViewEmployee} hide={handleCloseViewEmployee} id={selected} />
            </div>
        </div>
    )
}