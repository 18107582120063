import React, { Fragment, useState } from "react";
import { Button, Modal, Container, Row, Col, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { refreshPage } from "../../../utilities/common";
import { Navigate } from 'react-router-dom';

// css
import '../../common.css'
import './item-detail.css'

// components
import Navbar from '../../navbar'
import { useParams, useNavigate } from "react-router-dom";
import { deleteItem, getItem, updateItem } from "../../../utilities/api_calls/inventoryApi";
import { formatDate } from "../../../utilities/common";
import { getUserName } from "../../../utilities/api_calls/userApi";

function ItemDetail() {

    const navigate = useNavigate();

    // --- View Item Hooks --- //
    const [item, setItem] = useState({
        name: "",
        default_unit: "",
        price: "",
        remarks: "",
    });

    const [user, setUser] = useState("");

    const { id, page } = useParams();

    async function viewItem(id) {
        const response = await getItem(id);
        // console.log(response.data.data);
        setItem(response.data.data);
        viewUser(response.data.data.added_by);
        setEditItem(response.data.data);
    }

    async function viewUser(id) {
        const response = await getUserName(id);
        // console.log(response);
        setUser(response);
    }

    // --- Edit Item Hooks --- //
    const [editItem, setEditItem] = useState({
        name: "",
        default_unit: "",
        price: "",
        remarks: "",
    });

    // -- Edit Item Functions --- //
    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditItem(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    async function updateItemDetail() {
        const response = await updateItem(editItem, selected);
        // console.log(response);
        if (response.data.data.status === 200) {
            toast.success(response.data.data.message.success);
            setTimeout(function () {
                refreshPage();
            }, 3000);
        }
        if (response.data.status === 400) {
            toast.error(response.data.message[0]);
        }
    }

    // -- Edit Item Popup/Modal -- //
    const [showEditItem, setShowEditItem] = useState(false);
    const handleCloseEditItem = () => refreshPage();
    const handleShowEditItem = () => setShowEditItem(true);
    const [selected, setSelected] = useState("");

    function onclickEditItem(id) {
        handleShowEditItem();
        viewItem(id);
        setSelected(id);
    }

    // -- Delete Item Popup/Modal -- //
    const [showDeleteItem, setShowDeleteItem] = useState(false);
    const handleCloseDeleteItem = () => setShowDeleteItem(false);
    const handleShowDeleteItem = () => setShowDeleteItem(true);

    function onclickDeleteItem(id) {
        handleShowDeleteItem();
        setSelected(id);
    }

    async function delItem() {
        const response = await deleteItem(selected);
        // console.log(response);
        if (response.data.data.status === 200) {
            toast.success(response.data.data.message.success);
            setTimeout(function () {
                // refreshPage();
                navigate("/inventory-item/" + page);

            }, 3000);
        }
        if (response.data.status === 400) {
            toast.error(response.data.message[0]);
        }
    }

    /** Redirect **/
    const [redirect, setRedirect] = useState(false);

    React.useEffect(() => {
        viewItem(id);
        // eslint-disable-next-line
    }, []);


    if (redirect === true) {
        return (
            <Navigate to={"/inventory-item/" + page} />
        )
    }

    return (
        <>
            <div>

                <Navbar />
                <div className="active-cont">
                    <Fragment>
                        {/* <ToastContainer /> */}
                        <Row className="item-detail-expand">
                            <Col className="custom-modal-style my-auto item-detail-custom">
                                <Form>
                                    <div className="custom-modal-header" closeButton></div>
                                    <div className="d-flex flex-row">
                                        <Container className="col-4 my-auto">
                                            <Row><div className="h1 fw-bold my-0">item<br />details</div></Row>
                                            <div className="inventory-btn-wrapper mt-2">
                                                <Row className="my-2 mx-1">
                                                    <Button className="d-flex flex-row" onClick={() => onclickEditItem(id)}>
                                                        <FontAwesomeIcon className="align-self-center text-dark inv-icon" icon={'pen'} />
                                                        edit
                                                    </Button>
                                                </Row>
                                                <Row className="my-2 mx-1">
                                                    <Button className="d-flex flex-row" onClick={() => onclickDeleteItem(id)}>
                                                        <FontAwesomeIcon className="align-self-center text-dark inv-icon" icon={'trash'} />
                                                        delete
                                                    </Button>
                                                </Row>
                                            </div>
                                        </Container>

                                        <div className="custom-modal-vertline-border mx-3"></div>

                                        <Container className="col my-auto">
                                            <Row className='my-1'>
                                                <Col xs={7} className="fw-bold">item id</Col>
                                                <Col xs={5}>{item.id}</Col>
                                            </Row>
                                            <Row className='my-1'>
                                                <Col xs={7} className="fw-bold">item name</Col>
                                                <Col>{item.name}</Col>
                                            </Row>
                                            <Row className='my-1'>
                                                <Col xs={7} className="fw-bold">cost</Col>
                                                <Col>P {item.price}</Col>
                                            </Row>
                                            <Row className='my-1'>
                                                <Col xs={7} className="fw-bold">date added</Col>
                                                <Col>{formatDate(item.added_on)}</Col>
                                            </Row>
                                            <Row className='my-1'>
                                                <Col xs={7} className="fw-bold">added by</Col>
                                                <Col>{user}</Col>
                                            </Row>
                                            <Row className='my-1'>
                                                <Col xs={7} className="fw-bold">Remarks</Col>
                                                <Col>{item.remarks}</Col>
                                            </Row>
                                        </Container>
                                    </div>

                                    <div className="border-0 mt-3 px-5">
                                        <div className="d-flex flex-row justify-content-between">
                                            <div className="btn-conf-wrapper ms-auto">
                                                <Button type="submit" className="px-5 pb-1 mb-2 close-inventory-btn" onClick={() => setRedirect(true)}>CLOSE</Button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </Col>
                        </Row>

                    </Fragment>

                </div>

                {/* EDIT ITEM MODAL */}
                <Modal show={showEditItem} onHide={handleCloseEditItem} size="lg" className="custom-modal-style" centered>

                    <Modal.Header className="custom-modal-header d-flex justify-content-end" closeButton></Modal.Header>

                    <Modal.Body className="px-5 py-1">
                        <h1 className="custom-modal-body-title">Edit Item</h1>
                        <Container>
                            <Row className="nc-modal-custom-row">
                                <Col>item name</Col>
                                <Col xs={1}>:</Col>
                                <Col xs={7}><Form.Control type="text" name="name" placeholder="Enter item name" className="nc-modal-custom-input" value={editItem.name} onChange={(e) => handleEditChange(e)} /></Col>
                            </Row>

                            <Row className="nc-modal-custom-row">
                                <Col>default unit</Col>
                                <Col xs={1}>:</Col>
                                <Col xs={7}><Form.Control type="text" name="default_unit" placeholder="Enter default unit" className="nc-modal-custom-input" value={editItem.default_unit} onChange={(e) => handleEditChange(e)} /></Col>
                            </Row>

                            <Row className="nc-modal-custom-row">
                                <Col>default price</Col>
                                <Col xs={1}>:</Col>
                                <Col xs={7}><Form.Control type="text" name="price" placeholder="Enter input price" className="nc-modal-custom-input" value={editItem.price} onChange={(e) => handleEditChange(e)} /></Col>
                            </Row>

                            <Row className="nc-modal-custom-row">
                                <Col>Remarks</Col>
                                <Col xs={1}>:</Col>
                                <Col xs={7}><Form.Control as="textarea" className="nc-modal-custom-input" rows={5} name="remarks" value={editItem.remarks} onChange={(e) => handleEditChange(e)} /></Col>
                            </Row>
                        </Container>
                    </Modal.Body>

                    <Modal.Footer className="border-0 px-5">
                        <div className="d-flex flex-row justify-content-between">
                            <div className="btn-conf-wrapper ms-auto">
                                <Button type="submit" className="px-5 pb-1 mb-2" onClick={() => updateItemDetail()}>save</Button>
                            </div>
                        </div>
                    </Modal.Footer>

                </Modal>

                {/* DELETE EMPLOYEE MODAL*/}
                <Modal show={showDeleteItem} onHide={handleCloseDeleteItem} size="lg" className="custom-modal-style" centered>
                    <Modal.Header className="custom-modal-header del-prompt-header-custom"></Modal.Header>
                    <Modal.Body className="px-5">
                        <Container className="d-flex flex-row">
                            <FontAwesomeIcon
                                className="del-prompt-icon"
                                icon={'trash-alt'}
                                aria-hidden="true"
                                alt={'trash-alt'}
                                fixedWidth
                            />
                            <div className="ms-2">
                                <p className="del-prompt-par">Are you sure you want to delete this item?</p>
                                <p className="del-prompt-subpar">This item will be deleted immediately.<br />You can't undo this action.</p>
                            </div>
                        </Container>

                    </Modal.Body>
                    <Modal.Footer className="border-0 px-5">
                        <div className="cancel-btn-conf-wrapper">
                            <Button type="button" className="px-3" onClick={handleCloseDeleteItem}>cancel</Button>
                        </div>
                        <div className="btn-conf-wrapper">
                            <Button type="submit" className="px-3" onClick={() => delItem()}>delete</Button>
                        </div>
                    </Modal.Footer>
                </Modal>

            </div>
        </>
    );
}

export default ItemDetail;