import React, { useState } from "react";
import { Button, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactLoading from 'react-loading';

import { ToastContainer, toast } from 'react-toastify';
import { refreshPage } from '../../../utilities/common'

import './login.css'
import 'react-toastify/dist/ReactToastify.css';
import logo from '../../../images/new_logo.jpg'
import { login } from "../../../utilities/api_calls/authenticationApi";

function Login() {
  const { branchId } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [click, setClick] = useState(false);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const [loginData, setLoginData] = useState({
    email: '',
    password: '',
  });


  async function loginSubmit(e) {
    e.preventDefault()
    if (click === false) {
      setClick(true);
      const response = await login(loginData, branchId);

      if (!response.data) {
        toast.error("Invalid Login!")
        setClick(false);

        // setTimeout(function () {
        //   refreshPage();
        // }, 2000);
      }

      if (response.data.status === 200) {
        toast.success("Logging you in...");
        // console.log(response)
        localStorage.setItem('branch', JSON.stringify(branchId));
        localStorage.setItem('token', JSON.stringify(response.data.data.token));
        localStorage.setItem('user', JSON.stringify(response.data.data.id));
        localStorage.setItem('role_id', JSON.stringify(response.data.data.role_id));
        localStorage.setItem('token_expiry', JSON.stringify(response.data.data.token_expiry));
        setTimeout(() => refreshPage(), 1000);

      }

    }
    setClick(true);
  }

  function loginHandle(e) {
    const newData = { ...loginData };
    setLoginData(newData);
    newData[e.target.id] = e.target.value;
  }

  document.body.style = 'background: white;';

  return (
    <>
      <div className="login-screen">
        <div className="login-container">
          <div className="login-form-container">

            <form className="login-form" onSubmit={(e) => loginSubmit(e)}>

              {/* Header */}
              <div className="login-form-header">
                <img src={logo} alt={'logo'}></img>
                <h5 className="branch-name">
                  {branchId === "1" && ("Imelda Veteranos")}
                  {branchId === "2" && ("Robinsons Marasbaras")}
                  {branchId === "3" && ("Robinsons Ormoc")}
                </h5>
                <h4>Welcome!</h4>
                <h5>Sign-in to continue</h5>
              </div>

              {/* Username input */}
              <div className="form-group">
                <div className="username-wrapper">
                  <input
                    onChange={(e) => loginHandle(e)}
                    id="email"
                    value={loginData.email}
                    className="form-control"
                    placeholder="Email"
                    type="email"
                  />
                </div>

                <div> <br></br></div>

                {/* Password input */}
                <div className="password-wrapper">
                  <input
                    onChange={(e) => loginHandle(e)}
                    id="password"
                    value={loginData.password}
                    className="form-control pass-input-length"
                    placeholder="Password"
                    type={showPassword ? 'text' : 'password'}
                  />

                  <div className="password-icon">
                    <FontAwesomeIcon
                      className="icon"
                      icon={showPassword ? 'eye' : 'eye-slash'}
                      onClick={togglePassword}
                      aria-hidden="true"
                      alt={'eye'}
                      fixedWidth
                    />
                  </div>

                </div>

                {/* Button */}
                <div>
                  <Col className="btn-wrapper d-grid gap-2">
                    {click === true && (
                      <div className="login-loader-cont d-flex justify-content-center">
                        <ReactLoading type="spinningBubbles" color="#d4271c" height={105} width={50} />
                      </div>
                    )}
                    {click === false && (
                      <Button type="submit">Sign-in</Button>
                    )}
                  </Col>
                  {/* <ToastContainer hideProgressBar={false} /> */}
                </div>
              </div>

              {/* Incorrect Password Prompt */}
              {/*showSubmitError && <p className="error-message">Username or password is incorrect.</p>*/}

            </form>
          </div>
        </div>
      </div>
    </>
  );
}

/*
Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};
*/

export default Login;