import React, { Fragment, useState } from "react";
import { Button, Col, Row } from 'react-bootstrap';
import ReactLoading from 'react-loading';
import { Navigate } from 'react-router-dom';
import { getTodayDate, getTodayDateISO } from "../../../utilities/common";

// css
import '../../common.css';
import './daily-sales-report.css';

// components
import Navbar from '../../navbar';

// sample data (temp)
import { getSalesByType, getSalesByTypeOnBranch, getTotalDiscounts, getTotalDiscountsByBranch, getTotalExpenses, getTotalExpensesOnBranch, getTotalNet, getTotalNetByBranch, getTotalSales, getTotalSalesByBranch } from "../../../utilities/api_calls/reports/dailySalesReportApi";

function DailySalesReport() {
  const mainPage = '/daily-sales-report'
  const dateToday = getTodayDate().toDateString();
  const [showLoader, setShowLoader] = useState(false);
  


  /*** ALL BRANCHES ***/
  const [totalNet, setTotalNet] = useState("");
  const [totalSales, setTotalSales] = useState("");
  const [totalDiscounts, setTotalDiscounts] = useState("");
  const [salesByType, setSalesByType] = useState({ cash: '', card: '', bank_transfer: '', online: '' });
  const [totalExpenses, setTotalExpenses] = useState("");

  async function fetchAllBranchSales() {
    setShowLoader(false)
    const response1 = await getTotalNet();
    // console.log(response1)
    if (response1.error) {
      setTotalNet('0.00');
    } else {
      setTotalNet(response1.data.data.data.NET);
    }

    const response2 = await getTotalSales();
    // console.log(response2)
    if (response2.error) {
      setTotalSales('0.00');
    } else {
      setTotalSales(response2.data.data.data.total_sales);
    }

    const response8 = await getTotalDiscounts(getTodayDateISO(), getTodayDateISO());
    console.log(response8)
    if (response8.error) {
      setTotalDiscounts('0.00');
    } else {
      setTotalDiscounts(response8.data.data.discounts);
    }

    // Get Sales by Type
    var info1 = { ...salesByType };

    const response3 = await getSalesByType("cash");
    // console.log(response3)
    if (response3.error || response3.data.data === '') {
      info1["cash"] = '0.00';
    } else {
      info1["cash"] = response3.data.data.data.total_type_sales;
    }

    const response4 = await getSalesByType("card");
    // console.log(response4)
    if (response4.error) {
      info1["card"] = "0.00";
    } else {
      info1['card'] = response4.data.data.data.total_type_sales;
    }

    const response5 = await getSalesByType("bank_transfer");
    // console.log(response5)
    if (response5.error) {
      info1["bank_transfer"] = "0.00";
    } else {
      info1['bank_transfer'] = response5.data.data.data.total_type_sales;
    }

    const response6 = await getSalesByType("online");
    // console.log(response6)
    info1['online'] = response6.data.data.data.total_type_sales;

    setSalesByType(info1);

    const response7 = await getTotalExpenses();
    // console.log(response7)
    setTotalExpenses(response7.data.data.data.total_expenses);
  }

  /*** Imelda Veteranos ***/
  const [totalNetBranch1, setTotalNetBranch1] = useState("");
  const [totalSalesBranch1, setTotalSalesBranch1] = useState("");
  const [totalDiscountsBranch1, setTotalDiscountsBranch1] = useState("");
  const [salesByTypeBranch1, setSalesByTypeBranch1] = useState({ cash: '', card: '', bank_transfer: '', online: '' });
  const [totalExpensesBranch1, setTotalExpensesBranch1] = useState("");

  async function fetchBranch1Sales() {
    const response1 = await getTotalNetByBranch(1);
    // console.log(response1)
    if (response1.error) {
      setTotalNetBranch1('0.00');
    } else {
      setTotalNetBranch1(response1.data.data.data.NET);
    }

    const response2 = await getTotalSalesByBranch(1);
    // console.log(response2)
    if (response2.error) {
      setTotalSalesBranch1('0.00');
    } else {
      setTotalSalesBranch1(response2.data.data.data.total_branch_sales);
    }

    const response8 = await getTotalDiscountsByBranch(1, getTodayDateISO(), getTodayDateISO());
    console.log(response8)
    if (response8.error) {
      setTotalDiscountsBranch1('0.00');
    } else {
      setTotalDiscountsBranch1(response8.data.data.discounts);
    }


    // Get Sales by Type
    var info2 = { ...salesByTypeBranch1 };

    const response3 = await getSalesByTypeOnBranch("cash", 1);
    // console.log(response3)
    if (response3.error) {
      info2["cash"] = '0.00';
    } else {
      info2["cash"] = response3.data.data.data.total_type_sales_on_branch.total_type_sales_on_branch;
    }

    const response4 = await getSalesByTypeOnBranch("card", 1);
    // console.log(response4)
    if (response4.error) {
      info2["card"] = "0.00";
    } else {
      info2["card"] = response4.data.data.data.total_type_sales_on_branch.total_type_sales_on_branch;
    }

    const response5 = await getSalesByTypeOnBranch("bank_transfer", 1);
    // console.log(response5)
    if (response5.error) {
      info2["bank_transfer"] = "0.00";
    } else {
      info2["bank_transfer"] = response5.data.data.data.total_type_sales_on_branch.total_type_sales_on_branch;
    }

    const response6 = await getSalesByTypeOnBranch("online", 1);
    // console.log(response6)
    if (response6.error) {
      info2["online"] = "0.00";
    } else {
      info2["online"] = response6.data.data.data.total_type_sales_on_branch.total_type_sales_on_branch;
    }

    setSalesByTypeBranch1(info2);

    const response7 = await getTotalExpensesOnBranch(1);
    // console.log(response7)
    setTotalExpensesBranch1(response7.data.data.data.branch_expenses);
  }

  /*** Robinsons Marasbas ***/
  const [totalNetBranch2, setTotalNetBranch2] = useState("");
  const [totalSalesBranch2, setTotalSalesBranch2] = useState("");
  const [totalDiscountsBranch2, setTotalDiscountsBranch2] = useState("");
  const [salesByTypeBranch2, setSalesByTypeBranch2] = useState({ cash: '', card: '', bank_transfer: '', online: '' });
  const [totalExpensesBranch2, setTotalExpensesBranch2] = useState("");

  async function fetchBranch2Sales() {
    const response1 = await getTotalNetByBranch(2);
    // console.log(response1)
    if (response1.error) {
      setTotalNetBranch2('0.00');
    } else {
      setTotalNetBranch2(response1.data.data.data.NET);
    }

    const response2 = await getTotalSalesByBranch(2);
    // console.log(response2)
    if (response2.error) {
      setTotalSalesBranch2('0.00');
    } else {
      setTotalSalesBranch2(response2.data.data.data.total_branch_sales);
    }

    const response8 = await getTotalDiscountsByBranch(2, getTodayDateISO(), getTodayDateISO());
    console.log(response8)
    if (response8.error) {
      setTotalDiscountsBranch2('0.00');
    } else {
      setTotalDiscountsBranch2(response8.data.data.discounts);
    }

    // Get Sales by Type
    var info2 = { ...salesByTypeBranch2 };

    const response3 = await getSalesByTypeOnBranch("cash", 2);
    // console.log(response3)
    if (response3.error) {
      info2["cash"] = '0.00';
    } else {
      info2["cash"] = response3.data.data.data.total_type_sales_on_branch.total_type_sales_on_branch;
    }

    const response4 = await getSalesByTypeOnBranch("card", 2);
    // console.log(response4)
    if (response4.error) {
      info2["card"] = "0.00";
    } else {
      info2["card"] = response4.data.data.data.total_type_sales_on_branch.total_type_sales_on_branch;
    }

    const response5 = await getSalesByTypeOnBranch("bank_transfer", 2);
    // console.log(response5)
    if (response5.error) {
      info2["bank_transfer"] = "0.00";
    } else {
      info2["bank_transfer"] = response5.data.data.data.total_type_sales_on_branch.total_type_sales_on_branch;
    }

    const response6 = await getSalesByTypeOnBranch("online", 2);
    // console.log(response6)
    if (response6.error) {
      info2["online"] = "0.00";
    } else {
      info2["online"] = response6.data.data.data.total_type_sales_on_branch.total_type_sales_on_branch;
    }

    setSalesByTypeBranch2(info2);

    const response7 = await getTotalExpensesOnBranch(2);
    // console.log(response7)
    setTotalExpensesBranch2(response7.data.data.data.branch_expenses);
  }

  /*** Robinsons Place Ormoc ***/
  const [totalNetBranch3, setTotalNetBranch3] = useState("");
  const [totalSalesBranch3, setTotalSalesBranch3] = useState("");
  const [totalDiscountsBranch3, setTotalDiscountsBranch3] = useState("");
  const [salesByTypeBranch3, setSalesByTypeBranch3] = useState({ cash: '', card: '', bank_transfer: '', online: '' });
  const [totalExpensesBranch3, setTotalExpensesBranch3] = useState("");

  async function fetchBranch3Sales() {
    const response1 = await getTotalNetByBranch(3);
    // console.log(response1)
    if (response1.error) {
      setTotalNetBranch3('0.00');
    } else {
      setTotalNetBranch3(response1.data.data.data.NET);
    }

    const response2 = await getTotalSalesByBranch(3);
    // console.log(response2)
    if (response2.error) {
      setTotalSalesBranch3('0.00');
    } else {
      setTotalSalesBranch3(response2.data.data.data.total_branch_sales);
    }

    const response8 = await getTotalDiscountsByBranch(3, getTodayDateISO(), getTodayDateISO());
    console.log(response8)
    if (response8.error) {
      setTotalDiscountsBranch3('0.00');
    } else {
      setTotalDiscountsBranch3(response8.data.data.discounts);
    }

    // Get Sales by Type
    var info2 = { ...salesByTypeBranch3 };

    const response3 = await getSalesByTypeOnBranch("cash", 3);
    // console.log(response3)
    if (response3.error) {
      info2["cash"] = '0.00';
    } else {
      info2["cash"] = response3.data.data.data.total_type_sales_on_branch.total_type_sales_on_branch;
    }

    const response4 = await getSalesByTypeOnBranch("card", 3);
    // console.log(response4)
    if (response4.error) {
      info2["card"] = "0.00";
    } else {
      info2["card"] = response4.data.data.data.total_type_sales_on_branch.total_type_sales_on_branch;
    }

    const response5 = await getSalesByTypeOnBranch("bank_transfer", 3);
    // console.log(response5)
    if (response5.error) {
      info2["bank_transfer"] = "0.00";
    } else {
      info2["bank_transfer"] = response5.data.data.data.total_type_sales_on_branch.total_type_sales_on_branch;
    }

    const response6 = await getSalesByTypeOnBranch("online", 3);
    // console.log(response6)
    if (response6.error) {
      info2["online"] = "0.00";
    } else {
      info2["online"] = response6.data.data.data.total_type_sales_on_branch.total_type_sales_on_branch;
    }

    setSalesByTypeBranch3(info2);

    const response7 = await getTotalExpensesOnBranch(3);
    // console.log(response7)
    setTotalExpensesBranch3(response7.data.data.data.branch_expenses);
    setShowLoader(true)
  }

  //Redirection
  const [redirectToAll, setRedirectToAll] = useState({ state: false, type: '' });
  const [redirectToBranch1, setRedirectToBranch1] = useState({ state: false, type: '' });
  const [redirectToBranch2, setRedirectToBranch2] = useState({ state: false, type: '' });
  const [redirectToBranch3, setRedirectToBranch3] = useState({ state: false, type: '' });
  const [redirectToExpenses, setRedirectToExpenses] = useState({ state: false, branch: '' });


  function handleRedirectToAll(type) {
    setRedirectToAll({ state: true, type: type });
  }
  function handleRedirectToBranch1(type) {
    setRedirectToBranch1({ state: true, type: type });
  }
  function handleRedirectToBranch2(type) {
    setRedirectToBranch2({ state: true, type: type });
  }
  function handleRedirectToBranch3(type) {
    setRedirectToBranch3({ state: true, type: type });
  }
  function handleRedirectToExpenses(branch) {
    setRedirectToExpenses({ state: true, branch: branch });
  }

  React.useEffect(() => {
    fetchAllBranchSales();
    fetchBranch1Sales();
    fetchBranch2Sales();
    fetchBranch3Sales();
    // eslint-disable-next-line
  }, []);


  if (redirectToAll.state === true) {
    const link = mainPage + '/0/' + redirectToAll.type;
    return (
      <Navigate to={link} />
    )
  } else if (redirectToBranch1.state === true) {
    const link = mainPage + '/1/' + redirectToBranch1.type;
    return (
      <Navigate to={link} />
    )
  } else if (redirectToBranch2.state === true) {
    const link = mainPage + '/2/' + redirectToBranch2.type;
    return (
      <Navigate to={link} />
    )
  } else if (redirectToBranch3.state === true) {
    const link = mainPage + '/3/' + redirectToBranch3.type;
    return (
      <Navigate to={link} />
    )
  } else if (redirectToExpenses.state === true) {
    const link = '/expenses/1/' + redirectToExpenses.branch;
    return (
      <Navigate to={link} />
    )
  }

  function numberFormat(data) {
    if (data === "" || data === null) {
      return ("0.00");
    } else {
      return ((parseFloat(data)).toLocaleString(undefined, { minimumFractionDigits: 2 }));
    }
  }

  function Net(NetData) {
    return (
      <Col>
        <div className="d-flex justify-content-center align-items-center report-card">
          <div className="btn-report-wrapper">
            <Button className="net-report-card" type="button">
              {showLoader ? <>
                <h6 className="t-bold report-card-text">{NetData}</h6>
                <h6 className="report-card-text">NET</h6>
              </> :
                <>
                  <ReactLoading type="spinningBubbles" color="#d4271c" height={105} width={50} className='sales-loader' />
                </>
              }
            </Button>
          </div>
        </div>
      </Col>
    )
  }

  function Sales(SalesData, DataName, handler) {
    return (
      <Col>
        <div className="d-flex justify-content-center align-items-center report-card">
          <div className="btn-report-wrapper">
            <Button type="button" onClick={handler}>
              {showLoader ? <>
                <h6 className="t-bold report-card-text">{SalesData}</h6>
                <h6 className="report-card-text">{DataName}</h6>
              </> :
                <>
                  <ReactLoading type="spinningBubbles" color="#d4271c" height={105} width={50} className='sales-loader' />
                </>
              }
            </Button>
          </div>
        </div>
      </Col>
    )
  }

  return (
    <>
      <div>
        <Navbar />
        <div className="active-cont">
          <Fragment>
            <div className="cont-section cont-section-sales-report">

              {/* will change this part to make them dynamic soon */}

              <Row>
                <Col s={6}>
                  <span className="cont-sect-header">All branches</span>
                </Col>
                <Col s={6} className="d-flex justify-content-end">
                  <span className="text-right">Date : {dateToday}</span>
                </Col>
              </Row>

              <Row className="branch-report-row d-flex">
                {Net(numberFormat(totalNet))}
                {Sales(numberFormat(totalSales), 'Total Sales', () => handleRedirectToAll("total-sales"))}
                {Sales(numberFormat(totalDiscounts), 'Total Discounts')}
                {Sales(numberFormat(salesByType.cash), 'Cash', () => handleRedirectToAll("cash"))}
                {Sales(numberFormat(salesByType.card), 'Debit/Credit', () => handleRedirectToAll("card"))}
                {Sales(numberFormat(salesByType.bank_transfer), 'Bank', () => handleRedirectToAll("bank_transfer"))}
                {Sales(numberFormat(salesByType.online), 'Online', () => handleRedirectToAll("online"))}
                {Sales(numberFormat(totalExpenses), 'Expenses', () => handleRedirectToExpenses("0"))}
              </Row>

              <Row className="branch-report-row d-flex">
                <h1 className="cont-sect-header">Imelda Veteranos</h1>
                {Net(numberFormat(totalNetBranch1))}
                {Sales(numberFormat(totalSalesBranch1), 'Total Sales', () => handleRedirectToBranch1("total-sales"))}
                {Sales(numberFormat(totalDiscountsBranch1), 'Total Discounts')}
                {Sales(numberFormat(salesByTypeBranch1.cash), 'Cash', () => handleRedirectToBranch1("cash"))}
                {Sales(numberFormat(salesByTypeBranch1.card), 'Debit/Credit', () => handleRedirectToBranch1("card"))}
                {Sales(numberFormat(salesByTypeBranch1.bank_transfer), 'Bank', () => handleRedirectToBranch1("bank_transfer"))}
                {Sales(numberFormat(salesByTypeBranch1.online), 'Online', () => handleRedirectToBranch1("online"))}
                {Sales(numberFormat(totalExpensesBranch1), 'Expenses', () => handleRedirectToExpenses("1"))}
              </Row>

              <Row className="branch-report-row d-flex">
                <h1 className="cont-sect-header">Robinsons Marasbaras</h1>
                {Net(numberFormat(totalNetBranch2))}
                {Sales(numberFormat(totalSalesBranch2), 'Total Sales', () => handleRedirectToBranch2("total-sales"))}
                {Sales(numberFormat(totalDiscountsBranch2), 'Total Discounts')}
                {Sales(numberFormat(salesByTypeBranch2.cash), 'Cash', () => handleRedirectToBranch2("cash"))}
                {Sales(numberFormat(salesByTypeBranch2.card), 'Debit/Credit', () => handleRedirectToBranch2("card"))}
                {Sales(numberFormat(salesByTypeBranch2.bank_transfer), 'Bank', () => handleRedirectToBranch2("bank_transfer"))}
                {Sales(numberFormat(salesByTypeBranch2.online), 'Online', () => handleRedirectToBranch2("online"))}
                {Sales(numberFormat(totalExpensesBranch2), 'Expenses', () => handleRedirectToExpenses("2"))}
              </Row>

              <Row className="branch-report-row d-flex">
                <h1 className="cont-sect-header">Robinsons Place Ormoc</h1>
                {Net(numberFormat(totalNetBranch3))}
                {Sales(numberFormat(totalSalesBranch3), 'Total Sales', () => handleRedirectToBranch3("total-sales"))}
                {Sales(numberFormat(totalDiscountsBranch3), 'Total Discounts')}
                {Sales(numberFormat(salesByTypeBranch3.cash), 'Cash', () => handleRedirectToBranch3("cash"))}
                {Sales(numberFormat(salesByTypeBranch3.card), 'Debit/Credit', () => handleRedirectToBranch3("card"))}
                {Sales(numberFormat(salesByTypeBranch3.bank_transfer), 'Bank', () => handleRedirectToBranch3("bank_transfer"))}
                {Sales(numberFormat(salesByTypeBranch3.online), 'Online', () => handleRedirectToBranch3("online"))}
                {Sales(numberFormat(totalExpensesBranch3), 'Expenses', () => handleRedirectToExpenses("3"))}
              </Row>

            </div>
          </Fragment>
        </div>
      </div>
    </>
  );
}

export default DailySalesReport;