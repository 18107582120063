import { getBranch, getToken, getUser, tokenExpiry } from '../common';
import { getAPICall, postAPICall } from './axiosMethodCalls';

/***************************
 * PAYMENTS AND SUMMARY
 ***************************/

//GET
export const getSummary = async (id, branch_id) => {
    var branch = "";

    if (branch_id) {
        branch = branch_id
    } else {
        branch = getBranch().replace(/['"]+/g, '')
    }

    // console.log(branch)
    try {
        const response = await getAPICall(window.$link + 'payments/summary/' + id, {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: branch,
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

//GET
export const calculateGrandTotal = async (discount, id) => {
    try {
        const response = await getAPICall(window.$link + 'payments/calculate_grand_total', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            booking_id: id,
            discount: discount,
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

export const updateBooking = async (details, bookingId)=>{
  var bookingDetailsId = []
  var commissionedBy = []
  details.map((data, index)=>{
    if(data.type!=='package'){bookingDetailsId.push(data.id)
    commissionedBy.push(data.commissioned_by)}
  })
  // console.log(bookingDetailsId)
  // console.log(commissionedBy)
  try {
      const response = await postAPICall(window.$link + 'bookings/update/' + bookingId, {
          api_key: window.$api_key,
          token: getToken().replace(/['"]+/g, ''),
          requester: getUser(),
          branch_id: getBranch(),

          booking_detail_ids: bookingDetailsId,
          commissioned_by: commissionedBy,

      });
      return ({ data: response });
  } catch (error) {
      tokenExpiry(error);
      return ({ error: error });
  }
}

//POST
export const payThisBooking = async (type, payment, id, salesBy) => {
    if (type === 'cash') {
        try {
            const response = await getAPICall(window.$link + 'payments/create', {
                api_key: window.$api_key,
                token: getToken().replace(/['"]+/g, ''),
                added_by: getUser(),
                branch_id: getBranch().replace(/['"]+/g, ''),
                booking_id: id,
                payment_type: type,
                amount: payment.amount,
                or_no: payment.or_no,
                commissioned_by: salesBy,
                remarks: payment.remarks
            });
            return ({ data: response });
        } catch (error) {
            return ({ error: error });
        }
    } else if (type === 'card') {
        try {
            const response = await getAPICall(window.$link + 'payments/create', {
                api_key: window.$api_key,
                token: getToken().replace(/['"]+/g, ''),
                added_by: getUser(),
                branch_id: getBranch().replace(/['"]+/g, ''),
                booking_id: id,
                payment_type: type,

                card_type: payment.card_type,
                card_expiry: payment.card_expiry,
                "cvc/cvv": payment.cvc_cvv,
                acc_no: payment.acc_no,
                or_no: payment.or_no,
                commissioned_by: salesBy,
                remarks: payment.remarks
            });
            return ({ data: response });
        } catch (error) {
            return ({ error: error });
        }
    } else if (type === 'online') {
        try {
            const response = await getAPICall(window.$link + 'payments/create', {
                api_key: window.$api_key,
                token: getToken().replace(/['"]+/g, ''),
                added_by: getUser(),
                branch_id: getBranch().replace(/['"]+/g, ''),
                booking_id: id,
                payment_type: type,
                type: payment.type,
                amount: payment.amount,
                proof: payment.proof,
                or_no: payment.or_no,
                commissioned_by: salesBy,
                remarks: payment.remarks,
            });
            return ({ data: response });
        } catch (error) {
            return ({ error: error });
        }
    } else if (type === 'gift_certificate') {
        console.log(payment)
        try {
            const response = await getAPICall(window.$link + 'payments/create', {
                api_key: window.$api_key,
                token: getToken().replace(/['"]+/g, ''),
                added_by: getUser(),
                branch_id: getBranch().replace(/['"]+/g, ''),
                booking_id: id,
                payment_type: type,
                code: payment.code,
                or_no: payment.or_no,
                commissioned_by: salesBy,
                remarks: payment.remarks,
                gc_id: payment.selected_code,
            });
            return ({ data: response });
        } catch (error) {
            return ({ error: error });
        }
    } else if (type === 'bank_transfer') {
        // console.log("bankk")
        try {
            const response = await postAPICall(window.$link + 'payments/create', {
                api_key: window.$api_key,
                token: getToken().replace(/['"]+/g, ''),
                added_by: getUser(),
                branch_id: getBranch().replace(/['"]+/g, ''),
                booking_id: id,
                payment_type: type,
                amount: payment.amount,
                proof: payment.proof,
                or_no: payment.or_no,
                commissioned_by: salesBy,
                remarks: payment.remarks,
            });
            return ({ data: response });
        } catch (error) {
            return ({ error: error });
        }
    }

}


//POST
export const payNow = async (type, payment, id, salesBy) => {

    if (type === 'cash') {
        try {
            const response = await getAPICall(window.$link + 'payments/pay_now', {
                api_key: window.$api_key,
                token: getToken().replace(/['"]+/g, ''),
                added_by: getUser(),
                branch_id: getBranch().replace(/['"]+/g, ''),
                booking_id: id,
                payment_type: type,
                amount: payment.amount,
                or_no: payment.or_no,
                remarks: payment.remarks
            });
            return ({ data: response });
        } catch (error) {
            return ({ error: error });
        }
    } else if (type === 'card') {
        try {
            const response = await getAPICall(window.$link + 'payments/pay_now', {
                api_key: window.$api_key,
                token: getToken().replace(/['"]+/g, ''),
                added_by: getUser(),
                branch_id: getBranch().replace(/['"]+/g, ''),
                booking_id: id,
                payment_type: type,

                card_type: payment.card_type,
                card_expiry: payment.card_expiry,
                cvc_cvv: payment.cvc_cvv,
                acc_no: payment.acc_no,
                or_no: payment.or_no,
                remarks: payment.remarks
            });
            return ({ data: response });
        } catch (error) {
            return ({ error: error });
        }
    } else if (type === 'online') {
        try {
            const response = await getAPICall(window.$link + 'payments/pay_now', {
                api_key: window.$api_key,
                token: getToken().replace(/['"]+/g, ''),
                added_by: getUser(),
                branch_id: getBranch().replace(/['"]+/g, ''),
                booking_id: id,
                payment_type: type,
                type: payment.type,
                amount: payment.amount,
                proof: payment.proof,
                or_no: payment.or_no,
                remarks: payment.remarks,
            });
            return ({ data: response });
        } catch (error) {
            return ({ error: error });
        }
    } else if (type === 'bank_transfer') {
        try {
            const response = await getAPICall(window.$link + 'payments/pay_now', {
                api_key: window.$api_key,
                token: getToken().replace(/['"]+/g, ''),
                added_by: getUser(),
                branch_id: getBranch().replace(/['"]+/g, ''),
                booking_id: id,
                payment_type: type,
                amount: payment.amount,
                proof: payment.proof,
                or_no: payment.or_no,
                remarks: payment.remarks,
            });
            return ({ data: response });
        } catch (error) {
            return ({ error: error });
        }
    }

}
export const payBalanceNow = async (type, payment, id, amount) => {

    if (type === 'cash') {
        try {
            const response = await getAPICall(window.$link + 'payments/pay_now', {
                api_key: window.$api_key,
                token: getToken().replace(/['"]+/g, ''),
                added_by: getUser(),
                branch_id: getBranch().replace(/['"]+/g, ''),
                booking_id: id,
                payment_type: type,
                amount: amount,
                or_no: payment.or_no,
                remarks: payment.remarks
            });
            return ({ data: response });
        } catch (error) {
            return ({ error: error });
        }
    } else if (type === 'card') {
        try {
            const response = await getAPICall(window.$link + 'payments/pay_now', {
                api_key: window.$api_key,
                token: getToken().replace(/['"]+/g, ''),
                added_by: getUser(),
                branch_id: getBranch().replace(/['"]+/g, ''),
                booking_id: id,
                payment_type: type,

                card_type: payment.card_type,
                card_expiry: payment.card_expiry,
                cvc_cvv: payment.cvc_cvv,
                acc_no: payment.acc_no,
                or_no: payment.or_no,
                remarks: payment.remarks
            });
            return ({ data: response });
        } catch (error) {
            return ({ error: error });
        }
    } else if (type === 'online') {
        try {
            const response = await getAPICall(window.$link + 'payments/pay_now', {
                api_key: window.$api_key,
                token: getToken().replace(/['"]+/g, ''),
                added_by: getUser(),
                branch_id: getBranch().replace(/['"]+/g, ''),
                booking_id: id,
                payment_type: type,
                type: payment.type,
                amount: amount,
                proof: payment.proof,
                or_no: payment.or_no,
                remarks: payment.remarks,
            });
            return ({ data: response });
        } catch (error) {
            return ({ error: error });
        }
    }else if (type === 'bank_transfer') {
        try {
            const response = await getAPICall(window.$link + 'payments/pay_now', {
                api_key: window.$api_key,
                token: getToken().replace(/['"]+/g, ''),
                added_by: getUser(),
                branch_id: getBranch().replace(/['"]+/g, ''),
                booking_id: id,
                payment_type: type,
                type: payment.type,
                amount: amount,
                proof: payment.proof,
                or_no: payment.or_no,
                remarks: payment.remarks,
            });
            return ({ data: response });
        } catch (error) {
            return ({ error: error });
        }
    }

}

