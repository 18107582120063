import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { useState } from 'react';
import './App.css';
import './components/FontAwesomeIcons'
import "react-datepicker/dist/react-datepicker.css";
import { toast, ToastContainer } from 'react-toastify';
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

/*landing and login*/
import Branch from './components/view/login/branch'
import Login from './components/view/login/login'

/* customer */
import Customer from './components/view/customer/customer'
import CustomerDetails from './components/view/customer/customer-details'
import CustomerBooking from './components/view/customer/customer-booking'
import CustomerPayNow from './components/view/customer/customer-pay-now'

/* employee */
import Employee from './components/view/employee/employee'



/* gift certs */
import GiftCertificate from './components/view/gift-certificates/gift-certificate'

/* free service */
import FreeService from './components/view/free-service/FreeService';

/* customer balance */
import CustomerBalance from './components/view/customer-balance/CustomerBalance';
import CustomerBalanceDetails from './components/view/customer-balance/CustomerBalanceDetails';

/* transaction receipts */
import TransactionsReceipt from './components/view/transactions-receipt/TransactionsReceipt'

/* inventory */
import Inventory from './components/view/inventory/inventory'
import ItemDetail from './components/view/inventory/item-detail';
import ItemHistory from './components/view/inventory/item-history';

/* reports */
import CommissionReport from './components/view/reports/commission-report'
import FreeServiceReport from './components/view/reports/free-service-report';
import BalanceReport from './components/view/reports/balance-report';
import CustomerReport from './components/view/reports/customer-report';
import DailyProductsPurchasedReport from './components/view/reports/daily-products-purchased-report';
import DailyProductsPurchasedReportAll from './components/view/reports/daily-products-purchased-report-all';

import DailyServicesRendered from './components/view/reports/daily-services-rendered';

import DailySalesReport from './components/view/reports/daily-sales-report';
import DailySalesReportAll from './components/view/reports/daily-sales-report-all';
import DailyDiscountsReportAll from './components/view/reports/daily-discounts-report-all';

/* manage */
import Service from './components/view/manage/service'
import Package from './components/view/manage/package'
import Expenses from './components/view/manage/expenses'

/* daily transactions */
import DailyTransactions from './components/view/daily-transactions/daily-transactions';

/* extras */
import UserPreference from './utilities/user_preference';
import 'react-toastify/dist/ReactToastify.css';
import DailyServicesRenderedAll from './components/view/reports/daily-services-rendered-all';
import { getToken, getTokenExpiry, refreshPage, removeUserSession } from './utilities/common';
import Reports from './components/view/reports/reports';


function App() {
  //const [token, setAuthentication] = useState(window.$userToken);

  // User Setting Preference
  const [showInventory, setShowInventory] = useState(false); // checks whether inventory dropdown is dropped
  const [showManage, setShowManage] = useState(false); // checks whether manage dropdown is dropped
  const [showReport, setShowReport] = useState(false); // checks whether report dropdown is dropped

  // Credentials
  // eslint-disable-next-line
  const [token, setAuthentication] = useState(getToken());
  // eslint-disable-next-line
  const [tokenExpiry, setTokenExpiry] = useState(getTokenExpiry());

  function promptExpiry() {
    toast.warning("Token has expired. Please log in again...");
    setTimeout(() => {
      removeUserSession();
      refreshPage();
    }, 2000);
  }

  useEffect(() => {
    var startDate = new Date().getTime();
    var endDate;

    if (tokenExpiry !== null) {
      endDate = new Date(tokenExpiry.replace(/['"]+/g, ''));

      var seconds = Math.floor((endDate - startDate) / 1000);
      setInterval(promptExpiry, parseFloat(seconds) * 1000);

    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="App">
      <ToastContainer/>
      <UserPreference.Provider value={{ showManage, setShowManage, showReport, setShowReport, showInventory, setShowInventory }}>
        <Router>
          <Routes>
            <Route path="/" element={token ? <Navigate to="/customer/1" /> : <Branch />} />
            <Route path="/login/:branchId" element={token ? <Navigate to="/customer/1" /> : <Login />} />

            <Route path="/customer/:page" element={token ? <Customer /> : <Navigate to="/" />} />
            <Route path="/customer/booking" element={token ? <CustomerBooking /> : <Navigate to="/" />} />
            <Route path="/customer/details/:page/:id" element={token ? <CustomerDetails /> : <Navigate to="/" />} />
            <Route path="/customer/pay-now/:page/:id" element={token ? <CustomerPayNow /> : <Navigate to="/" />} />

            <Route path="/daily-transactions" element={token ? <DailyTransactions /> : <Navigate to="/" />} />

            <Route path="/expenses/:page/:branchId" element={token ? <Expenses /> : <Navigate to="/" />} />

            <Route path="/employee" element={token ? <Employee /> : <Navigate to="/" />} />

            <Route path="/inventory-item/:page" element={token ? <Inventory /> : <Navigate to="/" />} />
            <Route path="/inventory-item/item-detail/:id/:page" element={token ? <ItemDetail /> : <Navigate to="/" />} />
            <Route path="/inventory-history" element={token ? <ItemHistory /> : <Navigate to="/" />} />

            {/* Gc */}
            <Route path="/gift-certificate/:page" element={token ? <GiftCertificate /> : <Navigate to="/" />} />

            {/* Free Service */}
            <Route path="/free-service" element={token ? <FreeService /> : <Navigate to="/" />} />

            {/* Customer Balance */}
            <Route path="/customer-balance/:from/:page" element={token ? <CustomerBalance /> : <Navigate to="/" />} />
            <Route path="/customer-balance/details/:from/:page/:id" element={token ? <CustomerBalanceDetails /> : <Navigate to="/" />} />

            {/* Transactions Receipt */}
            <Route path="/transactions-receipt" element={token ? <TransactionsReceipt /> : <Navigate to="/" />} />

            {/* reports tab */}
            <Route path="/reports" element={token ? <Reports /> : <Navigate to="/" />} />

            <Route path="/daily-sales-report" element={token ? <DailySalesReport /> : <Navigate to="/" />} />
            <Route path="/daily-sales-report/:id/:type" element={token ? <DailySalesReportAll /> : <Navigate to="/" />} />
            <Route path="/daily-discounts-report/:id/:type" element={token ? <DailyDiscountsReportAll /> : <Navigate to="/" />} />

            <Route path="/daily-products-purchased-report" element={token ? <DailyProductsPurchasedReport /> : <Navigate to="/" />} />
            <Route path="/daily-products-purchased-report/all/:id" element={token ? <DailyProductsPurchasedReportAll /> : <Navigate to="/" />} />

            <Route path="/balance-report/:from/:page" element={token ? <CustomerBalance /> : <Navigate to="/" />} />
            <Route path="/balance-report/details/:from/:page/:id" element={token ? <CustomerBalanceDetails /> : <Navigate to="/" />} />

            <Route path="/daily-services-rendered" element={token ? <DailyServicesRendered /> : <Navigate to="/" />} />
            <Route path="/daily-services-rendered/all/:id" element={token ? <DailyServicesRenderedAll /> : <Navigate to="/" />} />

            <Route path="/commission-report" element={token ? <CommissionReport /> : <Navigate to="/" />} />
            <Route path="/free-service-report" element={token ? <FreeServiceReport /> : <Navigate to="/" />} />



            <Route path="/employees/:page" element={token ? <Employee /> : <Navigate to="/" />} />
            <Route path="/service/:page" element={token ? <Service /> : <Navigate to="/" />} />
            <Route path="/package/:page" element={token ? <Package /> : <Navigate to="/" />} />


          </Routes>
        </Router>
      </UserPreference.Provider>
    </div>

  );
}

export default App;
