import { Table } from "react-bootstrap"
import NoDataPrompt from "../../utilities/no-data-prompt"

export default function NoDataFound({tableType, tableHeaders, message}) {
    return (
        <>
            <div className={tableType.includes('customer_') === true ? "elixir-table-wrapper table-max" : "elixir-table-wrapper"}>
                <Table striped responsive size="sm" className={tableType.includes('report') === true ? 'text-center elixir-table fixed-table report-table' : 'text-center elixir-table fixed-table'}>

                    {/* HEADERS */}
                    <thead>
                        <tr>
                            {tableHeaders.map((header) => {
                                return (
                                    <th>{header}</th>
                                )
                            })}
                        </tr>
                    </thead>
                </Table>

                {/* DATA */}
                <NoDataPrompt text={message} type="customers" />
            </div>
        </>
    )
}