import { formatMDY, getAge, getBranch, getToken, getUser, tokenExpiry } from '../common';
import { getAPICall, postAPICall } from './axiosMethodCalls';

/***************************
 * CUSTOMERS 
 ***************************/

//GET
export const getAllCustomers = async (page, name) => {
    try {
        const response = await getAPICall(window.$link + 'customers/getAll', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            offset: page,
            name: name,
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}
//Get
export const searchCustomers = async (name) => {
    try {
        const response = await getAPICall(window.$link + 'customers/search', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            name: name,
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

//GET
export const getCustomer = async (id) => {
    try {
        const response = await getAPICall(window.$link + 'customers/view/' + id, {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch(),
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

//GET
export const getAvailableServicesDetails = async (id, filter) => {
    try {
        const response = await getAPICall(window.$link + 'customers/available_services/' + id, {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            date_from: formatMDY(filter.date_from),
            date_to: formatMDY(filter.date_to),
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

//GET
export const getBookings = async (id, filter) => {
    try {
        const response = await getAPICall(window.$link + 'customers/get_bookings/' + id, {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            date_from: formatMDY(filter.date_from),
            date_to: formatMDY(filter.date_to),
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

//GET
export const getHistoryDetails = async (id, filter) => {
    try {
        const response = await getAPICall(window.$link + 'customers/history/' + id, {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            date_from: formatMDY(filter.date_from),
            date_to: formatMDY(filter.date_to),
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

//GET
export const getGCS = async (id, filter) => {
    try {
        const response = await getAPICall(window.$link + 'customers/gcs_purchased/' + id, {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            date_from: formatMDY(filter.date_from),
            date_to: formatMDY(filter.date_to),
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}


//GET
export const getItemsPurchased = async (id, filter) => {
    try {
        const response = await getAPICall(window.$link + 'customers/items_purchased/' + id, {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            date_from: formatMDY(filter.date_from),
            date_to: formatMDY(filter.date_to),
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

//GET
export const getPackagesPurchased = async (id, filter) => {
    try {
        const response = await getAPICall(window.$link + 'customers/packages_purchased/' + id, {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            date_from: formatMDY(filter.date_from),
            date_to: formatMDY(filter.date_to),
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

//GET
export const getServicesPurchased = async (id, filter) => {
    try {
        const response = await getAPICall(window.$link + 'customers/services_purchased/' + id, {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            date_from: formatMDY(filter.date_from),
            date_to: formatMDY(filter.date_to),
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

//GET
export const checkExistingBooking = async (id) => {
    // console.log("called check existing booking")
    try {
        const response = await getAPICall(window.$link + 'customers/check_existing_booking', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            customer_id: id,
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

//GET
export const getSummary = async (id) => {
    try {
        const response = await getAPICall(window.$link + 'customers/packages_purchased/' + id, {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

//POST
export const payPackage = async (type, packageInfo, amount) => {
    try {
        const response = await getAPICall(window.$link + 'payments/create/', {
            type: type,
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            added_by: getUser(),
            branch_id: getBranch(),
            purchase_id: packageInfo.purchase_id,
            booking_id: packageInfo.booking_id,
            amount: amount,
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}
//POST
export const createThisCustomer = async (customer) => {

    try {
        const response = await postAPICall(window.$link + 'customers/create', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch(),
            first_name: customer.first_name,
            last_name: customer.last_name,
            middle_name: customer.middle_name,
            suffix: customer.suffix,
            birthdate: customer.birthdate,
            email: customer.email,
            gender: customer.gender,
            age: getAge(customer.birthdate),
            remarks: customer.remarks,
            address: customer.address,
            added_by: getUser(),
            contact_no: customer.contact_no,
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

export const updateCustomer = async (customer, id) => {

    try {
        const response = await getAPICall(window.$link + 'customers/update/' + id, {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            updated_by: getUser(),
            first_name: customer.first_name,
            last_name: customer.last_name,
            middle_name: customer.middle_name,
            suffix: customer.suffix,
            birthdate: customer.birthdate,
            email: customer.email,
            gender: customer.gender,
            age: getAge(customer.birthdate),
            remarks: customer.remarks,
            address: customer.address,
            contact_no: customer.contact_no,
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

//DELETE
export const deleteACustomer = async (id) => {
    try {
        const response = await getAPICall(window.$link + 'customers/delete/' + id, {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            updated_by: getUser(),
            branch_id: getBranch()
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}