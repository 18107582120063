import React, { Fragment, useState } from "react";
import { Button, Container, Row, Col, Form, Stack, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal } from 'react-bootstrap';
import { getBranch, getCommissionedBy, getTodayDate, numberFormat, refreshPage } from "../../../utilities/common";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';

// css
import '../../common.css'
import './customer-pay-now.css'

// components
import Navbar from '../../navbar'
import BuyModal from "../BuyModal";
import BuyGiftCertModal from "../BuyGiftCertModal";

// sample data
import { getBranchName } from "../../../utilities/api_calls/branchApi";
import { getAvailableItems } from "../../../utilities/api_calls/inventoryApi";
import { getAllServices } from "../../../utilities/api_calls/servicesApi";
import { getAllPackages } from "../../../utilities/api_calls/packageApi";
import { getAvailableGiftCert, getGiftCert, searchGiftCertificate } from "../../../utilities/api_calls/giftCertApi";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { checkExistingBooking } from "../../../utilities/api_calls/customerApi";
import { calculateGrandTotal, getSummary, payThisBooking, updateBooking } from "../../../utilities/api_calls/paymentApi";
import { removeItemPurchase } from "../../../utilities/api_calls/bookingApi";
import { getAllEmployees } from "../../../utilities/api_calls/employeeApi";
import PaymentModal from "../payment_modal";


function CustomerPayNow() {
  const { page } = useParams();

  // Payment Details
  const navigate = useNavigate()
  const [clickSave, setClickSave] = useState(false)
  const [paymentType, setPaymentType] = useState("");
  const [giftCerts, setGiftCerts] = useState([]);
  const [giftCertDeets, setGiftCertDeets] = useState([]);
  const [paymentDeets, setPaymentDeets] = useState({
    amount: "",
    card_type: "",
    card_expiry: "",
    cvc_cvv: "",
    acc_no: "",
    type: "",
    proof: "",
    code: "",
    selected_code: "",
    or_no: "",
    remarks: "",
  });

  //Handle Payment Details Change
  const handlePaymentChange = (e) => {
    const { name, value } = e.target;

    if (name === "code" && value !== "") {
      viewGCS(value);
    }

    if (name === "selected_code") {
      getGCSDeets(value);
      setPaymentDeets(prevState => ({
        ...prevState,
        [name]: value
      }));

    } else {
      setPaymentDeets(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  }

  async function getGCSDeets(value) {
    const response = await getGiftCert(value);
    // console.log(response)
    setGiftCertDeets(response.data.data);
  }

  async function viewGCS(value) {
    const response = await searchGiftCertificate(value, "purchased");
    // console.log(response)
    setGiftCerts(response.data.data.search);
  }

  // Payment Popup/Modal
  const [showPayment, setShowPayment] = useState(false);
  const handleClosePayment = () => setShowPayment(false);
  const handleShowPayment = () => setShowPayment(true);
  async function onclickPayment(id) {
    const response1 = await calculateGrandTotal(discount, existingBooking.booking_id);
    viewExistingSummary();
    handleShowPayment();
  }

  const cardPaymentDetail = () => {
    return (
      <div>
        <Col className="nc-modal-custom-row">
          <Col className="fw-bold">card type</Col>
          <Col className="">
            <Form.Control type="text" name="card_type" placeholder="Card Type" className="nc-modal-custom-input payment-input-size-custom mb-1" onChange={(e) => handlePaymentChange(e)} />
          </Col>
        </Col>
        <Col className="nc-modal-custom-row">
          <Col className="fw-bold">payment details</Col>
          <Col className="">
            <Form.Control type="text" name="acc_no" placeholder="Account Number" className="nc-modal-custom-input payment-input-size-custom mb-1" onChange={(e) => handlePaymentChange(e)} />
          </Col>
          <Col className="d-flex flex-row">
            <Form.Control
              type="text"
              placeholder="Expiry Date"
              onFocus={(e) => (e.target.type = "date")}
              onBlur={(e) => (e.target.type = "text")}
              className="nc-modal-custom-input payment-input-size-custom"
              name="card_expiry"
              onChange={(e) => handlePaymentChange(e)} />
            <Form.Control type="text" name="cvc_cvv" placeholder="CVV/CVC" className="nc-modal-custom-input payment-input-size-custom ms-1" onChange={(e) => handlePaymentChange(e)} />
          </Col>
        </Col>
      </div>
    )
  }

  const cashPaymentDetail = () => {
    return (
      <Col className="nc-modal-custom-row">
        <Col className="fw-bold">amount</Col>
        <Col className="">
          <Form.Control type="number" min="0.01" step="0.01" name="amount" placeholder="Enter amount" className="nc-modal-custom-input payment-input-size-custom mb-1" onChange={(e) => handlePaymentChange(e)} />
        </Col>
      </Col>
    )
  }

  const onlinePaymentDetail = () => {
    return (
      <div>
        <Col className="nc-modal-custom-row">
          <Col className="fw-bold">type</Col>
          <Form.Control type="text" name="type" placeholder="Channel type" className="nc-modal-custom-input payment-input-size-custom mb-1" onChange={(e) => handlePaymentChange(e)} />
        </Col>

        <Col className="nc-modal-custom-row">
          <Col className="fw-bold">amount</Col>
          <Col>
            <Form.Control type="number" min="0.01" step="0.01" name="amount" placeholder="Enter amount" className="nc-modal-custom-input payment-input-size-custom mb-1" onChange={(e) => handlePaymentChange(e)} />
          </Col>
        </Col>

        <Col className="nc-modal-custom-row">
          <Col className="fw-bold">proof of payment</Col>
          <Col className="">
            <Form.Control type="text" name="proof" placeholder="Reference Number" className="nc-modal-custom-input payment-input-size-custom mb-1" onChange={(e) => handlePaymentChange(e)} />
          </Col>
        </Col>
      </div>
    )
  }

  const bankPaymentDetail = () => {
    return (
      <div>
        <Col className="nc-modal-custom-row">
          <Col className="fw-bold">account details</Col>
          <Row className="account-detail-custom">
            <Col xs={4} className="fw-bold ms-3">Account Name</Col>
            :
            <Col>Lovely Shane Gucor</Col>
          </Row>
          <Row className="account-detail-custom">
            <Col xs={4} className="fw-bold ms-3">Card Number</Col>
            :
            <Col>12345678010</Col>
          </Row>
        </Col>

        <Col className="nc-modal-custom-row">
          <Col className="fw-bold">amount</Col>
          <Col className="">
            <Form.Control type="number" min="0.01" step="0.01" name="amount" placeholder="Enter amount" className="nc-modal-custom-input payment-input-size-custom mb-1" onChange={(e) => handlePaymentChange(e)} />
          </Col>
        </Col>

        <Col className="nc-modal-custom-row">
          <Col className="fw-bold">proof of payment</Col>
          <Col className="">
            <Form.Control type="text" name="proof" placeholder="Reference Number" className="nc-modal-custom-input payment-input-size-custom mb-1" onChange={(e) => handlePaymentChange(e)} />
          </Col>
        </Col>
      </div>
    )
  }

  const giftCertificatePaymentDetail = () => {
    return (
      <>
        <Col className="nc-modal-custom-row">
          <Col className="fw-bold">Search code</Col>
          <Col className="">
            <Form.Control type="text" name="code" placeholder="Enter gift certificate code" className="nc-modal-custom-input payment-input-size-custom mb-1" value={paymentDeets.code} onChange={(e) => handlePaymentChange(e)} />
          </Col>
          <Col>
            <Col className="fw-bold">SELECT AVAILABLE GIFT CERTIFICATE</Col>
            <select name="selected_code" onChange={(e) => handlePaymentChange(e)}>
              <option value="" hidden>--SELECT--</option>
              {giftCerts.length !== 0 && giftCerts.map((data, index) => {
                return <option value={data.ID}>{data.NAME + " - " + data.CODE}</option>
              })}
            </select>
          </Col>
        </Col>

        <Col className="nc-modal-custom-row">
          <Col className="fw-bold">gift certificate details</Col>
          {/* must be dynamic depending sa GCS code */}
          {giftCertDeets.length !== 0 && (
            <div>
              <p className="account-detail-custom ms-3 fw-normal">{giftCertDeets[0].name}</p>
              <p className="account-detail-custom ms-3 fw-normal">{"DETAILS: " + giftCertDeets[0].details}</p>
            </div>
          )}
        </Col>


      </>
    )
  }

  //Customer ID
  const { id } = useParams();

  //Redirect back
  const [redirect, setRedirect] = useState(false);
  const mainPage = '/customer';

  //Branch
  const [branchName, setBranchName] = useState("");

  async function fetchBranch() {
    const response = await getBranchName(getBranch().replace(/['"]+/g, ''));
    // console.log(response)
    setBranchName(response.data.data.name)
  }

  //Date today
  const dateToday = getTodayDate().toDateString().split(" ");
  const month = getTodayDate().toLocaleString('default', { month: 'long' });

  // Modal (Buy Product)
  const [showBuyProduct, setShowBuyProduct] = useState(false);
  const handleCloseBuyProduct = () => setShowBuyProduct(false);
  const handleShowBuyProduct = () => setShowBuyProduct(true);

  const [salesBy, setSalesBy] = useState(getCommissionedBy() !== null ? getCommissionedBy().replace(/['"]+/g, '') : "");

  const handleSalesBy = (data) => {
    localStorage.setItem('commissioned_by', JSON.stringify(data));
    setSalesBy(data);
    // console.log(getCommissionedBy().replace(/['"]+/g, ''))
  }


  // Modal (Buy Service)
  const [showBuyService, setShowBuyService] = useState(false);
  const handleCloseBuyService = () => setShowBuyService(false);
  const handleShowBuyService = () => setShowBuyService(true);


  // Modal (Buy Package)
  const [showBuyPackage, setShowBuyPackage] = useState(false);
  const handleCloseBuyPackage = () => setShowBuyPackage(false);
  const handleShowBuyPackage = () => setShowBuyPackage(true);


  // Modal (Buy Gift Certificate)
  const [showBuyGiftCert, setShowBuyGiftCert] = useState(false);
  const handleCloseBuyGiftCert = () => setShowBuyGiftCert(false);
  const handleShowBuyGiftCert = () => setShowBuyGiftCert(true);


  //All Employees
  const [employees, setEmployees] = useState([]);

  //Get Employees
  async function fetchEmployees() {
    setEmployees([])
    const response = await getAllEmployees();
    // console.log(response);
    setEmployees(response.data.data.employees);
  }

  //View Products
  const [viewProducts, setViewProducts] = useState([{
    id: "",
    price: "",
    name: "",
  }]);

  async function fetchProducts() {
    const response = await getAvailableItems();
    // console.log(response);
    setViewProducts(response.data.data.available_items)
  }

  //View Services
  const [viewServices, setViewServices] = useState([{
    id: "",
    price: "",
    name: "",
  }]);

  async function fetchServices() {
    const response = await getAllServices();
    // console.log(response);
    setViewServices(response.data.data.services)
  }

  //View Packages
  const [viewPackages, setViewPackages] = useState([{
    id: "",
    price: "",
    name: "",
  }]);

  async function fetchPackages() {
    const response = await getAllPackages();
    // console.log(response);
    setViewPackages(response.data.data.packages)
  }

  //View Gift Certificates
  const [viewGC, setViewGC] = useState([{
    id: "",
    price: "",
    name: "",
  }]);

  async function fetchGC() {
    const response = await getAvailableGiftCert();
    if (response.data) {
      setViewGC(response.data.data.available)
    }
  }

  //Check Existing Booking
  const [existingBooking, setExistingBooking] = useState({ booking_id: "0" });

  async function checkBooking() {
    // console.log("here")
    const response = await checkExistingBooking(id);
    // console.log(response);
    setExistingBooking(response.data.data[0])
  }

  //Existing Summary
  const [existingSummary, setExistingSummary] = useState([{
    id: "",
    code: "",
    name: "",
    quantity: "",
    total_price: "",
    type: "",
  }]);
  const [subTotal, setSubTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [savedDiscount, setSavedDiscount] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [needsCommissionBy, setNeedsCommissionBy] = useState(false)

  async function viewExistingSummary() {
    // console.log(existingBooking)
    var subTotal = 0;
    const response = await getSummary(existingBooking.booking_id);
    // console.log(response);
    if (response.data) {
      setExistingSummary(response.data.data.summary)
    }



    setSubTotal(response.data.data.sub_total);
    setSavedDiscount(response.data.data.discount)
    setDiscount(response.data.data.discount)
    setGrandTotal(response.data.data.grand_total);

  }


  // --- Remove Prompt/Modal --- //
  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);
  const [selected, setSelected] = useState({});

  function onclickDelete(type, id) {
    handleShowDelete();
    setSelected({
      type: type,
      id: id,
    })
  }

  const [click, setClick] = useState(false);

  async function removePurchase() {
    if (click === false) {
      setClick(true);
      const response = await removeItemPurchase(selected.type, existingBooking.booking_id, selected.id);
      // console.log(response);
      if (response.data.data.status === 200) {
        toast.success("Successfully Deleted!");
        setTimeout(function () {
          refreshPage();
        }, 1000);
      }
      if (response.data.data.status === 400 || response.data.data.status === 401) {
        toast.error("Error Deleting " + (selected.type).charAt(0).toUpperCase() + selected.type.slice(1) + "!");
        setTimeout(function () {
          refreshPage();
        }, 2000);
      }
    }
  }



  async function payPurchase() {
    var validation = true;
    if (needsCommissionBy === true && salesBy === "") {
      toast.error("Please add commissioned by.")
      validation = false;
    }
    if (paymentType === "") {
      toast.error("Please choose payment type.")
      validation = false;
    }

    if (discount !== savedDiscount) {
      toast.error("Please save discount first.")
      validation = false;
    }

    if (validation) {

      if (existingBooking.booking_id === "") {
        toast.error("No items to pay!");
        setTimeout(function () {
          refreshPage();
        }, 2000);
      }
      // console.log(hasCommissionBy)
      else if (click === false) {
        setClick(true);
        // console.log(paymentType)
        const response = await payThisBooking(paymentType, paymentDeets, existingBooking.booking_id, salesBy);
        // console.log(response);
        // console.log("hereee")
        if (response.error) {
          // setTimeout(function () {
          //   refreshPage();
          // }, 2000);
          toast.error("Payment Unsuccessful!");
        }
        else if (response.data.data.status === 200 || response.data.status === 201) {
          toast.success("Successfully Paid!");
          // setTimeout(function () {
          //   setRedirect(true);
          // }, 1000);
        }
      }
    }
    // else {
    //   toast.error("Please choose payment type")
    // }

  }



  // async function addDiscount() {

  //   const response = await calculateGrandTotal(discount, existingBooking.booking_id);
  // console.log(response);
  // if (response.data.data.status === 200 || response.data.data.status === 201) {
  //   // toast.success("Successfully Added Discount!");
  //   toast.success("Discount Successfully Added!");
  //   setTimeout(function () {
  //     setRedirect(true);
  //     refreshPage();
  //   }, 1000);
  // }
  // if (response.error) {
  //   toast.error("Discount Unsuccessful!");
  //   setTimeout(function () {
  //     refreshPage();
  //   }, 2000);
  // }
  // }

  async function discountHandler(e) {
    setDiscount(e.target.value);
    setGrandTotal(subTotal - e.target.value)
    // const response = await calculateGrandTotal(e.target.value, existingBooking.booking_id);
    // viewExistingSummary();
  }

  async function saveChanges() {
    // console.log("click button")
    if (!clickSave) {
      setClickSave(true)

      // save discount
      const response1 = await calculateGrandTotal(discount, existingBooking.booking_id);

      // update booking
      const response = await updateBooking(itemList, existingBooking.booking_id)
      // console.log(response)
      if (response.data) {
        toast.success("Booking saved successfully")
        setTimeout(() => {
          navigate('/customer/details/' + page + "/" + id)
        }, 2000)
      } else if (response.error) {
        toast.error("Error saving booking.")
        setTimeout(() => {
          setClickSave(false)
        }, 2000)
      }

    }
  }


  React.useEffect(() => {
    fetchEmployees();
    checkBooking();
    fetchBranch();
    fetchProducts();
    fetchServices();
    fetchPackages();
    fetchGC();
    // eslint-disable-next-line
  }, []);


  React.useEffect(() => {
    if (giftCertDeets.length !== 0) {
      setPaymentDeets(prevState => ({
        ...prevState,
        "code": giftCertDeets[0].gift_certificate_code
      }));
    }
    // eslint-disable-next-line
  }, [giftCertDeets]);

  React.useEffect(() => {
    if (existingBooking) {
      viewExistingSummary();
    }
    // eslint-disable-next-line
  }, [existingBooking]);

  const [itemList, setItemList] = useState([])
  React.useEffect(() => {
    setItemList([])
    if (existingSummary) {
      existingSummary.map((data, index) => {
        var info = {
          id: data.id,
          commissioned_by: data.COMMISSIONED_BY,
          type: data.type
        }
        setItemList(prev => [...prev, info])
      })
    }
  }, [existingSummary])

  const checkValue = (currentId, commissionedBy) => {
    if (itemList && currentId) {
      let currentItem = itemList.find(({ id }) => id === currentId)
      // console.log(currentItem)
      if (currentItem) {
        return currentItem.commissioned_by || ""
      } else {
        return ""
      }
    }
  }
  // console.log(itemList)
  const handleItemListChange = (id, commissionedBy) => {
    if (itemList) {
      let objIndex = itemList.findIndex((obj => obj.id === id));
      let newArray = [...itemList]
      newArray[objIndex].commissioned_by = commissionedBy
      setItemList(newArray)
    }
  }



  React.useEffect(() => {
    setPaymentDeets({});
  }, [paymentType]);

  if (redirect === true) {
    const link = mainPage + '/details/' + page + "/" + id;
    return (
      <Navigate to={link} />
    )
  }


  return (
    <>
      <div>
        <Navbar />

        <div className="active-cont paynow-cont-section">
          <Fragment>
            {/* <ToastContainer /> */}
            <Container className="d-flex flex-row">
              <Container>
                {/* <div className="close-cont" onClick={() => setRedirect(true)}>
                  <i className="ico-times" role="img" aria-label="Cancel"></i>
                </div> */}
                <Row className="h2 fw-bold">SUMMARY</Row>
                <Row className="h5 fw-light">BRANCH : {branchName} </Row> {/* temp branch */}
                <Row className="h6 fw-light">{month + " " + dateToday[2] + ", " + dateToday[3]}</Row>
                <Row className="summary-item-list px-3 py-3" id="growth">
                  <ListGroup>
                    {
                      existingSummary && existingSummary.sort((a, b) => (a.id > b.id)).map((item, index) =>
                        <ListGroup.Item key={item.id} className="d-flex justify-content-between item-summary">
                          {item.is_availed === '0' ?
                            <div className="btn-conf-wrapper del-action" onClick={() => onclickDelete(item.type, item.id)}>
                              <FontAwesomeIcon
                                id={`del-btn-${index}`}
                                role="button"
                                className="align-self-center mx-1"
                                icon={'trash'} />
                            </div>
                            :
                            <div className="btn-conf-wrapper del-action" style={{ opacity: "0" }}>
                              <FontAwesomeIcon
                                id={`del-btn-${index}`}
                                role="button"
                                className="align-self-center mx-1"
                                icon={'trash'} />
                            </div>
                          }
                          <div className="item-qty">
                            <p>{item.quantity}</p>
                          </div>
                          <div className="item-name">
                            <p>{item.code !== null && (item.code + "-")}{item.name}</p>
                          </div>
                          <div className="item-price"> P {item.total_price.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                          <div className={item.type !== 'package' || item.type !== 'gift certificate' ? "item-commission" : "item-commission zero-opacity"}
                          >
                            {/* {console.log(itemList.filter(info=>info.id===item.id)[0].commissioned_by)} */}
                            <select
                              value={checkValue(item.id, item.COMMISSIONED_BY)}
                              disabled={item.type === 'package' || item.type === "gift certificate"}
                              onChange={e => handleItemListChange(item.id, e.target.value)}
                            >
                              <option value="" hidden>--Commissioned by--</option>
                              {
                                employees.map((employee) => {
                                  return (<option key={employee.id} value={employee.id}>{employee.name}</option>)
                                })
                              }
                            </select>
                          </div>
                        </ListGroup.Item>
                      )
                    }
                  </ListGroup>
                </Row>
                <Stack>
                  <Row className="d-flex justify-content-end align-items-center py-1 mt-5">
                    <Col md={7} className="fw-bold">SUB-TOTAL</Col>
                    <Col md={5}>P {subTotal ? numberFormat(subTotal) : '0.00'}</Col> {/* must format amount */}
                  </Row>
                  <Row className="d-flex justify-content-end align-items-center nc-modal-custom-row py-1">
                    <Col md={7} className="fw-bold">DISCOUNT</Col>
                    <Col md={5}>
                      <Form.Control className="nc-modal-custom-input" placeholder="Php" value={discount} onChange={(e) => discountHandler(e)} /> {/* discount input */}
                    </Col>
                  </Row>
                  <Row className="d-flex justify-content-end align-items-center py-1">
                    <Col md={7} className="fw-bold">GRAND TOTAL</Col>
                    <Col md={5}>P {grandTotal ? numberFormat(grandTotal) : '0.00'}</Col> {/* must format amount */}
                  </Row>
                  {/* {needsCommissionBy === true && (<>
                    <Row className="d-flex justify-content-end align-items-center nc-modal-custom-row py-1">
                      <Col md={7} className="fw-bold">PRODUCT COMMISSIONED</Col>
                      <Col md={5}>
                        <Form.Select className="nc-modal-custom-input" name="commssioned_by" value={salesBy} onChange={(e) => handleSalesBy(e.target.value)}>
                          <option value="" hidden>--Select--</option>
                          {
                            employees.map((employee) => {
                              return (<option key={employee.id} value={employee.id}>{employee.name}</option>)
                            })
                          }
                        </Form.Select>
                        <div className="validity-error">
                          Required input*
                        </div>
                      </Col>
                    </Row>
                  </>
                  )} */}
                </Stack>
              </Container>
            </Container>

            {/* BUY BUTTONS WITH MODALS */}
            <div className="buttons-pay-now mt-4">
              <div className="btn-conf-wrapper ms-3">
                <Button onClick={handleShowBuyProduct} type="button" className="buy-btn px-2 py-1">BUY PRODUCT</Button>
              </div>
              <div className="btn-conf-wrapper ms-3">
                <Button onClick={handleShowBuyService} type="button" className="buy-btn px-2 py-1">BUY SERVICE</Button>
              </div>
              <div className="btn-conf-wrapper ms-3">
                <Button onClick={handleShowBuyPackage} type="button" className="buy-btn px-2 py-1">BUY PACKAGE</Button>
              </div>
              <div className="btn-conf-wrapper ms-3">
                <Button onClick={handleShowBuyGiftCert} type="button" className="buy-btn px-2 py-1">BUY GIFT CERTIFICATE</Button>
              </div>

              <div className="btn-conf-wrapper ms-3">
                {!clickSave ?
                  <Button type="submit" className="buy-btn px-4 py-1"
                    onClick={() => { saveChanges() }}
                  >save</Button> :
                  <div className="d-flex justify-content-center">
                    <div className="btn-conf-wrapper ms-auto">
                      <Button className="buy-btn px-4 py-1">
                        <ReactLoading type="spinningBubbles" color="#d4271c" height={20} width={20} />
                      </Button>
                    </div>
                  </div>
                }
              </div>
              <div className="srv-conf-wrapper ms-3">
                <div className="btn-conf-wrapper ms-auto">
                  <Button type="button" className="buy-btn px-4 py-1" onClick={() => onclickPayment()} disabled={!existingSummary}>Pay</Button>
                </div>
              </div>
            </div>

          </Fragment>

          {/* BUY MODALS */}
          <BuyModal
            showBuy_={showBuyProduct}
            closeBuy_={handleCloseBuyProduct}
            buyType={'product'}
            dataList={viewProducts}
            customerId={id}
            bookId={existingBooking.booking_id}
            employeeList={employees}
          />
          <BuyModal
            showBuy_={showBuyService}
            closeBuy_={handleCloseBuyService}
            buyType={'service'}
            dataList={viewServices}
            customerId={id}
            bookId={existingBooking.booking_id}
            employeeList={employees}
          />

          <BuyModal
            showBuy_={showBuyPackage}
            closeBuy_={handleCloseBuyPackage}
            buyType={'package'}
            dataList={viewPackages}
            customerId={id}
            bookId={existingBooking.booking_id}
            employeeList={employees}
          />

          <BuyGiftCertModal
            showBuy_={showBuyGiftCert}
            closeBuy_={handleCloseBuyGiftCert}
            buyType={'gift certificate'}
            dataList={viewGC.filter(gcs => gcs.BRANCH === branchName)}
            customerId={id}
            bookId={existingBooking.booking_id}
          />
        </div>

        {/* DELETE Package MODAL - this one is already done yo*/}
        <Modal show={showDelete} onHide={handleCloseDelete} size="lg" className="custom-modal-style" centered>
          <Modal.Header className="custom-modal-header del-prompt-header-custom"></Modal.Header>
          <Modal.Body className="px-5">
            <Container className="d-flex flex-row">
              <FontAwesomeIcon
                className="del-prompt-icon"
                icon={'trash-alt'}
                aria-hidden="true"
                alt={'trash-alt'}
                fixedWidth
              />
              <div className="ms-2">
                <p className="del-prompt-par">Are you sure you want to delete this {selected.type}?</p>
                <p className="del-prompt-subpar">This {selected.type} will be deleted immediately.<br />You can't undo this action.</p>
              </div>
            </Container>

          </Modal.Body>
          <Modal.Footer className="border-0 px-5">
            <div className="cancel-btn-conf-wrapper">
              <Button type="button" className="px-3" onClick={handleCloseDelete}>cancel</Button>
            </div>
            <div className="btn-conf-wrapper">
              <Button type="submit" className="px-3" onClick={() => removePurchase()}>delete</Button>
            </div>
          </Modal.Footer>
        </Modal>

        {/* Payment Modal */}
        <PaymentModal
          showVar={showPayment}
          showVarSetter={handleClosePayment}
          // cartPayment={[{ id: 7, name: 'underarm whitening', price: 2500 }]}
          paymentType='existing-booking'
          selectedBooking={existingBooking}
          handlePaymentChange={handlePaymentChange}
          payDetails={paymentDeets}
          existingSummary={existingSummary}
          subTotal={subTotal}
          discount={discount}
          grandTotal={grandTotal}
          giftCerts={giftCerts}
          giftCertDeets={giftCertDeets}
        />

      </div>
    </>
  );
}

export default CustomerPayNow;

{/* <div className="custom-modal-vertline-border mx-3"></div>
<Container className="custom-modal-style">

  <Row className="h4 fw-bold mb-4">PAYMENT</Row>

  <Col className="nc-modal-custom-row">
    <Col className="fw-bold">payment method</Col>
    <Form.Select className="nc-modal-custom-input" onChange={(e) => setPaymentType(e.target.value)} defaultvalue="" hidden>
      <option value="" disabled hidden>--SELECT--</option>
      <option value="card">Debit/Credit Card</option>
      <option value="cash">Cash</option>
      <option value="online">Online Payment</option>
      <option value="bank_transfer">Bank Transfer</option>
      <option value="gift_certificate">Gift Certificate</option>
    </Form.Select>
  </Col>

  {paymentType === "card" && cardPaymentDetail()}
  {paymentType === "cash" && cashPaymentDetail()}
  {paymentType === "online" && onlinePaymentDetail()}
  {paymentType === "bank_transfer" && bankPaymentDetail()}
  {paymentType === "gift_certificate" && giftCertificatePaymentDetail()}

  {(paymentType !== "") &&
    <Col className="d-flex flex-row">

      <Col className="nc-modal-custom-row mt-0">
        <Col className="fw-bold">remarks</Col>
        <Form.Control type="text" name="remarks" className="nc-modal-custom-input" onChange={(e) => handlePaymentChange(e)} />
      </Col>

      <Col className="nc-modal-custom-row mt-0">
        <Col className="fw-bold">OR NO.</Col>
        <Form.Control type="text" name="or_no" className="nc-modal-custom-input" onChange={(e) => handlePaymentChange(e)} />
      </Col>
    </Col>
  }

</Container> */}