import { Fragment, useState } from "react";
import { Button, Col, Container, Form, Row } from 'react-bootstrap';

// css
import '../../common.css';
import './customer-booking.css';

// components
import Navbar from '../../navbar';

// services
import { getBookingPackages, getBookingServices } from '../../temp-data-sample';
import PaymentModal from '../payment_modal';

function CustomerBooking() {

  // Add Pay Popup/Modal
  const [showPayment, setShowPayment] = useState(false);
  const handleClosePayment = () => setShowPayment(false);
  const handleShowPayment = () => setShowPayment(true);

  // this part handles segregating packages/services into two rows (poorly factored)
  const arrangedServices = [[], []];
  const arrangedPackages = [[], []];

  getBookingServices().forEach((b_service, index) => { // can be factored pa ni sya
    if (index % 2) {
      arrangedServices[0].push(b_service);
    } else {
      arrangedServices[1].push(b_service);
    }
  })
  getBookingPackages().forEach((b_package, index) => { // can be factored pa ni sya
    if (index % 2) {
      arrangedPackages[0].push(b_package);
    } else {
      arrangedPackages[1].push(b_package);
    }
  })

  // this part handles with keeping the checks
  const [services, setServices] = useState([]);

  function onBookingCheck(e) {
    let newService = services;

    if (e.target.checked) {
      newService.push(e.target.id);
    } else {
      const filtered = newService.filter((test) => test !== e.target.id);
      newService = filtered;
    }

    setServices(newService);
  }

  return (
    <>
      <div>
        <Navbar />
        <div className="active-cont">
          <Fragment>

            <Form>

              {/* header */}
              <div className="cont-section">
                <h1 className="cont-sect-header">customer booking</h1>
              </div>

              {/* clinical services header */}
              <div className="cont-section-novertpad">
                <h1 className="cont-sect-subheader">clinical services</h1>
              </div>

              <div className="cont-section-novertpad">
                <Container className="text-uppercase mt-3 mb-4 booking-service-custom">
                  <Row>
                    <Col>
                      {
                        arrangedServices[0].map((test) => {
                          return (
                            <Row className="flex-nowrap my-1">
                              <Col xs={9}><Form.Check type="checkbox" onChange={(e) => onBookingCheck(e)} id={'s_' + test.id} label={test.name} /></Col>
                              <Col className="d-flex flex-row">
                                <Col xs={2} className="me-1">P</Col>
                                <Col>{test.price}</Col>
                              </Col>
                            </Row>
                          )
                        })
                      }
                    </Col>
                    <Col>
                      {
                        arrangedServices[1].map((test) => {
                          return (
                            <Row className="flex-nowrap my-1">
                              <Col xs={9}><Form.Check type="checkbox" onChange={(e) => onBookingCheck(e)} id={'s_' + test.id} label={test.name} /></Col>
                              <Col className="d-flex flex-row">
                                <Col xs={2} className="me-1">P</Col>
                                <Col>{test.price}</Col>
                              </Col>
                            </Row>
                          )
                        })
                      }
                    </Col>
                  </Row>
                </Container>
              </div>

              {/* packages header */}
              <div className="cont-section-novertpad">
                <h1 className="cont-sect-subheader">packages</h1>
              </div>

              <div className="cont-section-novertpad">
                <Container className="text-uppercase mt-3 mb-4 booking-service-custom">
                  <Row>
                    <Col>
                      {
                        arrangedPackages[0].map((test) => {
                          return (
                            <Row className="flex-nowrap my-1">
                              <Col xs={9}><Form.Check type="checkbox" onChange={(e) => onBookingCheck(e)} id={'p_' + test.id} label={test.name} /></Col>
                              <Col className="d-flex flex-row">
                                <Col xs={2} className="me-1">P</Col>
                                <Col>{test.price}</Col>
                              </Col>
                            </Row>
                          )
                        })
                      }
                    </Col>
                    <Col>
                      {
                        arrangedPackages[1].map((test) => {
                          return (
                            <Row className="flex-nowrap my-1">
                              <Col xs={9}><Form.Check type="checkbox" onChange={(e) => onBookingCheck(e)} id={'p_' + test.id} label={test.name} /></Col>
                              <Col className="d-flex flex-row">
                                <Col xs={2} className="me-1">P</Col>
                                <Col>{test.price}</Col>
                              </Col>
                            </Row>
                          )
                        })
                      }
                    </Col>
                  </Row>
                </Container>
              </div>

              {/* proceed section */}
              <div className="cont-section-novertpad">
                <div className="d-flex flex-row justify-content-between">
                  <div className="btn-conf-wrapper ms-auto">
                    <Button type="button" onClick={handleShowPayment} className="px-5 py-1 mb-1">proceed</Button>
                  </div>
                </div>
              </div>

            </Form>

          </Fragment>

          <PaymentModal
            showVar={showPayment}
            showVarSetter={handleClosePayment}
            cartPayment={services}
            modalData={[getBookingServices(), getBookingPackages()]}
            paymentType='booking'
          />

        </div>
      </div>
    </>
  );
}

export default CustomerBooking;