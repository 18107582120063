import { formatMDY, formatMDYSlash, getBranch, getToken, getUser, tokenExpiry } from '../common';
import { getAPICall } from './axiosMethodCalls';

// GET
export const getAllBookings = async (id, filter) => {
    try {
        const response = await getAPICall(window.$link + 'customers/get_bookings_with_or_no', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            customer: id,
            date_from: formatMDY(filter.date_from),
            date_to:formatMDY(filter.date_to)
        });
        return ({ response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error });
    }
}

// GET
export const getAllPurchasedServices = async (id, filter) => {
    try {
        const response = await getAPICall(window.$link + 'customers/get_purchased_services_with_or_no', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            // branch_id: getBranch().replace(/['"]+/g, ''),
            customer: id,
            date_from: formatMDY(filter.date_from),
            date_to:formatMDY(filter.date_to)
        });
        return ({ response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error });
    }
}

// GET
export const getAllAvailedServices = async (id, filter) => {
    try {
        const response = await getAPICall(window.$link + 'customers/get_availed_services_with_or_no', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            branch_id: '',
            customer: id,
            date_from: formatMDY(filter.date_from),
            date_to:formatMDY(filter.date_to)
        });
        return ({ response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error });
    }
}

// GET
export const getAllPurchasedProducts = async (id, filter) => {
    try {
        const response = await getAPICall(window.$link + 'customers/get_purchased_product_with_or_no', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            // branch_id: getBranch().replace(/['"]+/g, ''),
            customer: id,
            date_from: formatMDY(filter.date_from),
            date_to:formatMDY(filter.date_to)
        });
        return ({ response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error });
    }
}
// GET
export const getAllPurchasedPackages = async (id, filter) => {
    try {
        const response = await getAPICall(window.$link + 'customers/get_purchased_package_with_or_no', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            // branch_id: getBranch().replace(/['"]+/g, ''),
            customer: id,
            date_from: formatMDY(filter.date_from),
            date_to:formatMDY(filter.date_to)
        });
        return ({ response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error });
    }
}

// GET
export const getPurchasedPackage = async (id) => {
    try {
        const response = await getAPICall(window.$link + 'customers/get_bookings_with_or_no', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            // branch_id: getBranch().replace(/['"]+/g, ''),
            customer: id
        });
        return ({ response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error });
    }
}

// GET
export const getAllGiftcerts = async (id, filter) => {
    try {
        const response = await getAPICall(window.$link + 'customers/get_purchased_giftcert_with_or_no', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            // branch_id: getBranch().replace(/['"]+/g, ''),
            customer: id,
            date_from: formatMDY(filter.date_from),
            date_to:formatMDY(filter.date_to)
        });
        return ({ response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error });
    }
}

// GET
export const getAllTransactions = async (filter) => {
  
    try {
        const response = await getAPICall(window.$link + 'transactions/get_bookings_with_or_no', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: filter.branch,
            date_from: formatMDY(filter.date_from),
            date_to:formatMDY(filter.date_to)
        });
        return ({ response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error });
    }
}