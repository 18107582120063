import { useState, useEffect } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import ReactLoading from 'react-loading';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createThisCustomer, getAllCustomers } from "../../../utilities/api_calls/customerApi";
import { checkContact, refreshPage, validateContact } from "../../../utilities/common";

import './addCustomer.css'

export default function AddCustomer({show, hide, allCustomers, page}) {
    const navigate = useNavigate()

    const [isValid, setIsValid] = useState(true);

    const [click, setClick] = useState(false);

    //Add Customer Inputs
    const [customer, setCustomer] = useState({
        first_name: "",
        middle_name: "",
        last_name: "",
        suffix: "",
        birthdate: "",
        gender: "female",
        contact_no: "",
        email: "",
        address: "",
        remarks: "",
    });

    //Handle Customer Input Change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setCustomer(prevState => ({
            ...prevState,
            [name]: value
        }));

        if(name === "contact_no") {
            setIsValid(checkContact(value))
        }
    }

    function submitCreate() {
        // console.log("gate1")
        if(customer.contact_no !== "") {
            // console.log("gate2")
            // console.log(checkContact(customer.contact_no))
            setIsValid(checkContact(customer.contact_no))
        } else {
            // console.log("gate3")
            setIsValid(true);
        }
        setTimeout(() => createCustomer(), 1000)
    }

    async function createCustomer() {
        if (click === false &&
            customer.first_name !== "" &&
            customer.last_name !== "") {
            setClick(true);
            // console.log("gate4")
            const response = await createThisCustomer(customer);
            // console.log(response);
            if (response.data.data.status === 201 || response.data.data.status === 200) {
                toast.success(response.data.data.message.success);
                setTimeout(function () {
                    refreshPage();
                }, 3000);
            } else if (response.data.data.error) {
                toast.error(response.data.data.message[0]);
            }
        } else {
            console.log(click === false,
                customer.first_name !== "",
                customer.last_name !== "",
                checkContact(customer.contact_no) !== false)
            toast.error("Please enter correct values for required inputs.")
        }
    }

    /***** Customer suggestion  *******/
    // const [allCustomers, setAllCustomers] = useState([])
    const { first_name, last_name } = customer
    const [fnSuggestions, setFnSuggestions] = useState([])
    const [lnSuggestions, setLnSuggestions] = useState([])
    const [renderFnSuggest, setRenderFnSuggest] = useState(false)
    const [renderLnSuggest, setRenderLnSuggest] = useState(false)
    // fetch all customers
    // async function fetchCustomers(){
    //   const response = await getAllCustomers()
    //   setAllCustomers(response.data.data.customers)
    // }
    
    // useEffect(()=>{
    //   fetchCustomers() 
    // },[])

    // search for matching name according to fname
    useEffect(()=>{
      
      if(first_name===""){
        setRenderFnSuggest(false)
        // setFnSuggestions([])
      }
      if(first_name!=="" && allCustomers.length>0){
        let searchWord = new RegExp(first_name.toLowerCase()) //create regexp for first name
        let filtered = allCustomers.filter(info=>searchWord.test(info.first_name.toLowerCase()))
        setFnSuggestions(filtered)
      }

    },[first_name])

    useEffect(()=>{
      
      if(last_name===""){
        setRenderLnSuggest(false)
        // setLnSuggestions([])
      }
      if(last_name!=="" && allCustomers.length>0){
        let searchWord = new RegExp(last_name.toLowerCase()) //create regexp for first name
        let filtered = allCustomers.filter(info=>searchWord.test(info.last_name.toLowerCase()))
        setLnSuggestions(filtered)
      }
    },[last_name])

    // check if customer already exists
    const [customerExist, setCustomerExist] = useState(false)
    const [matchedCustomer, setMatchedCustomer] = useState([])
    useEffect(()=>{
      if(last_name==="" || first_name ===""){
        setCustomerExist(false)
      }
      if(last_name!=="" && first_name !==""&& allCustomers.length>0){
        // filter customers with the same fn
        let filteredFn = allCustomers.filter(info=>info.first_name.toLowerCase()===first_name.toLowerCase())
        
        // then filter customers with the same ln as well
        let filteredLn = filteredFn.filter(info=>info.last_name.toLowerCase()===last_name.toLowerCase())
        setMatchedCustomer(filteredLn)
      }
    },[first_name, last_name])
// console.log(matchedCustomer.length>0)

    return (
        <>
            <Modal show={show} onHide={hide} size="lg" className="custom-modal-style" centered>
                <Modal.Header className="custom-modal-header d-flex justify-content-end" closeButton></Modal.Header>
                <Modal.Body className="px-5 py-1">
                    <h1 className="custom-modal-body-title">Add Customer</h1>

                    <Container>

                        <Row className="nc-modal-custom-row">
                            <Col>First Name</Col>
                            <Col xs={1}>:</Col>
                            <Col xs={7}>
                                <Form.Control type="text" name="first_name" placeholder="Enter first name" className="nc-modal-custom-input" 
                                onFocus={()=>{setRenderFnSuggest(true)}} 
                                onBlur={()=>{setTimeout(()=>{setRenderFnSuggest(false)},1000)}} 
                                onChange={(e) => handleChange(e)} required />
                                {customer.first_name === "" && (
                                    <div className="validity-error">
                                        First Name is required*
                                    </div>
                                )}
                            </Col>
                        </Row>
                        {fnSuggestions.length!==0 && renderFnSuggest && <div className="suggestions-list">
                          {fnSuggestions.map((data, index)=>
                            <><button key = {data.id} className="suggestions-item" name="first_name" value={data.first_name} onClick={()=>{navigate("/customer/details/" + page + "/" + data.id);setRenderFnSuggest(false)}}>{data.first_name + " " + data.last_name}</button><br/></>
                          )}
                        </div>}
                        <Row className="nc-modal-custom-row">
                            <Col>Middle Name</Col>
                            <Col xs={1}>:</Col>
                            <Col xs={7}>
                                <Form.Control type="text" name="middle_name" placeholder="Enter middle name" className="nc-modal-custom-input" onChange={(e) => handleChange(e)} />
                            </Col>
                        </Row>
                        <Row className="nc-modal-custom-row">
                            <Col>Last Name</Col>
                            <Col xs={1}>:</Col>
                            <Col xs={7}>
                                <Form.Control type="text" name="last_name" placeholder="Enter last name" className="nc-modal-custom-input" 
                                onFocus={()=>{setRenderLnSuggest(true)}} 
                                 onBlur={()=>{setTimeout(()=>{setRenderLnSuggest(false)},1000)}} 
                                onChange={(e) => handleChange(e)} />
                                {customer.last_name === "" && (
                                    <div className="validity-error">
                                        Last Name is required*
                                    </div>
                                )}
                            </Col>
                        </Row>
                        {lnSuggestions.length!==0 && renderLnSuggest && <div className="suggestions-list">
                          {lnSuggestions.map((data, index)=>
                            <><button key = {data.id} className="suggestions-item" name="last_name" value={data.last_name} onClick={()=>{navigate("/customer/details/" + page + "/" +data.id);setRenderLnSuggest(false)}}>{data.first_name + " " + data.last_name}</button><br/></>
                          )}
                        </div>}
                        <Row className="nc-modal-custom-row">
                            <Col>Suffix</Col>
                            <Col xs={1}>:</Col>
                            <Col xs={7}><Form.Control type="text" name="suffix" placeholder="Enter suffix" className="nc-modal-custom-input" onChange={(e) => handleChange(e)} /></Col>
                        </Row>
                        {/* <Row className="nc-modal-custom-row">
                            <Col>Birthdate</Col>
                            <Col xs={1}>:</Col>
                            <Col xs={7}><Form.Control type="date" name="birthdate" placeholder="Enter birthdate" className="nc-modal-custom-input" onChange={(e) => handleChange(e)} /></Col>
                        </Row> */}
                        {/* <Row className="nc-modal-custom-row">
                            <Col>Gender</Col>
                            <Col xs={1}>:</Col>
                            <Col xs={7}>
                                <Form.Select name="gender" placeholder="Enter gender" className="nc-modal-custom-input" onChange={(e) => handleChange(e)}>
                                <option value="" hidden>Select</option>
                                <option value="female">Female</option>
                                <option value="male">Male</option>
                                </Form.Select>
                                {hasGender === false && (
                                <div className="validity-error">
                                Gender is required*
                                </div>
                                )}
                            </Col>
                        </Row> */}
                        <Row className="nc-modal-custom-row">
                            <Col>Contact Number</Col>
                            <Col xs={1}>:</Col>
                            <Col xs={7}>
                                <Form.Control type="text" name="contact_no" placeholder="Enter contact number" className="nc-modal-custom-input" onChange={(e) => handleChange(e)} />
                            </Col>
                        </Row>
                        {/* <Row className="nc-modal-custom-row">
                            <Col>Email Address</Col>
                            <Col xs={1}>:</Col>
                            <Col xs={7}>
                                <Form.Control type="email" name="email" placeholder="Enter email address" className="nc-modal-custom-input" onChange={(e) => handleChange(e)} />
                                {validateEmail(customer.email) === false && (
                                <div className="validity-error">
                                    Invalid email format.
                                </div>
                                )}
                            </Col>
                        </Row> */}
                        <Row className="nc-modal-custom-row">
                            <Col>Address</Col>
                            <Col xs={1}>:</Col>
                            <Col xs={7}><Form.Control type="text" name="address" placeholder="Enter address" className="nc-modal-custom-input" onChange={(e) => handleChange(e)} /></Col>
                        </Row>
                        <Row className="nc-modal-custom-row">
                            <Col>Remarks</Col>
                            <Col xs={1}>:</Col>
                            <Col xs={7}><Form.Control type="text" name="remarks" placeholder="Enter remarks" className="nc-modal-custom-input" onChange={(e) => handleChange(e)} /></Col>
                        </Row>
                    </Container>
                </Modal.Body>

                <Modal.Footer className="border-0 px-5">
                    <div className="d-flex flex-row justify-content-between">
                        {click === true && (
                            <div className="d-flex justify-content-center">
                                <div className="btn-conf-wrapper ms-auto">
                                    <Button type="button" className="px-5 pb-1 mb-2">
                                        <ReactLoading type="spinningBubbles" color="#d4271c" height={25} width={20} />
                                    </Button>
                                </div>
                            </div>
                        )}
                        {click === false && (
                            <div className="btn-conf-wrapper ms-auto">
                                <Button type="button" className="px-5 pb-1 mb-2" disabled={matchedCustomer.length>0} onClick={() => submitCreate()}>Save</Button>
                            </div>
                        )}
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}