import { getBranch, getToken, getUser, tokenExpiry } from '../common';
import { getAPICall, postAPICall } from './axiosMethodCalls';

/***************************
 * GIFT CERTIFICATION
 ***************************/

//GET
export const getGiftCert = async (id) => {
    try {
        const response = await getAPICall(window.$link + 'gift_certificates/view/' + id, {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''), // review
        });
        // console.log(response)
        return ({ data: response });
    } catch (error) {
        // console.log('error at gcs avail')
        return ({ error: error });
    }
}

// SEARCH
export const searchGiftCertificate = async (code, type) => {
    try {
        const response = await getAPICall(window.$link + 'gift_certificates/search', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            code: code,
            type: type,

        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}


//GET
export const getAvailableGiftCert = async (filter,availablecurrentPage) => {
    try {
        const response = await getAPICall(window.$link + 'gift_certificates/available', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''), // review
            branch: filter === false ? "" : filter,
            offset: availablecurrentPage,
        });
        // console.log(response)
        return ({ data: response });
    } catch (error) {
        // console.log('error at gcs avail')
        return ({ error: error });
    }
}

//GET
export const getGiftCertPurchased = async (filter, purchasedcurrentPage) => {
    console.log(purchasedcurrentPage)
    try {
        const response = await getAPICall(window.$link + 'gift_certificates/purchased', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            branch: filter === false ? "" : filter,
            offset: purchasedcurrentPage,
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

//GET
export const getGiftCertUsed = async (filter, usedcurrentPage) => {
    try {
        const response = await getAPICall(window.$link + 'gift_certificates/used', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            branch: filter === false ? "" : filter,
            offset: usedcurrentPage,
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

// POST
export const addGiftCertificate = async (gift_certificate) => {

    try {
        var response;
        if (gift_certificate.gc_code_to !== "") {
            response = await getAPICall(window.$link + 'gift_certificates/create', {
                api_key: window.$api_key,
                token: getToken().replace(/['"]+/g, ''),
                requester: getUser(),
                branch_id: getBranch().replace(/['"]+/g, ''),
                branch: gift_certificate.branch,
                amount: gift_certificate.amount,
                service_id: gift_certificate.service_id,
                gc_code_from: gift_certificate.gc_code_from,
                gc_code_to: gift_certificate.gc_code_to,
            });
        }
        else {
            response = await getAPICall(window.$link + 'gift_certificates/create', {
                api_key: window.$api_key,
                token: getToken().replace(/['"]+/g, ''),
                requester: getUser(),
                branch_id: getBranch().replace(/['"]+/g, ''),
                branch: gift_certificate.branch,
                amount: gift_certificate.amount,
                service_id: gift_certificate.service_id,
                gc_code_from: gift_certificate.gc_code_from,
                gc_code_to: gift_certificate.gc_code_from,

            });
        }
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

//DELETE
export const deleteGiftCertificate = async (id) => {
    try {
        // console.log('delete gcs')
        const response = await getAPICall(window.$link + 'gift_certificates/delete/' + id, {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            updated_by: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
        });
        // console.log(response);
        return ({ data: response });
    } catch (error) {
        return ({ error: error });
    }
}

export const deleteMultipleGiftCertificate = async (gc_ids) => {
    try {
        // console.log('delete gcs')
        const response = await postAPICall(window.$link + 'gift_certificates/multiple_delete', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            updated_by: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            gc_ids: gc_ids
        });
        // console.log(response);
        return ({ data: response });
    } catch (error) {
        return ({ error: error });
    }
}

// UPDATE
export const updateGiftCertificate = async (gift_certificate, id) => {
    try {
        const response = await getAPICall(window.$link + 'gift_certificates/update/' + id, {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            updated_by: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            branch: gift_certificate.branch_id,
            amount: gift_certificate.amount,
            service_id: gift_certificate.service_id,
            gift_certificate_code: gift_certificate.gift_certificate_code
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

//POST
export const availGiftCertificate = async (gc) => {
    try {
        const response = await postAPICall(window.$link + "gift_certificates/avail_gc_service", {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            updated_by: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            gc_id: gc.id,
            customer_id: gc.customer_id,
            commissioned_by: gc.commissioned_by,
            remarks: gc.remarks,
        })
        return ({ data: response.data })
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error })
    }
}



