import { getBranch, getToken, getUser, tokenExpiry } from '../common';
import { getAPICall, postAPICall } from './axiosMethodCalls';

/***************************
 * PACKAGES
 ***************************/

//GET
export const getAllPackages = async (id) => {
    try {
        const response = await getAPICall(window.$link + 'packages/getAll', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

//Get
export const searchPackages = async (name) => {
    try {
        const response = await getAPICall(window.$link + 'packages/search', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            name: name,
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

//GET
export const getPackage = async (id) => {
    try {
        const response = await getAPICall(window.$link + 'packages/view/' + id, {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

//POST
export const addPackage = async (packageDetail, services) => {
    // console.log(packageDetail)
    var serviceID = [];
    var serviceQty = [];
    var serviceFree = [];
    // eslint-disable-next-line
    services.map((data) => {
        serviceID.push(data.service);
        serviceQty.push(data.quantity);
        serviceFree.push(data.isFree);
    })
    try {
        const response = await getAPICall(window.$link + 'packages/create', {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            requester: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            name: packageDetail.name,
            price: packageDetail.price,
            remarks: packageDetail.details,
            services: serviceID,
            quantities: serviceQty,
            is_free: serviceFree
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

//POST
export const updatePackageDetails = async (packageDetail, services, id) => {
    // console.log(packageDetail)
    var serviceID = [];
    var serviceQty = [];
    var serviceFree = [];
    // eslint-disable-next-line
    services.map((data) => {
        serviceID.push(data.service_id);
        serviceQty.push(data.qty);
        serviceFree.push(data.is_free);
    })

    try {
        const response = await postAPICall(window.$link + 'packages/update/' + id, {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            updated_by: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
            name: packageDetail.name,
            price: packageDetail.price,
            remarks: packageDetail.details,
            services: serviceID,
            quantities: serviceQty,
            is_free: serviceFree
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

//DELETE
export const deletePackage = async (id) => {
    try {
        const response = await getAPICall(window.$link + 'packages/delete/' + id, {
            api_key: window.$api_key,
            token: getToken().replace(/['"]+/g, ''),
            updated_by: getUser(),
            branch_id: getBranch().replace(/['"]+/g, ''),
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}

