// ! Some of the code here are for visuals only ra jud TT

import React, { useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import ReactLoading from 'react-loading';
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { payNow, payBalanceNow, payThisBooking } from "../../utilities/api_calls/paymentApi";
import { numberFormat, refreshPage } from "../../utilities/common";


// css
import '../common.css';
import './payment_modal.css';

function PaymentModal({ showVar, showVarSetter, cartPayment, modalData, paymentType, handlePaymentChange, type, payDetails, selectedBooking, existingSummary, balance, subTotal, discount, grandTotal, payExit, giftCerts, giftCertDeets }) {
  // console.log(existingSummary)
  // Card Payment Details
  const [paymentDeets, setPaymentDeets] = useState("cash");
  const handlePaymentDetailReset = () => setPaymentDeets("cash")
  const [click, setClick] = useState(false);
  const navigate = useNavigate()

  async function payPurchase() {
    if (click === false) {
      setClick(true);
      // console.log(paymentDeets)
      // console.log(payDetails)
      const response = await payNow(paymentDeets, payDetails, selectedBooking);
      // console.log(response);
      if (response.data.data.status === 200 || response.data.data.status === 201) {
        toast.success("Successfully Paid!");
        setTimeout(function () {
          refreshPage();
        }, 3000);
      }
      if (response.data.status === 400 || response.data.data.status === 400) {
        toast.success("Payment Unsuccessful!");
        setTimeout(function () {
          refreshPage();
        }, 3000);
      }
    }
  }

  function payBalance() {
    let validation = true
    if (balance != parseFloat(payDetails.amount)) {
      validation = false
      toast.error("Payment Unsuccessful!")
    }
    if (click === false && validation === true) {
      setClick(true)
      existingSummary.map(async (data, index) => {
        // console.log(data)
        const response = await payBalanceNow(paymentDeets, payDetails, data.id, data.balance)
        if (response.data.data.status === 200 || response.data.data.status === 201) {
          toast.success("Successfully Paid!");
          setTimeout(function () {
            navigate('/customer-balance/0/1')
          }, 2000);
        }
        if (response.data.status === 400 || response.data.data.status === 400) {
          toast.success("Payment Unsuccessful!");
          setTimeout(function () {
            refreshPage();
          }, 2000);
        }
      })
    }

  }

  // balance
  function checkItemTypes() {
    var allowBalance = true;

    existingSummary.map((item) => {
      if (item.type !== "package") {
        allowBalance = false;
      }
    })

    return allowBalance;
  }

  React.useEffect(() => {
    if (showVar) {
      toast.info("Downpayments are only allowed for packages.", { autoClose: false });
    } else {
      toast.dismiss();
    }
  }, [showVar]);

  async function payBooking() {
    var validation = true;
    var allowBalance = checkItemTypes();
    // console.log(allowBalance)

    if (paymentType === "") {
      toast.error("Please choose payment type.")
      validation = false;

    } else if (parseFloat(payDetails.amount) > grandTotal) {
      toast.error("Amount exceeds grand total.")
      validation = false;

    } else if (parseFloat(payDetails.amount) < grandTotal && allowBalance === false) {
      toast.error("Insufficient amount.");
      validation = false;

      // if(allowBalance === false) {
      //   toast.info("Downpayments are only allowed for packages.")
      // }

    } else if (validation && click === false) {
      setClick(true);
      const response = await payThisBooking(paymentDeets, payDetails, selectedBooking.booking_id)
      // console.log(response)
      if (response.data.data.error) {
        toast.error(response.data.data.message.failed);
        setTimeout(() => refreshPage(), 2000);
      }
      else if (response.data.data.status === 200 || response.data.status === 201) {
        toast.success("Successfully Paid!");
        setTimeout(() => navigate(-1), 1000);
      }
    }
  }


  const cashPaymentDetail = () => {
    return (
      <Col className="nc-modal-custom-row">
        <Col className="fw-bold">amount</Col>
        <Col className="">
          <Form.Control type="number" min="0.01" step="0.01" name="amount" placeholder="Enter amount" className="nc-modal-custom-input payment-input-size-custom mb-1" onChange={(e) => handlePaymentChange(e)} />
        </Col>
      </Col>
    )
  }

  const cardPaymentDetail = () => {
    return (
      <div>
        <Col className="nc-modal-custom-row">
          <Col className="fw-bold">amount</Col>
          <Col className="">
            <Form.Control type="number" min="0.01" step="0.01" name="amount" placeholder="Enter amount" className="nc-modal-custom-input payment-input-size-custom mb-1" onChange={(e) => handlePaymentChange(e)} />
          </Col>
        </Col>
        <Col className="nc-modal-custom-row">
          <Col className="fw-bold">card details</Col>
          <Col className="">
            <Form.Control type="text" name="card_type" placeholder="Card Type" className="nc-modal-custom-input payment-input-size-custom mb-1" onChange={(e) => handlePaymentChange(e)} />
          </Col>
          <Col className="">
            <Form.Control type="text" name="acc_no" placeholder="Account Number" className="nc-modal-custom-input payment-input-size-custom mb-1" onChange={(e) => handlePaymentChange(e)} />
          </Col>
          <Col className="d-flex flex-row ">
            <Form.Control type="date" name="card_expiry" className="nc-modal-custom-input payment-input-size-custom" onChange={(e) => handlePaymentChange(e)} />
            <Form.Control type="text" name="cvc_cvv" placeholder="CVV/CVC" className="nc-modal-custom-input payment-input-size-custom ms-1" onChange={(e) => handlePaymentChange(e)} />
          </Col>
        </Col>
      </div>
    )
  }
  // eslint-disable-next-line
  const chequePaymentDetail = () => {
    return (
      <div>
        <Col className="nc-modal-custom-row">
          <Col className="fw-bold">amount</Col>
          <Col className="">
            <Form.Control type="number" min="0.01" step="0.01" name="cheque-amount" placeholder="Enter amount" className="nc-modal-custom-input payment-input-size-custom mb-1" />
          </Col>
        </Col>
        <Col className="nc-modal-custom-row">
          <Col className="fw-bold">bank name</Col>
          <Col className="">
            <Form.Control type="text" name="bank_name" placeholder="Enter bank name" className="nc-modal-custom-input payment-input-size-custom mb-1" />
          </Col>
        </Col>
        <Col className="nc-modal-custom-row">
          <Col className="fw-bold">cheque number</Col>
          <Col className="">
            <Form.Control type="text" name="cheque_number" placeholder="Enter cheque number" className="nc-modal-custom-input payment-input-size-custom mb-1" />
          </Col>
        </Col>
        <Col className="nc-modal-custom-row">
          <Col className="fw-bold">cheque date</Col>
          <Col className="">
            <Form.Control type="date" name="cheque_date" className="nc-modal-custom-input payment-input-size-custom mb-1" />
          </Col>
        </Col>
      </div>
    )
  }

  const bankPaymentDetail = () => {
    return (
      <div>

        <Col className="nc-modal-custom-row">
          <Col className="fw-bold">amount</Col>
          <Col className="">
            <Form.Control type="number" min="0.01" step="0.01" name="amount" placeholder="Enter amount" className="nc-modal-custom-input payment-input-size-custom mb-1" onChange={(e) => handlePaymentChange(e)} />
          </Col>
        </Col>
        {/* 
        <Col className="nc-modal-custom-row">
          <Col className="fw-bold">bank name</Col>
          <Col className="">
            <Form.Control type="text" name="bank_name" placeholder="Enter bank name" className="nc-modal-custom-input payment-input-size-custom mb-1"/>
          </Col>
        </Col> */}

        <Col>
          <Col className="fw-bold">account details</Col>
          <Row className="account-detail-custom">
            <Col xs={4} className="fw-bold ms-3">Account Name</Col>
            :
            <Col>Lovely Shane Gucor</Col>
          </Row>
          <Row className="account-detail-custom">
            <Col xs={4} className="fw-bold ms-3">Card Number</Col>
            :
            <Col>12345678010</Col>
          </Row>
        </Col>

        <Col className="nc-modal-custom-row">
          <Col className="fw-bold">proof of payment</Col>
          <Col className="">
            <Form.Control type="text" name="proof" placeholder="Proof of Payment" className="nc-modal-custom-input payment-input-size-custom mb-1" onChange={(e) => handlePaymentChange(e)} />
          </Col>
        </Col>
      </div>
    )
  }

  const onlinePaymentDetail = () => {
    return (
      <div>

        <Col className="nc-modal-custom-row">
          <Col className="fw-bold">amount</Col>
          <Col>
            <Form.Control type="number" min="0.01" step="0.01" name="amount" placeholder="Enter amount" className="nc-modal-custom-input payment-input-size-custom mb-1" onChange={(e) => handlePaymentChange(e)} />
          </Col>
        </Col>

        <Col className="nc-modal-custom-row">
          <Col className="fw-bold">payment channels</Col>
          <Col className="">
            <Form.Control type="text" className="nc-modal-custom-input" name="type" onChange={(e) => handlePaymentChange(e)} />
            {/* <option value="gcash">GCash</option>
              <option value="paymaya">PayMaya</option>
            </Form.Select> */}
          </Col>
        </Col>

        {/* <Col>
          <Col className="fw-bold">account details</Col>
          <Row className="account-detail-custom">
            <Col xs={4} className="fw-bold ms-3">Account Name</Col>
            :
            <Col>Lovely Shane Gucor</Col>
          </Row>
          <Row className="account-detail-custom">
            <Col xs={4} className="fw-bold ms-3">Card Number</Col>
            :
            <Col>12345678010</Col>
          </Row>
        </Col> */}

        <Col className="nc-modal-custom-row">
          <Col className="fw-bold">proof of payment</Col>
          <Col className="">
            <Form.Control type="text" name="proof" placeholder="Proof of Payment" className="nc-modal-custom-input payment-input-size-custom mb-1" onChange={(e) => handlePaymentChange(e)} />
          </Col>
        </Col>
      </div>
    )
  }

  const giftCertificatePaymentDetail = () => {
    return (
      <>
        <Col className="nc-modal-custom-row">
          <Col className="fw-bold">Search code</Col>
          <Col className="">
            <Form.Control type="text" name="code" placeholder="Enter gift certificate code" className="nc-modal-custom-input payment-input-size-custom mb-1"
              value={payDetails.code}
              onChange={(e) => handlePaymentChange(e)}
            />
          </Col>
          <Col>
            <Col className="fw-bold">SELECT AVAILABLE GIFT CERTIFICATE</Col>
            <select name="selected_code" onChange={(e) => handlePaymentChange(e)}>
              <option value="" hidden>--SELECT--</option>
              {giftCerts.length !== 0 ? giftCerts.map((data, index) => {
                return <option value={data.ID}>{data.NAME + " - " + data.CODE}</option>
              }) : <option disabled>No GCs available</option>}
            </select>
          </Col>
        </Col>

        <Col className="nc-modal-custom-row">
          <Col className="fw-bold">gift certificate details</Col>
          {/* must be dynamic depending sa GCS code */}
          {giftCertDeets.length !== 0 && (
            <div>
              <p className="account-detail-custom ms-3 fw-normal">{giftCertDeets[0].name}</p>
              <p className="account-detail-custom ms-3 fw-normal">{"DETAILS: " + giftCertDeets[0].details}</p>
            </div>
          )}
        </Col>


      </>
    )
  }

  const shoppingCart = [];


  function updateCart() {
    let id, cartItemObj;
    var totalCost, finalCost = "";

    if (paymentType === 'booking') {
      cartPayment.forEach((cartItem) => {
        // services
        if (cartItem[0] === 's') {
          id = parseInt(cartItem.substring(2));
          cartItemObj = modalData[0].find(srv => srv.id === id);
          shoppingCart.push(cartItemObj);
          totalCost += cartItemObj.price;
          // packages
        } else if (cartItem[0] === 'p') {
          id = parseInt(cartItem.substring(2));
          cartItemObj = modalData[1].find(pck => pck.id === id);
          shoppingCart.push(cartItemObj);
          totalCost += cartItemObj.price;
        }
      })
    }
    else if (paymentType === "customer") { // sample temporary
      cartItemObj = cartPayment[0];
      shoppingCart.push(cartItemObj);
      totalCost += cartItemObj.price;
    }

    if (shoppingCart.length || totalCost >= discount) {
      // eslint-disable-next-line
      finalCost = totalCost - discount;
    }
  }

  return (
    <>
      <Modal show={showVar} onShow={updateCart()} onHide={() => { showVarSetter(); handlePaymentDetailReset() }} size="xl" className="custom-modal-style" centered>
        {/* <ToastContainer /> */}
        <Modal.Header className="custom-modal-header d-flex justify-content-end" closeButton></Modal.Header>

        <Modal.Body className="px-5 py-1 d-flex flex-row">

          <Container className="px-0">
            <Row className="nc-modal-custom-row ml-0 pl-0">
              <span className="custom-modal-body-subtitle pl-0">summary</span>
            </Row>
            <Row className="pb-3 purchlist-group-custom">

              {existingSummary && existingSummary.map((data, index) => {

                return <Row className='nc-modal-custom-row my-1'>
                  <Col xs={1}>{data.quantity}</Col>
                  <Col xs={6} className="fw-bold">{data.name}</Col>
                  <Col xs={5} className="fw-bold">{numberFormat(data.price || data.total_price) || ""}</Col>
                  {/* <Row>COMMISSIONED BY: {data.COMMISSIONED_BY}</Row> */}
                </Row>
              })}

            </Row>
            <Row className="pb-3 nc-modal-custom-row">
              <Row>
                <Col xs={7} className="fw-bold">total cost</Col>
                <Col className="fw-light d-flex flex-row">
                  <span className='me-0 invisible'>-</span>
                  <span className='me-1'>P</span>
                  <Col>{subTotal ? numberFormat(subTotal) : "0.00"}</Col>
                </Col>
              </Row>
              <Row>
                <Col xs={7} className="fw-bold">discount</Col>
                <Col className="fw-light d-flex flex-row">
                  <span className='me-0'>-</span>
                  <span className='me-1'>P</span>
                  <Col>{discount ? numberFormat(discount) : "0.00"}</Col>
                </Col>
              </Row>
              <Row>
                <Col xs={7} className="fw-bold">final cost</Col>
                <Col className="fw-light d-flex flex-row">
                  <span className='me-0 invisible'>-</span>
                  <span className='me-1'>P</span>
                  <Col>{grandTotal ? numberFormat(grandTotal) : '0.00'}</Col>
                </Col>
              </Row>
            </Row>
            {paymentType !== "existing-booking" && <Row className="pb-3 nc-modal-custom-row text-danger">
              <Row>
                <Col xs={7} className="fw-bold">balance</Col>
                <Col className="d-flex flex-row">
                  <span className='me-0 invisible'>-</span>
                  <span className='me-1'>P</span>
                  <Col>{numberFormat(balance) || ""}</Col>
                </Col>
              </Row>
            </Row>}
          </Container>

          <div className="custom-modal-vertline-border mx-3"></div>

          <Container>

            <Row className="nc-modal-custom-row p-0">
              <span className="custom-modal-body-subtitle p-0">payment</span>
            </Row>

            <Col className="nc-modal-custom-row">
              <Col className="fw-bold">payment method</Col>
              <Form.Select className="nc-modal-custom-input" name="payment_type" value={paymentDeets} onChange={(e) => { setPaymentDeets(e.target.value); }}>
                <option value="" selected hidden>--SELECT--</option>
                <option value="cash">Cash</option>
                {/* <option value="cheque">Cheque</option> */}
                <option value="card">Credit/Debit Card</option>
                <option value="online">Online Payment Channel</option>
                <option value="bank_transfer">Bank Transfer</option>
                {paymentType === "existing-booking" && <option value="gift_certificate">Gift Certificate</option>}
              </Form.Select>
            </Col>

            {paymentDeets === "cash" && cashPaymentDetail()}
            {/* {paymentDeets === "cheque" && chequePaymentDetail()} */}
            {paymentDeets === "card" && cardPaymentDetail()}
            {paymentDeets === "online" && onlinePaymentDetail()}
            {paymentDeets === "bank_transfer" && bankPaymentDetail()}
            {paymentDeets === "gift_certificate" && giftCertificatePaymentDetail()}

            <Col className="nc-modal-custom-row">
              <Col className="fw-bold">remarks</Col>
              <Form.Control as="textarea" className="nc-modal-custom-input" name="remarks" onChange={(e) => { handlePaymentChange(e) }} rows={1} />
            </Col>
            {paymentType === 'existing-booking' && <Col className="nc-modal-custom-row mt-0">
              <Col className="fw-bold">OR NO.</Col>
              <Form.Control type="text" name="or_no" className="nc-modal-custom-input" onChange={(e) => handlePaymentChange(e)} />
            </Col>}
          </Container>

        </Modal.Body>

        <Modal.Footer className="border-0 px-5">
          <div className="d-flex flex-row justify-content-between">
            <div className="btn-conf-wrapper ms-auto">
              {click === true && (
                <div className="d-flex justify-content-center">
                  <div className="btn-conf-wrapper ms-auto">
                    <Button type="submit" className="px-5 pb-1 mb-2">
                      <ReactLoading type="spinningBubbles" color="#d4271c" height={25} width={20} />
                    </Button>
                  </div>
                </div>
              )}
              {click === false && (
                <Button type="submit" className="px-5 pb-1 mb-2" onClick={() => { paymentType === 'balance' ? payBalance() : paymentType === 'existing-booking' ? payBooking() : payPurchase() }}>pay</Button>
              )}
            </div>
          </div>
        </Modal.Footer>

      </Modal>
    </>
  );
}

export default PaymentModal;