import { getAge, getTodayDate, getUser, tokenExpiry } from '../common';
import { getAPICall, postAPICall, putAPICall, deleteAPICall, postAPICallParams } from './axiosMethodCalls';

/***************************
 * LOGIN & LOGOUT
 ***************************/

//POST
export const login = async (user, branchId) => {

    try {
        const response = await postAPICall(window.$link + 'login/index', {
            api_key: window.$api_key,
            requester: 1,
            email: user.email,
            password: user.password,
            branch_id: branchId,
        });
        return ({ data: response });
    } catch (error) {
        return ({ error: error });
    }
}
export const submitPin = async (pin, branchId) => {
    try {
        const response = await postAPICall(window.$link + 'login/branch_verification', {
            api_key: window.$api_key,
            pin: pin,
            branch_id: branchId,
        });
        return({data: response});
    } catch (error) {
        return({error: error});
    }
}

//POST
export const logout = async () => {
    const user = getUser();
    try {
        const response = await postAPICall(window.$link + 'logout/index/' + user, {
            api_key: window.$api_key,
            requester: user,
        });
        return ({ data: response });
    } catch (error) {
        tokenExpiry(error);
        return ({ error: error });
    }
}
