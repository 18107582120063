import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import { toast } from "react-toastify";
import { Button, Container, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactLoading from 'react-loading';
import '../../../components/common.css';
import './branch.css'
import logo from '../../../images/new_logo.jpg'
import { submitPin } from "../../../utilities/api_calls/authenticationApi";

function Branch() {

    const [branch, setBranch] = useState("");
    const [selected, setSelected] = useState("");
    const [proceed, setProceed] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [branchPin, setBranchPin] = useState("")
    const [showLoading, setShowLoading] = useState(false)
    if (proceed === true && branch !== "") {
        const link = '/login/' + branch;
        return (
            <Navigate to={link} />
        )
    }
    
    const handleSumbitPin=()=>{
        if(branchPin !== ""){
            {SubmitPin()}
        }else{
            setShowLoading(true)
            console.log("can't proceed!!!")
        }
    }

    async function SubmitPin(){
        setShowLoading(true)
        const response = await submitPin(branchPin, branch);
        if(response.data){
            toast.success(response.data.data.messages)
            setProceed(true)
            setShowLoading(false)
        }else{
            toast.error(response.error.response.data.messages.error);
            setShowLoading(false)
        }
    }

    return (
        <div className='branch-screen'>

            <div className='branch-container'>
                <div className='logo-container'>
                    <img src={logo} alt={'logo'}></img>
                </div>

                <div className='menu-container'>
                    <div class="dropdown">
                        <a class="btn btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                            {selected === "" ? "Select Branch" : selected}
                        </a>
                        {/** TO CHANGE TO DYNAMIC **/}
                        <ul class="dropdown-menu text-center" aria-labelledby="dropdownMenuLink">
                            <li onClick={() => { setBranch("1"); setSelected("Imelda Veteranos") }}><a class="dropdown-item" href="#">Imelda Veteranos</a></li>
                            <li onClick={() => { setBranch("2"); setSelected("Robinsons Marasbas") }}><a class="dropdown-item" href="#">Robinsons Marasbaras</a></li>
                            <li onClick={() => { setBranch("3"); setSelected("Robinsons Ormoc") }}><a class="dropdown-item" href="#" onClick={() => setBranch("3")}>Robinsons Ormoc</a></li>
                        </ul>
                    </div>
                    {branch && (<button className="proceed-btn" onClick={() => setProceed(true)}>Proceed</button>)}
                    {/* {branch && (<button className="proceed-btn" onClick={() => setProceed(!proceed)}>Proceed</button>)} */}
                </div>
            </div>
            <Modal show={showModal} onHide={()=>setShowModal(false)} size="lg" className="custom-modal-style" centered>
                <Modal.Header className="custom-modal-header del-prompt-header-custom"></Modal.Header>
                <Modal.Body className="px-5">
                    <Container className="d-flex flex-row">
                        {/* <FontAwesomeIcon
                            className="del-prompt-icon"
                            icon={'trash-alt'}
                            aria-hidden="true"
                            alt={'trash-alt'}
                            fixedWidth
                        /> */}
                        <div className="col">
                            <p className="del-prompt-par">Input " {branch === "1" ? "Imelda Veteranos" : branch === "2" ? "Robinsons Marasbas" : "Robinsons Ormoc"} " Pin:</p>
                            <input type="text" placeholder="########" className="nc-modal-custom-input full-width" onChange={(e)=>setBranchPin(e.target.value)} required/>                        
                            </div>
                    </Container>

                </Modal.Body>
                <Modal.Footer className="border-0 px-5">
                    <div className="cancel-btn-conf-wrapper">
                        <Button type="button" className="px-3" onClick={()=>setShowModal(false)}>cancel</Button>
                    </div>
                    <div className="btn-conf-wrapper">
                        {!showLoading ?
                            <Button type="submit" className="px-3" onClick={handleSumbitPin}>submit</Button>
                            :
                            <ReactLoading type="spinningBubbles" color="#D4281C" height={20} width={8} className='ms-4' />
                        }
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Branch;