import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal, Row, Col, Form, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';

// css
import '../common.css'
import { createBooking } from "../../utilities/api_calls/bookingApi";
import { refreshPage } from "../../utilities/common";

export default function BuyModal({ showBuy_, closeBuy_, buyType, dataList, customerId }) {
    const tbHeader = ['QTY', 'DETAILS', 'UNIT PRICE', 'ACTION']
    const [itemList, setItemList] = useState([{ item: '', details: '', price: 0 }]);
    const [click, setClick] = useState(false);

    function addItem() {
        const newList = [...itemList, { item: '', details: '', price: 0 }];
        setItemList(newList);
    }

    function updateItemOnChange(e) {
        const rowId = parseInt((e.target.id).slice((e.target.id).length - 1));
        const newList = [...itemList];
        newList[rowId].item = e.target.value;
        newList[rowId].price = e.target.options[e.target.selectedIndex].dataset.price;
        newList[rowId].details = e.target.options[e.target.selectedIndex].dataset.details;
        setItemList(newList);
    }


    function deleteItem(id) {
        // console.log(id);
        const rowId = id;
        // console.log(rowId);
        const newList = [...itemList];
        // console.log(newList);
        newList.splice(rowId, 1);
        // console.log(newList);
        setItemList(newList);
    }

    async function addPurchase() {
        if (click === false) {
            setClick(true);
            const response = await createBooking(buyType, itemList, customerId);
            // console.log(response);
            if (response.data.data.status === 201) {
                toast.success(response.data.data.message.success);
                setTimeout(function () {
                    refreshPage();
                }, 2000);
            }
            if (response.data.status === 400) {
                toast.error(response.data.message[0]);
            }
        }
    }


    function formatAmount(amount) {
        return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }


    /* MAIN MODAL */
    return (
        <div>
            <Modal show={showBuy_} onHide={closeBuy_} size="lg" className="custom-modal-style" centered>
                <Modal.Header className="custom-modal-header" closeButton>
                    <span className="custom-modal-body-title">ADD {buyType}</span>
                </Modal.Header>
                <Modal.Body >
                    {/* <Row>
                        <Col sm={4} className="d-flex justify-content-center">
                            <label className="fw-bold">{buyType}</label>
                        </Col>
                        <Col sm={8} className="d-flex justify-content-center">
                            {tbHeader.map((header, index) => (
                                <th className='modal-commission-new' key={index}>{header}</th>
                            ))}
                        </Col>
                        <strong><hr className="new-line-hr"/></strong>
                    </Row>
                    <Row>
                        <Col sm={4}>
                        {itemList.map((e, index) => {
                            return (
                            <Row>
                            <td width={80} className=" nc-modal-custom-row buy-input-field">
                                <Form.Select
                                    id={`added-item-name-${index}`}
                                    onChange={(e) => {
                                        updateItemOnChange(e);
                                    }
                                    }
                                    value={itemList[index].item}
                                    name="buy_item"
                                    className="nc-modal-custom-input"
                                    required
                                >
                                    <option value="" disabled hidden>--Select--</option>
                                    {dataList.map((item) => {
                                        return (<option key={item.ID} value={item.ID} data-price={parseFloat(item.AMOUNT)} data-details={item.CODE}>{item.NAME}</option>)
                                    })
                                    }
                                    {dataList.length===0 && (
                                    <option value="" disabled>No Available Gift Certificate</option>
                                    )
                                    }
                                </Form.Select>
                            </td>
                            </Row>
                            )})}
                        </Col>
                        <Col sm={8}>
                        {itemList.map((e, index) => {
                            return (
                                <tr>
                                    {/* quantity read-only field */}
                                    {/* <td width={120} className="tcenter nc-modal-custom-row buy-input-field">1</td> */}

                                    {/* details read-only field */}
                                    {/* <td width={120} className="tcenter nc-modal-custom-row buy-input-field">{itemList[index].details}</td> */}

                                    {/* dynamically display the price of the selected item */}
                                    {/* <td width={120} className="tcenter">{formatAmount(parseFloat(itemList[index].price))}</td> */}

                                    {/* delete button */}
                                    {/* <td className="tcenter">
                                        <div className="btn-conf-wrapper del-action">
                                            <FontAwesomeIcon
                                                id={`del-btn-${index}`}
                                                onClick={() => deleteItem(index)}
                                                role="button"
                                                className="align-self-center mx-1"
                                                icon={'trash'} />
                                        </div>
                                    </td>
                                </tr>
                            )
                            })}
                        </Col> */}
                    {/* </Row> */}

                    <Table responsive size="sm" className='text-center buy-modal-table table-modal'>
                       

                        <thead>
                            <tr>
                                <th className="tcenter" >{buyType}</th>
                                {tbHeader.map((header, index) => (
                                    <th className="tcenter" key={index}>{header}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {itemList.map((e, index) => {

                                return (
                                    <tr>
                                        {/* displays available items to choose from */}
                                        <td width={200} className="tcenter nc-modal-custom-row buy-input-field">
                                            <Form.Select
                                                id={`added-item-name-${index}`}
                                                onChange={(e) => {
                                                    updateItemOnChange(e);
                                                }
                                                }
                                                value={itemList[index].item}
                                                name="buy_item"
                                                className="nc-modal-custom-input"
                                                required
                                            >
                                                <option value="" disabled hidden>--Select--</option>
                                                {dataList.map((item) => {
                                                    return (<option key={item.ID} value={item.ID} data-price={parseFloat(item.AMOUNT)} data-details={item.CODE}>{item.NAME}</option>)
                                                })
                                                }
                                                {dataList.length===0 && (
                                                  <option value="" disabled>No Available Gift Certificate</option>
                                                )
                                                }
                                            </Form.Select>
                                        </td>

                                        {/* quantity read-only field */}
                                        <td width={120} className="tcenter nc-modal-custom-row buy-input-field">1</td>

                                        {/* details read-only field */}
                                        <td width={200} className="tcenter nc-modal-custom-row buy-input-field">{itemList[index].details}</td>

                                        {/* dynamically display the price of the selected item */}
                                        <td width={120} className="tcenter">{formatAmount(parseFloat(itemList[index].price))}</td>

                                        {/* delete button */}
                                        <td className="tcenter">
                                            <div className="btn-conf-wrapper del-action">
                                                <FontAwesomeIcon
                                                    id={`del-btn-${index}`}
                                                    onClick={() => deleteItem(index)}
                                                    role="button"
                                                    className="align-self-center mx-1"
                                                    icon={'trash'} />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>

                    <Row className="d-flex flex-row justify-content-between">
                        <Col>
                            <div className="btn-conf-wrapper">
                                <Button className="px-5 pb-1 mb-2" type="button" onClick={addItem}>add {buyType}</Button>
                            </div>
                        </Col>
                        <Col>
                            <div className="total">
                                <span>Total: </span>
                                <span className="total-amount">
                                    {itemList && formatAmount(parseFloat(itemList.reduce(
                                        (total, item) =>
                                            total + (parseFloat(item.price)), 0.0
                                    )))}
                                </span>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>

                <Modal.Footer className="border-0 px-5">
                    <div className="d-flex flex-row justify-content-between">
                        <div className="btn-conf-wrapper ms-auto">
                            {click === true && (
                                <div className="d-flex justify-content-center">
                                    <div className="btn-conf-wrapper ms-auto">
                                        <Button type="submit" className="px-5 pb-1 mb-2">
                                            <ReactLoading type="spinningBubbles" color="#d4271c" height={25} width={20} />
                                        </Button>
                                    </div>
                                </div>
                            )}
                            {click === false && (
                                <Button type="submit" className="px-5 pb-1 mb-2" onClick={() => addPurchase()}>save</Button>
                            )}
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
