import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useState } from "react";
import { Button, Col, Container, Form, Modal, Row, Tab, Tabs } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import ReactLoading from 'react-loading';
import Select from 'react-select';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { formatDate, getBranch, getRoleId, getTodayDate, getTodayDateISO, refreshPage, subtractMonths, validateContact, validateEmail } from "../../../utilities/common";

// css
import '../../common.css';
import './customer-details.css';

// components
import Pagination from '@material-ui/lab/Pagination';
import Navbar from '../../navbar';

// sample table (temporary)
import TableTemplate from "../../table-template";
import { getBookingPackages, getBookingServices } from '../../temp-data-sample';

// modals
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { getBranchName } from "../../../utilities/api_calls/branchApi";
import { deleteACustomer, getAllCustomers, getAvailableServicesDetails, getBookings, getCustomer, getGCS, getHistoryDetails, getItemsPurchased, getPackagesPurchased, getServicesPurchased, updateCustomer } from "../../../utilities/api_calls/customerApi";
import { getAllEmployees, getEmployee } from "../../../utilities/api_calls/employeeApi";
import { getAllFreeService, getNoFreeServices } from "../../../utilities/api_calls/freeServicesAPI";
import { availGiftCertificate } from '../../../utilities/api_calls/giftCertApi';
import { getAllAvailedServices, getAllBookings, getAllGiftcerts, getAllPurchasedPackages, getAllPurchasedProducts, getAllPurchasedServices } from "../../../utilities/api_calls/legalAPI";
import { getSummary } from "../../../utilities/api_calls/paymentApi";
import { availService, getService } from "../../../utilities/api_calls/servicesApi";
import { getUserName } from "../../../utilities/api_calls/userApi";
import NoDataFound from "../no-data-found";
import PaymentModal from "../payment_modal";

// main function
export default function CustomerDetails() {

  const [isLoading, setIsLoading] = React.useState(true);
  // const today = (getTodayDate()).toLocaleDateString("fr-CA");
  const { id, page } = useParams();
  let navigate = useNavigate();

  //Loading
  const handleLoading = () => {
    setIsLoading(false);
  }

  // Pagination 1
  const [pages1, setPages1] = useState(1);
  const [currentPage1, setCurrentPage1] = useState(1);

  function handlePageChange1(event, value) {
    // console.log(value)
    setCurrentPage1(value)
  }

  // Pagination 2
  const [pages2, setPages2] = useState(1);
  const [currentPage2, setCurrentPage2] = useState(1);

  function handlePageChange2(event, value) {
    // console.log(value)
    setCurrentPage2(value)
  }

  // Pagination 3
  const [pages3, setPages3] = useState(1);
  const [currentPage3, setCurrentPage3] = useState(1);

  function handlePageChange3(event, value) {
    // console.log(value)
    setCurrentPage3(value)
  }

  // Pagination 4
  const [pages4, setPages4] = useState(1);
  const [currentPage4, setCurrentPage4] = useState(1);

  function handlePageChange4(event, value) {
    // console.log(value)
    setCurrentPage4(value)
  }

  // Pagination 5
  const [pages5, setPages5] = useState(1);
  const [currentPage5, setCurrentPage5] = useState(1);

  function handlePageChange5(event, value) {
    // console.log(value)
    setCurrentPage5(value)
  }

  // Pagination 6
  const [pages6, setPages6] = useState(1);
  const [currentPage6, setCurrentPage6] = useState(1);

  function handlePageChange6(event, value) {
    // console.log(value)
    setCurrentPage6(value)
  }

  // Pagination 7
  const [pages7, setPages7] = useState(1);
  const [currentPage7, setCurrentPage7] = useState(1);

  function handlePageChange7(event, value) {
    // console.log(value)
    setCurrentPage7(value)
  }

  //Filter
  const [filter, setFilter] = useState({
    date_from: subtractMonths(1),
    date_to: getTodayDate(),
  });

  // console.log(filter)

  // -- Filter Functions --- //
  const handleDateFromChange = (date) => {
    setFilter(prevState => ({
      ...prevState,
      ['date_from']: date
    }))
  }

  const handleDateToChange = (date) => {
    setFilter(prevState => ({
      ...prevState,
      ['date_to']: date
    }))
  }

  //All Customers
  const [customer, setCustomer] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    suffix: "",
    birthdate: "",
    gender: "",
    contact_no: "",
    email: "",
    address: "",
    remarks: "",
  });

  const [user, setUser] = useState("");

  //Get Customers
  async function fetchCustomer() {
    const response = await getCustomer(id);
    const userId = await getUserName(response.data.data.added_by);
    // console.log(response);

    setCustomer(response.data.data);
    setEditCustomer(response.data.data);
    setUser(userId);
    handleLoading();
  }

  //Edit Customer Inputs
  const [editCustomer, setEditCustomer] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    suffix: "",
    birthdate: "",
    gender: "",
    contact_no: "",
    email: "",
    address: "",
    remarks: "",
  });

  //Handle Customer Input Change
  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditCustomer(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  // Eligible for free service
  const [withFree, setWithFree] = useState(false)
  const [noFreeServices, setNoFreeServices] = useState([])
  async function checkFreeService() {
    let response = await getAllFreeService() //get all customers elligible for free
    let freeService = [];
    freeService = response?.data?.data?.free_services
    let customer = freeService.filter(info => info?.ID === id) // filter response that matches customer id
    // if (customer.length > 0) {
    //   setWithFree(true) // if customer id is found, set with free to true
    // } else if (customer.length < 1) {
    //   setWithFree(false) // else, set with free to false
    // }
  }

   // Eligible for No of free services
  async function fetchNoFreeServices() {
    setWithFree(false)
    setNoFreeServices([])
    let response = await getNoFreeServices(id) //get all customers elligible for free
    console.log(response.data.data.no_of_free_services[0])
    if(response.data){
      setNoFreeServices(response.data.data.no_of_free_services[0].NO_OF_FREE_SERVICES)
      setWithFree(true)
    } else{
      setWithFree(false)
    }
  }
console.log(noFreeServices)
  //Available Services
  const [services, setServices] = useState([]);

  async function fetchAvailableServices() {
    setServices([]);
    const response = await getAvailableServicesDetails(id, filter);
    if (response?.data?.data?.available_services) {
      setPages1(Math.ceil(response?.data?.data?.available_services.length / 5));
      let data = response?.data?.data?.available_services.sort(function (a, b) { return new Date(b.DATE_PURCHASED) - new Date(a.DATE_PURCHASED); });
      // console.log(data)
      setServices(data);
    }
  }

  function removeFilter() {
    setFilter({
      date_from: "",
      date_to: "",
    })
  }

  //Bookings
  const [bookings, setBookings] = useState([]);

  async function fetchBookings() {
    setBookings([])
    var response;
    if (getRoleId() === '3') {
      response = await getAllBookings(id, filter);
      // console.log(response);

      if (response.response.data) {
        setPages2(Math.ceil(response.response.data.length / 5));
        let data = response.response.data.sort(function (a, b) { return new Date(b.added_on) - new Date(a.added_on); })
        data.map(async (data, index) => {
          let branchName = await getBranchName(data.branch_id)
          let dermTech = await getUserName(data.added_by)
          var info = {}
          info.id = data.id
          info.BOOKING_ID = data.id
          info.DATE_BOOKED = data.added_on
          info.BRANCH = branchName.data.data.name
          info.DERM_TECH = dermTech
          setBookings(prev => [...prev, info])
        })
      }
    } else {
      response = await getBookings(id, filter);
      if (response.data) {
        setPages2(Math.ceil(response.data.data.bookings.length / 5));
        let data = response.data.data.bookings;
        setBookings(data);
      }
    }
    // console.log(response);
  }

  //History
  const [history, setHistory] = useState([]);

  async function fetchHistory() {
    setHistory([])
    var response;
    if (getRoleId() === '3') {
      response = await getAllAvailedServices(id, filter);
      // console.log(response)
      if (response.response.data) {
        setPages3(Math.ceil(response.response.data.length / 5));
        let data = response.response.data.sort(function (a, b) { return new Date(b.added_on) - new Date(a.added_on); })
        // console.log(data)
        data.map(async (data, index) => {
          let branchBooked = await getBranchName(data.branch_id)
          let branchAvailed = await getBranchName(data.serviced_in)
          let dermTech = await getEmployee(data.serviced_by)

          var info = {}
          info.id = data.id
          info.DATE_PURCHASED = data.added_on
          info.DATE_AVAILED = data.serviced_on
          info.BRANCH_BOOKED = branchBooked.data.data.name
          info.BRANCH_AVAILED = branchAvailed.data.data.name
          info.SERVICE = data.service
          info.DERM_TECH = dermTech.data.data[0].first_name + " " + dermTech.data.data[0].last_name
          setHistory(prev => [...prev, info])
        })
      }
    } else {
      response = await getHistoryDetails(id, filter);
      if (response.data) {
        setPages3(Math.ceil(response.data.data.history.length / 5));
        setHistory(response.data.data.history);//to change 
      }
    }
  }

  //GCS
  const [gcs, setGCS] = useState([]);

  async function fetchGCS() {
    setGCS([])
    var response;
    if (getRoleId() === '3') {
      response = await getAllGiftcerts(id, filter);
      // console.log(response);
      if (response.response.data) {
        setPages4(Math.ceil(response.response.data.length / 5));
        let data = response.response.data
        // console.log(response)
        data.map(async (data, index) => {
          let branchName = await getBranchName(data.branch_id)
          let dermTech = await getUserName(data.added_by)
          let serviceName = await getService(data.service_id)
          // console.log(serviceName)

          var info = {}

          info.id = data.id
          info.DATE = data.added_on
          info.BRANCH = branchName.data.data.name
          info.NAME = serviceName.data.data.name
          info.CODE = data.gift_certificate_code
          info.AMOUNT = data.amount
          info.DERM_TECH = dermTech
          info.STATUS = data.status

          setGCS(prev => [...prev, info])
        })
      }
    } else {
      const response = await getGCS(id, filter);
      if (response.data) {
        setPages4(Math.ceil(response.data.data.gcs_purchased.length / 5));
        setGCS(response.data.data.gcs_purchased);
      }
    }
  }

  //Avail GC
  const [showAvailGC, setShowAvailGC] = useState(false);
  const [gc, setGC] = useState({});

  function onClickAvailGC(id) {
    gc.id = id;
    setShowAvailGC(true);
  }

  function handleCloseAvailGC() {
    setGC({});
    setShowAvailGC(false);
  }

  function handleAvailChangeGC(name, value) {
    const newList = { ...gc };
    newList[name] = value;

    setGC(newList);
  }

  async function availGC() {
    // console.log(gc)
    const response = await availGiftCertificate(gc);
    console.log(response);

    if(response.data) {
      toast.success("Gift Certificate Availed Successfully!");
      setTimeout(() => refreshPage(), 1000);
    } else if(response.error) {
      toast.error(response.error.response.data.messages.error);
      setTimeout(() => refreshPage(), 1000);
    }
  }

  //Employee options
  const [employeesOption, setEmployeesOption] = useState([]);

  async function fetchEmployeesOption() {
    setEmployeesOption([]);
    const response = await getAllEmployees();
    var people = response.data.data.employees;
    people.map((person) => {
      var info = {};

      info.label = person.name;
      info.value = person.id;

      setEmployeesOption(prev => [...prev, info]);
    })
  }

  //Customer Options
  const [customersOption, setCustomersOption] = useState([]);

  async function fetchCustomersOption() {
    setCustomersOption([])
    const response = await getAllCustomers();
    var people = response.data.data.customers.sort((a, b) => (a.first_name.toLowerCase() > b.first_name.toLowerCase()) ? 1 : ((b.first_name.toLowerCase() > a.first_name.toLowerCase()) ? -1 : 0));
    people.map((person) => {
      var info = {};

      info.label = person.first_name.toLowerCase() + " " + person.middle_name.toLowerCase() + " " + person.last_name.toLowerCase() + " " + person.suffix.toLowerCase();
      info.value = person.id;

      setCustomersOption(prev => [...prev, info]);
    })
  }

  //Items Purchased
  const [items, setItems] = useState([]);

  async function fetchItemsPurchased() {
    setItems([])
    var response;
    if (getRoleId() === '3') {
      response = await getAllPurchasedProducts(id, filter);
      // console.log(response);

      if (response.response.data) {
        setPages5(Math.ceil(response.response.data.length / 5));
        let data = response.response.data.sort(function (a, b) { return new Date(b.added_on) - new Date(a.added_on); })
        data.map(async (data, index) => {
          let dermTech = await getEmployee(data.added_by)
          // console.log(dermTech)
          var info = {}
          info.id = data.id
          info.DATE = data.added_on
          info.branch_name = data.branch_purchased
          info.quantity = data.quantity
          info.item = data.name
          info.total_price = data.total_price
          info.first_name = dermTech.data.data[0].first_name + " " + dermTech.data.data[0].last_name
          setItems(prev => [...prev, info])
        })
      }
    } else {
      response = await getItemsPurchased(id, filter);
      if (response.data) {
        setPages5(Math.ceil(response.data.data.items_purchased.length / 5));
        let data = response.data.data.items_purchased
        setItems(data);
      }
    }

  }

  //Packages Purchased
  const [packages, setPackages] = useState([]);

  async function fetchPackagesPurchased() {
    setPackages([])
    var response;
    if (getRoleId() === '3') {
      response = await getAllPurchasedPackages(id, filter);
      // console.log(response)
      if (response) {
        setPages6(Math.ceil(response.response.data.length / 5));
        let data = response.response.data.sort(function (a, b) { return new Date(b.added_on) - new Date(a.added_on); })
        data.map((data, index) => {
          var info = {}
          info.ID = data.id
          info.BOOKING_ID = data.booking_id
          info.DATE_PURCHASED = data.added_on
          info.BRANCH = data.branch_purchased
          info.NAME = data.name
          info.AMOUNT = data.total_price
          info.BALANCE = data.balance
          info.COMMISSIONED_BY = data.sales_by
          setPackages(prev => [...prev, info])
        })
      }
    } else {
      response = await getPackagesPurchased(id, filter);
      if (response.data) {
        setPages6(Math.ceil(response.data.data.packages_purchased.length / 5));
        let data = response.data.data.packages_purchased;
        // console.log(data)
        setPackages(data);
      }
    }
  }

  //Services Purchased
  const [servicesPurchased, setServicesPurchased] = useState([]);

  async function fetchServicesPurchased() {
    setServicesPurchased([])
    var response;
    if (getRoleId() === '3') {
      response = await getAllPurchasedServices(id, filter);
      // console.log(response);

      if (response.response.data) {
        setPages7(Math.ceil(response.response.data.length / 5));
        let data = response.response.data.sort(function (a, b) { return new Date(b.added_on) - new Date(a.added_on); })
        data.map(async (data, index) => {
          // let branchName = await getBranchName(data.branch_id)
          let dermTech = await getUserName(data.added_by)

          var info = {}
          info.id = data.id
          info.DATE_PURCHASED = data.added_on
          info.BRANCH = data.branch_purchased
          info.NAME = data.service
          info.PRICE = data.total_price
          info.DERM_TECH = dermTech
          setServicesPurchased(prev => [...prev, info])
        })
      }
    } else {
      response = await getServicesPurchased(id, filter);
      if (response.data) {
        setPages7(Math.ceil(response.data.data.services_purchased.length / 5));
        let data = response.data.data.services_purchased;
        setServicesPurchased(data);
      }
    }

  }

  //Edit
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [hasFirstName, setHasFirstName] = useState(true);
  const [hasLastName, setHasLastName] = useState(true);
  const [hasGender, setHasGender] = useState(true);

  async function submitEdit() {

    //Validate Email
    if (validateEmail(editCustomer.email) === false && editCustomer.email !== "") {
      setIsValidEmail(false);
    } else {
      setIsValidEmail(true);
    }

    //Validate required inputs
    if (editCustomer.first_name === "") {
      setHasFirstName(false);
    } else {
      setHasFirstName(true);
    }

    if (editCustomer.last_name === "") {
      setHasLastName(false);
    } else {
      setHasLastName(true);
    }

    if (editCustomer.gender === "") {
      setHasGender(false);
    } else {
      setHasFirstName(true);
    }

    if (click === false) {
      setClick(true);
      if (editCustomer.first_name !== "" && editCustomer.last_name !== "" && editCustomer.gender !== "") {
        const response = await updateCustomer(editCustomer, id);
        // console.log(response);
        if (response.data.status === 200) {
          toast.success(response.data.data.message.success);
          setTimeout(function () {
            refreshPage();
          }, 3000);
        }
        if (response.data.status === 400) {
          toast.error(response.data.message[0]);
        }
      } else {
        setClick(false);
      }
    }
  }

  // Edit Customer Popup/Modal
  const [showEdit, setShowEdit] = useState(false);
  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = () => setShowEdit(true);

  // Avail Popup/Modal
  const [showAvail, setShowAvail] = useState(false);
  const handleCloseAvail = () => setShowAvail(false);
  const handleShowAvail = () => setShowAvail(true);
  const [selectedAvail, setSelectedAvail] = useState({});
  const [click, setClick] = useState(false);
  const [hasServiceBy, setHasServiceBy] = useState(true);
  const [hasAvailDate, setHasAvailDate] = useState(true);

  const [availServices, setAvailServices] = useState({
    serviced_on: getTodayDateISO(),
    serviced_by: "",
    remarks: "",
  });

  async function avail() {
    // console.log(selectedAvail)
    // console.log(availServices)

    if (availServices.serviced_by === "") {
      setHasServiceBy(false);
    } else {
      setHasServiceBy(true);
    }

    if (availServices.serviced_on === "") {
      setHasAvailDate(false);
    } else {
      setHasAvailDate(true);
    }

    if (availServices.serviced_by !== "" && availServices.serviced_on !== "") {
      if (click === false) {
        setClick(true);
        const response = await availService(availServices, selectedAvail.table_origin, selectedAvail.service_id, id);
        // console.log(response);
        if (response.data.status === 200) {
          toast.success(response.data.data.message.success);
          setTimeout(function () {
            refreshPage();
          }, 3000);
        }
        if (response.data.status === 400) {
          toast.error(response.data.message[0]);
        }
      }
    }
  }

  //Handle Avail Service Change
  const handleAvailChange = (e) => {
    const { name, value } = e.target;
    setAvailServices(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  function onclickAvail(table_origin, id) {
    handleShowAvail();
    setSelectedAvail({ table_origin: table_origin, service_id: id })
  }

  //All Employees
  const [employees, setEmployees] = useState([]);

  //Get Employees
  async function fetchEmployees() {
    const response = await getAllEmployees();
    // console.log(response);
    setEmployees(response.data.data.employees);
  }

  // Payment Popup/Modal
  const [showPayment, setShowPayment] = useState(false);
  const handleClosePayment = () => setShowPayment(false);
  const handleShowPayment = () => setShowPayment(true);
  const [selectedPurchase, setSelectedPurchase] = useState({});

  // Payment Details
  const [paymentDeets, setPaymentDeets] = useState({
    payment_type: "",
    amount: "",
    card_type: "",
    card_expiry: "",
    cvc_cvv: "",
    acc_no: "",
    type: "",
    proof: "",
    code: "",
    or_no: "",
    remarks: "",
    selected_code: "",
  });

  //Handle Payment Details Change
  const handlePaymentChange = (e) => {
    const { name, value } = e.target;
    setPaymentDeets(prevState => ({
      ...prevState,
      [name]: value
    }));
  }


  // async function payPurchase() {
  //   if(click === false) {
  //       setClick(true);
  //       const response = await payThisBooking(paymentDeets.payment_type, paymentDeets, selectedPurchase.booking_id);
  //       // console.log(response);
  //       if(response.data.data.status === 200 || response.data.data.status === 201) {
  //           toast.success("Successfully Paid!");
  //           setTimeout(function () {
  //             refreshPage();
  //           }, 3000);
  //         }
  //       if(response.data.status === 400 || response.data.data.status === 400) {
  //           toast.success("Payment Unsuccessful!"); 
  //           setTimeout(function () {
  //             refreshPage();
  //           }, 3000);
  //       } 
  //   }
  // }

  function onclickPayment(id, balance) {
    // console.log(id)
    // console.log(balance)
    handleShowPayment();
    viewExistingSummary(id, "");
    setSelectedPurchase({ booking_id: id, booking_balance: balance });
  }

  const [showSummary, setShowSummary] = useState(false);
  const handleCloseSummary = () => setShowSummary(false);
  const handleShowSummary = () => setShowSummary(true);

  //Existing Summary
  const [existingSummary, setExistingSummary] = useState([{
    date: "",
    id: "",
    name: "",
    quantity: "",
    total_price: "",
  }]);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [orNo, setOrNo] = useState([]);
  const [footer, setFooter] = useState({ total: 0, balance: 0 });
  const [subTotal, setSubTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0)
  const [selectedDetails, setSelectedDetails] = useState([{
    DATE_BOOKED: "",
    REMARKS: "",
  }])
  // here
  async function viewExistingSummary(id, booking_deets) {
    const response = await getSummary(id, booking_deets.BRANCH_ID);
    console.log(response.data.data.or_no)
    console.log(response);
    setExistingSummary(response.data.data.summary)
    setSelectedDetails(booking_deets)
    setOrNo(response.data.data.or_no);
    setSubTotal(response?.data?.data?.sub_total);
    setGrandTotal(response?.data?.data?.grand_total);
    setDiscount(response?.data?.data?.discount)
    
    setPaymentHistory(response?.data?.data?.payment_history === 0 ? [] : response?.data?.data?.payment_history)
    var payments = response.data.data.payment_history;
    var total = 0;
    payments.map((payment) => {
      total += parseInt(payment.PAID_AMOUNT);
    })

    setFooter({ total: total, balance: parseInt(response.data.data.grand_total) - total })

    // var subTotal = 0;

    // response.data.data.summary.map((data) => {
    //   return (subTotal += parseFloat(data.total_price))
    // })
  }

  function onclickSummary(id, details) {
    viewExistingSummary(id, details);
    handleShowSummary();
  }

  //Branch
  // eslint-disable-next-line
  const [branchName, setBranchName] = useState("");

  async function fetchBranch() {
    const response = await getBranchName(getBranch().replace(/['"]+/g, ''));
    // console.log(response)
    setBranchName(response.data.data.name)
  }


  // Transaction button
  const mainPage = '/customer';

  const [redirectToPayNow, setRedirectToPayNow] = useState(false);

  function handleRedirectToPayNow() {
    setRedirectToPayNow(true);
  }

  function fetchData() {
    // console.log("fetchData()")
    fetchBookings();
    fetchHistory();
    fetchGCS();
    fetchItemsPurchased();
    fetchPackagesPurchased();
    fetchAvailableServices();
    fetchServicesPurchased();
  }

  const [deleteCustomer, showDeleteCustomer] = useState(false);
  const handleShowDelete = () => showDeleteCustomer(true);
  const handleCloseDelete = () => showDeleteCustomer(false);

  async function delCustomer() {
    // console.log(id)
    const response = await deleteACustomer(id);
    console.log(response);
    if (response.data.data.status === 200) {
      toast.success("Customer Deleted Successfully!");
      setTimeout(function () {
        navigate("/customer/" + page);
        setTimeout(() => refreshPage(), 500)
      }, 2000);
    }
    if (response.data.status === 400) {
      toast.error(response.data.message[0]);
    }
  }

  function onClickDeleteCustomer(id) {
    handleShowDelete();
  }

  // React.useEffect(() => {
  //   window.addEventListener("load",handleLoading);
  //   return () => window.removeEventListener("load",handleLoading);
  // },[]);

  React.useEffect(() => {
    fetchCustomer();
    // fetchData();
    fetchNoFreeServices();
    checkFreeService();
    fetchEmployeesOption();
    fetchCustomersOption();
  }, [])

  React.useEffect(() => {
    fetchBranch();
    fetchBookings();
    fetchHistory();
    fetchGCS();
    fetchItemsPurchased();
    fetchPackagesPurchased();
    fetchAvailableServices();
    fetchServicesPurchased();
    fetchEmployees();
    // eslint-disable-next-line
  }, [filter]);

  if (redirectToPayNow === true) {
    const link = mainPage + '/pay-now/' + page + "/" + id;
    return (
      <Navigate to={link} />
    )
  }

  // console.log(selectedPurchase)

  return !isLoading ? (
    <>
      <div>
        <Navbar />
        <div className="active-cont">
          <Fragment>
            {/* <ToastContainer /> */}
            {/* header */}
            <div className="d-flex flex-row cont-section">
              <FontAwesomeIcon
                className="icon me-3 fa-xl align-self-center edit-details-icon"
                icon="fa-solid fa-angle-left"
                onClick={() => navigate("/customer/" + page)}
              />
              <h1 className="m-0 cont-sect-header">Customer Details</h1>
              <FontAwesomeIcon
                className="icon mx-2 fa-lg align-self-center edit-details-icon"
                icon={'pen'}
                aria-hidden="true"
                alt={'search'}
                onClick={handleShowEdit}
                fixedWidth
              />
            </div>

            {/* customer info section */}
            <div className="cont-section-novertpad">
              <div className=" flex-row justify-content-between">
                <Row>
                  <Col sm={4}>
                    <Row>
                      <Col>
                        <label>Name</label>
                      </Col>
                      <Col>
                        <label>{customer.first_name + " " + customer.middle_name + " " + customer.last_name}</label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label>Customer ID.</label>
                      </Col>
                      <Col>
                        <label>{customer.id}</label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label>Contact Number</label>
                      </Col>
                      <Col>
                        <label>{customer.contact_no}</label>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={4}>
                    <Row>
                      <Col>
                        <label>Addres</label>
                      </Col>
                      <Col>
                        <label>{customer.address}</label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label>Added By</label>
                      </Col>
                      <Col>
                        <label>{user}</label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label>Remarks</label>
                      </Col>
                      <Col>
                        <label>{customer.remarks}</label>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={2}>
                    {noFreeServices > 0 && (
                      <FontAwesomeIcon
                        icon={'gift'}
                        aria-hidden="true"
                        alt={'free'}
                        className="gift-icon fa-5x"
                      />
                    )}
                    <label className='gift-text'>No. of Free Services : &nbsp; {noFreeServices > 0 ? noFreeServices : 0}</label>
                  </Col>
                  <Col sm={2}>
                    {getRoleId() === '4' ?
                      <div className="delete-customer">
                        <Button type="button" onClick={onClickDeleteCustomer}>DELETE CUSTOMER</Button>
                      </div> : null
                    }
                  </Col>
                </Row>
              </div>
            </div>

            <div className="cont-section-novertpad">
              <div className="flex-row justify-content-between">

                {/* <div className="d-flex flex-column px-3"> */}
                  
                  
                  {/* <table class="table table-lg table-responsive">
                    <tbody>
                      <tr >
                        <th scope="row">Name</th>
                        <td>{customer.first_name + " " + customer.middle_name + " " + customer.last_name}</td>
                      </tr>
                      <tr>
                        <th scope="row">Customer ID</th>
                        <td>{customer.id}</td>
                      </tr>
                      <tr>
                        <th scope="row">Contact Number</th>
                        <td>{customer.contact_no}</td>
                      </tr>
                      {/* <tr>
                        <th scope="row">Birthdate</th>
                        <td>{formatDate(customer.birthdate)}</td>
                      </tr> */}
                      {/* <tr>
                        <th scope="row">Age</th>
                        <td>{getAge(customer.birthdate)}</td>
                      </tr> */}
                    {/* </tbody>
                  </table>  */}
                {/* </div> */}

                {/* <div className="d-flex flex-column px-3">
                  <table class="table table-lg table-responsive">
                    <tbody>
                      <tr>
                        <th scope="row">Gender</th>
                        <td>{customer.gender}</td>
                      </tr>
                      <tr>
                        <th scope="row">Email Address</th>
                        <td>{customer.email}</td>
                      </tr>
                    </tbody>
                  </table>
                </div> */}

                {/* <div className="d-flex flex-column px-3">
                  <table class="table table-lg table-responsive">
                    <tbody>
                      <tr>
                        <th scope="row">Address</th>
                        <td>{customer.address}</td>
                      </tr>
                      <tr>
                        <th scope="row">Added By</th>
                        <td>{user}</td>
                      </tr>
                      <tr>
                        <th scope="row">Remarks</th>
                        <td>{customer.remarks}</td>
                      </tr>
                    </tbody>
                  </table>
                </div> */}

                {/* <div className="d-flex flex-column justify-content-center px-3 ml-5"> */}
                  {/* {noFreeServices > 0 && (
                    <FontAwesomeIcon
                      icon={'gift'}
                      aria-hidden="true"
                      alt={'free'}
                      className="gift-icon fa-5x"
                    />
                  )}
                  <h6 className='gift-text'>No. of Free Services : &nbsp; {noFreeServices > 0 ? noFreeServices : 0}</h6> */}
                {/* </div> */}
                {/* {getRoleId() === '4' ?
                  <div className="delete-customer">
                    <Button type="button" onClick={onClickDeleteCustomer}>DELETE CUSTOMER</Button>
                  </div> : null} */}

              </div>
              <hr />
            </div>

            {/** FILTER */}

            <div className='filter-cont filter-label-row1'>
              FROM:
              <DatePicker selected={filter.date_from} dateFormat="MM/dd/yyyy" className="filter-date-picker mx-3 px-1" name="date_from" placeholderText="mm/dd/yyyy" onChange={date => handleDateFromChange(date)} />
              {/* <input type="date" className="filter-date-picker" name="date_from" value={filter.date_from} onChange={(e) => handleFilterChange(e)} /> */}

              TO:
              <DatePicker selected={filter.date_to} dateFormat="MM/dd/yyyy" className="filter-date-picker mx-3 px-1" name="date_to" placeholderText="mm/dd/yyyy" onChange={date => handleDateToChange(date)} />
              {/* <input type="date" className="filter-date-picker" name="date_to" value={filter.date_to} onChange={(e) => handleFilterChange(e)} /> */}

              <div className="btn-conf-wrapper in-line  remove-filter-btn">
                <Button type="button" className="px-2 py-1 mtn-1" onClick={() => removeFilter()}>Remove Filter</Button>
              </div>
            </div>

            {/* table section - temp */}
            <div className="cont-section-novertpad customer-deets-table">
              <Tabs defaultActiveKey="avail_service" id="customer-transaction" variant="pills" className="customer-transaction-tab">
                <Tab eventKey="avail_service" className="customer-transaction-tab-title" title="Available Services">
                  <div className="customer-details-table">
                    {services.length === 0 ?
                      <NoDataFound
                        tableType='customer_available_service'
                        tableHeaders={["Date Purchased", "Branch", "Name", "Service", "", ""]}
                        message="available services" />
                      : <>
                        <TableTemplate
                          tableType='customer_available_service'
                          tableHeaders={["Date Purchased", "Branch", "Name", "Service", "", ""]}
                          tableData={services}
                          tableFuncs={[null, null, null, [onclickAvail]]}
                          currentPage={currentPage1}
                        />
                        <Pagination count={pages1} page={currentPage1} shape="rounded" defaultPage={1} siblingCount={2} onChange={handlePageChange1} showFirstButton showLastButton />
                      </>}
                  </div>
                </Tab>
                <Tab eventKey="customer_booking" title="Booking">
                  {bookings.length === 0 ?
                    <NoDataFound
                      tableType='customer_booking'
                      tableHeaders={["Date Booked", "Branch", "Derm Tech", ""]}
                      message="bookings" />
                    : <>
                      <TableTemplate
                        tableType='customer_booking'
                        tableHeaders={["Date Booked", "Branch", "Derm Tech", ""]}
                        tableData={bookings.sort(function (a, b) { return new Date(b.DATE_BOOKED) - new Date(a.DATE_BOOKED); })}
                        tableFuncs={[null, null, null, [onclickSummary]]}
                        currentPage={currentPage2}
                      />
                      <Pagination count={pages2} page={currentPage2} shape="rounded" defaultPage={1} siblingCount={2} onChange={handlePageChange2} showFirstButton showLastButton />
                    </>}
                </Tab>
                <Tab eventKey="customer_service_purchased" title="Service Purchased">
                  {servicesPurchased.length === 0 ?
                    <NoDataFound
                      tableType='customer_service_purchases'
                      tableHeaders={["Date Purchased", "Branch", "Name", "Amount", "Derm Tech"]}
                      message="services" />
                    : <>
                      <TableTemplate
                        tableType='customer_service_purchases'
                        tableHeaders={["Date Purchased", "Branch", "Name", "Amount", "Derm Tech"]}
                        tableData={servicesPurchased.sort(function (a, b) { return new Date(b.DATE_PURCHASED) - new Date(a.DATE_PURCHASED); })}
                        currentPage={currentPage7}
                      />
                      <Pagination count={pages7} page={currentPage7} shape="rounded" defaultPage={1} siblingCount={2} onChange={handlePageChange7} showFirstButton showLastButton />
                    </>}
                </Tab>
                <Tab eventKey="customer_item_purchased" title="Item Purchased">
                  {items.length === 0 ?
                    <NoDataFound
                      tableType='customer_item_purchases'
                      tableHeaders={["Date Purchased", "Branch", "Name", "Amount", "Derm Tech"]}
                      message="products" />
                    : <>
                      <TableTemplate
                        tableType='customer_item_purchases'
                        tableHeaders={["Date Purchased", "Branch", "Name", "Amount", "Derm Tech"]}
                        tableData={items.sort(function (a, b) { return new Date(b.DATE) - new Date(a.DATE); })}
                        currentPage={currentPage5}
                      />
                      <Pagination count={pages5} page={currentPage5} shape="rounded" defaultPage={1} siblingCount={2} onChange={handlePageChange5} showFirstButton showLastButton />
                    </>}
                </Tab>
                <Tab eventKey="customer_package_purchased" title="Package Purchased">
                  {packages.length === 0 ?
                    <NoDataFound
                      tableType='customer_package_purchases'
                      tableHeaders={["Date Purchased", "Branch", "Name", "Amount", "Balance", ""]}
                      message="packages" />
                    : <>
                      <TableTemplate
                        tableType='customer_package_purchases'
                        tableHeaders={["Date Purchased", "Branch", "Name", "Amount", "Balance", ""]}
                        tableData={packages.sort(function (a, b) { return new Date(b.DATE_PURCHASED) - new Date(a.DATE_PURCHASED); })}
                        tableFuncs={[null, null, null, [onclickPayment]]}
                        currentPage={currentPage6}
                      />
                      <Pagination count={pages6} page={currentPage6} shape="rounded" defaultPage={1} siblingCount={2} onChange={handlePageChange6} showFirstButton showLastButton />
                    </>}
                </Tab>
                <Tab eventKey="customer_giftcert_purchased" title="GCS Purchased">
                  {gcs.length === 0 ?
                    <NoDataFound
                      tableType='customer_giftcert_purchases'
                      tableHeaders={["Date Purchased", "Branch", "Name", "CODE", "Amount", "Derm Tech", ""]}
                      message="gift certificates" />
                    : <>
                      <TableTemplate
                        tableType='customer_giftcert_purchases'
                        tableHeaders={["Date Purchased", "Branch", "Name", "CODE", "Amount", "Derm Tech", ""]}
                        tableData={gcs.sort(function (a, b) { return new Date(b.DATE) - new Date(a.DATE); })}
                        tableFuncs={[onClickAvailGC]}
                        currentPage={currentPage4}
                      />
                      <Pagination count={pages4} page={currentPage4} shape="rounded" defaultPage={1} siblingCount={2} onChange={handlePageChange4} showFirstButton showLastButton />
                    </>}
                </Tab>
                <Tab eventKey="history" title="History">
                  {history.length === 0 ?
                    <NoDataFound
                      tableType='customer_history'
                      tableHeaders={["Date Availed", "Branch Availed", "Branch Booked", "Service", "Derm Tech", "Date Purchased", ""]}
                      message="history" />
                    : <>
                      <TableTemplate
                        tableType='customer_history'
                        tableHeaders={["Date Availed", "Branch Availed", "Branch Booked", "Particular", "Name ", "Derm Tech", "Date Purchased", ""]}
                        tableData={history.sort(function (a, b) { return new Date(b.DATE_PURCHASED) - new Date(a.DATE_PURCHASED); })}
                        currentPage={currentPage3}
                      />
                      <Pagination count={pages3} page={currentPage3} shape="rounded" defaultPage={1} siblingCount={2} onChange={handlePageChange3} showFirstButton showLastButton />
                    </>}
                </Tab>
              </Tabs>
            </div>


            <div className="cont-section-novertpad">
              <div className="d-flex justify-content-end">

                <div className="srv-conf-wrapper ms-3 mt-2 transaction-button">
                  <Button onClick={handleRedirectToPayNow} type="button" className="px-2 py-1 mb-1 my-2">Transaction</Button>
                </div>
              </div>
            </div>

          </Fragment>

          <Modal show={showSummary} onHide={() => { handleCloseSummary() }} size="lg" className="custom-modal-style" centered>
            <Form>
              <Modal.Header className="custom-modal-header justify-content-end" closeButton></Modal.Header>
              <Modal.Body className="px-5 py-1 flex-column">
                <div className=" flex-row">
                  <Container className="col-4 my-auto">
                    <Row><div className="h1 fw-bold my-0">Summary</div></Row>
                    <Row><div className="h7 my-0">BRANCH: {selectedDetails?.BRANCH}</div></Row>
                    <Row><div className="h7 my-0">DATE:  {selectedDetails?.DATE_BOOKED ? formatDate(selectedDetails?.DATE_BOOKED) : ""}</div></Row>
                    <Row><div className='h7 my-0'>CUSTOMER: {customer.first_name + " " + customer.middle_name + " " + customer.last_name}</div></Row>
                    {/* <Row><div className="h7 my-0">REMARKS: {selectedDetails.REMARKS}</div></Row> */}
                  </Container>

                  <div className="custom-modal-vertline-border mx-2"></div>

                  <Container className="my-auto">
                    {existingSummary && existingSummary?.map((data, index) => {
                      return <Row className='nc-modal-custom-row my-1'>
                        <Col xs={1}>{data?.quantity}</Col>
                        <Col xs={4} className="fw-bold">{data?.code ? (data?.code + "-") : ""}{data?.name ? data?.name : ""}</Col>
                        <Col xs={3} className="fw-bold">{data?.total_price ? data?.total_price : ""}</Col>
                        <Col xs={4} className="fw-bold">{data?.commissioner || "-"}</Col>
                      </Row>
                    })}


                    <Row className='nc-modal-custom-row my-1'>
                      <Col xs={7} className="fw-bold"></Col>
                      <Col xs={5} className="fw-bold"></Col>
                    </Row>
                    <Row className='nc-modal-custom-row my-1'>
                      <Col xs={7} className="fw-bold"></Col>
                      <Col xs={5} className="fw-bold"></Col>
                    </Row>
                    <Row className='nc-modal-custom-row my-1'>
                      <Col xs={7} className="fw-bold"></Col>
                      <Col xs={5} className="fw-bold"></Col>
                    </Row>
                    <Row className='nc-modal-custom-row my-1'>
                      <Col xs={7} className="fw-bold"></Col>
                      <Col xs={5} className="fw-bold"></Col>
                    </Row>
                    <Row className='nc-modal-custom-row my-1'>
                      <Col xs={7} className="fw-bold"> </Col>
                      <Col xs={5} className="fw-bold"> </Col>
                    </Row>
                    <Row className='nc-modal-custom-row my-1'>
                      <Col xs={7} className="fw-bold"></Col>
                      <Col xs={5} className="fw-bold"></Col>
                    </Row>
                    <Row className='nc-modal-custom-row my-1'>
                      <Col xs={7} className="fw-bold"></Col>
                      <Col xs={5} className="fw-bold"></Col>
                    </Row>
                    <Row className='nc-modal-custom-row my-1'>
                      <Col xs={7} className="fw-bold"></Col>
                      <Col xs={5} className="fw-bold"></Col>
                    </Row>
                    <Row className='my-1'>
                      <Row className="nc-modal-custom-row my-1">
                        <Col xs={5} className="fw-bold">Sub-Total</Col>
                        <Col xs={5} className="fw-bold">{subTotal?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Col>
                      </Row>
                      <Row className="nc-modal-custom-row my-1">
                        <Col xs={5} className="fw-bold">Discount</Col>
                        <Col xs={5} className="fw-bold">{discount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Col>
                      </Row>
                      <Row className="nc-modal-custom-row my-1">
                        <Col xs={5} className="fw-bold">Grand Total</Col>
                        <Col xs={5} className="fw-bold">{grandTotal?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Col>
                      </Row>
                      <Row className="nc-modal-custom-row my-1">
                        <Col xs={5} className="fw-bold">OR NO.</Col>
                        <Col xs={5} className="fw-bold">
                          {/* {orNo === null || orNo.length === 0 ? "NONE" : orNo.map((data, index) => {
                          if(orNo.length - 1 === index) {
                            return <span>{data.OR_NO}</span>
                          } else {
                            return <span>{data.OR_NO}, </span>
                          }
                        })} */}
                          <span>{orNo}</span>
                        </Col>
                      </Row>
                      {/* <Row className="nc-modal-custom-row my-1">
                      <Col xs={10} className="fw-bold">COMMISSIONED BY: {existingSummary.commissioner}</Col>
                    </Row> */}
                    </Row>
                  </Container>
                </div>

                <hr className="text-danger" />

                <div className="d-flex flex-column">
                  <div className="h5 my-0 mb-3 text-uppercase fw-bolder">Payment History</div>

                  <div>
                    {paymentHistory.length === 0 && (
                      <span>No history to show</span>
                    )}
                    {paymentHistory.length !== 0 && (
                        <TableTemplate
                        tableType="sales_payment_history"
                        tableHeaders={["DATE", "PAYMENT TYPE", "PAID AMOUNT", "REMARKS"]}
                        tableData={paymentHistory}
                        hasFooter={true}
                        footerData={footer}
                        />
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className="border-0 px-5">
                <div className="d-flex flex-row justify-content-between">
                  <div className="btn-conf-wrapper ms-auto">
                  </div>
                </div>
              </Modal.Footer>
            </Form>
          </Modal>

          <Modal show={showEdit} onHide={handleCloseEdit} size="lg" className="custom-modal-style" centered>
            <Modal.Header className="custom-modal-header d-flex justify-content-end" closeButton></Modal.Header>
            <Modal.Body className="px-5 py-1">
              <h1 className="custom-modal-body-title">Edit Customer Details</h1>
              <Container>
                <Row className="nc-modal-custom-row">
                  <Col>First Name</Col>
                  <Col xs={1}>:</Col>
                  <Col xs={7}>
                    <Form.Control type="name" name="first_name" placeholder="Enter first name" className="nc-modal-custom-input" value={editCustomer.first_name} onChange={(e) => handleEditChange(e)} />
                    {hasFirstName === false && (
                      <div className="validity-error">
                        First Name is required*
                      </div>
                    )}
                  </Col>
                </Row>
                <Row className="nc-modal-custom-row">
                  <Col>Middle Name</Col>
                  <Col xs={1}>:</Col>
                  <Col xs={7}>
                    <Form.Control type="name" name="middle_name" placeholder="Enter middle name" className="nc-modal-custom-input" value={editCustomer.middle_name} onChange={(e) => handleEditChange(e)} />
                  </Col>
                </Row>
                <Row className="nc-modal-custom-row">
                  <Col>Last Name</Col>
                  <Col xs={1}>:</Col>
                  <Col xs={7}>
                    <Form.Control type="name" name="last_name" placeholder="Enter last name" className="nc-modal-custom-input" value={editCustomer.last_name} onChange={(e) => handleEditChange(e)} />
                    {hasLastName === false && (
                      <div className="validity-error">
                        Last Name is required*
                      </div>
                    )}
                  </Col>
                </Row>
                <Row className="nc-modal-custom-row">
                  <Col>Suffix</Col>
                  <Col xs={1}>:</Col>
                  <Col xs={7}><Form.Control type="text" name="suffix" placeholder="Enter suffix" className="nc-modal-custom-input" value={editCustomer.suffix} onChange={(e) => handleEditChange(e)} /></Col>
                </Row>
                {/* <Row className="nc-modal-custom-row">
                  <Col>Birthdate</Col>
                  <Col xs={1}>:</Col>
                  <Col xs={7}><Form.Control type="date" name="birthdate" placeholder="Enter birthdate" className="nc-modal-custom-input" value={editCustomer.birthdate} onChange={(e) => handleEditChange(e)} /></Col>
                </Row> */}
                {/* <Row className="nc-modal-custom-row">
                  <Col>Gender</Col>
                  <Col xs={1}>:</Col>
                  <Col xs={7}>
                    <Form.Select className="nc-modal-custom-input" name="gender" onChange={(e) => handleEditChange(e)}>
                      <option value="" disabled>--SELECT--</option>
                      <option value="female">Female</option>
                      <option value="male">Male</option>
                    </Form.Select>
                    {hasGender === false && (
                      <div className="validity-error">
                        Gender is required*
                      </div>
                    )}
                  </Col>
                </Row> */}
                <Row className="nc-modal-custom-row">
                  <Col>Contact Number</Col>
                  <Col xs={1}>:</Col>
                  <Col xs={7}>
                    <Form.Control type="text" name="contact_no" placeholder="Enter contact number" className="nc-modal-custom-input" value={editCustomer.contact_no} onChange={(e) => handleEditChange(e)} />
                  </Col>
                </Row>
                {/* <Row className="nc-modal-custom-row">
                  <Col>Email Address</Col>
                  <Col xs={1}>:</Col>
                  <Col xs={7}>
                    <Form.Control type="email" name="email" placeholder="Enter email address" className="nc-modal-custom-input" value={editCustomer.email} onChange={(e) => handleEditChange(e)} />
                    {isValidEmail === false && (
                      <div className="validity-error">
                        Invalid email format.
                      </div>
                    )}
                  </Col>
                </Row> */}
                <Row className="nc-modal-custom-row">
                  <Col>Address</Col>
                  <Col xs={1}>:</Col>
                  <Col xs={7}><Form.Control type="text" name="address" placeholder="Enter address" className="nc-modal-custom-input" value={editCustomer.address} onChange={(e) => handleEditChange(e)} /></Col>
                </Row>
                <Row className="nc-modal-custom-row">
                  <Col>Remarks</Col>
                  <Col xs={1}>:</Col>
                  <Col xs={7}><Form.Control type="text" name="remarks" placeholder="Enter remarks" className="nc-modal-custom-input" value={editCustomer.remarks} onChange={(e) => handleEditChange(e)} /></Col>
                </Row>
              </Container>
            </Modal.Body>
            <Modal.Footer className="border-0 px-5">
              {click === true && (
                <div className="d-flex justify-content-center">
                  <div className="btn-conf-wrapper ms-auto">
                    <Button type="submit" className="px-5 pb-1 mb-2">
                      <ReactLoading type="spinningBubbles" color="#d4271c" height={25} width={20} />
                    </Button>
                  </div>
                </div>
              )}
              {click === false && (
                <div className="d-flex flex-row justify-content-between">
                  <div className="btn-conf-wrapper ms-auto">
                    <Button type="submit" className="px-5 pb-1 mb-2" onClick={() => submitEdit()}>save</Button>
                  </div>
                </div>
              )}
            </Modal.Footer>
          </Modal>

          <Modal show={showAvail} onHide={handleCloseAvail} size="lg" className="custom-modal-style" centered>
            <Modal.Header className="custom-modal-header d-flex justify-content-end" closeButton></Modal.Header>
            <Modal.Body className="px-5 py-1">
              <h1 className="custom-modal-body-title">Avail Service</h1>
              <Container>
                <Row className="nc-modal-custom-row">
                  <Col>Date Availed</Col>
                  <Col xs={1}>:</Col>
                  <Col xs={7}>
                    <Form.Control type="date" name="serviced_on" value={availServices.serviced_on} className="nc-modal-custom-input pe-2" onChange={(e) => handleAvailChange(e)} />
                    {hasAvailDate === false && (
                      <div className="validity-error">
                        Date availed is required*
                      </div>
                    )}
                  </Col>
                </Row>
                <Row className="nc-modal-custom-row">
                  <Col>Serviced By</Col>
                  <Col xs={1}>:</Col>
                  <Col xs={7}>
                    <Form.Select className="nc-modal-custom-input" name="serviced_by" value={availServices.serviced_by} onChange={(e) => handleAvailChange(e)}>
                      <option value="" hidden>--Select--</option>
                      {
                        employees.map((employee) => {
                          return (<option key={employee.id} value={employee.id}>{employee.name}</option>)
                        })
                      }
                    </Form.Select>
                    {hasServiceBy === false && (
                      <div className="validity-error">
                        Serviced by is required*
                      </div>
                    )}
                  </Col>
                </Row>
                <Row className="nc-modal-custom-row">
                  <Col>Remarks</Col>
                </Row>
                <Row className="nc-modal-custom-row">
                  <Col><Form.Control as="textarea" className="nc-modal-custom-input" rows={5} name="remarks" onChange={(e) => handleAvailChange(e)} /></Col>
                </Row>
              </Container>
            </Modal.Body>
            <Modal.Footer className="border-0 px-5">
              <div className="d-flex flex-row justify-content-between">
                <div className="btn-conf-wrapper ms-auto">
                  {click === true && (
                    <div className="d-flex justify-content-center">
                      <div className="btn-conf-wrapper ms-auto">
                        <Button type="submit" className="px-5 pb-1 mb-2">
                          <ReactLoading type="spinningBubbles" color="#d4271c" height={25} width={20} />
                        </Button>
                      </div>
                    </div>
                  )}
                  {click === false && (
                    <Button type="submit" className="px-5 pb-1 mb-2" onClick={() => avail()}>save</Button>
                  )}
                </div>
              </div>
            </Modal.Footer>
          </Modal>

          {/* DELETE MODAL */}
          <Modal show={deleteCustomer} onHide={handleCloseDelete} size="lg" className="custom-modal-style" centered>
            <Modal.Header className="custom-modal-header del-prompt-header-custom"></Modal.Header>
            <Modal.Body className="px-5">
              <Container className="d-flex flex-row">
                <FontAwesomeIcon
                  className="del-prompt-icon"
                  icon={'trash-alt'}
                  aria-hidden="true"
                  alt={'trash-alt'}
                  fixedWidth
                />
                <div className="ms-2">
                  <p className="del-prompt-par">Are you sure you want to delete this customer?</p>
                  <p className="del-prompt-subpar">This customer will be deleted immediately.<br />You can't undo this action.</p>
                </div>
              </Container>

            </Modal.Body>
            <Modal.Footer className="border-0 px-5">
              <div className="cancel-btn-conf-wrapper">
                <Button type="button" className="px-3" onClick={handleCloseDelete}>cancel</Button>
              </div>
              <div className="btn-conf-wrapper">
                <Button type="submit" className="px-3" onClick={() => delCustomer()}>delete</Button>
              </div>
            </Modal.Footer>
          </Modal>

          {/* avail gift certificate */}
          <Modal show={showAvailGC} onHide={handleCloseAvailGC} size="lg" className="custom-modal-style" centered>
            <Modal.Header className="custom-modal-header d-flex justify-content-between" closeButton>
              <h1 className="custom-modal-body-title me-0 ms-3">Avail Gift Certificate</h1>
            </Modal.Header>
            <Modal.Body className="px-5 py-1">
              <Container>
                <Row className="nc-modal-custom-row gift-cert-form">
                  <Col>Availed By</Col>
                  <Col xs={1}>:</Col>
                  <Col xs={7}>
                    <Select
                      name="customer_id"
                      className='react-select-container-modal'
                      classNamePrefix="dropdown-modal"
                      placeholder="Select Customer..."
                      value={customersOption.filter(info => info.value === gc.customer_id)}
                      options={customersOption}
                      onChange={(e) => handleAvailChangeGC("customer_id", e.value)}
                    />
                    {!gc.customer_id && (
                      <div className="validity-error">
                        Availed By is required*
                      </div>
                    )}
                  </Col>
                </Row>
                <Row className="nc-modal-custom-row gift-cert-form">
                  <Col>Commissioned By</Col>
                  <Col xs={1}>:</Col>
                  <Col xs={7}>
                    <Select
                      name="commissioned_by"
                      className='react-select-container-modal'
                      classNamePrefix="dropdown-modal"
                      placeholder="Select Employee..."
                      value={employeesOption.filter(info => info.value === gc.commissioned_by)}
                      options={employeesOption}
                      onChange={(e) => handleAvailChangeGC("commissioned_by", e.value)}
                    />
                    {!gc.commissioned_by && (
                      <div className="validity-error">
                        Commissioned by is required*
                      </div>
                    )}
                  </Col>
                </Row>
                <Row className="nc-modal-custom-row">
                  <Col>Remarks</Col>
                </Row>
                <Row className="nc-modal-custom-row">
                  <Col><Form.Control as="textarea" className="nc-modal-custom-input" rows={5} name="remarks" onChange={(e) => handleAvailChangeGC("remarks", e.target.value)} /></Col>
                </Row>
              </Container>
            </Modal.Body>
            <Modal.Footer className="border-0 px-5">
              <div className="d-flex flex-row justify-content-between">
                <div className="btn-conf-wrapper ms-auto">
                  <Button type="submit" className="px-5 pb-1 mb-2" onClick={() => availGC()}>save</Button>
                </div>
              </div>
            </Modal.Footer>
          </Modal>

          <PaymentModal
            showVar={showPayment}
            showVarSetter={handleClosePayment}
            cartPayment={[{ id: 7, name: 'underarm whitening', price: 2500 }]} // sample temporary
            modalData={[getBookingServices(), getBookingPackages()]}
            paymentType='customer'
            handlePaymentChange={handlePaymentChange}
            type={paymentDeets.payment_type}
            payDetails={paymentDeets}
            selectedBooking={selectedPurchase.booking_id}
            existingSummary={existingSummary}
            balance={selectedPurchase.booking_balance}
            subTotal={subTotal}
            discount={discount}
            grandTotal={grandTotal}
          />



        </div>
      </div>
    </>
  ) : (<div className="d-flex justify-content-center loader-cont">
    <div className="row">
      <ReactLoading type="spinningBubbles" color="#d4271c" height={150} width={150} />
    </div>
  </div>);
}