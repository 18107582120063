/* This is where the temp data for the tables are placed */

/* Customer */

const customer = [
    {
        id: 1000, first_name: "Juan", last_name: "dela Cruz", middle_name: "Ramos",
        suffix: "III", birthdate: "2001-01-01", contact_no: "09123456789",
        email: "jrdelacruz@test.com", address: "Purok Santan, Babag, Cebu City, Cebu 6000, Philippines",
        gender: "male", age: 21, remarks: "this is a test remark", added_by: "L. Gucor",
        added_on: Date()
    },
    {
        id: 1001, first_name: "Juana", last_name: "Ana", middle_name: "Santa Cruz",
        suffix: "III", birthdate: "2001-01-01", contact_no: "09123456789",
        email: "jrdelacruz@test.com", address: "Purok Santan, Babag, Cebu City, Cebu 6000, Philippines",
        gender: "male", age: 21, remarks: "this is a test remark", added_by: "L. Gucor",
        added_on: Date()
    },
    {
        id: 1002, first_name: "Ariana", last_name: "Grande", middle_name: "Tan",
        suffix: "III", birthdate: "2001-01-01", contact_no: "09123456789",
        email: "jrdelacruz@test.com", address: "Purok Santan, Babag, Cebu City, Cebu 6000, Philippines",
        gender: "male", age: 21, remarks: "this is a test remark", added_by: "L. Gucor",
        added_on: Date()
    }

]

const employee = [
    {
        id: 1, first_name: "Juan", last_name: "dela Cruz", middle_name: "Ramos",
        nickname: "One", role: "dermatologist", date_hired: "2001-01-01", gender: "male",
        address: "Purok Santan, Babag, Cebu City, Cebu 6000, Philippines", contact_no: "09123456789",
        salary: 15000.00, tin: "1", sss: "1", phic: "1", hdmf: "1"
    },
    {
        id: 2, first_name: "Juan", last_name: "dela Cruz", middle_name: "Ramos",
        nickname: "One", role: "dermatologist", date_hired: "2001-01-01", gender: "male",
        address: "Purok Santan, Babag, Cebu City, Cebu 6000, Philippines", contact_no: "09123456789",
        salary: 15000.00, tin: "1", sss: "1", phic: "1", hdmf: "1"
    },
    {
        id: 3, first_name: "Juan", last_name: "dela Cruz", middle_name: "Ramos",
        nickname: "One", role: "dermatologist", date_hired: "2001-01-01", gender: "male",
        address: "Purok Santan, Babag, Cebu City, Cebu 6000, Philippines", contact_no: "09123456789",
        salary: 15000.00, tin: "1", sss: "1", phic: "1", hdmf: "1"
    }
]

const item = [
    { id: 1, item_name: "facial serum", imelda: 42, marasbas: 42, ormoc: 42, price: 1234 },
    { id: 2, item_name: "facial wash", imelda: 43, marasbas: 42, ormoc: 42, price: 1256 },
    { id: 3, item_name: "lotion", imelda: 45, marasbas: 42, ormoc: 42, price: 34 },
    { id: 4, item_name: "moisturizer", imelda: 48, marasbas: 42, ormoc: 42, price: 89 },
    { id: 5, item_name: "sunblock", imelda: 41, marasbas: 42, ormoc: 42, price: 87 },
    { id: 6, item_name: "facial serum", imelda: 49, marasbas: 42, ormoc: 42, price: 1233 },
    { id: 7, item_name: "test", imelda: 44, marasbas: 42, ormoc: 42, price: 100 },
    { id: 8, item_name: "item8", imelda: 43, marasbas: 42, ormoc: 42, price: 125 },
]

const sample_service = [
    { id: 1, service_name: "Glutathione Drip", price: 1000 },
    { id: 2, service_name: "Facial Cream", price: 1000 },
    { id: 3, service_name: "Lotion", price: 1000 },
    { id: 4, service_name: "Sunscreen", price: 1000 },
    { id: 5, service_name: "Sample Service", price: 1000 },
]

const sample_gift_certificates_available = [
    { id: 1, date_added: "03-20-22", code: 1111, description: "Facial  Serum", branch: "Imelda", amount: 1000 },
    { id: 2, date_added: "03-20-22", code: 1111, description: "Facial Wash", branch: "R Marasbas", amount: 2300 },
    { id: 3, date_added: "03-20-22", code: 1111, description: "Lotion", branch: "R Ormoc", amount: 1200 },
    { id: 4, date_added: "03-20-22", code: 1111, description: "Moisturizer", branch: "Imelda", amount: 1500 },
    { id: 5, date_added: "03-20-22", code: 1111, description: "SunBlock", branch: "R Marasbas", amount: 4300 }
]

const sample_gift_certificates_purchased = [
    { id: 1, date_purchased: "03-20-22", code: 1111, description: "Facial  Serum", branch: "Imelda", customer: "Lovely" },
    { id: 2, date_purchased: "03-20-22", code: 1111, description: "Facial Wash", branch: "R Marasbas", customer: "Lovely" },
    { id: 3, date_purchased: "03-20-22", code: 1111, description: "Lotion", branch: "R Ormoc", customer: "Lovely" },
    { id: 4, date_purchased: "03-20-22", code: 1111, description: "Moisturizer", branch: "Imelda", customer: "Lovely" },
    { id: 5, date_purchased: "03-20-22", code: 1111, description: "SunBlock", branch: "R Marasbas", customer: "Lovely" }
]

const sample_gift_certificates_used = [
    { id: 1, date_used: "03-20-22", code: 1111, description: "Facial  Serum", branch: "Imelda", derm_tech: "Lovely", customer: "Lovely" },
    { id: 2, date_used: "03-20-22", code: 1111, description: "Facial Wash", branch: "R Marasbas", derm_tech: "Lovely", customer: "Lovely" },
    { id: 3, date_used: "03-20-22", code: 1111, description: "Lotion", branch: "R Ormoc", derm_tech: "Lovely", customer: "Lovely" },
    { id: 4, date_used: "03-20-22", code: 1111, description: "Moisturizer", branch: "Imelda", derm_tech: "Lovely", customer: "Lovely" },
    { id: 5, date_used: "03-20-22", code: 1111, description: "SunBlock", branch: "R Marasbas", derm_tech: "Lovely", customer: "Lovely" }
]


const sample_package = [
    { id: 1, package_name: "package-1", price: 1000 },
    { id: 2, package_name: "package-2", price: 1000 },
    { id: 3, package_name: "package-3", price: 1000 },
    { id: 4, package_name: "package-4", price: 1000 },
    { id: 5, package_name: "package-5", price: 1000 },
]

const sample_expenses = [
    { id: 1, date_added: "03-20-22", branch: "Imelda", item: "Facial  Serum", quantity: 2, price: 1000, grand_total: 1000, added_by: "Lovely" },
    { id: 2, date_added: "03-20-22", branch: "R Marasbas", item: "Facial Wash", quantity: 2, price: 2300, grand_total: 2300, added_by: "Lovely" },
    { id: 3, date_added: "03-20-22", branch: "R Ormoc", item: "Lotion", quantity: 2, price: 1200, grand_total: 1200, added_by: "Lovely" },
    { id: 4, date_added: "03-20-22", branch: "Imelda", item: "Moisturizer", quantity: 2, price: 1500, grand_total: 1500, added_by: "Lovely" },
    { id: 5, date_added: "03-20-22", branch: "R Marasbas", item: "SunBlock", quantity: 2, price: 4300, grand_total: 4300, added_by: "Lovely" }
]

const sample_giftcert = [
    { id: 1, giftcert_name: "gc-1", details: "detail_text", price: 260 },
    { id: 2, giftcert_name: "gc-2", details: "detail_text", price: 280 },
    { id: 3, giftcert_name: "gc-3", details: "detail_text", price: 290 },
    { id: 4, giftcert_name: "gc-4", details: "detail_text", price: 220 },
    { id: 5, giftcert_name: "gc-5", details: "detail_text", price: 250 },
]

const available_services = [
    { id: 100, date_purchased: "2001-01-01", branch: "Imelda", name: "korean drip", service: "korean snow drip", is_free: true },
    { id: 101, date_purchased: "2001-01-01", branch: "R. Ormoc", name: "korean drip", service: "korean snow drip", is_free: false },
    { id: 102, date_purchased: "2001-01-01", branch: "Imelda", name: "korean drip", service: "korean snow drip", is_free: false },
    { id: 103, date_purchased: "2001-01-01", branch: "R. Ormoc", name: "korean drip", service: "korean snow drip", is_free: true },
    { id: 103, date_purchased: "2001-01-01", branch: "R. Ormoc", name: "korean drip", service: "korean snow drip", is_free: true },
    { id: 103, date_purchased: "2001-01-01", branch: "R. Ormoc", name: "korean drip", service: "korean snow drip", is_free: true }
]

const purchases = [
    { id: 200, date_purchased: "2001-01-01", total_amount: 1500.00, balance: 1250.00, derm_tech: "L. Gucor" },
    { id: 201, date_purchased: "2001-01-01", total_amount: 1500.00, balance: 1250.00, derm_tech: "L. Gucor" },
    { id: 202, date_purchased: "2001-01-01", total_amount: 1500.00, balance: 1250.00, derm_tech: "L. Gucor" },
    { id: 203, date_purchased: "2001-01-01", total_amount: 1500.00, balance: 1250.00, derm_tech: "L. Gucor" },
    { id: 204, date_purchased: "2001-01-01", total_amount: 1500.00, balance: 1250.00, derm_tech: "L. Gucor" },
]

const customer_booking = [
    { id: 200, date_booked: "2001-01-01", branch: "Imelda", derm_tech: "Juan" },
    { id: 201, date_booked: "2001-01-01", branch: "R Marasbas", derm_tech: "Tores" },
    { id: 202, date_booked: "2001-01-01", branch: "R Ormoc", derm_tech: "Dela Cruz" },
    { id: 203, date_booked: "2001-01-01", branch: "Imelda", derm_tech: "Tores" },
    { id: 204, date_booked: "2001-01-01", branch: "R Marasbas", derm_tech: "Juan" },
]

const customer_balance = [
    { date_purchased: "2001-01-01", branch: "Imelda", customer_name: "Juan", amount: "1000", total_balance: "1000" },
    { date_purchased: "2001-01-01", branch: "R Marasbas", customer_name: "Tores", amount: "1000", total_balance: "1000" },
    { date_purchased: "2001-01-01", branch: "R Ormoc", customer_name: "Dela Cruz", amount: "1000", total_balance: "1000" },
    { date_purchased: "2001-01-01", branch: "Imelda", customer_name: "Tores", amount: "1000", total_balance: "1000" },
    { date_purchased: "2001-01-01", branch: "R Marasbas", customer_name: "Juan", amount: "1000", total_balance: "1000" },
]

const service_purchased = [
    { id: 200, date_purchased: "2001-01-01", branch: "Imelda", name: "Korean Dip", amount: 1800.00, balance: 1250.00, derm_tech: "L. Gucor" },
    { id: 201, date_purchased: "2001-01-01", branch: "R. Marasbaras", name: "Korean Dip", amount: 1900.00, balance: 1250.00, derm_tech: "L. Gucor" },
    { id: 202, date_purchased: "2001-01-01", branch: "R. Ormoc", name: "Korean Dip", amount: 1200.00, balance: 1250.00, derm_tech: "L. Gucor" },
    { id: 203, date_purchased: "2001-01-01", branch: "Imelda", name: "Korean Dip", amount: 1500.00, balance: 1250.00, derm_tech: "L. Gucor" },
    { id: 204, date_purchased: "2001-01-01", branch: "R. Marasbaras", name: "Korean Dip", amount: 1500.00, balance: 1250.00, derm_tech: "L. Gucor" },
]

const package_purchased = [
    { id: 200, date_purchased: "2001-01-01", branch: "Imelda", name: "Korean Dip", amount: 1600.00, balance: 1250.00, derm_tech: "L. Gucor" },
    { id: 201, date_purchased: "2001-01-01", branch: "R. Marasbaras", name: "Korean Dip", amount: 1500.00, balance: 0.00, derm_tech: "L. Gucor" },
    { id: 202, date_purchased: "2001-01-01", branch: "R. Ormoc", name: "Korean Dip", amount: 1500.00, balance: 1250.00, derm_tech: "L. Gucor" },
    { id: 203, date_purchased: "2001-01-01", branch: "Imelda", name: "Korean Dip", amount: 1500.00, balance: 0.00, derm_tech: "L. Gucor" },
    { id: 204, date_purchased: "2001-01-01", branch: "R. Marasbaras", name: "Korean Dip", amount: 1500.00, balance: 1250.00, derm_tech: "L. Gucor" },
]

const item_purchased = [
    { id: 200, date_purchased: "2001-01-01", branch: "Imelda", name: "Korean Dip", amount: 1700.00, balance: 1250.00, derm_tech: "L. Gucor" },
    { id: 201, date_purchased: "2001-01-01", branch: "R. Marasbaras", name: "Korean Dip", amount: 1500.00, balance: 1250.00, derm_tech: "L. Gucor" },
    { id: 202, date_purchased: "2001-01-01", branch: "R. Ormoc", name: "Korean Dip", amount: 1500.00, balance: 1250.00, derm_tech: "L. Gucor" },
    { id: 203, date_purchased: "2001-01-01", branch: "Imelda", name: "Korean Dip", amount: 1500.00, balance: 1250.00, derm_tech: "L. Gucor" },
    { id: 204, date_purchased: "2001-01-01", branch: "R. Marasbaras", name: "Korean Dip", amount: 1500.00, balance: 1250.00, derm_tech: "L. Gucor" },
]

const giftcert_purchased = [
    { id: 200, date_purchased: "2001-01-01", branch: "Imelda", name: "Gift 001", amount: 200.00, balance: 100, derm_tech: "L. Gucor" },
    { id: 201, date_purchased: "2001-01-01", branch: "R. Marasbaras", name: "Gift 002", amount: 300.00, balance: 100, derm_tech: "L. Gucor" },
    { id: 202, date_purchased: "2001-01-01", branch: "R. Ormoc", name: "Gift 003", amount: 2500.00, balance: 0, derm_tech: "L. Gucor" },
    { id: 203, date_purchased: "2001-01-01", branch: "Imelda", name: "Gift 004", amount: 2500.00, balance: 100, derm_tech: "L. Gucor" },
    { id: 204, date_purchased: "2001-01-01", branch: "R. Marasbaras", name: "Gift 005", amount: 2500.00, balance: 0, derm_tech: "L. Gucor" },
]


const history = [
    { id: 301, date_purchased: "2001-01-01", branch: "R. Marasbaras", service: "korean snow drip", derm_tech: "L. Gucor", date_availed: "2001-01-01" },
    { id: 302, date_purchased: "2001-01-01", branch: "Imelda", service: "underarm whitening", derm_tech: "L. Gucor", date_availed: "2001-01-01" },
    { id: 303, date_purchased: "2001-01-01", branch: "Imelda", service: "microneedling", derm_tech: "L. Gucor", date_availed: "2001-01-01" },
    { id: 304, date_purchased: "2001-01-01", branch: "Imelda", service: "eyebag removal", derm_tech: "L. Gucor", date_availed: "2001-01-01" },
    { id: 305, date_purchased: "2001-01-01", branch: "R. Ormoc", service: "4d body contour", derm_tech: "L. Gucor", date_availed: "2001-01-01" }
]

const booking_services = [
    { id: 1, name: 'korean snow drip (glutathione)', price: 700 },
    { id: 2, name: 'mega whitening cinderella drip', price: 1300 },
    { id: 3, name: 'meso lipo (arms, legs, stomach)', price: 950 },
    { id: 4, name: 'meso lipo (face v-shape)', price: 1350 },
    { id: 5, name: 'eyebag removal', price: 1000 },
    { id: 6, name: 'elixir whole body sculpting', price: 1000 },
    { id: 7, name: 'underarm whitening', price: 2500 },
    { id: 8, name: 'stem cell drip with glutathione', price: 1500 },
    { id: 9, name: '4d body contour', price: 1400 },
    { id: 10, name: 'elixir facial (basic, acne, 3d)', price: 750 },
    { id: 11, name: 'korean glass skin facial', price: 1000 },
    { id: 12, name: 'microneedling', price: 1100 },
]

const booking_packages = [
    { id: 1, name: 'valentines package', price: 4000 },
    { id: 2, name: 'christmas package', price: 5000 },
    { id: 3, name: 'birthday package', price: 2500 },
    { id: 4, name: 'labor day package', price: 3000 },
    { id: 5, name: 'halloween package', price: 4500 },
    { id: 6, name: 'back to school package', price: 3500 },
]

const item_history = [
    { id: 1, date: "2001-01-01", product_name: "Facial Wash", type: "Stock In", branch: "Imelda", quantity: 10, customer: "Lovely", added_by: "Gioly", remarks: "test" },
    { id: 2, date: "2001-01-01", product_name: "Facial Serum", type: "Purchased", branch: "Imelda", quantity: 10, customer: "Lovely", added_by: "Gioly", remarks: "test" },
    { id: 3, date: "2001-01-01", product_name: "Lotion", type: "Stock In", branch: "Imelda", quantity: 10, customer: "Lovely", added_by: "Gioly", remarks: "test" },
    { id: 4, date: "2001-01-01", product_name: "Moisturizer", type: "Stock In", branch: "Imelda", quantity: 10, customer: "Lovely", added_by: "Gioly", remarks: "test" },
    { id: 5, date: "2001-01-01", product_name: "Sunblock", type: "Stock In", branch: "Imelda", quantity: 10, customer: "Lovely", added_by: "Gioly", remarks: "test" },
    { id: 6, date: "2001-01-01", product_name: "Facial", type: "Purchased", branch: "Imelda", quantity: 10, customer: "Lovely", added_by: "Gioly", remarks: "test" },
    { id: 7, date: "2001-01-01", product_name: "Facial", type: "Purchased", branch: "Imelda", quantity: 10, customer: "Lovely", added_by: "Gioly", remarks: "Stock In" },
    { id: 8, date: "2001-01-01", product_name: "Facial", type: "Stock In", branch: "Imelda", quantity: 10, customer: "Lovely", added_by: "Gioly", remarks: "Stock In" },
    { id: 9, date: "2001-01-01", product_name: "Facial", type: "Stock In", branch: "Imelda", quantity: 10, customer: "Lovely", added_by: "Gioly", remarks: "Stock In" },
    { id: 10, date: "2001-01-01", product_name: "Facial", type: "Purchased", branch: "Imelda", quantity: 10, customer: "Lovely", added_by: "Gioly", remarks: "Stock In" },
    { id: 11, date: "2001-01-01", product_name: "Facial", type: "Stock In", branch: "Imelda", quantity: 10, customer: "Lovely", added_by: "Gioly", remarks: "test" },
    { id: 12, date: "2001-01-01", product_name: "Facial", type: "Purchased", branch: "Imelda", quantity: 10, customer: "Lovely", added_by: "Gioly", remarks: "Stock In" },
    { id: 13, date: "2001-01-01", product_name: "Facial", type: "Stock In", branch: "Imelda", quantity: 10, customer: "Lovely", added_by: "Gioly", remarks: "test" },
    { id: 14, date: "2001-01-01", product_name: "Facial", type: "Stock In", branch: "Imelda", quantity: 10, customer: "Lovely", added_by: "Gioly", remarks: "Stock In" }
]

const sample_commission_report = [
    { id: 1, date: "03-20-22", type: "Sales", particulars: "Item", name: "pimple soap", transaction_id: 1111, commission: 50 },
    { id: 2, date: "03-20-22", type: "Sales", particulars: "Item", name: "diamond peel", transaction_id: 1111, commission: 50 },
    { id: 3, date: "03-20-22", type: "Sales", particulars: "Item", name: "pimple soap", transaction_id: 1111, commission: 50 },
    { id: 4, date: "03-20-22", type: "Sales", particulars: "Item", name: "diamond peel", transaction_id: 1111, commission: 50 },
    { id: 5, date: "03-20-22", type: "Service", particulars: "Service", name: "exilift", transaction_id: 1111, commission: 50 },
    { id: 6, date: "03-20-22", type: "Service", particulars: "Service", name: "pimple soap", transaction_id: 1111, commission: 50 },
    { id: 7, date: "03-20-22", type: "Service", particulars: "Service", name: "gluta", transaction_id: 1111, commission: 50 },
]

const sample_free_service_report = [
    { id: 1, customer_name: "Lovely" },
    { id: 2, customer_name: "Lovely" },
    { id: 3, customer_name: "Lovely" },
    { id: 4, customer_name: "Lovely" },
    { id: 5, customer_name: "Lovely" },
    { id: 6, customer_name: "Lovely" },
]

const sample_balance_report = [
    { id: 1, customer_name: "Abeyed", total_balance: 1300.50 },
    { id: 2, customer_name: "Barugu", total_balance: 200.00 },
    { id: 3, customer_name: "Birna", total_balance: 800.00 },
    { id: 4, customer_name: "Canoyin", total_balance: 400.00 }
]

const sample_customer_report = [
    { id: 1, date_purchased: "03-23-21", branch: "Imelda", name: "Diode Ice Laser", amount: 2495.00, balance: 800 },
    { id: 1, date_purchased: "01-02-21", branch: "R Ormoc", name: "Diode Ice Laser", amount: 7495.00, balance: 500 }
]

/*  
    branchID 1 = Imelda Reternos
    branchID 2 = Robinsons Marasbas
    branchID 3 = Robinsons Place Ormoc
*/
const sample_daily_sales_report = [
    { ID: 1, DATE: "03-20-22", CUSTOMER: "Abeyed", GRAND_TOTAL: 200, PAID_AMOUNT: 100, BALANCE: 100 },
    { ID: 1, DATE: "03-20-22", CUSTOMER: "Abeyed", GRAND_TOTAL: 200, PAID_AMOUNT: 100, BALANCE: 100 },
    { ID: 1, DATE: "03-20-22", CUSTOMER: "Abeyed", GRAND_TOTAL: 200, PAID_AMOUNT: 100, BALANCE: 100 },
    { ID: 1, DATE: "03-20-22", CUSTOMER: "Abeyed", GRAND_TOTAL: 200, PAID_AMOUNT: 100, BALANCE: 100 },
    { ID: 1, DATE: "03-20-22", CUSTOMER: "Abeyed", GRAND_TOTAL: 200, PAID_AMOUNT: 100, BALANCE: 100 },
    { ID: 1, DATE: "03-20-22", CUSTOMER: "Abeyed", GRAND_TOTAL: 200, PAID_AMOUNT: 100, BALANCE: 100 },
    { ID: 1, DATE: "03-20-22", CUSTOMER: "Abeyed", GRAND_TOTAL: 200, PAID_AMOUNT: 100, BALANCE: 100 },
    { ID: 1, DATE: "03-20-22", CUSTOMER: "Abeyed", GRAND_TOTAL: 200, PAID_AMOUNT: 100, BALANCE: 100 },
    { ID: 1, DATE: "03-20-22", CUSTOMER: "Abeyed", GRAND_TOTAL: 200, PAID_AMOUNT: 100, BALANCE: 100 },
]

const sample_daily_products_purchased_report = [
    { id: 1, date: "03-20-22", branch: "Imelda", customer_name: "Lovely", product: "lotion", quantity: 1, amount: 200, derm_tech: "Lovely" },
    { id: 2, date: "03-20-22", branch: "R. Ormoc", customer_name: "Lovely", product: "glutha", quantity: 1, amount: 990, derm_tech: "Lovely" },
    { id: 3, date: "03-20-22", branch: "Imelda", customer_name: "Lovely", product: "lotion", quantity: 1, amount: 200, derm_tech: "Lovely" },
    { id: 4, date: "03-20-22", branch: "R. Marasbas", customer_name: "Lovely", product: "lotion", quantity: 1, amount: 200, derm_tech: "Lovely" },
    { id: 5, date: "03-20-22", branch: "Imelda", customer_name: "Lovely", product: "glutha", quantity: 1, amount: 990, derm_tech: "Lovely" },
]

const sample_daily_services_rendered = [
    { id: 1, date: "03-20-22", branch: "Imelda", customer_name: "Lovely", service: "Glutha", remarks: "Done", derm_tech: "Lovely" },
    { id: 2, date: "03-20-22", branch: "R Marasbaras", customer_name: "Lovely", service: "Glutha", remarks: "Done", derm_tech: "Lovely" },
    { id: 3, date: "03-20-22", branch: "R Ormoc", customer_name: "Lovely", service: "Glutha", remarks: "Done", derm_tech: "Lovely" },
    { id: 4, date: "03-20-22", branch: "Imelda", customer_name: "Lovely", service: "Glutha", remarks: "Done", derm_tech: "Lovely" },
    { id: 5, date: "03-20-22", branch: "R Marasbas", customer_name: "Lovely", service: "Glutha", remarks: "Done", derm_tech: "Lovely" }
]

const sample_free_services_rendered = [
    { id: 1, date: "03-20-22", branch: "Imelda", customer_name: "Lovely", name: "Basic Facial", derm_tech: "Lovely" },
    { id: 2, date: "03-20-22", branch: "R Marasbaras", customer_name: "Lovely", name: "Basic Facial", derm_tech: "Lovely" },
    { id: 3, date: "03-20-22", branch: "R Ormoc", customer_name: "Lovely", name: "Basic Facial", derm_tech: "Lovely" },
    { id: 4, date: "03-20-22", branch: "Imelda", customer_name: "Lovely", name: "Basic Facial", derm_tech: "Lovely" },
    { id: 5, date: "03-20-22", branch: "R Marasbas", customer_name: "Lovely", name: "Basic Facial", derm_tech: "Lovely" }
]


const sample_summary = [
    { id: 1, item: "Glutha", amount: 1000 },
    { id: 2, item: "Lotion", amount: 250 },
    { id: 3, item: "Korean Snow Drip", amount: 1500 },
    { id: 4, item: "Glutha", amount: 1000 },
    { id: 5, item: "Korean Snow Drip", amount: 1500 },
    { id: 6, item: "Lotion", amount: 250 },
    { id: 7, item: "Glutha", amount: 1000 },
    { id: 8, item: "Korean Snow Drip", amount: 1500 },
    { id: 9, item: "Lotion", amount: 250 },
    { id: 10, item: "CH Package", amount: 2500 },
]

const transactions_receipt = [
    { id: 1, date: "03-20-22", branch: "Imelda", customer_name: "Lovely", transaction_id: "001", grand_total: "389.00", derm_tech: "Lovely" },
    { id: 2, date: "03-20-22", branch: "R Marasbaras", customer_name: "Lovely", transaction_id: "002", grand_total: "2389.00", derm_tech: "Lovely" },
    { id: 3, date: "03-20-22", branch: "R Ormoc", customer_name: "Lovely", transaction_id: "003", grand_total: "89.00", derm_tech: "Lovely" },
    { id: 4, date: "03-20-22", branch: "Imelda", customer_name: "Lovely", transaction_id: "004", grand_total: "180.00", derm_tech: "Lovely" },
    { id: 5, date: "03-20-22", branch: "R Marasbas", customer_name: "Lovely", transaction_id: "005", grand_total: "3089.00", derm_tech: "Lovely" }
];




// ==================================================

export const getCustomerBooking = () => {
    return customer_booking;
}

export const getCustomerBalance = () => {
    return customer_balance;
}

export const getSampleCustomerReport = () => {
    return sample_customer_report;
}

export const getSampleBalanceReport = () => {
    return sample_balance_report;
}

export const getItemHistory = () => {
    return item_history;
}

export const getDailyProductsPurchasedReport = () => {
    return sample_daily_products_purchased_report;
}

export const getDailyServicesRendered = () => {
    return sample_daily_services_rendered;
}

export const getFreeServicesRendered = () => {
    return sample_free_services_rendered;
}

export const getTransactionsReceipt = () => {
    return transactions_receipt;
}

export const getDailySalesReport = () => {
    return sample_daily_sales_report;
}

export const getCommissionReport = () => {
    return sample_commission_report;
}

export const getFreeServiceReport = () => {
    return sample_free_service_report;
}

export const getBookingServices = () => {
    return booking_services;
}

export const getBookingPackages = () => {
    return booking_packages;
}

export const getSampleCustomers = () => {
    return customer;
};

export const getSampleEmployees = () => {
    return employee;
};

export const getSampleInventory = () => {
    return item;
}

export const getSampleService = () => {
    return sample_service;
}

export const getSamplePackage = () => {
    return sample_package;
}

export const getSampleGiftCertificatesAvailable = () => {
    return sample_gift_certificates_available;
}

export const getSampleGiftCertificatesPurchased = () => {
    return sample_gift_certificates_purchased;
}

export const getSampleGiftCertificatesUsed = () => {
    return sample_gift_certificates_used;
}

export const getSampleExpenses = () => {
    return sample_expenses;
}

export const getSampleGiftCert = () => {
    return sample_giftcert;
}

export const getAvailableServices = () => {
    return available_services;
}

export const getPurchases = () => {
    return purchases;
}

export const getServicePurchases = () => {
    return service_purchased;
}

export const getPackagePurchases = () => {
    return package_purchased;
}

export const getItemPurchases = () => {
    return item_purchased;
}

export const getGiftCertPurchases = () => {
    return giftcert_purchased;
}

export const getHistory = () => {
    return history;
}

export const getSampleSummaryList = () => {
    return sample_summary;
}